<template>
    <v-card tile>
        <!-- Meeting Title Header -->
        <v-toolbar class="flex-grow-0" color="primary" dark dense flat>
            <v-toolbar-title color="white--text" class="flex-shrink-1 mt-2" style="max-width: 70%;"> {{ $t('Attachments') }}</v-toolbar-title>
            <v-spacer />
            <v-btn dark icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
            <div class="dropZone-info" @drag="onChange">
                <span class="fa fa-cloud-upload dropZone-title" />
                <span class="dropZone-title">{{ $t('DragDrop') }}</span>
                <div class="dropZone-upload-limit-info">
                    <div>{{ $t('MaxFileSize') }} 5 {{ $t('MB') }}</div>
                </div>
            </div>
            <input type="file" multiple @change="onChange">
        </div>
        <div class="uploadedFile-info">
            <v-simple-table dense class="mt-4">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>
                                {{ $t('Icon') }}
                            </th>
                            <th>
                                {{ $t('FileName') }}
                            </th>
                            <th>
                                {{ $t('FileSize') }}
                            </th>
                            <th>
                                {{ $t('FileExtension') }}
                            </th>
                            <th>
                                {{ $t('Actions') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in files" :key="index">
                            <td>
                                <v-icon>{{ icons[item.name.split('.').pop()] }}</v-icon>
                            </td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.size }}</td>
                            <td>{{ item.name.split('.').pop() }}</td>
                            <td>
                                <v-icon color="error" @click="removeFile(index)">mdi-trash-can-outline</v-icon>
                                <v-icon v-if="item.id" color="primary" class="ms-2" @click="loadAttachment(item.id)">mdi-eye</v-icon>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <v-card-actions class="justify-center ma-2">
            <v-btn tile :disabled="loading" color="primary" @click="AddAttachments">
                {{ $t('Add') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script lang="ts">
    import RecommendationsService from '@/services/MeetingAgendaRecommendationsService'


    export default ({
        components: {
        },
        directives: {
        },
        filters: {
        },
        props: {
            id: {
                type: Number,
                default: null
            },
        },
        data() {
            return {
                loading: false,
                files: [],
                file: '',
                dragging: false,
                selectedFileId: null,
                icons: {
                    'pdf': 'mdi-file-pdf-box mdi-30px',
                    'docx': 'mdi-file-word mdi-30px',
                    'xlsx': 'mdi-file-excel mdi-30px',
                    'file': 'mdi-file mdi-30px',
                    'png': 'mdi-file-image mdi-30px',
                    'jpeg': 'mdi-file-image mdi-30px',
                    'jpg': 'mdi-file-image mdi-30px',
                    'txt': 'mdi-text-box-outline mdi-30px',
                    'rar': 'mdi-folder-zip mdi-30px',
                    'exe': 'mdi-cog mdi-30px',
                },
            }
        },
        computed: {
        },
        watch: {
        },
        created() {
        },



        methods: {
            AddAttachments() {

                this.loading = true
                let formdata = new FormData()
                formdata.append('recommendationId', JSON.stringify(this.id))

                for (let i = 0; i < this.files.length; i++) {
                    formdata.append(`file${i}`, this.files[i])
                }

                RecommendationsService.addRecommendationAttachment(formdata).then(response => {
                    if (response.data) {
                        this.update()
                        this.close()
                    } else {
                        this.$root.$notification(this.$t(response.message), 'error')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            onChange(e) {
                var files = e.target.files || e.dataTransfer.files
                if (!files.length) {
                    this.dragging = false
                    return
                }
                for (var i = 0; i < files.length; i++) {
                    this.createFile(files[i])
                }

            },



            createFile(file) {
                if (!file.type.match('text.*|image.*|application.*')) {
                    this.$root.$notification(this.$t('InvalidFileType'), 'error')
                    this.dragging = false
                    return
                }

                if (file.size > 5000000) {
                    this.$root.$notification(this.$t('FileSizeExceeded'), 'error')
                    this.dragging = false
                    return
                }

                this.file = file
                this.files.push(file)
                this.dragging = false
            },

            removeFile(index) {
                this.files.splice(index, 1)
            },
            close() {
                this.$emit('close')
            },
            update() {
                this.$emit('update')
            },
        },
    })
</script>

<style>
</style>