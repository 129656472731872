<template>
    <div>
        <v-toolbar color="primary" dense flat>
            <v-toolbar-title class="white--text c-text-h5">
                {{ $t('AddNote') }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn dark icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card class="pa-2 pb-5">
            <div class="title-card">
                {{ $t("AgendaNotes") }}
            </div>
            <v-col>
                <v-row class="ml-5">
                    <v-text-field v-model="text" :rules="cleanRequiredStringRules" dense outlined :label="$t('AgendaNote')" />
                </v-row>
                <v-checkbox v-model="isPublic" dense :label="$t('Public')" />
            </v-col>
            <v-card-actions class="justify-center ">
                <v-btn v-show="!selectedNote" class="mb-3" color="teal" dark @click="AddMeetingAgendaNote">
                    {{ $t('Add') }}
                </v-btn>
                <v-btn v-show="selectedNote" class="mb-3" color="teal" dark @click="EditAgendaNote">
                    {{ $t('Edit') }}
                </v-btn>
                <v-btn v-show="selectedNote" class="mb-3" color="teal" dark @click="CancelEditNote">
                    {{ $t('Cancel') }}
                </v-btn>
            </v-card-actions>
            <v-divider color="teal" class="ma-3" />

            <v-data-table class=" elevated-table" :headers="headers"
                          dense
                          item-key="id"
                          :items="notes"
                          :items-per-page="5">
                <template v-slot:[`item.isPublic`]="{ item }">
                    <v-icon color="teal">
                        {{ item.isPublic ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt| formatDateAr }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon v-if="item.createdBy == loggedInUser?.id"
                            small
                            class="mx-1"
                            :disabled="loading"
                            color="blue"
                            @click="EnableEdit(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon v-if="item.createdBy == loggedInUser?.id"
                            small
                            color="error"
                            :disabled="loading"
                            @click="deleteNote(item.id)">
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>

            <confirm-dialog ref="confirmDialog" />
        </v-card>
    </div>
</template>

<script>
    import MeetingAgendaService from '@/services/MeetingAgendaService'
    import ConfirmDialog from '@/components/controls/ConfirmDialog.vue'
    import { mapGetters } from 'vuex'

    export default ({
        components: {
            ConfirmDialog
        },

        props: {
            id: {
                type: Number,
                default:null
            },
        },
        data() {
            return {
                text: '',
                loading: false,
                isPublic: false,
                cleanRequiredStringRules: [this.$rules.requiredRule, this.$rules.onlyAlphanumericRule],
                headers: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('Text'), value: 'text' },
                    { text: this.$t('CreatedBy'), value: 'createdByName' },
                    { text: this.$t('CreatedDate'), value: 'createdAt' },
                    { text: this.$t('Public'), value: 'isPublic' },
                    { text: this.$t('Actions'), value: 'actions' },
                ],
                notes: [],
                selectedNote: null,
            }
        },
        computed: {
            ...mapGetters('user', ['loggedInUser', 'language']),

        },
        created() {
        },
        mounted() {
            this.loadNotes()
        },
        methods: {
            AddMeetingAgendaNote() {
                this.loading = true
                let formData = {
                    'meetingAgendaId': this.id,
                    'text': this.text,
                    'isPublic': this.isPublic
                }
                MeetingAgendaService.addMeetingAgendaNote(formData).then((res) => {
                    if (res.data) {
                        this.CancelEditNote()
                        this.$root.$notification(this.$t('AddNoteSuccess'), 'success')
                        this.loadNotes()
                    } else {
                        this.$root.$notification(this.$t('HEEEEE'), 'error')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            EnableEdit(note) {
                this.selectedNote = note
                this.text = note.text
                this.isPublic = note.isPublic

            },
            EditAgendaNote() {
                this.loading = true
                let formData = {
                    'id': this.selectedNote.id,
                    'text': this.text,
                    'isPublic': this.isPublic
                }
                MeetingAgendaService.editMeetingAgendaNote(formData).then((res) => {
                    if (res.data) {
                        this.$root.$notification(this.$t('UpdateNoteSuccess'), 'success')
                        this.loadNotes()
                        this.CancelEditNote()
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false

                })
            },
            CancelEditNote() {
                this.selectedNote = null
                this.isPublic = false
                this.text = ''

            },
            loadNotes() {
                MeetingAgendaService.getAgendaNotes(this.id).then((res) => {
                    this.notes = res.data

                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            deleteNote(meetingAgendaNoteId) {
                this.CancelEditNote()
                this.$refs.confirmDialog.open(this.$t('DeleteNote'), this.$t('ConfirmDelete'))
                    .then((confirmed) => {
                        if (confirmed) {
                            MeetingAgendaService.deleteAgendaNote(meetingAgendaNoteId).then((res) => {
                                if (res) {
                                    this.loadNotes()
                                }

                            }).catch(() => {
                                this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                            }).finally(() => {
                                this.loading = false
                            })
                        } else {

                        }
                    })

            },
            close() {
                this.$emit('close')
            }

        },
    })
</script>

<style scoped>
    .elevated-table {
        border: 1px solid #000000; /* Border around the table */
    }
</style>