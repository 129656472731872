import { mainApiAxios as axios } from '@/plugins/axios'


export default {

    getCouncilsAndCoumitteesCounts() {
        return axios.get('dashboard/councils-comittees-count')
    },
    getRecommendationsCounts() {
        return axios.get('dashboard/recommendations-count')
    },
    getTasksCounts() {
        return axios.get('dashboard/tasks-count')
    },
    getUsersCounts() {
        return axios.get('dashboard/users-count')
    },
    getMeetingMinutesCounts() {
        return axios.get('dashboard/meeting-minutes-count')
    },
    getMeetingsCounts() {
        return axios.get('dashboard/meetings-count')
    },
}