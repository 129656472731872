<template>
    <CommonDialog :value="value" :title="$t('ResetPassword')" width="500" :fullscreen="false" show-action-toolbar @input="$emit('input', false)">
<v-form ref="form" v-model="valid" lazy-validation :disabled="loading" @submit.prevent="save">
            <v-container>
                <v-row>
                    <v-col cols="12" class="pb-0 pt-0">
                        <v-text-field v-model="password1"
                                      :type="show ? 'text' : 'password'"
                                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                      :rules="[rules.required, rules.min8, rules.nospaces, rules.complex, rules.nomatch1]"
                                      autocomplete="off"
                                      :label="$t('NewPassword')"
                                      @click:append="show = !show" />
                    </v-col>
                    <v-col cols="12" class="pt-0">
                        <v-text-field v-model="password2"
                                      :type="show ? 'text' : 'password'"
                                      :rules="[rules.required, rules.nomatch2, rules.min8, rules.nospaces, rules.complex]"
                                      autocomplete="off"
                                      :label="$t('ConfirmPassword')" />
                    </v-col>
                    <v-col v-if="showCodeInput" cols="12" class="pt-0">
                        <span class="subtitle-1">{{ $t('code-sent-tomobile') }}</span>
                    </v-col>
                    <v-col v-if="showCodeInput" cols="12" class="pt-0">
                        <v-otp-input v-model="code" length="4" />
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
        <template v-slot:actions>
            <v-btn color="secondary" class="white--text" :disabled="loading" @click="save">{{ $t("Update") }}</v-btn>
            <v-btn color="error" :disabled="loading" @click="$emit('input', false)">{{ $t("Cancel") }}</v-btn>
        </template>
    </CommonDialog>
</template>

<script>


    import CommonDialog from '@/components/controls/CommonDialog.vue'
    import UsersService from '@/services/UsersService'

    export default {

        components: {
            CommonDialog
        },

        props: {
            value: {
                type: Boolean,
                default: null
            },
            userId: {
                type: Number,
                default: null
            }
        },

        data() {
            return {
                password1: null,
                password2: null,
                code: null,
                showCodeInput: false,
                validator: null,
                show: false,
                loading: false,
                valid: false,
                rules: {
                    required: (v) => !!v || this.$t('Required'),
                    min8: (v) => v && v.length >= 8 || this.$t('Atleast8Characters'),
                    nospaces: (v) => v && v.match(/^\S*$/g) != null || this.$t('spaces-not-allowed'),
                    complex: (v) => v && v.match(/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~!@#$%^*\-\_=+[{\]};:,.?])/g) != null || this.$t('ComplexPassword'),
                    nomatch1: (v) => !this.password2 || v == this.password2 || this.$t('PasswordsDontMatch'),
                    nomatch2: (v) => !this.password1 || v == this.password1 || this.$t('PasswordsDontMatch'),
                }
            }
        },

        methods: {

            save() {
                if (this.$refs.form.validate()) {
                    this.loading = true

                    UsersService.resetPassword({
                        userId: this.userId,
                        newPassword: this.password2
                    }).then((res) => {
                        if (res.data) {
                            this.$root.$notification(this.$t('PasswordUpdated'), 'success')
                        } else {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
            },
        }
    }
</script>

<style>
</style>