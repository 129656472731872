import { mainApiAxios as axios } from '@/plugins/axios'

export default {


    loadMeeting(meetingId) {
        return axios.get(`meetings/${meetingId}`)
    },
    loadLiveMeeting(meetingId) {
        return axios.get(`meetings/${meetingId}/live-meeting`)
    },
    listMeetingAttachments(meetingId) {
        return axios.get(`meetings/${meetingId}/meeting-attachments`)
    },
    listDraftMeetings(page, pageSize) {
        return axios.get(`meetings/user/drafts/${page}/${pageSize}`)
    },

    searchUserMeetings(formData, page, pageSize) {
        return axios.post(`meetings/search/${page}/${pageSize}`, formData)
    },
    ListUserMeetingsForCalender(formdata) {
        return axios.post('meetings/calender-search', formdata)
    },
    listMeetingsForAutoComplete(search) {
        return axios.get(`meetings?search=${search}&mode=Autocomplete`)
    },

    listAttendees(meetingId) {
        return axios.get(`meetings/${meetingId}/attendees`)
    },

    listAgendas(meetingId) {
        return axios.get(`meetings/${meetingId}/agendas`)
    },

    delete(meetingId) {
        return axios.delete(`meetings/${meetingId}`)
    },
    
    nextAgenda(meetingId) {
        return axios.put(`meetings/${meetingId}/next-agenda`, {})
    },
    pauseOrResume(meetingId) {
        return axios.put(`meetings/${meetingId}/pause-resume`, {})
    },
    generateMeetingMinutes(meetingId) {
        return axios.post(`meetings/${meetingId}/meeting-minutes-generate`, {})
    },
    uploadInitialMeetingMinutes(meetingId, formData) {
        return axios.post(`meetings/${meetingId}/upload-initial-meeting-minutes`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },
    uploadFinalMeetingMinutes(meetingId, formData) {
        return axios.post(`meetings/${meetingId}/upload-final-meeting-minutes`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },
    getMeetingMinutes (meetingId) {
        return axios.get(`meetings/${meetingId}/initial-meeting-minutes`, {})
    },
    generateFinalMeetingMinutes(meetingId) {
        return axios.post(`meetings/${meetingId}/final-meeting-minutes-generate`, {})
    },
    getFinalMeetingMinutes(meetingId) {
        return axios.get(`meetings/${meetingId}/final-meeting-minutes`, {})
    },
    sendInitialMeetingMinutesForApproval(formData) {
        return axios.post('meetings/send-initial-Meeting-Minutes', formData)
    },
    sendFinalMeetingMinutesForApproval(formData) {
        return axios.post('meetings/send-final-Meeting-Minutes', formData)
    },
    getInitialMeetingMinutesExistUsers(meetingId) {
        return axios.get(`meetings/${meetingId}/initial-meeting-minutes-users`)
    },
    getFinalMeetingMinutesExistUsers(meetingId) {
        return axios.get(`meetings/${meetingId}/final-meeting-minutes-users`)
    },
    approveInitialMeetingMinutes(meetingId) {
        return axios.put(`meetings/${meetingId}/approve-initial-meeting-minutes`, {})
    },
    approveFinalMeetingMinutes(meetingId) {
        return axios.put(`meetings/${meetingId}/approve-final-meeting-minutes`, {})
    },
    reCreateInitialMeetingMinutes(meetingId) {
        return axios.post(`meetings/${meetingId}/meeting-minutes-re-generate`, {})
    },
    attendUser(formData) {
        return axios.put('meetings/user-attend', formData)
    },
    getInitialMeetingMinutesVersions(meetingId) {
        return axios.get(`meetings/${meetingId}/initial-meeting-minutes-versions`)
    },
    updateMeetingInfo(formData) {
        return axios.put('meetings/meeting-info', formData)
    },
    saveMeetingInfo(formData) {
        return axios.post('meetings/meeting-info', formData)
    },
    removeMeetingAttendee(meetingId,userId) {
        return axios.delete(`meetings/${meetingId}/meeting-attendee/${userId}`)
    },
    addMeetingAttendee(meetingId,formData) {
        return axios.post(`meetings/${meetingId}/meeting-attendee`, formData)
    },
    updateMeetingAttendee(meetingId, formData) {
        return axios.put(`meetings/${meetingId}/meeting-attendee`, formData)
    },
    updateMeetingAgenda(meetingId,formData) {
        return axios.put(`meetings/${meetingId}/meeting-agenda`, formData)
    },
    addMeetingAgenda(meetingId,formData) {
        return axios.post(`meetings/${meetingId}/meeting-agenda`, formData)
    },
    removeMeetingAgenda(meetingId,agendaId) {
        return axios.delete(`meetings/${meetingId}/meeting-agenda/${agendaId}`)
    },

    updateMeetingAttachment(meetingId, formData) {
        return axios.put(`meetings/${meetingId}/meeting-attachment`, formData)
    },

    addMeetingAttachment(meetingId, formData,privacyId, agendaId) {
        let url = `meetings/${meetingId}/meeting-attachment`
        const params = []

        params.push(`privacyId=${privacyId}`)
        if (agendaId !== null && agendaId !== undefined) {
            params.push(`agendaId=${agendaId}`)
        }

        if (params.length > 0) {
            url += `?${params.join('&')}`
        }

        return axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    removeMeetingAttachment(meetingId, attachmentId) {
        return axios.delete(`meetings/${meetingId}/meeting-attachment/${attachmentId}`)
    },
    listAssociatedMeetings(meetingId) {
        return axios.get(`meetings/${meetingId}/associated-meeting`)
    },
    addAssociatedMeetings(meetingId, associatedId) {
        return axios.post(`meetings/${meetingId}/associated-meeting/${associatedId}`, {})
    },
    removeAssociatedMeetings(meetingId, associatedId) {
        return axios.delete(`meetings/${meetingId}/associated-meeting/${associatedId}`)
    },
    approveMeeting(meetingId) {
        return axios.post(`meetings/${meetingId}/approve-meeting`, {})
    },
    sendMeeting(meetingId) {
        return axios.post(`meetings/${meetingId}/send-meeting`, {})
    },
    loadMeetingApprovals(meetingId) {
        return axios.get(`meetings/${meetingId}/meeting-user-approvals`)

    },
    loadMeetingMinutesUsersSigns(meetingId) {
        return axios.get(`meetings/${meetingId}/meeting-minutes-users-sign`)

    },
    loadFinalMeetingMinutesVersions(meetingId) {
        return axios.get(`meetings/${meetingId}/final-meeting-minutes-versions`)

    },
    loadMeetingMinutesVersions(meetingId) {
        return axios.get(`meetings/${meetingId}/initial-meeting-minutes-versions`)

    },
    loadMeetingMinutesApprovals(attachmentId){
        return axios.get(`meetings/${attachmentId}/meeting-minutes-users-approvals`)

    },
    endMeeting(meetingId) {
        return axios.put(`meetings/${meetingId}/end-meeting`)

    },

    getMeetingSummary(meetingId) {
        return axios.get(`meetings/${meetingId}/meeting-summary`)
    },

    addOrUpdateMeetingSummary(meetingId,summary) {
        return axios.post(`meetings/${meetingId}/meeting-summary`, summary)
    },


    listMeetingAgendaVotingReports(meetingId) {
        return axios.get(`meetings/${meetingId}/meeting-agenda-vote-reports`)
    },

}