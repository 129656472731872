<template>
    <div>
      
        <v-row>
            <v-col cols="4">
                <Doughnut v-if="councilsAndComitteesData" :labels="councilsAndComitteesLabels" :title="$t('CommitteesAndCouncilsCount')" :height="380" :series="councilsAndComitteesData" />
            </v-col>
            <v-col cols="4">
                <BarChart v-if="meetingsSeries" :is-arabic="isArabicLanguage" :labels="meetingStatusesLabels" :show-bottom-labels="showBottomLabels" :title="$t('MeetingStatuses')" :height="350" :series="meetingsSeries" />
            </v-col>
            <v-col cols="4">
                <Doughnut v-if="recommendationsData" :labels="recommendationsLabels" :height="380" :series="recommendationsData" :title="$t('Recommendations')"  />
            </v-col>
            <v-col cols="4">
                <Doughnut v-if="tasksData" :labels="tasksLabels" :height="380" :series="tasksData" :title="$t('Tasks')" />
            </v-col>
            <v-col cols="4">
                <Doughnut v-if="usersData" :labels="usersLabels" :height="380" :series="usersData" :title="$t('ActiveUsers')"  />
            </v-col>
            <v-col cols="4">
                <Doughnut v-if="meetingsMinutesData" :labels="meetingMinutesLabels" :height="380" :series="meetingsMinutesData" :title="$t('MeetingMinutes')" />
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import Doughnut from '@/components/app/charts/Doghnut'
    import BarChart from '@/components/app/charts/Bar'
    import DashboardService from '@/services/DashboardService.js'
    import { mapGetters } from 'vuex'

    export default ({
        components: {
            Doughnut, BarChart
        },
        data() {
            return {
                loading: false,
                dataLoaded: false,
                showBottomLabels: true,
                councilsAndComitteesLabels: [
                    this.$t('Council'),
                    this.$t('Comittee')
                ],
                recommendationsLabels: [
                    this.$t('CompletedRecommendations'),
                    this.$t('IncompletedRecommendations')
                ],
                tasksLabels: [
                    this.$t('CompletedTasks'),
                    this.$t('IncompleteTasks')
                ],
                usersLabels: [
                    this.$t('ActiveUsers'),
                    this.$t('InactiveUsers')
                ],
                meetingMinutesLabels: [
                    this.$t('InitialMeetingsMinutes'),
                    this.$t('FinalMeetingsMinutes')
                ],
                meetingStatusesLabels: [
                ],
                meetingsSeries: null,
                councilsAndComitteesData: null,
                recommendationsData: null,
                tasksData: null,
                usersData: null,
                meetingsMinutesData: null,
            }
        },
        computed: {
            ...mapGetters('user', ['loggedInUser', 'language']),
            isArabicLanguage() {
                return this.language == 'ar'
            },
        },
        created() {

        },
        mounted() {
            this.loading = true
            let promises = [
                DashboardService.getCouncilsAndCoumitteesCounts(),
                DashboardService.getRecommendationsCounts(),
                DashboardService.getTasksCounts(),
                DashboardService.getUsersCounts(),
                DashboardService.getMeetingMinutesCounts(),
                DashboardService.getMeetingsCounts(),
            ]

            Promise.all(promises)
                .then(
                    ([
                        councilsAndComitteesCount,
                        recommendationsCount,
                        tasksCount,
                        usersCount,
                        meetingsMinutesCount,
                        meetingsCount,
                    ]) => {

                        this.councilsAndComitteesData = councilsAndComitteesCount.data.values
                        this.recommendationsData = recommendationsCount.data.values
                        this.tasksData = tasksCount.data.values
                        this.usersData = usersCount.data.values
                        this.meetingsMinutesData = meetingsMinutesCount.data.values
                        this.meetingStatusesLabels = meetingsCount.data.labels
                        this.meetingsSeries = meetingsCount.data.series

                    },
                ).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
        },
        methods: {
        },
    })
</script>

<style>
</style>