<template>
    <div>
        <v-card tile :loading="loading" class="mb-4">
            <div class="card-title">{{ $t('ProcessFollowup') }}</div>
            <v-card-text>
                <v-row>
                    <v-col class="pt-0 pb-0" cols="4">
                        <v-select v-model="status" :loading="controlLoading"
                                  :items="statuses"
                                  :label="$t('Status')"
                                  clearable
                                  item-text="name"
                                  item-value="id">
                            <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-bind="attrs" v-on="on">
                                    <v-list-item-content>
                                        {{ $t(item.name) }}
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="4">
                        <v-text-field v-model="title" :label="$t('ProcessTitle')" clearable maxlength="100" @keydown.enter.prevent="listResults()" />
                    </v-col>
                    <v-col class="pt-0 pb-0" cols="4">
                        <v-text-field v-model="processIdentifier" :label="`${$t('ReferenceNumber')}/${$t('ProcessInstanceId')}`" clearable maxlength="20" @keydown.enter.prevent="listResults()" />
                    </v-col>
                </v-row>
                <v-row class="mt-1">
                    <v-col cols="12">
                        <v-btn :disabled="loading" tile
                               class="me-1 pt-0 white--text"
                               color="secondary"
                               type="submit"
                               @click="listResults()">
                            {{ $t('Search') }}
                        </v-btn>
                        <v-btn :disabled="loading" tile
                               class="ms-2 pt-0 white--text"
                               color="primary"
                               @click="reset()">
                            {{ $t('Reset') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card v-if="loadGrid" tile>
            <GridWithServerPaging :headers="headers" :items="result" :total="totalCount" :loading="loading" class="pt-2" disable-sort show-expand @onChange="listResults" @item-expanded="expand">
                <template v-slot:[`item.id`]="{ item }">
                    <router-link :to="{ name: 'followup-details', params: { processInstanceId: item.id } }">
                        {{ item.id }}
                    </router-link>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <router-link :to="{ name: 'followup-details', params: { processInstanceId: item.id }}" target="_blank">
                                <v-icon v-bind="attrs" class="ms-6" v-on="on">mdi-arrow-expand-all</v-icon>
                            </router-link>
                        </template>
                        <span>{{ $t('OpenNewWindow') }}</span>
                    </v-tooltip>
                 </template>
                <template v-slot:[`item.dateStarted`]="{ item }">
                    {{ item.dateStarted | formatDateGregorian }}
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    {{ $t(item.status) }}
                </template>
                <template v-slot:expanded-item="{ item }">
                    <td :colspan="headers.length" class="td-border">
                        <v-progress-linear v-if="loadingActivities" indeterminate reverse />
                        <template v-if="item.activities && item.activities.length > 0">
                            <v-simple-table class="ma-2 small-font" dense fixed-header max-height="400px">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>{{ $t('Title') }}</th>
                                            <th>{{ $t('StartDate') }}</th>
                                            <th>{{ $t('DueDate') }}</th>
                                            <th>{{ $t('EndDate') }}</th>
                                            <th>{{ $t('TaskOwner') }}</th>
                                            <th>{{ $t('Status') }}</th>
                                            <th>{{ $t('ActivityNumber') }}</th>
                                            <th>{{ $t('ParentActivityNumber') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(activity, i) in item.activities" :key="i">
                                            <td>{{ activity.title }}</td>
                                            <td>{{ activity.startDate | formatDateTimeGregorian }}</td>
                                            <td>{{ activity.dueDate | formatDateGregorian }}</td>
                                            <td>{{ activity.endDate | formatDateTimeGregorian }}</td>
                                            <td>{{ activity.owner }}</td>
                                            <td :class="activity.status"> {{ $t(activity.status) }}</td>
                                            <td>{{ activity.id }}</td>
                                            <td>{{ activity.parentId }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </template>
                        <span v-else>{{ $t('NoItemsToShow') }}</span>
                    </td>
                </template>
            </GridWithServerPaging>
        </v-card>
    </div>
</template>

<script>
    import ProcessesService from '@/services/ProcessesService.js'
    import LookupsService from '../../services/LookupsService'
    import GridWithServerPaging from '@/components/controls/GridWithServerPaging.vue'

    export default {
        components: {
            GridWithServerPaging
        },

        data() {
            return {
                loadingActivities: false,
                loading: false,
                loadGrid: false,
                controlLoading: false,
                status: null,
                title: null,
                totalCount: 0,
                processIdentifier: null,
                searchText: '',
                statuses: [],
                result: [],
                headers: [
                    { text: this.$t('ProcessInstanceId'), align: 'start', value: 'id', sortable: false },
                    { text: this.$t('Title'), value: 'title', sortable: false },
                    { text: this.$t('StartDate'), value: 'dateStarted', sortable: false },
                    { text: this.$t('Status'), value: 'status', sortable: false },
                    { text: this.$t('ReferenceNumber'), value: 'reference', sortable: false },
                ],
            }
        },

        created() {
            this.listProcessStatuses()
        },

        methods: {
            listProcessStatuses() {
                this.controlLoading = true
                LookupsService.listProcessStatuses().then((res) => {
                    if (res.data) {
                        this.statuses = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.controlLoading = false
                })
            },

            listResults(options) {
                this.loadGrid = true
                this.loading = true

                const searchObj = {
                    text: this.searchText || null,
                    statusId: this.status,
                    title: this.title || null,
                    processIdentifier: this.processIdentifier || null,
                    page: options != undefined ? options.page : 1,
                    pageSize: options != undefined ? options.itemsPerPage : 10,
                    sortBy: options != undefined && options.sortBy && options.sortBy.length > 0 ? options.sortBy[0] : null,
                    sortDesc: options != undefined && options.sortDesc && options.sortDesc.length > 0 ? options.sortDesc[0] : null,
                }

                ProcessesService.list(searchObj)
                    .then((res) => {
                        this.result = res.data.data
                        this.totalCount = res.data.total
                        this.loading = false
                    })
                    .catch(() => {
                        this.result = []
                        this.totalCount = 0
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        this.loading = false
                    })
            },

            expand(data) {
                if (data.value == true && !data.item.activities) {
                    this.loadingActivities = true
                    ProcessesService.listActivities(data.item.id).then(res => {
                        if (res.data) {
                            data.item.activities = res.data
                        }
                        else {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loadingActivities = false
                    })
                }
            },

            reset() {
                this.status = null
                this.processIdentifier = null
                this.result = []
                this.totalCount = 0
                this.searchText = null
                this.title = null
            },
        },
    }
</script>

<style>
    .Completed {
     color:green !important; font-weight:600
    }
    .Active{
        color: darkorange !important;
        font-weight: 600
    }
    .td-border {
        border-right: 10px solid #474747;
        border-top: 2px solid #a7a2a2;
        border-bottom: 2px solid #a7a2a2 !important;
    }
</style>