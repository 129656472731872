<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon :disabled="disabled" :class="{ 'is-active': isActive, 'white--text': true }" v-bind="attrs" @click="$emit('clicked', name)" v-on="on">
                <v-icon>{{ icon }}</v-icon>
            </v-btn>
        </template>
        <span>{{ $t(name) }}</span>
    </v-tooltip>
</template>

<script>
    export default {
        name: 'TiptapButton',

        props: {
            name: {
                type: String,
                default: ''
            },
            icon: {
                type: String,
                default: ''
            },
            isActive: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            }
        }
    }
</script>