<template>
    <div style="height: 400px;">
        <v-row>
            <v-col cols="6">
                <v-select v-model="selectedVersion"
                          class="my-2 pa-0 mx-3"
                          :items="versions"
                          item-text="name"
                          dense
                          outlined
                          :label="$t('Versions')"
                          item-value="id"
                          style="height: 36px;"
                          />
            </v-col>
            <v-col col="1" class="d-flex">
                <v-icon @click="viewSelectedAttachment">
                    mdi-eye
                </v-icon>
            </v-col>
        </v-row>
        <v-data-table class="elevated-table bordered-table"
                      :headers="headers"
                      dense
                      item-key="id"
                      style="height: 400px; overflow-y: auto; display: flex; flex-direction: column;"
                      :items="approvals">
            <template v-slot:[`item.approveDate`]="{ item }">
                {{ item.approveDate| formatDateWithTimeAr }}
            </template>
            <template v-slot:[`item.statusName`]="{ item }">
                <v-icon color="teal">
                    {{ item.approveDate ? 'mdi-check' : 'mdi-close' }}
                </v-icon>
            </template>
        </v-data-table>
        <CommonDialog v-model="attachmentDialog" :title="$t('Attachment')" css-content-class="pa-0" @input="attachmentDialog= false">
            <v-container fluid class="stretch">
                <FileViewer :query="documentQuery" name="recommendationAttachment" />
            </v-container>
        </CommonDialog>
    </div>
</template>

<script>
    import MeetingsService from '@/services/MeetingsService'
    import CommonDialog from '@/components/controls/CommonDialog.vue'
    import FileViewer from '@/components/controls/FileViewer.vue'
    import AttachmentsService from '@/services/AttachmentsService.js'

    export default({
        props: {
            meetingId: {
                type: Number,
                default: null,
            },
        },
        components: {
            CommonDialog, FileViewer
        },
        data() {
            return {
                approvals: [],
                loading: false,
                versions: [],
                attachmentDialog: false,
                documentQuery: null,
                selectedVersion: null,
                headers: [
                    { text: this.$t('Name'), value: 'userName' },
                    { text: this.$t('Signed'), value: 'statusName' },
                    { text: this.$t('SignDate'), value: 'approveDate' },
                ]
            }
        },
        created() {
            this.loadMeetingApprovals()
            this.loadFinalMeetingMinutesVersions()
        },
        methods: {
            loadMeetingApprovals() {
                this.loading = true
                MeetingsService.loadMeetingMinutesUsersSigns(this.meetingId).then((res) => {
                    if (res.data) {
                        this.approvals = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            loadFinalMeetingMinutesVersions() {
                this.loading = true
                MeetingsService.loadFinalMeetingMinutesVersions(this.meetingId).then((res) => {
                    if (res.data) {
                        this.versions = res.data
                        if (this.versions.length > 0) {
                            this.selectedVersion = this.versions[this.versions.length - 1].id
                        }
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            viewSelectedAttachment() {
                AttachmentsService.getAttachment(this.selectedVersion).then(response => {
                    this.documentQuery = response.data
                    this.attachmentDialog = true
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                })
            },
        },
    })
</script>

<style>
    .bordered-table {
        border: 1px solid #ccc;
    }

    .v-data-table__wrapper {
        flex: 1 1 auto;
    }
</style>