import { mainApiAxios as axios } from '@/plugins/axios'


export default {

    listOnlineUsers() {
        return axios.get('chat/online')
    },
    listUserChats() {
        return axios.get('chat/list')
    },
    loadChatDetails(chatId) {
        return axios.get(`chat/details/${chatId}`)
    },
    searchUsersAndChats() {
        return axios.get('chat/search')
    },
    sendMessage(formData) {
        return axios.post('chat/chat-message', formData)
    },
    sendMeetingMessage(formData) {
        return axios.post('chat/meeting-message', formData)
    },
    getUnreadChatMessages() {
        return axios.get('chat/unread-messages-count')
    },
    createChat(formData) {
        return axios.post('chat', formData)
    },
    loadMeetingChatMessages(meetingId) {
        return axios.get(`chat/meeting-chat-details/${meetingId}`)
    },
}