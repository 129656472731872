<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" class="py-0">
                <v-switch v-model="meeting.isCommittee" :disabled="viewMode" :label="$t('RelatedCommittee')" color="teal darken-2" @change="setCommittee($event)" />
            </v-col>
            <v-col cols="4">
                <v-text-field v-model="meeting.referenceNumber" dense outlined :label="$t('ReferenceNumber')" disabled prepend-icon="mdi-tag-text" />
            </v-col>

            <v-col cols="4">
                <v-autocomplete hide-details v-model="meeting.committeeId" dense outlined :disabled="!meeting.isCommittee || viewMode"
                                :loading="loading" :rules="!meeting.isCommittee ? [] : nameRules" item-text="name" item-value="id"
                                :items="committees" :label="$t('Committees')" prepend-icon="mdi-account-group" @change="onCommitteeChange" />
            </v-col>
            <v-col cols="4">
                <v-select hide-details v-model="meeting.councilSessionId" dense outlined :disabled="this.viewMode || meeting.committeeTypeId != 1" :loading="loading" :rules="this.viewMode || meeting.committeeTypeId != 1 ? [] : nameRules" item-text="name" item-value="id" :items="councilSessions" :label="$t('CouncilSessions')" prepend-icon="mdi-account-group" />
            </v-col>
            <v-col cols="4">
                <v-text-field hide-details v-model="meeting.location" :disabled="viewMode" dense outlined :label="$t('MeetingRoom')" :rules="cleanRequiredStringRules" prepend-icon="mdi-map-marker" />
            </v-col>
            <v-col cols="4">
                <DatePicker :hideDetails="true" v-model="meeting.date" dense :disabled="viewMode" :outlined="true" :label="$t('MeetingDate')" :rules="requiredDateRule" />
            </v-col>
            <v-col cols="4">
                <hijri-date-picker v-model="meeting.date" dense
                                   :label="$t('MeetingDate')"
                                   :rules="requiredDateRule"
                                   styles="pt-0"
                                   outlined
                                   hide-details
                                   :disabled="viewMode" />
            </v-col>
            <v-col cols="4">
                <v-dialog ref="startTimedialog" v-model="startTimeModal" :return-value.sync="time" persistent width="300px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field hide-details v-model="meeting.startTime" :disabled="viewMode" dense outlined class="mb-5" :label="$t('StartTime')" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" :rules="nameRules" v-on="on" />
                    </template>
                    <v-time-picker hide-details v-if="startTimeModal" v-model="meeting.startTime" full-width>
                        <v-spacer />
                        <v-btn small tile color="error" @click="saveStartTime">
                            {{ $t('Cancel') }}
                        </v-btn>
                        <v-btn small tile color="secondary" @click="saveStartTime">
                            {{ $t('Ok') }}
                        </v-btn>
                    </v-time-picker>
                </v-dialog>
            </v-col>
            <v-col cols="4">
                <v-dialog ref="endTimedialog" v-model="endTimeModal" :return-value.sync="time" persistent width="300px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="meeting.endTime" dense outlined :disabled="viewMode" :label="$t('EndTime')" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" :rules="nameRules" v-on="on" />
                    </template>
                    <v-time-picker v-if="endTimeModal" v-model="meeting.endTime" full-width>
                        <v-spacer />
                        <v-btn small tile color="error" @click="saveEndTime">
                            {{ $t('Cancel') }}
                        </v-btn>
                        <v-btn small tile color="secondary" @click="saveEndTime">
                            {{ $t('Ok') }}
                        </v-btn>
                    </v-time-picker>
                </v-dialog>
            </v-col>
            <v-col cols="4">
                <v-select outlined v-model="meeting.typeId" dense :items="meetingTypes" item-text="name" item-value="id" :rules="nameRules" :label="$t('MeetingType')" prepend-icon="mdi-menu-down" @change="checkType"></v-select>
            </v-col>
            <v-col cols="4">
                <v-text-field v-model="meeting.url" dense outlined :label="$t('Link')" :disabled="!enableLink" :rules="!enableLink ? [] : nameRules" prepend-icon="mdi-link-box-variant" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <v-textarea v-model="meeting.title" outlined :disabled="viewMode" :label="$t('Subject')" value="" rows="5" :rules="cleanRequiredStringRules" prepend-icon="mdi-book-open-variant" />
            </v-col>
            <v-col cols="6">
                <v-textarea v-model="meeting.notes" outlined :label="$t('Comment')" :rules="cleanStringRules" :disabled="viewMode" value="" rows="5" prepend-icon="mdi-comment" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import DatePicker from '@/components/controls/DatePicker.vue'
    import CouncilCommitteesService from '@/services/CouncilCommitteesService.js'
    import LookupsService from '@/services/LookupsService.js'
    import HijriDatePicker from '@/components/controls/HijriDatePicker.vue'
    import { MeetingTypeEnum, CommitteeType } from '@/helpers/constants/enumerations.js'


    export default {
        components: {
            DatePicker, HijriDatePicker
        },

        props: {
            value: {
                type: Object,
                default: () => { }
            },
            viewMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                time: null,
                enableLink: false,
                menu2: false,
                loading: false,
                startTimeModal: false,
                endTimeModal: false,
                cleanRequiredStringRules: [this.$rules.requiredRule, this.$rules.onlyAlphanumericRule],
                requiredDateRule: [this.$rules.requiredRule],
                cleanStringRules: [this.$rules.onlyAlphanumericRule],

                nameRules: [v => !!v || this.$t('RequiredField')],
                committees: [],
                meetingTypes: [],
                councilSessions: [],
                meeting: {
                    title: null,
                    isCommittee: false,
                    committeeId: null,
                    date: null,
                    startTime: null,
                    endTime: null,
                    location: null,
                    notes: null,
                    typeId: null,
                    url: null,
                    committeeTypeId: null,
                    councilSessionId: null,
                    meetingAttendees: []
                }
            }
        },

        watch: {
            meeting: {
                handler: function () {
                    this.$emit('input', this.meeting)
                },
                deep: true
            },
            value: {
                handler: function () {
                    this.meeting = this.value
                }
            }
        },
        created() {
            this.meeting = this.value
            this.listUserCommittees()
            this.listMeetingTypes()
            this.listCouncilSessions()
        },

        methods: {
            listUserCommittees() {
                if (this.viewMode && this.meeting.isCommittee && this.meeting.committeeId) {
                    this.committees.push({id:this.meeting.committeeId,name:this.meeting.committeeName})
                    return
                }
                CouncilCommitteesService.listUserCommitteesForMeeting().then((res) => {
                    if (res.data) {
                        this.committees = res.data

                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            listMeetingTypes() {
                LookupsService.listMeetingTypes().then((res) => {
                    if (res.data) {
                        this.meetingTypes = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            listCouncilSessions() {
                LookupsService.listCouncilSessions().then((res) => {
                    if (res.data) {
                        this.councilSessions = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            setCommittee(event) {
                if (event == false) {
                    this.meeting.committeeId = null
                    this.meeting.councilSessionId = null
                }
            },

            checkType(e) {
                switch (e) {
                    case MeetingTypeEnum.Remote:
                        this.enableLink = true
                        break
                    default:
                        this.enableLink = false
                        break

                }
            },

            saveEndTime() {
                this.endTimeModal = false
                if (this.meeting.startTime && !this.isEndTimeAfterStartTime(this.meeting.startTime, this.meeting.endTime)) {
                    this.meeting.endTime = null
                    this.$root.$notification(this.$t('EndTimeValidation'), 'error')
                }
            },
            saveStartTime() {
                this.startTimeModal = false
                if (this.meeting.endTime && !this.isEndTimeAfterStartTime(this.meeting.startTime, this.meeting.endTime)) {
                    this.meeting.startTime = null
                    this.$root.$notification(this.$t('StartTimeValidation'), 'error')
                }
            },
            isEndTimeAfterStartTime(startTime, endTime) {
                const start = new Date(`1970-01-01T${startTime}:00`)
                const end = new Date(`1970-01-01T${endTime}:00`)
                return end > start
            },
            onCommitteeChange(committeeId) {
                const selectedCommittee = this.committees.find(committee => committee.id === committeeId)
                if (selectedCommittee) {
                    this.meeting.committeeTypeId = selectedCommittee.typeId
                    this.meeting.councilSessionId = null
                }
            }
        },
    }
</script>

<style>
</style>