import { mainApiAxios as axios } from '@/plugins/axios'

export default {

   
    searchRecommendations(formdata, page, pageSize) {
        return axios.post(`meetingAgendaRecommendations/search/${page}/${pageSize}`, formdata)
    },
    listRecommendationNotes(recommendationId,page,pageSize) {
        return axios.get(`meetingAgendaRecommendations/${recommendationId}/${page}/${pageSize}/recommendation-notes`)
    },

    deleteRecommendationNote(recommendationId) {
        return axios.delete(`meetingAgendaRecommendations/${recommendationId}/recommendation-notes`)
    },
    editRecommendationNote(formData) {
        return axios.put('meetingAgendaRecommendations/recommendation-notes', formData)
    },
    addRecommendationNote(formData) {
        return axios.post('meetingAgendaRecommendations/recommendation-notes', formData)
    },
    listRecommendationAttachments(recommendationId, page, pageSize) {
        return axios.get(`meetingAgendaRecommendations/${recommendationId}/${page}/${pageSize}/recommendation-attachments`)
    },

    deleteRecommendationAttachment(recommendationId) {
        return axios.delete(`meetingAgendaRecommendations/${recommendationId}/recommendation-attachments`)
    },

    addRecommendationAttachment(formData) {
        return axios.post('meetingAgendaRecommendations/recommendation-attachments', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },
    getRecommendationDetails(recommendationId) {
        return axios.get(`meetingAgendaRecommendations/${recommendationId}`)
    },
    updateRecommendationProgress(formData) {
        return axios.put('meetingAgendaRecommendations', formData)
    },
}