<template>
    <v-card tile>
        <v-toolbar class="flex-grow-0" color="primary" dark dense flat>
            <v-toolbar-title color="white--text" class="flex-shrink-1 mt-2" style="max-width: 70%;">
                {{ $t('RecommendationNote') }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn dark icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-form ref="noteForm" v-model="valid">
            <v-col>
                <v-row class="ma-3">
                    <v-text-field v-model="text" dense outlined :label="$t('RecommendationNote')" :rules="cleanRequiredStringRules" />
                </v-row>
            </v-col>
        </v-form>
        <v-card-actions class="justify-center ma-2">
            <v-btn v-show="!editId" tile class="mb-3" color="primary" dark @click="AddRecommendationNote">
                {{ $t('Add') }}
            </v-btn>
            <v-btn v-show="editId" tile class="mb-3" color="secondary" dark @click="EditRecommendationNote">
                {{ $t('Edit') }}
            </v-btn>
            <v-btn v-show="editId" tile class="mb-3" color="error" dark @click="close">
                {{ $t('Cancel') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script lang="ts">
    import RecommendationsService from '@/services/MeetingAgendaRecommendationsService'

    export default ({
        components: {
        },
        directives: {
        },
        filters: {
        },
        props: {
            note: {
                type: Object,
                default:null
            },
            id: {
                type: Number,
                default:null
            },
        },
        data() {
            return {
                text: '',
                loading: false,
                valid: true,
                editId: null,
                cleanRequiredStringRules: [this.$rules.requiredRule, this.$rules.onlyAlphanumericRule],

            }
        },
        computed: {
            
        },
        watch: {
        },
        
        created() {
            if (this.note) {
                this.text = this.note.text
                this.editId=this.note.id
            }
        },
        methods: {
            AddRecommendationNote() {
                if (this.$refs.noteForm.validate()) {
                    this.loading = true
                    let formData = {
                        'recommendationId': this.id,
                        'text': this.text,
                    }
                    RecommendationsService.addRecommendationNote(formData).then((res) => {
                        if (res.data) {
                            this.$root.$notification(this.$t('AddNoteSuccess'), 'success')
                            this.close()
                            this.update()
                        } else {
                            this.$root.$notification(this.$t('HEEEEE'), 'error')
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
               
            },

            EditRecommendationNote() {
                if (this.$refs.noteForm.validate()) {
                    this.loading = true
                    let formData = {
                        'id': this.note.id,
                        'text': this.text,
                    }
                    RecommendationsService.editRecommendationNote(formData).then((res) => {
                        if (res.data) {
                            this.$root.$notification(this.$t('UpdateNoteSuccess'), 'success')
                            this.close()
                            this.update()
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false

                    })
                }
            },
            update() {
                this.$emit('update')
            },
            close() {
                this.$emit('close')
            },
           
        },
    })
</script>

<style>
</style>