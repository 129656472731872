<template>
    <v-card tile>
        <v-toolbar dense dark color="secondary" class="flex-grow-0">
            <v-toolbar-title class="ms-2">
                {{ $t('SelectFileAgenda') }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon dark @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4">
            <v-container fluid>
                <v-select v-model="selectedItemId" clearable :items="agendaItems" item-text="title" item-value="id" :label="$t('Select')" />
            </v-container>
            <v-container fluid>
                <v-select v-model="selectedPrivacyId" :rules="nameRules" item-text="name" item-value="id" :items="privacies" :label="$t('PrivacyLevel')" />
            </v-container>

        </v-card-text>
        <v-card-actions style="background:#e6e5e4">
            <v-spacer />
            <v-btn :disabled="loading" tile color="primary" @click="sendSelectedItem">
                {{ $t('Select') }}
                <v-icon small class="ms-2">mdi-floppy</v-icon>
            </v-btn>
            <v-btn :disabled="loading" tile color="error" @click="close">
                {{ $t('Cancel') }}
                <v-icon small class="ms-2">mdi-close</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import MeetingsService from '@/services/MeetingsService'
    import { AttachmentsRecordType } from '@/helpers/constants/enumerations'
    import LookupsService from '@/services/LookupsService.js'

    export default ({
        components: {
        },
        props: {
            agendaItems: {
                type: Array,
                default: () => []
            },
            attachmentId: {
                type: Number,
                default: null
            },
            meetingId: {
                type: Number,
                default: null
            },
            agendaId: {
                type: Number,
                default: null
            },
            privacyId: {
                type: Number,
                default: null
            },
        },
        data() {
            return {
                selectedItemId: null,
                loading: false,
                privacies: [],
                selectedPrivacyId:null,
            }
        },
        created() {
            this.selectedItemId = this.agendaId
            this.listPrivacies()
        },
        methods: {
            listPrivacies() {
                if (this.privacies && this.privacies.length > 0) {
                    return
                }
                LookupsService.listPrivacies().then((res) => {
                    if (res.data) {
                        this.privacies = res.data
                        this.selectedPrivacyId =this.privacyId
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                })
            },
            close() {
                this.$emit('close')
            },
            sendSelectedItem() {
                this.loading = true
                let formdata = {
                    'recordId': this.selectedItemId ?? this.meetingId,
                    'id': this.attachmentId,
                    'privacyId': this.selectedPrivacyId,
                    'recordTypeId': this.selectedItemId ? AttachmentsRecordType.MeetingAgenda : AttachmentsRecordType.Meeting
                }
                MeetingsService.updateMeetingAttachment(this.meetingId, formdata).then(response => {
                    if (response.data) {
                        this.$emit('updateAttachments', response.data)
                        this.close()
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
        },
    })
</script>

<style>
</style>