import { mainApiAxios as axios } from '@/plugins/axios'

export default {

    listProcessTypes() {
        return axios.get('lookups/process-types')
    },


    listFormFields(formId) {
        return axios.get(`lookups/form-fields?formId=${formId}`)
    },

    listStructureTypes() {
        return axios.get('lookups/structure-types')
    },

    listStructures() {
        return axios.get('lookups/structures')
    },
    listBranches() {
        return axios.get('lookups/branches')
    },
    listInternalStructures() {
        return axios.get('lookups/internal-structures')
    },

    listRoles() {
        return axios.get('lookups/roles')
    },

    listLanguages() {
        return axios.get('lookups/languages')
    },

    listDatasources() {
        return axios.get('lookups/data-sources')
    },

    listDataTables(connectionId) {
        return axios.get(`lookups/data-tables?connectionId=${connectionId}`)
    },

    listDataFields(connectionId, tableName) {
        return axios.get(`lookups/data-fields?connectionId=${connectionId}&tableName=${tableName}`)
    },

    listMappings() {
        return axios.get('lookups/mappings')
    },

    listProcessStatuses() {
        return axios.get('lookups/process-statuses')
    },

    listDataTypes() {
        return axios.get('lookups/data-types')
    },

    listOwnerTypes() {
        return axios.get('lookups/owner-types')
    },

    listRolesForAutoComplete(search) {
        return axios.get(`lookups/roles?search=${search}&mode=Autocomplete`)
    },

    listProcessInstancesForAutoComplete(search) {
        return axios.get(`lookups/processInstances?search=${search}&mode=Autocomplete`)
    },

    getRoleById(roleId) {
        return axios.get(`lookups/roles/${roleId}`)
    },

    getUserById(userId) {
        return axios.get(`lookups/users/${userId}`)
    },

    getMeetingById(meetingId) {
        return axios.get(`lookups/meetings/${meetingId}`)
    },

    getDepartmentById(departmentId) {
        return axios.get(`lookups/departments/${departmentId}`)
    },

    listRoles(search) {
        return axios.get(`lookups/roles?search=${search}&mode=List`)
    },

    listPriorities() {
        return axios.get('lookups/priorities')
    },

    listRoleTypes() {
        return axios.get('lookups/role-types')
    },

    listProcessStages() {
        return axios.get('lookups/process-stages')
    },

    listAccountTypes() {
        return axios.get('lookups/account-types')
    },

    listPermissionLevels() {
        return axios.get('lookups/permission-levels')
    },

    listFinancialProcessTypes() {
        return axios.get('lookups/financial-process-types')
    },

    listProcessStructures(financialProcessTypeId) {
        return axios.get(`lookups/financial-process-structures/${financialProcessTypeId}`)
    },

    listCommitteeTypes() {
        return axios.get('lookups/committee-types')
    },

    listCommittees() {
        return axios.get('lookups/committees')
    },

    listCommitteeRoles() {
        return axios.get('lookups/committee-roles')
    },

    listVotingTypes() {
        return axios.get('lookups/voting-types')
    },
    listMeetingStatuses() {
        return axios.get('lookups/meeting-statuses')
    },
    listMeetingTypes() {
        return axios.get('lookups/meeting-types')
    },
    listMeetingRecommendationsStatuses() {
        return axios.get('lookups/meeting-recommendations-statuses')
    },
    listPrivacies() {
        return axios.get('lookups/privacies')
    },
    listCouncilSessions() {
        return axios.get('lookups/council-sessions')
    },
}