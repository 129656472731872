<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" sm="2" class="pt-0">
                <v-card class="mx-auto" tile outlined>
                    <v-list dense nav>
                        <v-subheader>{{ $t('FormControls') }}</v-subheader>
                        <v-list-item-group>
                            <v-list-item @click="addFiled('Header')">
                                <v-list-item-icon>
                                    <v-icon>mdi-format-header-1</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('Header') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="addFiled('Text')">
                                <v-list-item-icon>
                                    <v-icon>mdi-text-shadow</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('Text') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="addFiled('Email')">
                                <v-list-item-icon>
                                    <v-icon>mdi-email</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('Email') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="addFiled('Date')">
                                <v-list-item-icon>
                                    <v-icon>mdi-calendar-clock</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('Date') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="addFiled('TextArea')">
                                <v-list-item-icon>
                                    <v-icon>mdi-format-line-spacing</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('TextArea') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="addFiled('Upload')">
                                <v-list-item-icon>
                                    <v-icon>mdi-cloud-upload</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('Upload') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="addFiled('Number')">
                                <v-list-item-icon>
                                    <v-icon>mdi-numeric</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('Number') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="addFiled('Boolean')">
                                <v-list-item-icon>
                                    <v-icon>mdi-toggle-switch</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('Bool') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item> <v-list-item @click="addFiled('DropDown')">
                                <v-list-item-icon>
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('Dropdown') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="addFiled('Checkbox')">
                                <v-list-item-icon>
                                    <v-icon>mdi-checkbox-marked</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('Checkbox') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="addFiled('Radio')">
                                <v-list-item-icon>
                                    <v-icon>mdi-radiobox-marked</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('RadioGroup') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="addFiled('Masters')">
                                <v-list-item-icon>
                                    <v-icon>mdi-database</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('MasterDataSources') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-col>
            <v-col cols="12" sm="10">
                <Draggable v-model="formFieldsArray" class="p-4" @end="updateItemOrder">
                    <transition-group dense>
                        <v-card v-for="control in formFieldsArray" :key="control.Id" tile class="mb-3" elevation="5">
                            <v-card-text>
                                <v-row>
                                    <v-col cols="4">
                                        <div class="slot mt-4 mb-4">
                                            <input v-model.lazy="control.FieldName" required="required" class="custom-field" type="text">
                                            <label><v-icon class="me-2">{{ getIcon(control) }}</v-icon>{{ $t('FieldName') }}</label>
                                        </div>
                                    </v-col>
                                    <v-col cols="4">
                                        <div class="slot mt-4 mb-4">
                                            <input v-model.lazy="control.HelpText" required="required" class="custom-field" type="text">
                                            <label>{{ $t('HelpText') }}</label>
                                        </div>
                                    </v-col>
                                    <v-col cols="3">
                                        <div class="slot mt-4 mb-4">
                                            <input v-model.lazy="control.Col" required="required" class="custom-field" type="number">
                                            <label>{{ $t('ColumnWidth') }}</label>
                                        </div>
                                    </v-col>
                                    <v-col class="text-center">
                                        <v-btn v-if="isExpandable(control.InputType)" fab color="primary" class="white--text mt-4" x-small @click="control.Visible = !control.Visible">
                                            <v-icon>{{ control.Visible ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                        </v-btn>
                                        <v-btn fab color="error" class="white--text mt-4 ms-4" x-small @click="remove(control.Id)">
                                            <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-col>

                                    <v-col v-show="control.Visible" cols="12" class="mt-2">
                                        <div class="row">
                                            <div v-if="checkIfText(control.InputType)" class="col-3">
                                                <div class="slot mt-4 mb-4">
                                                    <input v-model.lazy="control.MaxChar" required="required" class="custom-field" type="number">
                                                    <label>{{ $t('MaxChar') }}</label>
                                                </div>
                                            </div>

                                            <v-col v-if="checkIfText(control.InputType)" cols="3">
                                                <div class="slot mt-4 mb-4">
                                                    <input v-model.lazy="control.FieldValue[0]" required="required" class="custom-field" type="text">
                                                    <label>{{ $t('DefaultValue') }}</label>
                                                </div>
                                            </v-col>
                                            <v-col v-if="control.InputType === 'Upload'" cols="3">
                                                <div class="slot mt-4 mb-4">
                                                    <input v-model.lazy="control.Min" required="required" class="custom-field" type="number">
                                                    <label>{{ $t('MinDocs') }}</label>
                                                </div>
                                            </v-col>

                                            <v-col v-if="control.InputType === 'Upload'" cols="3">
                                                <div class="slot mt-4 mb-4">
                                                    <input v-model.lazy="control.Max" required="required" class="custom-field" type="number">
                                                    <label>{{ $t('MaxDocs') }}</label>
                                                </div>
                                            </v-col>

                                            <v-col v-if="control.InputType === 'Upload'" cols="3">
                                                <div class="slot mt-4 mb-4">
                                                    <input v-model.lazy="control.MaxFileSize" required="required" class="custom-field" type="number">
                                                    <label>{{ $t('MaxFileSize') }}</label>
                                                </div>
                                            </v-col>

                                            <v-col v-if="control.InputType === 'Upload'" cols="3">
                                                <div class="slot mt-4 mb-4">
                                                    <input v-model.lazy="control.FileType" required="required" class="custom-field" type="text">
                                                    <label>{{ $t('FileType') }}</label>
                                                </div>
                                            </v-col>
                                            <v-col v-if="control.InputType === 'Upload'" cols="3">
                                                <v-switch v-model="control.Multiple" :label="$t('Multiple')" color="primary" />
                                            </v-col>

                                            <v-col v-if="control.InputType === 'Date'" cols="3">
                                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field v-model="control.FieldValue[0]" :label="$t('Date')" hint="MM/DD/YYYY format" persistent-hint v-bind="attrs" v-on="on" />
                                                    </template>
                                                    <v-date-picker v-model="control.FieldValue[0]" no-title color="primary" @input="menu = false" />
                                                </v-menu>
                                            </v-col>

                                            <v-col cols="3">
                                                <v-switch v-if="isShowInInitiation(control.InputType)" v-model="control.ShowInInitiation" dense color="primary" :label="$t('ShowInInitiation')" />
                                            </v-col>

                                            <v-col cols="2">
                                                <v-switch v-if="isRequired(control.InputType)" v-model="control.Required" :label="$t('Required')" color="primary" dense />
                                            </v-col>

                                            <v-col v-if="checkIfList(control.InputType)" cols="12" class="mt-2">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <table width="100%" border="1" cellspacing="1" cellpadding="1">
                                                            <thead>
                                                                <tr>
                                                                    <th width="400" class="text-start pa-2">{{ $t('Options') }}</th>
                                                                    <th width="100">
                                                                        <v-icon dark color="primary" @click="addOption(control)">
                                                                            mdi-plus-circle
                                                                        </v-icon>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(option, index) in control.Options" :key="option.id">
                                                                    <td class="text-start pa-1">
                                                                        <v-text-field v-model="option.Label" color="primary" class="mb-2 pt-0" hide-details />
                                                                    </td>
                                                                    <td class="text-center" width="100">
                                                                        <v-icon color="error" dark @click="RemoveOption(control.Options, index)">
                                                                            mdi-minus-circle
                                                                        </v-icon>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </v-col>

                                            <v-col v-if="control.InputType === 'Masters'" cols="3">
                                                <v-select v-model="control.ControlType" :loading="loadingDdl" dense :label="$t('ControlType')" item-text="name" item-value="value" :items="controlOptions" @input="onSelectTypeChange()" />
                                            </v-col>
                                            <v-col v-if="control.InputType === 'Masters'" cols="3">
                                                <v-select v-model="control.ConnectionId" :loading="loadingDdl" dense :label="$t('Database')" item-text="name" item-value="id" :items="dbConnections" @input="onSelectDBChange($event)" />
                                            </v-col>
                                            <v-col v-if="control.InputType === 'Masters'" cols="3">
                                                <v-select v-model="control.TableName" :loading="loadingDdl" dense :label="$t('DbTable')" :items="dbTables" @input="onSelectTableChange($event, control.ConnectionId)" />
                                            </v-col>
                                            <v-col v-if="control.InputType === 'Masters'" cols="3">
                                                <v-select v-model="control.TextField" dense :label="$t('TextField')" :items="dbTableFields" />
                                            </v-col>
                                            <v-col v-if="control.InputType === 'Masters'" cols="3">
                                                <v-select v-model="control.ValueField" :label="$t('ValueField1')" dense :items="dbTableFields" />
                                            </v-col>
                                            <v-col v-if="control.InputType === 'Upload'" cols="12">
                                                <b><label class="primary--text">{{ $t('AllowedFileExt') }}</label></b>
                                                <v-row>
                                                    <v-col v-for="ext in Extensions" :key="ext.name" cols="3">
                                                        <v-checkbox :id="ext.name" v-model="control.Accept" dense color="success" :label="ext.name" :value="ext.name" />
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
</transition-group>
                </Draggable>
                <v-alert v-if="formFieldsArray.length == 0" color="grey lighten-3" class="text-center">{{ $t('SelectFromMenu') }}</v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>


    import Draggable from 'vuedraggable'
    import { createGuid } from '@/helpers/common.js'
    import LookupsService from '@/services/LookupsService.js'
    export default {

        components: {
            Draggable,
        },

        data() {
            return {
                menu: false,
                loadingDdl: false,
                formFieldsArray: [],
                dbConnections: [],
                dbTables: [],
                dbTableFields: [],
                controlOptions: [{ name: 'DropDown', value: 'dropdown' }, { name: 'Checkbox List', value: 'checkbox' }, { name: 'Radio List', value: 'radio' }],
                Extensions: [{ name: '.pdf' }, { name: '.doc' }, { name: '.docx' }, { name: '.xls' }, { name: '.xlsx' }, { name: '.txt' },
                { name: '.png' }, { name: '.jpg' }, { name: '.jpeg' }, { name: '.gif' }, { name: '.exe' }, { name: 'js' }, { name: '.xml' }, { name: '.pptx' }]
            }
        },
        watch: {
            formFieldsArray: {
                handler: function (val, oldVal) {
                    if (this.$route.query.id) {
                        if (val.find(e => e.InputType === 'Masters')) {
                            let masters = val.find(e => e.InputType === 'Masters')
                            this.setMasters(masters)
                        }
                    }
                },
                deep: true
            }
        },


        methods: {
            addFiled(type) {
                this.formFieldsArray.push({
                    FieldType: this.getType(type), InputType: type, FieldName: '', Col: 4, FieldValue: [], HelpText: '',
                    MaxChar: 100, Required: false, ShowInInitiation: true, Permissions: [], Options: [], TableName: '', TextField: '', ValueField: '',
                    ControlType: '', Order: this.formFieldsArray.length + 1, Id: createGuid(), Visible: false, ConnectionId: 0, Min: 0, Max: 0, FileType: '',
                    Multiple: false, Accept: [], MaxFileSize: '',
                })
            },

            getType(type) {
                switch (type) {
                    case 'Text':
                    case 'TextArea':
                    case 'Email':
                    case 'Masters':
                    case 'Header':
                        return 'System.String'
                    case 'Number':
                        return 'System.Int64'
                    case 'Date':
                    case 'DateTime':
                        return 'System.DateTime'
                    case 'Boolean':
                        return 'System.Boolean'
                    case 'DropDown':
                    case 'Checkbox':
                    case 'Radio':
                        return null
                }
            },

            getIcon(control) {
                switch (control.InputType) {
                    case 'Text':
                        return 'mdi-text-shadow'
                    case 'TextArea':
                        return 'mdi-format-align-justify'
                    case 'Email':
                        return 'mdi-email'
                    case 'Header':
                        return 'mdi-format-header-1'
                    case 'Masters':
                        return 'mdi-database'
                    case 'Number':
                        return 'mdi-numeric'
                    case 'Date':
                        return 'mdi-calendar-clock'
                    case 'DateTime':
                        return 'mdi-calendar-clock'
                    case 'Boolean':
                        return 'mdi-toggle-switch'
                    case 'DropDown':
                        return 'mdi-chevron-down'
                    case 'Checkbox':
                        return 'mdi-checkbox-marked'
                    case 'Upload':
                        return 'mdi-cloud-upload'
                    case 'Radio':
                        return 'mdi-radiobox-marked'
                }
            },

            checkIfText(type) {
                switch (type) {
                    case 'Text':
                    case 'Email':
                    case 'TextArea':
                    case 'Number':
                        return true
                }
            },

            checkIfList(type) {
                switch (type) {
                    case 'DropDown':
                    case 'Checkbox':
                    case 'Radio':
                        return true
                }
            },

            listConnections() {
                this.loadingDdl = true
                LookupsService.listDatasources()
                    .then(res => {
                        this.dbConnections = res.data
                        this.loadingDdl = false
                    })
                    .finally(() => {
                        this.loadingDdl = false
                    })
            },

            listTables(dbId) {
                this.loadingDdl = true
                if (dbId) {
                    LookupsService.listDataTables(dbId)
                        .then(res => {
                            this.dbTables = res.data
                            this.loadingDdl = false
                        })
                        .finally(() => {
                            this.loadingDdl = false
                        })
                }
            },

            onSelectTypeChange() {
                this.listConnections()
            },

            onSelectDBChange(dbId) {
                this.listTables(dbId)
            },

            onSelectTableChange(tableName, dbId) {
                this.listDataFields(dbId, tableName)
            },

            listDataFields(dbId, tableName) {
                if (dbId && tableName) {
                    this.loadingDdl = false
                    LookupsService.listDataFields(dbId, tableName)
                        .then(res => {
                            this.dbTableFields = res.data
                            this.loadingDdl = false
                        })
                        .finally(() => {
                            this.loadingDdl = false
                        })
                }
            },

            updateItemOrder: function () {
                var items = this.formFieldsArray.map(function (item, index) {
                    item.Order = index + 1
                })
                if (this.debounce) return
                this.debounce = setTimeout(function (items) {
                    this.debounce = false
                })
            },

            addOption(control) {
                control.Options.push({ Label: '', Value: createGuid() })
            },

            RemoveOption(options, index) {
                options.splice(index, 1)
            },

            setMasters(control) {
                if (control.ConnectionId > 0) {
                    this.listConnections()
                    this.listTables(control.ConnectionId)
                    this.listDataFields(control.ConnectionId, control.TableName)
                }
            },

            async remove(id) {
                if (await this.$root.$confirm(this.$t('Delete'), this.$t('DeleteText'))) {
                    this.formFieldsArray = this.formFieldsArray.filter(x => x.Id != id)
                }
            },

            isRequired(inputType) {
                if (inputType === 'Boolean' || inputType === 'Header') {
                    return false
                }
                else {
                    return true
                }
            },

            isShowInInitiation(inputType) {
                if (inputType === 'Header') {
                    return false
                }
                else {
                    return true
                }
            },

            isExpandable(inputType) {
                if (inputType === 'Header') {
                    return false
                }
                else {
                    return true
                }
            }
        }
    }
</script>

<style scoped>
    /********************custom css*/
    .input-icon {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        height: 24px;
        -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-width: 24px;
        width: 24px;
    }

    .slot {
        align-items: center;
        display: flex;
        margin-bottom: 8px;
        min-height: inherit;
        position: relative;
        transition: .3s cubic-bezier(.25,.8,.5,1);
        transition-property: height,min-height;
        width: 100%;
    }

        .slot label {
            right: 0px;
            left: auto;
            position: absolute;
            transform-origin: top right;
            height: 20px;
            line-height: 20px;
            letter-spacing: normal;
        }

    .custom-field {
        border-bottom: 1.5px solid #ddd;
        max-height: 32px;
        flex: 1 1 auto;
        line-height: 20px;
        padding: 8px 0px;
        max-width: 100%;
        min-width: 0;
        width: 100%;
    }

        .custom-field:invalid {
            box-shadow: none;
        }

        .custom-field:active, .custom-field:focus, .custom-field textarea:active, .custom-field:focus {
            outline: none;
        }

    .slot:focus-within label {
        right: 0;
        bottom: 10px;
        transition: .3s cubic-bezier(.25,.8,.5,1);
        transform: translateY(-18px) scale(.75)
    }

    .slot:focus-within input {
        border-bottom: solid primary 2px;
    }

    .custom-field:valid {
        border-bottom: solid primary 2px;
    }

        .custom-field:valid ~ label {
            right: 0;
            bottom: 10px;
            transition: .3s cubic-bezier(.25,.8,.5,1);
            transform: translateY(-18px) scale(.75)
        }

    .custom-field:invalid {
        border-bottom: solid grey 1.5px;
    }

    .slot label {
        color: rgba(0,0,0,.6)
    }
</style>
