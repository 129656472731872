<template>
    <div>
        <v-card class="mb-3 rounded-lg">
            <v-toolbar>
                <h3>{{ $t('Recommendations') }}</h3>
            </v-toolbar>
            <v-card-text class="mb-2">
                <v-row>
                    <v-col cols="3">
                        <v-text-field v-model="searchModel.meetingReferenceNo"
                                      dense outlined
                                      :label="$t('ReferenceNumber')" />
                    </v-col>

                    <v-col cols="3">
                        <DatePicker v-model="searchModel.fromDate"
                                    dense
                                    :outlined="true"
                                    :label="$t('FromDate')" />
                    </v-col>
                    <v-col cols="3">
                        <DatePicker v-model="searchModel.toDate"
                                    dense
                                    :outlined="true"
                                    :label="$t('ToDate')" />
                    </v-col>
                    <v-col cols="3">
                        <v-text-field v-model="searchModel.title"
                                      dense
                                      outlined
                                      :label="$t('Title')"
                                      prepend-icon="mdi-book-open-variant" />
                    </v-col>
                </v-row>
                <v-row class="mt-1">
                    <v-col cols="12">
                        <v-btn tile :disabled="loading"
                               class="me-1 pt-0 white--text"
                               color="primary"
                               type="submit"
                               @click="listRecommendations()">
                            {{ $t('Search') }}
                            <v-icon class="ms-2">mdi-magnify</v-icon>
                        </v-btn>
                        <v-btn tile :disabled="loading"
                               class="ms-2 white--text"
                               color="primary"
                               @click="reset()">
                            {{ $t('Reset') }}
                            <v-icon class="ms-2">mdi-refresh</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-text>
                <GridWithServerPaging :headers="headers" dense :items="recommendations" :items-per-page="options.itemsPerPage" :total="totalCount" @onChange="listRecommendations">
                    <template v-slot:[`item.createdAt`]="{ item }">
                        {{ item.createdAt | formatDateGregorian }}
                    </template>
                    <template v-slot:[`item.title`]="{ item }">
                        {{ item.title | trim(60) }}
                    </template>
                    <template v-slot:[`item.progress`]="{ item }">
                        <v-progress-circular :rotate="-90" :size="35" :width="3" :value="item.progress" color="secondary">
                            <h6>{{ getRecommendationProgress(item.progress) }}</h6>
                        </v-progress-circular>
                    </template>
                    <template v-slot:[`item.preview`]="{ item }">
                        <v-icon v-if="item.canEdit" color="primary" @click="openManageRecommendation(item, false)"> mdi-pencil</v-icon>
                        <v-icon v-else color="primary" @click="openManageRecommendation(item, true)"> mdi-eye</v-icon>
                    </template>
                </GridWithServerPaging>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import MeetingAgendaRecommendationsService from '@/services/MeetingAgendaRecommendationsService.js'
    import DatePicker from '@/components/controls/DatePicker.vue'
    import LookupsService from '@/services/LookupsService.js'
    import { AgendaRecommendationStatusEnum } from '@/helpers/constants/enumerations'
    import GridWithServerPaging from '@/components/controls/GridWithServerPaging.vue'

    export default {
        components: {
            DatePicker,
            GridWithServerPaging
        },

        data() {
            return {
                selectedRecommendationId: null,
                loading: false,
                recommendations: [],
                statusItems: [],
                selectedStatus: null,
                totalCount: 0,
                headers: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('ReferenceNumber'), value: 'meetingReferenceNo' },
                    { text: this.$t('MeetingDate'), value: 'createdAt' },
                    { text: this.$t('Title'), value: 'text', width: '25%' },
                    { text: this.$t('CreatedBy'), value: 'createdByName' },
                    { text: this.$t('Progress'), value: 'percentage' },
                    { text: this.$t('Status'), value: 'status' },
                    { text: this.$t('Actions'), value: 'preview', width: '6%' },
                ],
                searchModel: {
                    meetingReferenceNo: null,
                    fromDate: null,
                    toDate: null,
                    title: null,
                },
                options: {
                    page: 1, itemsPerPage: 10
                },
            }
        },

        created() {
            this.loadRecommendationsStatuses()
            this.listRecommendations()
        },


        methods: {
            listRecommendations(options) {
                this.loading = true
                const page = options != undefined ? options.page : this.options.page
                const pageSize = options != undefined ? options.itemsPerPage : this.options.itemsPerPage
                MeetingAgendaRecommendationsService.searchRecommendations(this.searchModel, page, pageSize).then((res) => {

                    this.recommendations = res.data.data
                    this.totalCount = res.data.total
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            loadRecommendationsStatuses() {
                LookupsService.listMeetingRecommendationsStatuses()
                    .then(
                        (statuses) => {
                            this.statusItems = statuses.data
                        },
                    ).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                    })

            },
            reset() {
                this.searchModel.statusId = null
                this.searchModel.meetingReferenceNo = null
                this.searchModel.fromDate = null
                this.searchModel.toDate = null
                this.searchModel.title = null
                this.searchModel.onlyMyRecommendations = true
            },
            getRecommendationProgress(progress) {
                return progress + '%'
            },

            openManageRecommendation(recommendation, readOnly) {
                if (readOnly) {
                    this.$router.push({ name: 'recommendation-details', params: { id: recommendation.id, viewMode: true } })
                }
                else {
                    this.$router.push({ name: 'recommendation-details', params: { id: recommendation.id } })
                }
            },
        },
    }
</script>

<style>
</style>