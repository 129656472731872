import Vue from 'vue'
import App from './App.vue'
import VueSignaturePad from 'vue-signature-pad'
import VueApexCharts from 'vue-apexcharts'
import store from '@/plugins/store/index.js'
import vuetify from '@/plugins/vuetify/index.js'
import { i18n } from '@/plugins/i18n/index.js'
import router from '@/plugins/router/index.js'
import filters from '@/plugins/filters//modules/dateFilters.js'
import MeetingDashboard from '@/components/app/meeting/MeetingDashboard.vue'
import { requiredRule, noHtmlTagsRule, onlyAlphanumericRule, maxLengthRule, minLengthRule, safeStringRule } from '@/helpers/validationRules'

// to use them without import
Vue.prototype.$rules = {
	requiredRule,
	noHtmlTagsRule,
	onlyAlphanumericRule,
	maxLengthRule,
	minLengthRule,
	safeStringRule
}


Vue.config.productionTip = false
Vue.use(VueSignaturePad)
Vue.use(filters)
Vue.component('MeetingDashboard', MeetingDashboard)

new Vue({
	vuetify,
	router,
	store,
	i18n,
	VueApexCharts,
	render: h => h(App),
}).$mount('#app')