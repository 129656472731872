	<template>
	<v-dialog :value="value"
			  dense
			  :fullscreen="fullscreen"
			  :width="width"
			  scrollable
			  persistent>
		<v-card tile>
			<v-toolbar dense dark color="secondary" class="flex-grow-0">
				<v-toolbar-title class="ms-2">
					{{ title }}
				</v-toolbar-title>
				<v-spacer />
				<v-btn icon dark @click="close()">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
			<v-card-text :class="cssContentClass">
				<slot />
			</v-card-text>
			<v-divider />
			<v-card-actions v-if="showActionToolbar" style="background:#e6e5e4">
				<v-spacer />
				<slot name="actions" />
				<v-spacer />
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
	export default {

		props: {
			title: {
				type: String,
				default: '',
			},

			fullscreen: {
				type: Boolean,
				default: true
			},

			width: {
                type: String,
				default: null,
			},

			value: {
				type: Boolean,
				default: false,
			},

			showActionToolbar: {
				type: Boolean,
				default: false
			},
			//when we have multiple nested dialogs, only the parent dialog should toggle the overflow/scroll
			nested: {
				type: Boolean,
				default: false
			},

			cssContentClass: {
				type: String,
				default: 'pa-2'
			}
		},

		updated() {

			if (!this.nested) {
				if (this.value) {
					document.documentElement.classList.add('no-overflow')
				} else {
					document.documentElement.classList.remove('no-overflow')
				}
			}
        },
       
        destroyed() {
            if (!this.nested) {
                document.documentElement.classList.remove('no-overflow')
            }
        },

		methods: {
			close() {
				if (!this.nested) {
					document.documentElement.classList.remove('no-overflow')
				}
				this.$emit('input', false)
			},
		}
	}
</script>