import { mainApiAxios as axios } from '@/plugins/axios'

export default {


    list(searchInput) {
        return axios.post('processes/search', searchInput)
    },

	createProcess(formData) {
        return axios.post('processes', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    listActivities(processInstanceId) {
        return axios.get(`processes/${processInstanceId}/activities`)
    },

    getProcess(processInstanceId) {
        return axios.get(`processes/${processInstanceId}`)
    },

    listLetters(processInstanceId) {
        return axios.get(`processes/${processInstanceId}/letters`)
    },

    listCommentsByProcessId(processInstanceId) {
        return axios.get(`processes/${processInstanceId}/comments`)
    },

    listProcesses() {
        return axios.get('processes/apps')
    },

    listInitiationData(processInstanceId) {
        return axios.get(`processes/${processInstanceId}/initiation`)
    },

    listCommentsReport(search) {
        return axios.post('processes/comments-report', search)
    },

    getProcessStatusReport() {
        return axios.get('processes/reports/process-status-report')
    },

    getFinancialProcessReport() {
        return axios.get('processes/reports/financial-process-report')
    },

    listAuditItems(processInstanceId) {
        return axios.get(`processes/${processInstanceId}/auditItems`)
    },
}