<template>
    <v-card width="600">
        <div class="card-title">{{ $t('AssociatedMeetings') }}</div>
        <v-data-table :headers="headers"
                      dense
                      item-key="id"
                      :items="meetings"
                      :items-per-page="5">
            <template v-slot:[`item.name`]="{ item }">

                {{item.name|trim(35)}}
            </template>
            <template v-slot:[`item.actions`]="{ item }">

                <v-icon v-if="meetingOwner" @click="viewMeeting(item)">
                    mdi-eye
                </v-icon>
            </template>

        </v-data-table>
        <v-dialog v-if="meetingOpened" v-model="meetingOpened"  fullscreen hide-overlay persistent>
            <meeting-dashboard :meeting="selectedMeeting" view-mode @close="meetingOpened = false" />
        </v-dialog>
    </v-card>
</template>

<script>
    import MeetingsService from '@/services/MeetingsService'
    export default ({
        components: {
        },
        props: {
            meetingId: {
                type: Number,
                default: null
            },
            meetingOwner: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                meetings: [],
                loading: false,
                meetingViewMode:true,
                headers: [
                    { text: this.$t('MeetingNumber'), value: 'id' },
                    { text: this.$t('Subject'), value: 'name' },
                    { text: this.$t('Actions'), value: 'actions' },
                ],
                selectedMeeting: null,
                meetingOpened :false,

            }
        },
        created() {
            this.listAssociatedMeetings()
        },
        methods: {
            listAssociatedMeetings() {
                if (this.meetingId) {
                    this.loading = true
                    MeetingsService.listAssociatedMeetings(this.meetingId).then((res) => {
                        if (res.data) {
                            this.meetings = res.data
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
            },
            viewMeeting(meeting) {
                this.selectedMeeting = meeting
                this.meetingOpened = true
            }

        },
    })
</script>

<style scoped>
</style>