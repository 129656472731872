<template>
    <CommonDialog :value="value" :fullscreen="false" width="800" :title="edit == true ? $t('Edit') : $t('Add')" show-action-toolbar @input="$emit('input', false)">
        <v-form ref="userForm" v-model="valid" lazy-validation>
            <v-container>
                <v-row>
                    <v-col cols="12" md="6" class="mb-4">
                        <v-switch v-model="user.approved" :label="$t('Approved')" color="teal" />
                    </v-col>
                    <v-col cols="12" md="6" class="mb-4">
                        <v-select v-model="user.defaultLanguageId" :items="languages" item-text="name" item-value="id" :label="$t('SelectLanguage')" />
                    </v-col>
                    <v-col cols="12" md="6"><v-text-field v-model="user.fullnameAr" dense :label="$t('FullNameAr')" :rules="cleanRequiredStringRules" required /></v-col>
                    <v-col cols="12" md="6"><v-text-field v-model="user.fullnameEn" dense :label="$t('FullNameEn')" :rules="cleanRequiredStringRules" required /></v-col>
                    <v-col cols="12" md="6"><v-text-field v-model="user.username" dense :label="$t('Username')" :rules="cleanRequiredStringRules" required /></v-col>
                    <v-col cols="12" md="6"><v-text-field v-model="user.email" dense :label="$t('Email')" :rules="requiredEmailRules" required /></v-col>
                    <v-col cols="12" md="6"><v-text-field v-model="user.mobile" dense :label="$t('Mobile')" /></v-col>
                    <v-col cols="12" md="6"><v-text-field v-model="user.nationalId" type="number" :rules="nationalIdRules" dense :label="$t('NationalId')" /></v-col>
                </v-row>
            </v-container>
        </v-form>
        <template v-slot:actions>
            <v-btn v-if="!edit" color="secondary" class="white--text" tile :disabled="posting" small @click="save()">
                {{ $t("Add") }}
            </v-btn>
            <v-btn v-if="edit" color="primary" class="white--text" tile :disabled="posting" small @click="update()">
                {{ $t("Update") }}
            </v-btn>
            <v-btn color="error" class="white--text" tile :disabled="posting" small @click="$emit('input', false); resetStructureObj()">
                {{ $t("Cancel") }}
            </v-btn>
        </template>
    </CommonDialog>
</template>

<script>
    import CommonDialog from '@/components/controls/CommonDialog'
    import LookupsService from '@/services/LookupsService.js'
    import UsersService from '@/services/UsersService.js'

    export default {
        components: {
            CommonDialog
        },

        props: {
            value: {
                type: Boolean,
                default: false
            },

            edit: {
                type: Boolean,
                default: false
            },

            userId: {
                type: Number,
                default: null
            }
        },

        data() {
            return {
                valid: true,
                posting: false,
                loading: false,
                languages: [],
                cleanRequiredStringRules: [this.$rules.requiredRule, this.$rules.noHtmlTagsRule, this.$rules.onlyAlphanumericRule],
                nationalIdRules: [this.$rules.minLengthRule(10), this.$rules.maxLengthRule(10)],
                requiredEmailRules: [this.$rules.requiredRule,this.$rules.emailRule],
                user: {
                    id: null,
                    fullnameAr: '',
                    fullnameEn: '',
                    username: '',
                    email: '',
                    mobile: null,
                    nationalId: null,
                    defaultLanguageId: 1,
                    approved: true
                },
                emailRules: [
                    v => !!v || this.$t('RequiredField'),
                    v => /.+@.+\..+/.test(v) || this.$t('ValidEmail')
                ],
                nameRules: [
                    v => !!v || this.$t('RequiredField')
                ],
            }
        },


        created() {
            if (this.edit) {
                this.loadUser()
            }
            LookupsService.listLanguages().then((res) => {
                if (res.data) {
                    this.languages = res.data
                }

            }).catch(() => {
                this.$root.$notification(this.$t('ErrorOccurred'), 'error')
            }).finally(() => {
                this.loading = false
            })
        },

        methods: {

            save() {
                if (this.$refs.userForm.validate()) {
                    this.posting = true
                    UsersService.addUser(this.user).then((res) => {
                        this.$refs.userForm.resetValidation()
                        this.resetUserObj()
                        this.$emit('update', true)

                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.posting = false
                    })
                }
            },

            loadUser() {
                UsersService.getUser(this.userId).then((res) => {
                    this.user = res.data

                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            update() {
                if (this.$refs.userForm.validate()) {
                    UsersService.updateUser(this.user).then((res) => {
                        this.$refs.userForm.resetValidation()
                        this.resetUserObj()
                        this.$emit('update', true)
                        this.$emit('input', false)
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
            },


            resetUserObj() {
                if (this.user) {
                    this.user.id = null
                    this.user.fullnameAr = ''
                    this.user.fullnameEn = ''
                    this.user.username = ''
                    this.user.email = ''
                    this.user.mobile = ''
                    this.user.nationalId = null
                    this.user.defaultLanguageId = 1
                    this.user.approved = true
                }
            }
        },
    }
</script>

<style>
</style>