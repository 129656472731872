<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" sm="6">
                <v-text-field v-model="workflowDefinition.name" :rules="rules" counter="25" clearable maxlength="25" :label="$t('ProcessNumber')" required autocomplete="off" />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field v-model="workflowDefinition.title" :rules="rules" clearable maxlength="100" :label="$t('Title')" counter="100" required autocomplete="off" />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field v-model="workflowDefinition.abbreviation" :rules="rules" maxlength="100" clearable :label="$t('Abbreviation')" required autocomplete="off" />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field v-model="workflowDefinition.description" :rules="rules" required clearable :label="$t('Description')" autocomplete="off" />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field v-model="workflowDefinition.store" clearable :label="$t('Store')" />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field v-model="workflowDefinition.titleTemplate" clearable :label="$t('TitleTemplate')" />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field v-model="workflowDefinition.icon" clearable :label="$t('Icon')" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: {
            value: {
                type: Object,
                default: () => { }
            },
        },

        data() {
            return {
                valid: true,
                workflowDefinition: this.value,
                rules: [
                    v => !!v || this.$t('RequiredField')
                ],
            }
        },
    }
</script>

<style>
</style>