import { render, staticRenderFns } from "./CouncilsAndComiteesGeneralInfo.vue?vue&type=template&id=56854068&scoped=true"
import script from "./CouncilsAndComiteesGeneralInfo.vue?vue&type=script&lang=js"
export * from "./CouncilsAndComiteesGeneralInfo.vue?vue&type=script&lang=js"
import style0 from "./CouncilsAndComiteesGeneralInfo.vue?vue&type=style&index=0&id=56854068&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56854068",
  null
  
)

export default component.exports