<template>
    <v-card tile :loading="loading" class="rounded-lg">
        <v-toolbar>
            <h3>{{ $t('SystemSettings') }}</h3>
        </v-toolbar>
        <v-card-text>
            <v-row>
                <v-col cols="4">
                    <h2 class="my-4">{{ $t('PrimaryColor') }}</h2>
                    <v-color-picker v-model="primaryColor" show-swatches class="mb-3" />
                </v-col>
                <v-col cols="4">
                    <h2 class="my-4">{{ $t('SecondaryColor') }}</h2>
                    <v-color-picker v-model="secondaryColor" show-swatches />
                </v-col>
                <v-col cols="4">
                    <h2 class="my-4">{{ $t('NavigationColor') }}</h2>
                    <v-color-picker v-model="navigationColor" show-swatches />
                </v-col>
            </v-row>
            <v-divider />
            <v-row>
                <v-col class="text-center">
                    <v-btn class="ma-2 mt-4" tile color="primary" @click="updateColors">
                        {{ $t('Update') }}
                        <v-icon class="ms-3">mdi-update</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider />

            <v-row class="mt-2">
                <v-col v-for="setting in settings" :key="setting.categoryName" cols="4">
                    <v-card tile color="grey lighten-4" height="800" class="overflow-y-auto">
                        <div class="card-title primary white--text">{{ setting.categoryName }}</div>
                        <v-card-text>
                            <v-row v-for="item in setting.items" :key="item.id" no-gutters>
                                <v-col cols="5" class="ps-0">
                                    <v-chip small label> {{ item.name }}</v-chip>
                                </v-col>
                                <v-col cols="7">
                                    <v-text-field v-model="item.value" outlined dense />
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider />
                        <v-card-actions>
                            <v-btn tile color="primary" :loading="loading" @click="save(setting)">{{ $t('Save') }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import SettingsService from '@/services/SettingsService'

    export default {

        data() {
            return {
                data: null,
                loading: false,
                primaryColor: '',
                secondaryColor: null,
                navigationColor: null,
                settings: []

            }
        },

        created() {
            this.listThemeColors()
            this.listAll()

        },

        methods: {
            save(setting) {
                this.loading = true
                SettingsService.updateSettings(setting).then(res => {
                    if (res.data) {
                        this.$root.$notification(this.$t('SettingsUpdated'), 'success')
                    }
                }).finally(() => {
                    this.loading = false
                })
            },

            listThemeColors() {
                SettingsService.listThemeColors().then(res => {
                    if (res.data) {
                        this.primaryColor = res.data.primaryColor
                        this.secondaryColor = res.data.secondaryColor
                        this.navigationColor = res.data.navigationColor
                    }
                })
            },

            listAll() {
                SettingsService.listAll().then(res => {
                    if (res.data) {
                        this.settings = res.data
                    }
                })
            },

            updateColors() {
                let themeColor = { primaryColor: this.primaryColor, secondaryColor: this.secondaryColor, navigationColor: this.navigationColor }
                SettingsService.updateTheme(themeColor).then(res => {
                    this.$root.$notification(this.$t('ThemeUpdated'), 'success')
                })
            }
        },
    }

</script>

<style>
</style>