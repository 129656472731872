<template>
    <div>
        <v-toolbar color="primary" dense flat>
            <v-toolbar-title class="white--text c-text-h5">
                {{ $t('VotingResults') }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn dark icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card class="pa-2 pt-4">
            <v-card-text v-if="reports.length > 0" style=" max-height:400px; overflow-y:scroll;">
                <v-row v-for="(table, index) in reports" :key="index" style="margin-bottom: 20px;">
                    <v-col cols="12">
                        <h3 class="mb-3 secondary-color">  {{ $t('Agenda') }}: {{table.title}}</h3>
                        <v-data-table class="elevated-table bordered-table"
                                      :headers="getHeaders(table.votes)"
                                      :items="[table]"
                                      dense
                                      item-key="id"
                                      style=" width:90%;  display: flex; flex-direction: column;"
                                      disable-pagination hide-default-footer>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-else-if="reports.length == 0 && !loading" class="text-center">
                <span>{{ $t('NoItemsToShow') }}</span>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import MeetingsService from '@/services/MeetingsService'

    export default ({
        props: {
            id: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                loading: false,
                reports: [],
            }
        },
        mounted() {
        },
        created() {
            this.loadMeetingAgendaVotingReport(this.id)
        },
        methods: {
            loadMeetingAgendaVotingReport(meetingId) {
                this.loading = true
                MeetingsService.listMeetingAgendaVotingReports(meetingId).then((res) => {
                    if (res.data) {
                        this.reports = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            getHeaders(votes) {
                const headers = [{ text: this.$t('Options'), width: '15%' }]
                //const headers = [{ text: this.$t('Agenda'), value: 'totalVotes' }]
                for (const option in votes) {
                    headers.push({ text: option, value: `votes.${option}` })
                }
                headers.push({ text: this.$t('TotalVotes'), value: 'totalVotes', width: '25%' })
                return headers
            },

            close() {
                this.$emit('close')
            },
        },
    })
</script>

<style>
    .bordered-table {
        border: 2px solid #ccc;
    }

    .v-data-table__wrapper {
        flex: 1 1 auto;
    }
</style>