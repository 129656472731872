<template>
    <div>
        <v-card class="mb-3 rounded-lg">
           <v-toolbar><h3> {{ $t('CouncilsAndCommittees') }}</h3></v-toolbar>
            <v-card-text class="mb-2">
                <v-row>
                    <v-col cols="3">
                        <v-text-field v-model="searchModel.meetingReferenceNo"
                                      dense outlined
                                      :label="$t('ReferenceNumber')"
                                      prepend-icon="mdi-numeric" />
                    </v-col>

                    <v-col cols="3">
                        <v-text-field v-model="searchModel.title"
                                      dense
                                      outlined
                                      :label="$t('Title')"
                                      prepend-icon="mdi-book-open-variant" />
                    </v-col>
                </v-row>
                <v-row class="mt-1">
                    <v-col cols="12">
                        <v-btn tile :disabled="loading"
                               class="me-1 pt-0 white--text"
                               color="primary"
                               type="submit"
                               @click="listRecommendations()">
                            {{ $t('Search') }}
                            <v-icon class="ms-2">mdi-magnify</v-icon>
                        </v-btn>
                        <v-btn tile :disabled="loading"
                               class="ms-2 white--text"
                               color="primary"
                               @click="reset()">
                            {{ $t('Reset') }}
                            <v-icon class="ms-2">mdi-refresh</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
       
            <v-card-text>
                <GridWithServerPaging :headers="headers" :total="totalCount" :items="attendanceItems" :items-per-page="10" @onChange="loadAttendanceReport">
                    <template v-slot:[`item.date`]="{ item }">
                        {{ item.date | formatDateGregorian }}
                    </template>
                    <template v-slot:[`item.title`]="{ item }">
                        {{ item.title | trim(60) }}
                    </template>
                    <template v-slot:[`item.attended`]="{ item }">
                        <v-icon v-if="item.attended" color="green">mdi-check</v-icon>
                        <v-icon v-else color="error">mdi-close</v-icon>
                    </template>
                </GridWithServerPaging>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import ReportsService from '@/services/ReportsService.js'
    import GridWithServerPaging from '@/components/controls/GridWithServerPaging.vue'

    export default {
        components: {
            GridWithServerPaging
        },
        data() {
            return {
                loading: false,
                attendanceItems: [],
                headers: [
                    { text: this.$t('CommitteeName'), value: 'committeeName' },
                    { text: this.$t('Title'), value: 'title',width:'30%' },
                    { text: this.$t('MeetingNumber'), value: 'meetingId', align: 'center' },
                    { text: this.$t('Date'), value: 'meetingDate', align: 'center' },
                    { text: this.$t('Attended'), value: 'attended', align: 'center' },
                    { text: this.$t('Username'), value: 'userName' },
                ],
                searchModel: {
                    meetingReferenceNo: null,
                    fromDate: null,
                    toDate: null,
                    title: null,
                },
                options: {
                    page: 1, itemsPerPage: 10
                },
                totalCount: null,
            }
        },

        mounted() {
            this.loadAttendanceReport(this.options)
        },
        methods: {
            loadAttendanceReport(options) {

                const page = options != undefined ? options.page : this.options.page
                const pageSize = options != undefined ? options.itemsPerPage : this.options.itemsPerPage
                this.loading = true
                ReportsService.getAttendnaceReport(page, pageSize).then((res) => {
                    this.attendanceItems = res.data.data
                    this.totalCount = res.data.total
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            showMeetingDetails(committee) {
                this.selectedCommitteeId = committee.id
                this.showMeetingCountDialogue = true
            }
        },
    }
</script>

<style>
</style>