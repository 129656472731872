import { mainApiAxios as axios } from '@/plugins/axios'

export default {

    getStructure(structureId) {
        return axios.get(`structures/${structureId}`)
    },

    listStructures() {
        return axios.get('structures')
    },
    listStructuresWithAnnualPlan() {
        return axios.get('structures/with-annual-plans')
    },

    listExternalStructures() {
        return axios.get('structures/external')
    },

    listOrganization(onlyActive) {
        return axios.get(`structures/organization?onlyActive=${onlyActive}`)
    },

    addStructure(structureObj) {
        return axios.post('structures', structureObj)
    },

    updateStructure(structureId, structureObj) {
        return axios.put(`structures/${structureId}`, structureObj)
    },

    listUsersInStructure(structureId) {
        return axios.get(`structures/${structureId}/users`)
    },

    listRoles(structureId) {
        return axios.get(`structures/${structureId}/roles`)
    },

    removeUserFromStructure(structureId, userId) {
        return axios.delete(`structures/${structureId}/user/${userId}`)
    },

    addUserToStructure(structureId, userId, roleId) {
        return axios.post(`structures/${structureId}/add-user/${userId}/role/${roleId}`,)
    }
}