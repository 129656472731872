import axios from 'axios'
import store from '@/plugins/store'
import router from '@/plugins/router'
import { startSignalRConnection, stopSignalRConnection } from '@/plugins/hub/myHubPlugin.js'

let isRefreshing = false // Flag to track if a refresh token is in progress
let failedRequestsQueue = [] // Queue to store requests while refreshing

const mainApiAxios = axios.create({
    baseURL: process.env.VUE_APP_MAIN_API,
    timeout: 500000,
    headers: {
        'Accept': 'application/json; charset=utf-8',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '-1',
    },
})

mainApiAxios.interceptors.request.use(
    (config) => {
        const token = store.getters['user/token']
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    },
)

mainApiAxios.interceptors.response.use(
    (res) => {
        if (res.config.responseType === 'blob') {
            return res
        }

        return res.data
    },
    async (error) => {
        const originalRequest = error.config

        // Handle token refresh if 401 Unauthorized error occurs
        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedRequestsQueue.push({ resolve, reject })
                })
                    .then((token) => {
                        originalRequest.headers['Authorization'] = `Bearer ${token}`
                        return mainApiAxios(originalRequest)
                    })
                    .catch((err) => {
                        return Promise.reject(err)
                    })
            }

            originalRequest._retry = true
            isRefreshing = true

            const refreshToken = store.getters['user/refreshToken']
            if (refreshToken) {
                try {
                    // Call the refresh token API
                    const response = await axios.post(`${process.env.VUE_APP_MAIN_API}auth/refresh-token`, { refreshToken })

                    const newToken = response.data.data?.token
                    if (!newToken) {
                        failedRequestsQueue=[]
                    }
                    if (newToken) {
                        store.dispatch('user/updateToken', newToken)
                        originalRequest.headers['Authorization'] = `Bearer ${newToken}`

                        await stopSignalRConnection()
                        await startSignalRConnection()

                        // Resolve all queued requests
                        failedRequestsQueue.forEach(({ resolve }) => resolve(newToken))
                        failedRequestsQueue = []
                        return mainApiAxios(originalRequest) // Retry the original request
                    } else {
                        // If no token is returned, reject all pending requests
                        failedRequestsQueue.forEach(({ reject }) => reject(error))
                        failedRequestsQueue = []
                        return Promise.reject(error) // Reject this request
                    }
                    
                } catch (refreshError) {
                    // Handle refresh token errors (e.g., token expired)
                    originalRequest._retry = false // Prevent retrying the API
                    store.dispatch('user/removeUserSession')

                    // Redirect to login if not already there
                    if (router.currentRoute.name !== 'login') {
                        router.push({ name: 'login' })
                    }

                    // Reject all pending requests
                    failedRequestsQueue = []
                } finally {
                    isRefreshing = false
                }
            } else {
                // If no refresh token, remove session and redirect to login
                store.dispatch('user/removeUserSession')
                if (router.currentRoute.name !== 'login') {
                    router.push({ name: 'login' })
                }
                return Promise.reject(error)// Reject the original request
            }
        }

        // Handle other 401 errors
        if (error.response.status === 401 && error.response.data.success === false) {
            router.push({ name: 'not-found' })
        }

        return Promise.reject(error)
    }
)




export { mainApiAxios }