<template>
    <v-card tile>
        <v-container>
            <v-row>
                <v-col cols="12" sm="4" md="4">
                    <v-checkbox v-model="rules.CanRedirect" :label="$t('CanRedirect')" hide-details />
                </v-col>
                <v-col cols="12" sm="4" md="4">
                    <v-checkbox v-model="rules.CanRework" :label="$t('CanRework')" hide-details />
                </v-col>
                <v-col cols="12" sm="4" md="4">
                    <v-checkbox v-model="rules.CanClose" :label="$t('CanClose')" hide-details />
                </v-col>
                <v-col cols="12" sm="4" md="4">
                    <v-checkbox v-model="rules.CanComplete" :label="$t('CanComplete')" hide-details />
                </v-col>
                <v-col cols="12" sm="4" md="4">
                    <v-checkbox v-model="rules.CanAnnotate" :label="$t('CanAnnotate')" hide-details />
                </v-col>
                <v-col cols="12" sm="4" md="4">
                    <v-checkbox v-model="rules.CanStamp" :label="$t('CanStamp')" hide-details />
                </v-col>
                <v-col cols="12" sm="4" md="4">
                    <v-checkbox v-model="rules.ViewNotes" :label="$t('ViewNotes')" hide-details />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" md="6">
                    <v-select v-model="rules.NextProcessStageId" clearable item-text="name" item-value="id" :items="processStages" :label="$t('NextProcessStageId')" outlined dense />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <v-select v-model="rules.PreviousProcessStageId" clearable item-text="name" item-value="id" :items="processStages" :label="$t('PreviousProcessStageId')" outlined dense />
                </v-col>
            </v-row>
        </v-container>
        <v-divider class="mt-8" />
        <v-card-actions class="d-block text-end grey lighten-3">
            <v-btn tile color="secondary" small class="white--text" @click="saveRules">
                {{ $t('Save') }}
            </v-btn>
            <v-btn tile color="error" small class="white--text" @click="$emit('close')">
                {{ $t('Cancel') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import LookupsService from '@/services/LookupsService.js'

    export default {

        props: {
            task: {
                type: Object,
                default: null
            },
        },
        data() {
            return {
                processStages: [],
                rules: {
                    CanRedirect: false,
                    CanRework: false,
                    CanClose: false,
                    CanComplete: false,
                    CanAnnotate: false,
                    CanStamp: false,
                    CanAddPredefinedAnnotations: false,
                    ViewNotes: false,
                    PreviousProcessStageId: null,
                    NextProcessStageId: null,
                }
            }
        },

        created() {
            this.mapRules()
            this.listLookups()

        },

        methods: {
            mapRules() {
                if (this.task.ActivityRule) {
                    this.rules = this.task.ActivityRule
                }
            },

            saveRules() {
                this.$emit('update', this.rules)
            },

            listLookups() {
                this.loading = true
                let promises = [
                    LookupsService.listProcessStages(),
                ]

                Promise.all(promises)
                    .then(
                        ([
                            processStages,
                        ]) => {
                            this.processStages = processStages.data.map(x => ({ name: this.$t(x.name), id: x.id }))
                        },
                    ).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
            },
        },
    }
</script>
