<template>
    <v-card tile :loading="loading">
<v-stepper v-model="e1">
            <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" step="1" color="primary">
                    {{ $t('WorkflowDefinition') }}
                </v-stepper-step>

                <v-divider />

                <v-stepper-step :complete="e1 > 2" step="2" color="primary">
                    {{ $t('WorkflowProperties') }}
                </v-stepper-step>

                <v-divider />

                <v-stepper-step step="3" :complete="e1 > 3" color="primary">
                    {{ $t('FormBuilder') }}
                </v-stepper-step>

                <v-divider />

                <v-stepper-step step="4" :complete="e1 > 4" color="primary">
                    {{ $t('WorkflowDesigner') }}
                </v-stepper-step>

                <v-divider />

                <v-stepper-step step="5" :complete="e1 > 5" color="primary">
                    {{ $t('Publish') }}
                </v-stepper-step>
                <v-divider />
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1" class="pa-0">
                    <v-card tile :elevation="1" color="secondary">
                        <v-card-text class="pa-2 text-end">
                            <v-btn color="primary" tile class="white--text" small @click="getWorkflowDefinition">
                                {{ $t('Next') }}
                                <v-icon>
                                    mdi-reply
                                </v-icon>
                            </v-btn>
                        </v-card-text>
                    </v-card>
                    <v-card tile class="mb-3" :elevation="0">
                        <v-form ref="workflowDefForm" v-model="valid">
                            <workflow-definition v-model="workflowObject.workflowDefinition" />
                        </v-form>
                    </v-card>
                </v-stepper-content>

                <v-stepper-content step="2" class="pa-0">
                    <v-card tile color="secondary">
                        <v-card-text class="pa-2">
                            <v-btn tile color="error" small @click="e1 = 1">
                                <v-icon dark>
                                    mdi-reply mdi-flip-h
                                </v-icon>
                                {{ $t('Previous') }}
                            </v-btn>
                            <v-btn tile color="primary" small class="float-end" dark @click="getWorkflowProperties">
                                {{ $t('Next') }}
                                <v-icon>
                                    mdi-reply
                                </v-icon>
                            </v-btn>
                        </v-card-text>
                    </v-card>
                    <v-card tile class="mb-3" :elevation="0">
                        <v-card-text>
                            <workflow-properties v-model="workflowObject.workflowProperties" />
                        </v-card-text>
                    </v-card>
                </v-stepper-content>

                <v-stepper-content step="3" class="pa-0">
                    <v-card tile :elevation="1" color="secondary">
                        <v-card-text class="pa-2">
                            <v-btn color="primary" tile small class="white--text float-end" @click="getFormData()">
                                {{ $t('Next') }}
                                <v-icon>
                                    mdi-reply
                                </v-icon>
                            </v-btn>

                            <v-btn color="error" tile class="ms-3" small @click="e1 = 2">
                                <v-icon>
                                    mdi-reply mdi-flip-h
                                </v-icon>
                                {{ $t('Previous') }}
                            </v-btn>
                        </v-card-text>
                    </v-card>
                    <v-card tile class="mb-3" :elevation="0">
                        <FormBuilder ref="formData" />
                    </v-card>
                </v-stepper-content>

                <v-stepper-content step="4" class="pa-0">
                    <v-card tile :elevation="1" color="secondary">
                        <v-card-text class="pa-2">
                            <v-btn color="primary" tile small class="white--text float-end" @click="getWorkflowDesigner">
                                {{ $t('Next') }}
                                <v-icon>
                                    mdi-reply
                                </v-icon>
                            </v-btn>

                            <v-btn color="error" tile class="ms-3" small @click="e1 = 3">
                                <v-icon>
                                    mdi-reply mdi-flip-h
                                </v-icon>
                                {{ $t('Previous') }}
                            </v-btn>
                        </v-card-text>
                    </v-card>
                    <v-card tile class="mb-3" :elevation="0">
                        <Designer ref="workflowDesigner" :form-data="workflowObject.formData" />
                    </v-card>
                </v-stepper-content>

                <v-stepper-content step="5" class="pa-0">
                    <v-card tile :elevation="1" color="secondary">
                        <v-card-text class="pa-2">
                            <v-btn color="error" tile class="ms-3" small @click="e1 = 4">
                                <v-icon>
                                    mdi-reply mdi-flip-h
                                </v-icon>
                                {{ $t('Previous') }}
                            </v-btn>
                        </v-card-text>
                    </v-card>
                    <v-card tile class="mb-3" :elevation="0">
                        <publish ref="publish" @clicked="publishWorkflow" />
                    </v-card>
                </v-stepper-content>
            </v-stepper-items>
</v-stepper>
    </v-card>
</template>

<script>
    import WorkflowDefinition from '@/components/app/admin/case/WorkflowDefinition.vue'
    import WorkflowProperties from '@/components/app/admin/case/WorkflowProperties.vue'
    import Designer from '@/components/app/admin/case/Designer.vue'
    import Publish from '@/components/app/admin/case/Publish.vue'
    import FormBuilder from '@/components/app/admin/case/FormBuilder.vue'

    import { ActivityTypesEnum } from '@/helpers/constants/enumerations.js'
    import CaseEngineService from '@/services/CaseEngineService.js'
    import { jsPlumb as JSPlumb } from 'jsplumb'
    import { insertParam } from '@/helpers/common.js'


    export default {

        components: {
            WorkflowDefinition, WorkflowProperties, Designer, FormBuilder, Publish
        },

        data() {
            return {
                workflowObject: {
                    activityDefinitions: null,
                    transitions: null,
                    workflowDefinition: { name: '', title: '', abbreviation: '', description: '', icon: '', store: '', titleTemplate: '' },
                    workflowProperties: null,
                    formData: null
                },
                sections: {
                    fillCount: 0
                },
                valid: true,
                dataChanged: true,
                loading: false,
                e1: 1,
            }
        },

        mounted() {
            if (this.$route.query.id) {
                this.loadProcess(this.$route.query.id)
            }
        },

        updated() {
            let workflowDesignerComponent = this.$refs.workflowDesigner
            if (workflowDesignerComponent.instance)
                workflowDesignerComponent.instance.repaintEverything()
        },

        methods: {

            getWorkflowDefinition() {
                if (this.$refs.workflowDefForm.validate()) {
                    this.sections.fillCount = 1
                    this.e1 = 2
                }
            },

            getWorkflowProperties() {
                this.e1 = 3
            },

            getWorkflowDesigner() {
                let designerComponent = this.$refs.workflowDesigner
                let activityDefinitions = designerComponent.nodes
                let transitions = designerComponent.connections
                let allConnections = designerComponent.instance.getAllConnections()
                let savedTransitions = designerComponent.connections
                this.dataChanged = this.cehckChanges(designerComponent.nodes, allConnections)
                for (var i = 0; i < activityDefinitions.length; i++) {
                    let currentDomNode = document.getElementById(activityDefinitions[i].BlockId)
                    let node = activityDefinitions[i]
                    let positions = this.findPos(currentDomNode)
                    node.positionX = positions[0]
                    node.positionY = positions[1]
                }
                let result = savedTransitions.filter(o1 => !allConnections.some(o2 => o1.Id === o2.id))
                if (result.length > 0) {
                    for (var i = 0; i < result.length; i++) {
                        savedTransitions = savedTransitions.filter(st => st.Id !== result[i].Id)
                        transitions = savedTransitions
                    }
                }
                for (var i = 0; i < allConnections.length; i++) {
                    let plumbConnection = allConnections[i]
                    let itemExists = savedTransitions.filter(tra => tra.Id === plumbConnection.id)
                    if (itemExists.length > 0) {
                        let transition = itemExists[0]
                        transition.SourceAnchorPosition = plumbConnection.endpoints[0].anchor.type
                        transition.DestinationAnchorPosition = plumbConnection.endpoints[1].anchor.type
                        transition.PageSourceId = plumbConnection.sourceId
                        transition.PageTargetId = plumbConnection.targetId
                    }
                    else {
                        let newConnection = {
                            Id: plumbConnection.id, complete: true, condition: '', destinationAnchorPosition: plumbConnection.endpoints[1].anchor.type,
                            sourceAnchorPosition: plumbConnection.endpoints[0].anchor.type, emailTemplateId: 0, labelName: '',
                            originalId: '', pageSourceId: plumbConnection.sourceId, pageTargetId: plumbConnection.targetId,
                        }
                        transitions.push(newConnection)
                    }
                }
                this.workflowObject.transitions = transitions
                this.workflowObject.activityDefinitions = activityDefinitions
                this.e1 = 5
            },

            getFormData() {
                let formData = this.$refs.formData
                this.workflowObject.formData = formData.formFieldsArray
                this.e1 = 4
            },

            cehckChanges(nodes, connections) {
                if (this.$route.query.id) {
                    let nodeChanges = this.comparer(this.originalNodeIds, nodes.map(n => n.BlockId))
                    let connectionChanges = this.comparer(this.originalConnectionIds, connections.map(n => n.id))
                    if (nodeChanges && connectionChanges)
                        return false
                    else
                        return true
                }
                else
                    return false
            },

            comparer(a, b) {
                if (a.length != b.length)
                    return false
                else {
                    for (var i = 0; i < a.length; i++)
                        if (a[i] != b[i])
                            return false
                    return true
                }
            },

            findPos(obj) {

                var curleft, curtop = 0
                if (obj) {
                    if (obj.offsetParent) {
                        curleft = obj.offsetLeft
                        curtop = obj.offsetTop
                    }
                }
                return [curleft, curtop]
            },

            publishWorkflow: function (newVersion) {

                if (this.dataChanged && !newVersion) {
                    this.$root.$notification(this.$t('NewVersionRequired'), 'info')
                    this.$refs.publish.loading = false
                }
                else {
                    var jsonString = JSON.stringify(this.workflowObject)
                    CaseEngineService.publish(jsonString, this.$route.query.id || 0, newVersion)
                        .then(res => {
                            if (res.success) {
                                this.$root.$notification(this.$t('WorkflowPublished'), 'success')
                                if (res.data) {
                                    insertParam('id', res.data)
                                }
                            }
                        }).catch(err => {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                            this.$refs.publish.loading = false
                        }).finally(() => {
                            this.$refs.publish.loading = false
                        })
                }
            },

            loadProcess(processId) {
                this.loading = true
                CaseEngineService.getProcess(processId)
                    .then(res => {
                        if (res.data) {
                            this.setWorkflowDefinition(res.data.workflowDefinition)
                            this.workflowObject.workflowProperties = res.data.workflowProperties
                            let currentNodes = JSON.parse(res.data.workflowDefinition.designer).ActivityDefinitions
                            let currentTransitions = JSON.parse(res.data.workflowDefinition.designer).Transitions
                            let formData = JSON.parse(res.data.workflowDefinition.designer).FormData || []
                            this.originalConnectionIds = currentTransitions.map(a => a.Id)
                            this.originalNodeIds = currentNodes.map(a => a.BlockId)
                            let workflowDesignerComponent = this.$refs.workflowDesigner
                            let formDataComponent = this.$refs.formData

                            workflowDesignerComponent.nodes = currentNodes
                            workflowDesignerComponent.connections = currentTransitions

                            formData.forEach(function (part, index) {
                                this[index].Visible = false
                            }, formData)
                            formDataComponent.formFieldsArray = formData

                            let instance = window.jsp = jsPlumb.getInstance({
                                DragOptions: { cursor: 'pointer', zIndex: 2000 },
                                ConnectionOverlays: workflowDesignerComponent.connectionOverlays,
                                Container: workflowDesignerComponent.container
                            })

                            workflowDesignerComponent.instance = instance
                            workflowDesignerComponent.$nextTick(function () {
                                instance.batch(function () {
                                    currentNodes.map(function (node, key) {
                                        let sourceAnchors = []
                                        let targetAnchors = []
                                        switch (node.TypeId) {
                                            case ActivityTypesEnum.StartActivity:
                                                sourceAnchors = ['TopCenter', 'RightMiddle', 'BottomCenter', 'LeftMiddle', 'TopLeft', 'BottomLeft']
                                                targetAnchors = []
                                                break
                                            case ActivityTypesEnum.EndActivity:
                                                sourceAnchors = []
                                                targetAnchors = ['TopCenter', 'RightMiddle', 'BottomCenter', 'LeftMiddle', 'TopLeft', 'BottomLeft']
                                                break
                                            case ActivityTypesEnum.TaskActivity:
                                                sourceAnchors = ['RightMiddle', 'TopRight', 'BottomRight', 'LeftMiddle']
                                                targetAnchors = ['TopCenter', 'BottomCenter', 'TopLeft', 'BottomLeft']
                                                break
                                        }
                                        workflowDesignerComponent._addEndpoints(node.BlockId, sourceAnchors, targetAnchors, workflowDesignerComponent.sourceEndpoint, workflowDesignerComponent.targetEndpoint, instance)
                                        instance.draggable(jsPlumb.getSelector('.window'), { grid: [20, 20] })
                                        document.getElementById(node.BlockId).style.left = (node.PositionX) + 'px'
                                        document.getElementById(node.BlockId).style.top = node.PositionY + 'px'
                                    })

                                    currentTransitions.map(function (trans, key) {
                                        instance.connect({ uuids: [trans.PageSourceId + trans.SourceAnchorPosition, trans.PageTargetId + trans.DestinationAnchorPosition], detachable: true, reattach: false })
                                        instance.getAllConnections()[key].getOverlay('label').setLabel(trans.Complete === true
                                            ? "<i class='mdi mdi-check-all mdi-24px'></i>"
                                            : "<i class='mdi mdi-close-outline mdi-24px error--text'></i>")
                                        instance.getAllConnections()[key].id = trans.Id
                                    })
                                })
                            })
                        }
                    }).catch(err => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
            },

            setWorkflowDefinition(data) {
                this.workflowObject.workflowDefinition.name = data.name
                this.workflowObject.workflowDefinition.title = data.title
                this.workflowObject.workflowDefinition.abbreviation = data.abbreviation
                this.workflowObject.workflowDefinition.description = data.description
                this.workflowObject.workflowDefinition.icon = data.icon
                this.workflowObject.workflowDefinition.store = data.store
                this.workflowObject.workflowDefinition.titleTemplate = data.titleTemplate
            }
        },
    }
</script>

<style>
</style>