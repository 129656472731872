<template>
    <v-card class="mx-auto white--text my-8  elevation-5 text-center" max-width="500" tile>
        <v-card-text>
            <h3 class="primary--text">{{ $t('PublishWeb') }}</h3>
            <h4>
                <v-switch v-model="newVersion" class="primary--text" :label="$t('NewVersion')" />
            </h4>
            <p>
                <v-btn :loading="loading"
                       :disabled="loading" small
                       color="primary"
                       class="white--text"
                       fab
                       @click="publish">
                    <v-icon dark>
                        mdi-cloud-upload
                    </v-icon>
                </v-btn>
            </p>
            <div class="text--primary" />
        </v-card-text>
        <v-card-actions />
    </v-card>
</template>

<script lang="ts">

    export default {

        data() {
            return {
                newVersion: false,
                loading: false
            }
        },

        methods: {

            publish() {
                this.loading = true
                this.$emit('clicked', this.newVersion)
            }
        },
    }
</script>

<style>
</style>