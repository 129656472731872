import { mainApiAxios as axios } from '@/plugins/axios'


export default {

    listDataSources() {
        return axios.get('dataSources')
    },

    listSimpleDataSources() {
        return axios.get('dataSources/simple-list')
    },

    updateDataSource(dataSourceId, dataSourceObj) {
        return axios.put(`dataSources/${dataSourceId}`, dataSourceObj)
    },

    addDataSource(dataSourceObj) {
        return axios.post('dataSources', dataSourceObj)
    },

    deleteDataSource(dataSourceId) {
        return axios.delete(`dataSources/${dataSourceId}`)
    }
}