import { mainApiAxios as axios } from '@/plugins/axios'


export default {

    listRoles() {
        return axios.get('roles')
    },

    updateRole(roleId, roleObj) {
        return axios.put(`roles/${roleId}`, roleObj)
    },

    addRole(roleObj) {
        return axios.post('roles', roleObj)
    },

    deleteRole(roleId) {
        return axios.delete(`roles/${roleId}`)
    }
}