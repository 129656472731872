import { mainApiAxios as axios } from '@/plugins/axios'

export default {

    login(username, password) {
        return axios.post('auth', { username: username, password: password })
    },

    requestVerificationCode(requestVerificationCode) {
        return axios.post('auth/verify', requestVerificationCode)
    },

    checkVerificationCode(requestVerificationCode) {
        return axios.post('auth/2fa', requestVerificationCode)
    },
    getLoginOptions() {
        return axios.get('auth/login-options')
    },
}