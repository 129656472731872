<template>
    <v-card tile :loading="loading">
        <v-data-table :headers="headers" hide-default-footer :items="agendas" :items-per-page="20" :expanded.sync="expanded" :single-expand="singleExpand" show-expand item-key="id">
            <template v-slot:expanded-item="{ item }">
                <td :colspan="headers.length">
                    <v-card class="ma-4" tile>
                        <v-card-text>
                            <v-row v-for="(topic, topicIndex) in item.agendaTopics" :key="topicIndex">
                                <v-col cols="12" md="12">
                                    <v-icon>mdi-arrow-left-bold</v-icon>
                                    {{ topic }}
                                </v-col>
                            </v-row>
                            <v-row v-if="item.agendaTopics.length == 0">
                                <v-col cols="12" md="12">
                                    {{ $t('NoItemsToShow') }}
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </td>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import MeetingsService from '@/services/MeetingsService.js'

    export default {
        components: {
        },
        directives: {
        },
        filters: {
        },
        props: {
            meetingId: {
                type: Number,
                default: null
            },
        },
        data() {
            return {
                loading: false,
                singleExpand: true,
                expanded: [],
                agendas: [],
                headers: [
                    { text: this.$t('Subject'), value: 'title' },
                    { text: this.$t('Duration'), value: 'duration' },
                    { text: this.$t('Voting'), value: 'voting' },
                    { text: this.$t('RelatedTask'), value: 'duty' },
                ],
            }
        },
        computed: {
        },
        watch: {
        },
        beforeCreate() {
        },
        created() {
            this.listAgendas(this.meetingId)
        },
        beforeMount() {
        },
        mounted() {
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeDestroy() {
        },
        destroyed() {
        },
        methods: {
            listAgendas(meetingId) {
                this.loading = true
                MeetingsService.listAgendas(meetingId).then(res => {
                    if (res.data) {
                        this.agendas = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            }
        },
    }
</script>

<style>
</style>