import { render, staticRenderFns } from "./AddMeetingOrAgendaAttachment.vue?vue&type=template&id=4e1cb390"
import script from "./AddMeetingOrAgendaAttachment.vue?vue&type=script&lang=ts"
export * from "./AddMeetingOrAgendaAttachment.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports