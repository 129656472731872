<template>
    <v-card flat>
        <v-card-text>
            <v-row class="mt-5 mb-5" justify="space-between" align="center" no-gutters>
                <v-col cols="6">
                    <v-list style="max-height:500px" class="overflow-y-auto" two-line subheader>
                        <v-list-item>
                            <div style="width:100%" class="d-flex justify-space-between align-center">
                                <v-list-item-title><b>{{ $t('AvailableColumns') }}</b></v-list-item-title>
                                <v-btn color="primary" small tile @click="addAllColumns">{{ $t('Select') }} {{ columns.length }} {{ $t('Column') }}</v-btn>
                            </div>
                        </v-list-item>
                        <v-divider />
                        <v-list-item v-for="item in columns" :key="item.FieldName">
                            <v-list-item-content>
                                <v-list-item-title v-text="item.FieldName" />
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-icon v-if="addedColumns.includes(item)" class="ma-0 pa-0" color="success lighten-1">mdi-check-circle</v-icon>
                                <v-btn v-else small icon @click="addColumn(item)">
                                    <v-icon color="primary lighten-1">mdi-plus</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-col>

                <v-col cols="6">
                    <v-list class="overflow-y-auto" style="height:500px;max-height:500px" two-line subheader>
                        <v-list-item>
                            <div style="width:100%" class="d-flex justify-space-between align-center">
                                <v-list-item-title><b>{{ $t('SelectedColumns') }}</b></v-list-item-title>
                                <v-btn color="error" small tile @click="removeAllColumns">{{ $t('Remove') }}</v-btn>
                            </div>
                        </v-list-item>
                        <v-divider />
                        <v-list-item v-for="item in addedColumns" :key="item.FieldName" @click="() => {}">
                            <v-list-item-content>
                                <v-list-item-title v-text="item.FieldName" />
                                <v-list-item-subtitle v-text="item.FieldName" />
                            </v-list-item-content>

                            <v-list-item-action>
                                <div>
                                    <v-btn icon @click="addFilter(item)">
                                        <v-icon color="secondary lighten-1">mdi-filter</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="removeColumns(item)">
                                        <v-icon color="red lighten-2">mdi-delete-outline</v-icon>
                                    </v-btn>
                                </div>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>


            <v-toolbar color="primary" dense>
                <span class="subtitle-1 white--text">FILTERS</span>
                <v-spacer />
                <v-btn tile color="secondary" class="float-end" @click="addFilter">
                    <v-icon>mdi-plus</v-icon>{{ $t('AddFilter') }}
                </v-btn>
            </v-toolbar>
            <v-divider />
            <div v-if="addedFilters.length > 0" class="mt-7">
                <v-row v-for="(filter, index) in addedFilters"
                       :key="filter.index"
                       align="center"
                       justify="start"
                       flex-wrap
                       no-gutters>
                    <v-col cols="1">
                        <div class="d-flex align-center">
                            <v-btn color="error" icon @click="removeFilter(filter.index)">
                                <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                            <v-btn-toggle v-model="addedFilters[index].andOrOr" small>
                                <v-btn small text value="&&">
                                    and
                                </v-btn>
                                <v-btn small text value="||">
                                    or
                                </v-btn>
                            </v-btn-toggle>
                        </div>
                    </v-col>
                    <v-col class="mr-2" cols="2">
                        <v-select v-model="addedFilters[index].column" dense :items="selectedColumns" :label="$t('Column')" @change="clearFilterDataUponColumnChange(index)" />
                    </v-col>
                    <v-col v-if="addedFilters[index].column" cols="2">
                        <v-select v-model="addedFilters[index].filter" dense :items="columnFilters(filter.column)" class="mr-2" :label="$t('Condition')" />
                    </v-col>
                    <v-col v-if="addedFilters[index].column && addedFilters[index].filter && ['DATE', 'TIME', 'DATETIME', 'System.DateTime'].includes( colType(addedFilters[index].column) ) === false && addedFilters[index].file === null " class="mr-2" cols="1">
                        <v-text-field v-model="addedFilters[index].inputField1" :rules="[rules.required]" autocomplete="new-value" :label="$t('Value')" dense />
                    </v-col>
                    <v-col v-if="addedFilters[index].column && addedFilters[index].filter && addedFilters[index].filter === 'between' && colType(addedFilters[index].column) === 'NUMERIC'" cols="1">
                        <div class="d-flex justify-center">
                            <span class="text-uppercase">and</span>
                        </div>
                    </v-col>
                    <v-col v-if="addedFilters[index].column && addedFilters[index].filter && addedFilters[index].filter === 'between' && colType(addedFilters[index].column) === 'NUMERIC' " cols="1">
                        <v-text-field v-model="addedFilters[index].inputField2" required :label="$t('Value')" :rules="[rules.required]" dense />
                    </v-col>
                    <v-col v-if="addedFilters[index].column && addedFilters[index].filter && addedFilters[index].filter == 'in' " cols="1">
                        <div class="d-flex align-center">
                            <v-file-input v-model="addedFilters[index].file" dense :hide-input="addedFilters[index].file === null" accept=".txt" label="Upload file" @change="uploadFile($event, index)" />
                        </div>
                    </v-col>
                    <v-col v-if="addedFilters[index].column && addedFilters[index].filter && addedFilters[index].filter !== 'in' && ['DATE', 'DATETIME', 'System.DateTime'].includes( colType(addedFilters[index].column) )" class="mr-2" cols="1">
                        <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field v-model="addedFilters[index].datePicker1" :label="addedFilters[index].filter === 'between' ? 'Start date' : 'date' " readonly dense :rules="[rules.required]" v-on="on" />
                            </template>
                            <v-date-picker v-model="addedFilters[index].datePicker1" no-title scrollable />
                        </v-menu>
                    </v-col>
                    <v-col v-if="addedFilters[index].column && addedFilters[index].filter && addedFilters[index].filter !== 'in' && ['TIME', 'DATETIME', 'System.DateTime'].includes(colType(addedFilters[index].column))" cols="1">
                        <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field v-model="addedFilters[index].timePicker1" :label="addedFilters[index].filter === 'between' ? 'Start time' : 'time' " readonly dense :rules="[rules.required]" v-on="on" />
                            </template>
                            <v-time-picker v-model="addedFilters[index].timePicker1" use-seconds format="24hr" scrollable />
                        </v-menu>
                    </v-col>
                    <v-col v-if="addedFilters[index].column && addedFilters[index].filter && ['DATE', 'DATETIME', 'TIME', 'System.DateTime'].includes(colType(addedFilters[index].column)) && addedFilters[index].filter === 'between'" cols="1">
                        <div class="d-flex justify-center">
                            <span class="text-uppercase">and</span>
                        </div>
                    </v-col>
                    <v-col v-if="addedFilters[index].column && addedFilters[index].filter && ['DATE', 'DATETIME', 'System.DateTime'].includes(colType(addedFilters[index].column)) && addedFilters[index].filter === 'between'" cols="1">
                        <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field v-model="addedFilters[index].datePicker2" label="End date" readonly dense :rules="[rules.required]" v-on="on" />
                            </template>
                            <v-date-picker v-model="addedFilters[index].datePicker2" no-title scrollable />
                        </v-menu>
                    </v-col>
                    <v-col v-if="addedFilters[index].column && addedFilters[index].filter && ['TIME', 'DATETIME', 'System.DateTime'].includes(colType(addedFilters[index].column)) && addedFilters[index].filter === 'between'" class="ml-2" cols="1">
                        <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field v-model="addedFilters[index].timePicker2" label="End time" readonly dense :rules="[rules.required]" v-on="on" />
                            </template>
                            <v-time-picker v-model="addedFilters[index].timePicker2" use-seconds format="24hr" scrollable />
                        </v-menu>
                    </v-col>
                </v-row>
            </div>

            <div class="mt-5">
                <v-list-item-title class="mb-5"><b>Query preview</b></v-list-item-title>
                <v-divider />
                <pre class="pb-5 pt-2" v-text="query" />
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: 'QueryBuilder',

        props: {
            formData: {
                type: Array,
                default: () => []
            },
        },

        data() {
            return {
                addedFilters: [],
                filterIndex: 0,
                columnSearch: '',
                validRowNumberForm: true,
                validFilters: false,
                numberRows: 10000,
                addedColumns: [],
                file: '',
                rules: {
                    required: value => !!value || 'Required.',
                    maxAndIntQueryRows: value =>
                        (!!parseInt(value) && value >= 0 && value <= 10000) ||
                        'Please insert a number between 1 and 10000 rows'
                },
                selectAllColumns: false,

                filters: [
                    'equal',
                    'not equal',
                    'greater than',
                    'less than',
                    'between',
                    'in'
                ],

                selectedTable: null,
                columns: [
                ],
                operators: {
                    'greater than': '>',
                    'less than': '<',
                    equal: '==',
                    'not equal': '!=',
                    in: 'in',
                    between: 'between'
                }
            }
        },
        computed: {
            computedDateFormatted() {
                return this.formatDate(this.date)
            },
            query() {
                const selectStatement = ''
                if (this.$data.addedFilters.length > 0) {
                    let conditionsStatement = this.$data.addedFilters.map(
                        (filter, index) => {
                            let statement =
                                index > 0
                                    ? `${filter.andOrOr} '${filter.column}'`
                                    : `'${filter.column}'`
                            if (
                                ['equal', 'not equal', 'greater than', 'less than'].includes(
                                    filter.filter
                                ) &&
                                this.colType(filter.column) === 'NUMERIC'
                            ) {
                                statement = `${statement} ${this.$data.operators[filter.filter]
                                    } ${filter.inputField1}`
                                return statement
                            } else if (
                                ['equal', 'not equal'].includes(filter.filter) &&
                                this.colType(filter.column) === 'System.String'
                            ) {
                                statement = `${statement} ${this.$data.operators[filter.filter]
                                    } '${filter.inputField1}'`
                                return statement
                            } else if (
                                ['equal', 'not equal', 'greater than', 'less than'].includes(
                                    filter.filter
                                ) &&
                                this.colType(filter.column) === 'DATE'
                            ) {
                                statement = `${statement} ${this.$data.operators[filter.filter]
                                    } '${filter.datePicker1}'`
                                return statement
                            } else if (
                                filter.filter === 'between' &&
                                this.colType(filter.column) === 'DATE'
                            ) {
                                statement = `${statement} ${this.$data.operators[filter.filter]
                                    } '${filter.datePicker1}' and '${filter.datePicker2}'`
                                return statement
                            } else if (
                                ['equal', 'not equal', 'greater than', 'less than'].includes(
                                    filter.filter
                                ) &&
                                this.colType(filter.column) === 'TIME'
                            ) {
                                statement = `${statement} ${this.$data.operators[filter.filter]
                                    } '${filter.timePicker1}'`
                                return statement
                            } else if (
                                filter.filter === 'between' &&
                                this.colType(filter.column) === 'TIME'
                            ) {
                                statement = `${statement} ${this.$data.operators[filter.filter]
                                    } '${filter.timePicker1}' and '${filter.timePicker2}'`
                                return statement
                            } else if (
                                ['equal', 'not equal', 'greater than', 'less than'].includes(
                                    filter.filter
                                ) &&
                                this.colType(filter.column) === 'DATETIME' || this.colType(filter.column) === 'System.DateTime'
                            ) {
                                statement = `${statement} ${this.$data.operators[filter.filter]
                                    } '${filter.datePicker1} ${filter.timePicker1}'`
                                return statement
                            } else if (
                                filter.filter === 'between' &&
                                this.colType(filter.column) === 'DATETIME' || this.colType(filter.column) === 'System.DateTime'
                            ) {
                                statement = `${statement} ${this.$data.operators[filter.filter]
                                    } '${filter.datePicker1} ${filter.timePicker1}' and '${filter.datePicker2
                                    } ${filter.timePicker2}'`
                                return statement
                            } else if (
                                filter.filter === 'between' &&
                                this.colType(filter.column) === 'NUMERIC'
                            ) {
                                statement = `${statement} ${this.$data.operators[filter.filter]
                                    } '${filter.inputField1}' and '${filter.inputField2}'`
                                return statement
                            } else if (filter.filter === 'in') {
                                statement = `${statement} ${this.$data.operators[filter.filter]
                                    } (${filter.fileContent})`
                                return statement
                            }
                        }
                    )
                    return (
                        selectStatement +
                        conditionsStatement.join('\n')
                    )
                }
                return selectStatement
            },
            columnData() {
                if (this.$data.columnSearch) {
                    return this.$data.columns.filter(
                        column =>
                            column.FieldName
                                .toLowerCase()
                                .includes(this.$data.columnSearch.toLowerCase()) ||
                            column.desc.includes(this.$data.columnSearch)
                    )
                }
                return this.$data.columns
            },
            selectedColumns() {
                if (this.$data.addedColumns) {
                    return this.$data.addedColumns.map(column => column.FieldName)
                }
                return []
            }
        },

        mounted() {
            if (this.formData) {
                this.columns = this.formData
            }
        },

        methods: {
            formatDate(date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },
            addColumn: function (colData) {
                if (this.$data.addedColumns.includes(colData) === false) {
                    this.$data.addedColumns.push(colData)
                }
            },
            addAllColumns: function () {
                this.$data.addedColumns = Array.from(this.$data.columns)
            },
            removeColumns: function (colData) {
                const index = this.$data.addedColumns.indexOf(colData)
                if (index > -1) {
                    this.$data.addedColumns.splice(index, 1)
                }
                this.$data.addedFilters = this.$data.addedFilters.filter(
                    filter => filter.column !== colData.FieldName
                )
            },
            removeAllColumns: function () {
                this.$data.addedColumns = []
            },
            parseDate(date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            loadTextFromFile(ev) {
                const file = ev.target.files[0]
                const reader = new FileReader()
                reader.onload = e => this.setFileContentForFilter(e.target.result)
                reader.readAsText(file)
            },
            uploadFile(evt, filterIdx) {
                if (evt !== undefined && evt !== null) {
                    var reader = new FileReader()
                    reader.readAsText(evt)
                    reader.onload = () => {
                        this.setFileContentForFilter(reader.result, filterIdx)
                    }
                } else {
                    this.$data.addedFilters[filterIdx].fileContent = null
                    this.$data.addedFilters[filterIdx].file = null
                    this.$data.addedFilters[filterIdx].inputField1 = null
                }
            },
            setFileContentForFilter(fileData, filterIdx) {
                const filter = this.$data.addedFilters[filterIdx]
                var formatterData
                if (filter && this.colType(filter.column) === 'NUMERIC') {
                    formatterData = fileData
                        .split('\n')
                        .map(dataPoint => parseInt(dataPoint))
                } else {
                    formatterData = fileData
                        .split('\n')
                        .map(dataPoint => dataPoint.toString())
                }
                this.$data.addedFilters[filterIdx].fileContent = formatterData
                this.$data.addedFilters[filterIdx].inputField1 = formatterData
            },
            addFilter(colData = null) {
                let id = this.$data.filterIndex
                let filterData = {
                    index: id,
                    column: null,
                    andOrOr: 'and',
                    filter: null,
                    inputField1: null,
                    inputField2: null,
                    datePicker1: null,
                    datePicker2: null,
                    timePicker1: null,
                    timePicker2: null,
                    file: null,
                    fileContent: null
                }
                if (colData !== null) {
                    filterData.column = colData.FieldName
                }
                this.$data.addedFilters.push(filterData)
                this.$data.filterIndex += 1
            },
            removeFilter(filterIndex) {
                this.$data.addedFilters = this.$data.addedFilters.filter(
                    filter => filter.index !== filterIndex
                )
            },
            clearFilterDataUponColumnChange(filterArrayIndex) {
                this.$data.addedFilters[filterArrayIndex].filter = null
                this.$data.addedFilters[filterArrayIndex].inputField1 = null
                this.$data.addedFilters[filterArrayIndex].inputField2 = null
                this.$data.addedFilters[filterArrayIndex].datePicker1 = null
                this.$data.addedFilters[filterArrayIndex].datePicker2 = null
                this.$data.addedFilters[filterArrayIndex].timePicker1 = null
                this.$data.addedFilters[filterArrayIndex].timePicker2 = null
                this.$data.addedFilters[filterArrayIndex].fileContent = null
            },
            colType(col) {
                if (col) {
                    const colData = this.$data.columns.find(item => item.FieldName === col)
                    return colData.FieldType
                }
            },
            columnFilters(col) {

                if (col !== null && col !== undefined) {
                    const colData = this.$data.columns.find(item => item.FieldName === col)
                    if (
                        ['NUMERIC', 'DATE', 'TIME', 'DATETIME', 'System.DateTime'].includes(colData.FieldType) ===
                        true
                    ) {
                        return [
                            'equal',
                            'not equal',
                            'greater than',
                            'less than',
                            'between',
                            'in'
                        ]
                    }
                    else if (colData.FieldType === 'System.String') {
                        return ['equal', 'not equal', 'in']
                    }
                } else {
                    return []
                }
            }
        }
    }
</script>