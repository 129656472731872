<template>
    <v-container fill-height fluid>
        <v-row v-if="TwoFA" align="center" justify="center">
            <v-col class="text-center">
                <v-card width="15%" tile class="ma-auto elevation-4" :loading="loading">
                    <img src="../assets/images/logo.png" alt="" style="max-height:50px" class="mt-8">
                    <div v-if="!showCodeInput" class="secondary-color"><h3>{{ $t('Last4Digits') }}</h3></div>
                    <div v-if="showCodeInput" class="secondary-color"><h3>{{ $t('VerificationCode') }}</h3></div>
                    <v-card-text>
                        <v-otp-input v-if="!showCodeInput" v-model="last4MobileDigits" length="4" />
                        <v-otp-input v-if="showCodeInput" v-model="code" length="4" />
                        <div v-if="errorLogin" class="error--text mb-2">
                            {{ errorMsg }}
                        </div>
                        <v-btn v-if="!showCodeInput" class="white--text mt-4" color="primary" block :disabled="manyRequests" @click="requestVerificationCode">
                            {{ $t("Send") }}
                            <v-icon class="ms-3">mdi-cellphone-wireless</v-icon>
                        </v-btn>

                        <v-btn v-if="showCodeInput" class="white--text mt-4" color="primary" block :disabled="manyRequests" @click="checkVerificationCode">
                            {{ $t("Login") }}
                            <v-icon class="ms-3">mdi-cellphone-wireless</v-icon>
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex'
    import AuthService from '@/services/AuthService'
    export default {

        data() {
            return {
                requestVerificationCodeData:
                {
                    user: null,
                    last4MobileDigits: null
                },
                requestCodeCount: 0,
                maxNumberOfCodeRequests: 4,
                loading: false,
                errorLogin: true,
                showCodeInput: false,
                manyRequests: false,
                last4MobileDigits: '',
                code: '',
                errorMsg: ''
            }
        },
        computed: {
            ...mapGetters('user', ['TwoFA']),
        },

        created() {
            if (!this.TwoFA) {
                this.$router.push('/')
            }
        },

        methods: {

            checkVerificationCode() {
                if (this.code) {
                    this.loading = true
                    this.requestVerificationCodeData.user = this.TwoFA
                    this.requestVerificationCodeData.last4MobileDigits = this.code
                    AuthService.checkVerificationCode(this.requestVerificationCodeData)
                        .then(res => {
                            if (res.data) {
                                this.$store.dispatch('user/removeUser2FA', this.TwoFA)
                                this.$store.dispatch('user/setUserSession', res.data)
                                this.$store.dispatch('app/fetch_options')
                                window.location.href = '/'
                            } else {
                                this.errorMsg = this.$t(res.message)
                                this.errorLogin = true
                            }

                        }).catch(err => {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                            this.errorMsg = ''
                        }).finally(() => {
                            if (this.requestCodeCount >= this.maxNumberOfCodeRequests) {
                                this.code = false
                                this.manyRequests = true
                                this.$root.$notification(this.$t('TooManyRequests'), 'error')
                                this.$store.dispatch('user/removeUser2FA', this.TwoFA)
                                window.location.replace('/')
                            }
                            this.loading = false
                            this.requestCodeCount++
                        })
                }
            },

            requestVerificationCode() {
                if (this.last4MobileDigits) {
                    this.loading = true
                    this.requestVerificationCodeData.user = this.TwoFA
                    this.requestVerificationCodeData.last4MobileDigits = this.last4MobileDigits

                    AuthService.requestVerificationCode(this.requestVerificationCodeData)
                        .then(res => {
                            if (res.success) {
                                this.showCodeInput = true
                                this.errorLogin = false
                                this.requestCodeCount = 0
                            } else {
                                this.errorMsg = this.$t(res.message)
                                this.errorLogin = true
                            }

                        }).catch(err => {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                            this.errorMsg = ''
                        }).finally(() => {
                            if (this.requestCodeCount >= this.maxNumberOfCodeRequests) {
                                this.showLast4MobileDigitsInput = false
                                this.manyRequests = true
                                this.$root.$notification(this.$t('TooManyRequests'), 'error')
                                this.$store.dispatch('user/removeUser2FA', this.TwoFA)
                                window.location.replace('/')
                            }
                            this.loading = false
                            this.requestCodeCount++
                        })
                }
                else {
                    this.$root.$notification(this.$t('FillOtp'), 'error')
                }
            },
        },
    }
</script>

<style>
</style>