import { i18n } from '@/plugins/i18n/index.js'

export const requiredRule = v => !!v ||  i18n.t('Required')

export const noHtmlTagsRule = v => {
    const regex = /<\/?[^>]+(>|$)/g
    return !regex.test(v) || i18n.t('HtmlDisallowed')
}

export const maxLengthRule = max => v => v.length <= max || `Maximum length is ${max} characters`

export const minLengthRule = min => v => v.length >= min || `Minimum length is ${min} characters`

export const onlyAlphanumericRule = v => {
    const regex = /^[^@$!%*?&#^;,<>{}[\]()|'"`~/\\+=]*$/
    return regex.test(v) || i18n.t('InvalidString')
}

export const emailRule = v => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(v) || i18n.t('InvalidEmail')
}
export const phoneNumberRule = v => {
    // Regex to validate phone numbers with only numbers and +
    const regex = /^\+?[0-9]*$/
    return regex.test(v) || i18n.t('PhoneValidation')
}