import ProcessFollowup from '@/pages/Processes/ProcessFollowup.vue'
import FollowupDetails from '@/pages/Processes/FollowupDetails.vue'
import InitiateProcess from '@/pages/Processes/InitiateProcess.vue'

const baseUrl = process.env.VUE_APP_BASE_URL 

export default [
	
	{
		name: 'followup',
		path: `${baseUrl}/processes/followup`,
		component: ProcessFollowup,
		meta: {
			secure: true
		}
	},
	{
		name: 'followup-details',
		path: `${baseUrl}/processes/followup-details/:processInstanceId`,
		component: FollowupDetails,
		meta: {
			secure: true
		}
	},
	{
		name: 'initiate-process',
		path: `${baseUrl}/processes/initiate-process/:workflowId`,
		component: InitiateProcess,
		meta: {
			secure: true
		}
	},

]