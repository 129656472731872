<template>
	<v-dialog v-model="dialog" width="30%" @keydown.esc="cancel">
		<v-card>
			<v-toolbar color="primary" dense flat>
				<v-toolbar-title class="white--text c-text-h5">
					{{ title }}
				</v-toolbar-title>
			</v-toolbar>
			<v-card-text v-show="!!message" class=" pa-4 grey--text text--darken-4 c-text-subtitle-2 font-weight-bold">
				{{ message }}
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn small color="secondary" class="white--text" tile @click.native="agree">
					{{ $t("Yes") }}
				</v-btn>
				<v-btn small color="error" tile @click.native="cancel">
					{{ $t("No") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
	export default {

		data: () => ({
			dialog: false,
			resolve: null,
			reject: null,
			message: null,
			title: null,
		}),
		
		watch: {
			dialog() {
				if (this.dialog) {
					document.documentElement.classList.add('no-dialog-overflow')
				} else {
					document.documentElement.classList.remove('no-dialog-overflow')
				}
			},
		},

		methods: {
			open(title, message) {
				this.dialog = true
				this.title = title
				this.message = message
				return new Promise((resolve, reject) => {
					this.resolve = resolve
					this.reject = reject
				})
			},
			agree() {
				this.resolve(true)
				this.dialog = false
			},
			cancel() {
				this.resolve(false)
				this.dialog = false
			},
		}
	}
</script>