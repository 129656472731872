<template>
    <v-card tile>
        <v-toolbar class="flex-grow-0" color="primary" dark dense flat>
            <v-toolbar-title color="white--text" class="flex-shrink-1 mt-2" style="max-width: 70%;">
                {{votingType?$t('EditVotingType'):$t('AddVotingType')   }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn dark icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-form ref="typeForm" v-model="valid">
            <v-row class="ma-3">
                <v-col cols="6">
                    <v-text-field v-model="voteTypeModel.nameAr" dense outlined :label="$t('NameAr')" :rules="cleanRequiredStringRules" required />

                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="voteTypeModel.nameEn" dense outlined :label="$t('NameEn')" :rules="cleanRequiredStringRules" required />

                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="voteTypeModel.displayOrder" dense outlined type="number" :label="$t('DisplayOrder')" :rules="requiredRules" required />

                </v-col>
                <v-col cols="6">
                    <v-checkbox v-model="voteTypeModel.active" dense outlined :label="$t('Active')" />

                </v-col>
            </v-row>
        </v-form>
        <v-card-actions class="justify-center ma-2">
            <v-btn v-show="!votingType" tile class="mb-3" color="primary" dark @click="AddVotingType">
                {{ $t('Add') }}
            </v-btn>
            <v-btn v-show="votingType" tile class="mb-3" color="secondary" dark @click="EditVotingType">
                {{ $t('Edit') }}
            </v-btn>
            <v-btn v-show="votingType" tile class="mb-3" color="error" dark @click="close">
                {{ $t('Cancel') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import SettingsService from '@/services/SettingsService'

    export default ({
        components: {
        },
        directives: {
        },
        filters: {
        },
        props: {
            votingType: {
                type: Object,
                default: null
            },
        },
        data() {
            return {
                text: '',
                loading: false,
                valid: true,
                editId: null,
                cleanRequiredStringRules: [this.$rules.requiredRule, this.$rules.onlyAlphanumericRule],

                voteTypeModel: {
                    id: 0,
                    nameAr: null,
                    nameEn: null,
                    active: false,
                    displayOrder:null,

                },
                requiredRules: [
                    v => !!v || this.$t('RequiredField')
                ],
            }
        },
        computed: {

        },
        watch: {
        },

        created() {
            if (this.votingType) {
                this.voteTypeModel.id = this.votingType.id
                this.voteTypeModel.nameAr = this.votingType.nameAr
                this.voteTypeModel.nameEn = this.votingType.nameEn
                this.voteTypeModel.active = this.votingType.active
                this.voteTypeModel.displayOrder = this.votingType.displayOrder
            }
        },
        methods: {
            AddVotingType() {
                if (this.$refs.typeForm.validate()) {
                    this.loading = true
                    SettingsService.addVotingType(this.voteTypeModel).then((res) => {
                        if (res.data) {
                            this.$root.$notification(this.$t('AddedSuccess'), 'success')
                            this.close()
                            this.update()
                        } else {
                            this.$root.$notification(this.$t('HEEEEE'), 'error')
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }

            },

            EditVotingType() {
                if (this.$refs.typeForm.validate()) {
                    this.loading = true
                    SettingsService.updateVotingType(this.voteTypeModel).then((res) => {
                        if (res.data) {
                            this.$root.$notification(this.$t('UpdateSuccess'), 'success')
                            this.close()
                            this.update()
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false

                    })
                }
            },
            update() {
                this.$emit('update')
            },
            close() {
                this.$emit('close')
            },

        },
    })
</script>

<style>
</style>