<template>
    <v-card width="500">
        <div class="card-title">{{ $t('MeetingInfo') }}</div>
        <v-card-text>
            <v-col cols="12">
                <v-row>
                    <v-text-field :label="$t('MeetingNumber')"
                                  outlined
                                  dense
                                  readonly
                                  :value="meetingDetails.id" />
                </v-row>
                <v-row>
                    <v-text-field :label="$t('ReferenceNumber')"
                                  outlined
                                  dense
                                  readonly
                                  :value="meetingDetails.referenceNumber" />
                </v-row>
                <v-row>
                    <v-text-field :label="$t('Comittee')"
                                  outlined
                                  dense
                                  readonly
                                  :value="meetingDetails.committeeName" />
                </v-row>
                <v-row v-if="meetingDetails.councilSessionName">
                    <v-text-field :label="$t('CouncilSession')"
                                  outlined
                                  dense
                                  readonly
                                  :value="meetingDetails.councilSessionName" />
                </v-row>
                <v-row v-if="showMeetingUrl">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-bind="attrs" :label="$t('MeetingUrl')"
                                          outlined
                                          dense
                                          readonly
                                          :value="meetingDetails.url"
                                          v-on="on" />
                        </template>
                        <span>{{ meetingDetails.url }}</span>
                    </v-tooltip>
                    <v-icon class="ms-2 mb-4" size="22" @click="copyToClipboard">mdi-content-copy</v-icon>
                </v-row>

                <v-row class="mt-0 pt-0">
                    <v-chip-group>
                        <v-chip color="teal"
                                outlined>
                            {{ $t('StartTime') }}: {{ meetingDetails.startTime }}
                        </v-chip>
                        <v-chip color="error"
                                outlined>
                            {{ $t('EndTime') }}: {{ meetingDetails.endTime }}
                        </v-chip>
                    </v-chip-group>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-chip-group>
                            {{ $t('AgendaNotes') }}:
                        </v-chip-group>
                        <v-card color="gray lighten-4" outlined>
                            <v-card-text color="teal">
                                <h4> {{ meetingDetails.notes|trim(150) }}</h4>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-card-text>
    </v-card>
</template>

<script>
    import { MeetingTypeEnum } from '@/helpers/constants/enumerations.js'


    export default ({

        name: 'LiveMeetingInfo',
        props: {
            meetingDetails: {
                type: Object,
                default: null
            },
        },
        data() {
            return {
            }
        },
        computed: {
            showMeetingUrl() {
                return this.meetingDetails.typeId == MeetingTypeEnum.Remote
            },
        },
        methods: {
            copyToClipboard() {
                let copyText = this.meetingDetails.url
                navigator.clipboard.writeText(`${copyText}`)
                this.$root.$notification(this.$t('CopySucceeded'), 'success')
            }
        }

    })
</script>

<style>
</style>
