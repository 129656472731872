import ComitteesSummaryReport from '@/pages/Reports/ComitteesSummaryReport.vue'
import AttendanceReport from '@/pages/Reports/AttendanceReport.vue'

const baseUrl = process.env.VUE_APP_BASE_URL 

export default [

	{
		name: 'comittees-summary-report',
		path: `${baseUrl}/reports/comittees-summary`,
		component: ComitteesSummaryReport,
		meta: {
			secure: true
		}
	},
	{
		name: 'attendance-report',
		path: `${baseUrl}/reports/attendance`,
		component: AttendanceReport,
		meta: {
			secure: true
		}
	},

]