<template>
    <v-card tile min-height="300" height="90vh">
        <v-toolbar dense>
            <v-card-text class="bold">
                <h3> {{ $t('VotingTypesSettings') }}</h3>
            </v-card-text>

            <v-icon class="float-end me-2" color="primary" @click="openAddType">
                mdi-plus-circle-outline
            </v-icon>
        </v-toolbar>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <div class="card-title">{{ $t('VotingTypes') }}</div>
                    <v-data-table :headers="headers" dense
                                  :items="votingTypes" class="elevation-1"
                                  :items-per-page="10">
                        <template v-slot:[`item.options`]="{ item }">
                            <v-icon color="primary" size="22" @click="showTypeOptions(item.id)"> mdi-format-list-numbered</v-icon>
                            <v-icon color="primary" size="22" class="ms-2" @click="openEditType(item)"> mdi-pencil  </v-icon>
                            <v-icon color="error" size="22" class="ms-2" @click="deleteVotingType(item.id)"> mdi-trash-can-outline</v-icon>

                        </template>
                        <template v-slot:[`item.active`]="{ item }">
                            <v-icon v-if="item.active" color="teal">mdi-check</v-icon>
                            <v-icon v-else color="error"> mdi-close</v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
        <v-dialog v-if="showTypeDialogue" v-model="showTypeDialogue" width="1000">
            <AddVotingTypeDialogue :voting-type="selectedType" @close="showTypeDialogue = false" @update="listVotingTypes" />
        </v-dialog>
        <v-dialog v-if="showTypeOptionsDialogue" v-model="showTypeOptionsDialogue" width="1000">
            <VotingTypeOptions :voting-type-id="selectedTypeId" @close="showTypeOptionsDialogue = false" />
        </v-dialog>
        <confirm-dialog ref="confirmDialog" />

    </v-card>
</template>

<script>
    import SettingsService from '@/services/SettingsService'
    import AddVotingTypeDialogue from '@/components/app/admin/UpsertVotingType.vue'
    import VotingTypeOptions from '@/components/app/admin/VotingTypeOptions.vue'
    import ConfirmDialog from '@/components/controls/ConfirmDialog.vue'

    export default {
        components: {
            AddVotingTypeDialogue, ConfirmDialog, VotingTypeOptions
        },
        data() {
            return {
                selectedType: null,
                selectedTypeId: null,
                showTypeOptionsDialogue: false,
                showTypeDialogue: false,
                votingTypes: [],
                headers: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('NameAr'), value: 'nameAr' },
                    { text: this.$t('NameEn'), value: 'nameEn' },
                    { text: this.$t('Active'), value: 'active' },
                    { text: this.$t('DisplayOrder'), value: 'displayOrder' },
                    { text: this.$t('Options'), value: 'options' }
                ],
            }
        },

        created() {
            this.listVotingTypes()
        },

        methods: {


            listVotingTypes() {
                this.loading = true
                SettingsService.listAllVotingTypes()
                    .then((res) => {
                        if (res.data) {
                            this.votingTypes = res.data
                        }
                    })
                    .catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
            },
            openEditType(type) {
                this.selectedType = type
                this.showTypeDialogue = true


            },
            openAddType() {
                this.selectedType = null
                this.showTypeDialogue = true
            },
            deleteVotingType(votingTypeId) {
                this.$refs.confirmDialog.open(this.$t('ConfirmDelete'), this.$t('DeleteVotingTypeConfirm'))
                    .then((confirmed) => {
                        if (confirmed) {
                            SettingsService.deleteVotingType(votingTypeId).then((res) => {
                                if (res.success) {
                                    this.listVotingTypes()
                                } else {
                                    this.$root.$notification(res.message, 'error')
                                }

                            }).catch(() => {
                                this.$root.$notification(this.$t('DeleteFailedRelated'), 'error')
                            }).finally(() => {
                                this.loading = false
                            })
                        } else {

                        }
                    })


            },
            showTypeOptions(typeId) {
                this.selectedTypeId = typeId
                this.showTypeOptionsDialogue = true

            }
        }
    }
</script>

<style>
</style>