<template>
    <v-card tile :loading="loading">
        <div class="card-title">
            {{ $t('AuditTrail') }}
        </div>
        <v-toolbar class="py-2" height="80">
            <v-text-field v-model="search" :label="$t('SearchTextInput')" />
            <v-btn tile class="mb-4 ms-4" color="primary" @click="listAuditTrails()">
                {{ $t('Search') }}
                <v-icon color="white" class="ms-2">mdi-database-search</v-icon>
            </v-btn>
            <v-btn tile class="mb-4 ms-4" color="primary" @click=" search = ''; listAuditTrails();">
                <v-icon color="white" class="ms-2">mdi-refresh</v-icon>
            </v-btn>
            <v-spacer />
        </v-toolbar>
        <GridWithServerPaging :headers="headers" :items="auditLogs" :show-expand="showExpand" :total="totalCount" disable-sort class="mt-4" @onChange="listAuditTrails">
            <template v-slot:[`item.createdDate`]="{item}">
                {{ item.createdDate | formatDateTimeGregorian }}
            </template>
            <template v-slot:expanded-item="{ item }">
                <td :colspan="headers.length" class="td-border">
                    {{ item.additionalInfo }}
                </td>
            </template>
        </GridWithServerPaging>
    </v-card>
</template>

<script>
    import GridWithServerPaging from '@/components/controls/GridWithServerPaging.vue'
    import AuditLogsService from '@/services/AuditLogsService.js'

    export default ({
        components: {
            GridWithServerPaging
        },
        directives: {
        },
        filters: {
        },
        props: {
        },
        data() {
            return {
                showExpand: true,
                loading: false,
                search: '',
                auditLogs: [],
                totalCount: 0,
                headers: [
                    { text: this.$t('Id'), align: 'start', sortable: false, value: 'id' },
                    { text: this.$t('Username'), value: 'username' },
                    { text: this.$t('ProcessInstanceId'), value: 'processInstanceId' },
                    { text: this.$t('RecordId'), value: 'recordId' },
                    { text: this.$t('LetterId'), value: 'letterId' },
                    { text: this.$t('CommentId'), value: 'commentId' },
                    { text: this.$t('ActionName'), value: 'actionName' },
                    { text: this.$t('ControllerName'), value: 'controllerName' },
                    { text: this.$t('Description'), value: 'description' },
                    { text: this.$t('CreatedDate'), value: 'createdDate' },
                ],
                options: {
                    page: 1, itemsPerPage: 10
                },
            }
        },
        computed: {
        },
        watch: {
        },
        beforeCreate() {
        },
        created() {
            this.listAuditTrails()
        },
        beforeMount() {
        },
        mounted() {
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeDestroy() {
        },
        destroyed() {
        },
        methods: {
            listAuditTrails(options) {
                const page = options != undefined ? options.page : 1
                const pageSize = options != undefined ? options.itemsPerPage : 10
                this.loading = true
                AuditLogsService.listAuditTrails(page, pageSize, this.search)
                    .then((res) => {
                        if (res.data) {
                            this.auditLogs = res.data.data
                            this.totalCount = res.data.total
                        }
                    })
                    .catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => this.loading = false)
            },

        },
    })
</script>

<style>
</style>