<template>
    <div>
        <GeneralInformation :general-info="generalInfo" />
    </div>
</template>

<script>
    import GeneralInformation from '@/components/app/followUp/GeneralInformation.vue'
    import ProcessesService from '@/services/ProcessesService.js'

    export default {
        components: {
            GeneralInformation
        },

        data() {
            return {
                loading: false,
                generalInfo: null,
                processId: parseInt(this.$route.params.processInstanceId)
            }
        },

        created() {
            this.getProcess()
        },

        methods: {
            getProcess() {
                this.loading = true
                ProcessesService.getProcess(this.processId)
                    .then((res) => {
                        this.generalInfo = res.data
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
    }
</script>

<style>
</style>