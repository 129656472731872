<template>
    <CommonDialog :value="value" :fullscreen="true" :title="$t('Permissions')" show-action-toolbar @input="$emit('input', false)">
        <v-card-text>
            <v-row>
                        <v-card-text>
                            <v-row>
                                <v-col v-for="group in userPermissionsList.items" :key="group.groupName" cols="6">
                                    <v-card tile>
                                        <div class="card-title primary white--text"> {{ $t(group.groupName) }}</div>
                                        <v-card-text>
                                            <v-row>
                                                <v-col v-for="item in group.items" :key="item.id" cols="4">
                                                    <v-checkbox v-model="item.hasAccess" :label="$t(item.name)" class="text-start" color="primary" hide-details @change="updatePermission($event, item.id)" />
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
            </v-row>
        </v-card-text>
    </CommonDialog>
</template>

<script>
    import CommonDialog from '@/components/controls/CommonDialog'
    import UsersService from '@/services/UsersService.js'
    import LookupsService from '@/services/LookupsService.js'
    import PermissionsService from '@/services/PermissionsService.js'
    import CouncilCommitteesService from '@/services/CouncilCommitteesService.js'

    export default {
        components: {
            CommonDialog
        },

        props: {
            value: {
                type: Boolean,
                default: false
            },
            userId: {
                type: Number,
                default: null
            },
            committeeId: {
                type: [Number,String],
                default: null
            }
        },
        data() {
            return {
                userPermissionsList: [],
                permissionLevels: []
            }
        },

        created() {
            this.loadPermissions()
        },

        methods: {

            updatePermission(enabled, permissionId) {
                let formData= {
                   'userId': this.userId,
                   'permissionId': permissionId,
                    'enabled':enabled

                }
                CouncilCommitteesService.updatePermission(this.committeeId,formData).then((res) => {
                    if (res.data) {
                        this.$root.$notification(this.$t('UpdateSuccess'), 'success')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            loadPermissions() {
                this.loading = true
                CouncilCommitteesService.getUserCommitteesPermissions(this.committeeId,this.userId).then((res) => {
                    this.userPermissionsList = res.data
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            }
        },
    }
</script>

<style>
</style>