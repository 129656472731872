<template>
    <div>
        <v-card v-if="editor" elevation="0"
                tile
                :class="{ 'error-border': showError }">
            <v-card-title class="pa-0 primary darken-1">
                <tiptap-button v-for="option in editorOptions"
                               :key="option.name"
                               :name="option.name"
                               :disabled="!editable || option.disabled"
                               :is-active="editor.isActive(...option.active)"
                               :icon="option.icon"
                               @clicked="handleClick" />
            </v-card-title>
            <editor-content :editor="editor" :style="{maxHeight: maxHeight}" class="editor" />
        </v-card>
        <p v-if="showError"
           class="v-messages theme--light error--text ml-1 mr-1 mb-0">
            {{ $t("mandatory-field") }}
        </p>
    </div>
</template>

<script>
    import { Editor, EditorContent } from '@tiptap/vue-2'
    import StarterKit from '@tiptap/starter-kit'
    import TextAlign from '@tiptap/extension-text-align'
    import Image from '@tiptap/extension-image'
    import TiptapButton from '@/components/controls/TextEditorButton.vue'
    import Table from '@tiptap/extension-table'
    import TableRow from '@tiptap/extension-table-row'
    import TableCell from '@tiptap/extension-table-cell'
    import TableHeader from '@tiptap/extension-table-header'

    export default {
        components: {
            EditorContent,
            TiptapButton,
        },

        props: {
            value: {
                type: String,
                default: '',
            },
            editable: {
                type: Boolean,
                default: true,
            },
            required: {
                type: Boolean,
                default: false,
            },
            maxHeight: {
                type: String,
                default: '20rem'
            }
        },

        data() {
            return {
                validating: false,
                requiredRule: [(v) => !!v || this.$t('mandatory-field')],
                editor: null,
                editorOptions: [
                    { name: 'Bold', icon: 'mdi-format-bold', active: ['bold'] },
                    {
                        name: 'Italic',
                        icon: 'mdi-format-italic',
                        active: ['italic'],
                    },
                    {
                        name: 'Strike',
                        icon: 'mdi-format-strikethrough',
                        active: ['strike'],
                    },
                    { name: 'Code', icon: 'mdi-code-tags', active: ['code'] },
                    {
                        name: 'H1',
                        icon: 'mdi-format-header-1',
                        active: ['heading', { level: 1 }],
                    },
                    {
                        name: 'H2',
                        icon: 'mdi-format-header-2',
                        active: ['heading', { level: 2 }],
                    },
                    {
                        name: 'H3',
                        icon: 'mdi-format-header-3',
                        active: ['heading', { level: 3 }],
                    },
                    {
                        name: 'H4',
                        icon: 'mdi-format-header-4',
                        active: ['heading', { level: 4 }],
                    },
                    {
                        name: 'H5',
                        icon: 'mdi-format-header-5',
                        active: ['heading', { level: 5 }],
                    },
                    {
                        name: 'H6',
                        icon: 'mdi-format-header-6',
                        active: ['heading', { level: 6 }],
                    },
                    {
                        name: 'Bullet List',
                        icon: 'mdi-format-list-bulleted',
                        active: ['bulletList'],
                    },
                    {
                        name: 'Ordered List',
                        icon: 'mdi-format-list-numbered',
                        active: ['orderedList'],
                    },
                    {
                        name: 'Horizontal Rule',
                        icon: 'mdi-minus',
                        active: ['horizontalRule'],
                    },
                    { name: 'Undo', icon: 'mdi-undo', active: ['undo'] },
                    { name: 'Redo', icon: 'mdi-redo', active: ['redo'] },

                    {
                        name: 'Right',
                        icon: 'mdi-format-align-right',
                        active: [{ textAlign: 'right' }],
                    },
                    {
                        name: 'Center',
                        icon: 'mdi-format-align-center',
                        active: [{ textAlign: 'center' }],
                    },
                    {
                        name: 'Left',
                        icon: 'mdi-format-align-left',
                        active: [{ textAlign: 'left' }],
                    },
                    {
                        name: 'Table',
                        icon: 'mdi-table',
                        active: ['Table'],
                    },
                    {
                        name: 'DeleteTable',
                        icon: 'mdi-table-cancel',
                        active: ['DeleteTable'],
                    },
                    {
                        name: 'TableColB',
                        icon: 'mdi-table-column-plus-before',
                        active: ['TableColBefore'],
                    },
                    {
                        name: 'TableColA',
                        icon: 'mdi-table-column-plus-after',
                        active: ['TableColAfter'],
                    },
                    {
                        name: 'DeleteCol',
                        icon: 'mdi-table-column-remove',
                        active: ['DeleteCol'],
                    },
                    {
                        name: 'AddRowBefore',
                        icon: 'mdi-table-row-plus-before',
                        active: ['AddRowBefore'],
                    },
                    {
                        name: 'AddRowAfter',
                        icon: 'mdi-table-row-plus-after',
                        active: ['AddRowAfter'],
                    },
                    {
                        name: 'DeleteRow',
                        icon: 'mdi-table-row-remove',
                        active: ['DeleteRow'],
                    },
                    {
                        name: 'MergeCells',
                        icon: 'mdi-table-merge-cells',
                        active: ['MergeCells'],
                    },
                    {
                        name: 'SplitCells',
                        icon: 'mdi-table-split-cell',
                        active: ['SplitCells'],
                    },
                ],
            }
        },

        computed: {
            showError() {
                return (
                    this.required &&
                    this.editor != null &&
                    !this.extractStyledContent().trim() &&
                    this.validating
                )
            },

        },

        watch: {
            value(value) {

                const isSame = this.extractStyledContent() === value
                if (isSame) {
                    return
                }
                this.editor.commands.setContent(value, false)
            },

            editable() {
                this.editor.setEditable(this.editable)
            },
        },

        mounted() {
            this.editor = new Editor({
                editable: this.editable,
                content: this.value,
                extensions: [
                    StarterKit,
                    TextAlign.configure({
                        types: ['heading', 'paragraph', 'image'],
                    }),
                    Image,
                    Table.configure({
                        resizable: true,
                    }),
                    TableRow,
                    TableHeader,
                    TableCell,
                ],
                onUpdate: () => {
                    this.$emit('input', this.extractStyledContent())
                },
            })
        },

        beforeDestroy() {
            this.editor.destroy()
        },

        methods: {
            handleClick(name) {
                switch (name) {
                    case 'Bold':
                        this.editor.chain().focus().toggleBold().run()
                        break
                    case 'Italic':
                        this.editor.chain().focus().toggleItalic().run()
                        break
                    case 'Strike':
                        this.editor.chain().focus().toggleStrike().run()
                        break
                    case 'Code':
                        this.editor.chain().focus().toggleCode().run()
                        break
                    case 'H1':
                        this.editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 1 })
                            .run()
                        break
                    case 'H2':
                        this.editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 2 })
                            .run()
                        break
                    case 'H3':
                        this.editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 3 })
                            .run()
                        break
                    case 'H4':
                        this.editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 4 })
                            .run()
                        break
                    case 'H5':
                        this.editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 5 })
                            .run()
                        break
                    case 'H6':
                        this.editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 6 })
                            .run()
                        break
                    case 'Bullet List':
                        this.editor.chain().focus().toggleBulletList().run()
                        break
                    case 'Ordered List':
                        this.editor.chain().focus().toggleOrderedList().run()
                        break
                    case 'Horizontal Rule':
                        this.editor.chain().focus().setHorizontalRule().run()
                        break
                    case 'Undo':
                        this.editor.chain().focus().undo().run()
                        break
                    case 'Redo':
                        this.editor.chain().focus().redo().run()
                        break
                    case 'Center':
                        this.editor.chain().focus().setTextAlign('center').run()
                        break
                    case 'Right':
                        this.editor.chain().focus().setTextAlign('right').run()
                        break
                    case 'Left':
                        this.editor.chain().focus().setTextAlign('left').run()
                        break
                    case 'Table':
                        this.editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()
                        break

                    case 'DeleteTable':
                        this.editor.chain().focus().deleteTable().run()
                        break

                    case 'TableColB':
                        this.editor.chain().focus().addColumnBefore().run()
                        break
                    case 'TableColA':
                        this.editor.chain().focus().addColumnAfter().run()
                        break
                    case 'DeleteCol':
                        this.editor.chain().focus().deleteColumn().run()
                        break
                    case 'AddRowBefore':
                        this.editor.chain().focus().addRowBefore().run()
                        break
                    case 'AddRowAfter':
                        this.editor.chain().focus().addRowAfter().run()
                        break
                    case 'DeleteRow':
                        this.editor.chain().focus().deleteRow().run()
                        break
                    case 'MergeCells':
                        this.editor.chain().focus().mergeCells().run()
                        break
                    case 'SplitCells':
                        this.editor.chain().focus().splitCell().run()
                        break
                    default:
                        return null
                }
            },

            validate() {
                this.validating = true
                return (
                    !this.required ||
                    (this.required && this.extractStyledContent.trim() != '')
                )
            },

            reset() {
                this.validating = false
                this.editor.commands.setContent(null, false)
            },

            extractStyledContent() {
                const editorView = this.editor.view
                const styledContent = editorView.dom.innerHTML
                const customStyles = '<style> table { width:100%; } th, td { border: 1px solid #ddd; text-align:center; font-size:11px !important }</style>'
                return `${customStyles}${styledContent}`
            },
        },
    }
</script>

<style>
    .ProseMirror { padding: 20px }

    .error-border { border: 1px #b71c1c solid !important }
    .tiptap { border: 2px solid #ddd !important; height: 20em; overflow: scroll }
        .tiptap .editor { overflow: auto; }
        .tiptap table { border-collapse: collapse; width: 100%; direction: ltr }
            .tiptap table td, .tiptap table th { min-width: 1em; border: 2px solid #ced4da; position:relative; vertical-align: middle; box-sizing: border-box; margin-bottom: 0; }
            .tiptap table th { font-weight: bold; background-color: #f1f3f5; }
        .tiptap .selectedCell:after { z-index: 2; position: absolute; content: ""; left: 0; right: 0; top: 0; bottom: 0; background: rgba(200, 200, 255, 0.4); pointer-events: none; }
</style>

