import { mainApiAxios as axios } from '@/plugins/axios'

export default {

    sendVote(formdata) {
        return axios.post('meetingAgenda/meeting-agenda-vote', formdata)
    },
    addMeetingAgendaNote(formdata) {
        return axios.post('meetingAgenda/note', formdata)
    },
    getAgendaNotes(meetingAgendaId) {
        return axios.get(`meetingAgenda/${meetingAgendaId}/note`)
    },
    editMeetingAgendaNote(formdata) {
        return axios.put('meetingAgenda/note', formdata)
    },
    deleteAgendaNote(meetingAgendaNoteId) {
        return axios.delete(`meetingAgenda/${meetingAgendaNoteId}/note`)
    },

    addMeetingAgendaRecommendation(formdata) {
        return axios.post('meetingAgenda/recommendation', formdata)
    },
    getAgendaRecommendations(meetingAgendaId) {
        return axios.get(`meetingAgenda/${meetingAgendaId}/recommendation`)
    },
    editMeetingAgendaRecommendation(formdata) {
        return axios.put('meetingAgenda/recommendation', formdata)
    },
    deleteAgendaRecommendation(meetingAgendaNoteId) {
        return axios.delete(`meetingAgenda/${meetingAgendaNoteId}/recommendation`)
    },
    getAgendaNotes(meetingAgendaId) {
        return axios.get(`meetingAgenda/${meetingAgendaId}/note`)
    },
    listMeetingAgendaVotingResults(meetingAgendaId) {
        return axios.get(`meetingAgenda/${meetingAgendaId}/meeting-agenda-vote-results`)
    },
   
}