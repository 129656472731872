<template>
    <v-navigation-drawer v-model="drawer"
                         app
                         color="navigation"
                         :right="isArabicLanguage ? true : false"
                         :mini-variant.sync="mini"
                         permanent>
        <v-list-item class="px-2">

            <router-link to="/" class="white--text text-decoration-none">
                <v-list-item-avatar>
                    <img src="../../assets/images/intalio.png" alt="" class="mb-0">
                    <!--<v-img small :src="require('@/assets/images/Ilogo.png')" class="pa-md-4 mx-lg-auto" style="width:50px" alt />-->
                </v-list-item-avatar>
            </router-link>
            <v-list-item-title>{{ $t("ApplicationName") }}</v-list-item-title>


            <v-btn icon
                   @click.stop="mini = !mini">
                <v-icon v-if="!isArabicLanguage">mdi-chevron-left</v-icon>
                <v-icon v-else>mdi-chevron-right</v-icon>
            </v-btn>
        </v-list-item>
        <v-divider />


        <v-list dense>
            <template v-for="item in menus">
                <v-list-item v-if="item.route" :key="item.id" :to="{ name: item.route }">
                    <v-list-item-icon>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-badge v-if="item.id=='Chat'" :content="chatMessagesCount"
                                         :value="chatMessagesCount"
                                         color="green"
                                         overlap>
                                    <v-icon v-bind="attrs" v-on="on"> {{ item.icon }}</v-icon>
                                </v-badge>
                                <v-icon v-else v-bind="attrs" v-on="on"> {{ item.icon }}</v-icon>
                            </template>
                            <span>{{ $t(item.title) }}</span>
                        </v-tooltip>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <b class="node-item"> {{ $t(item.title) }}</b>
                    </v-list-item-content>
                </v-list-item>

                <v-list-group v-if="!item.route" :key="item.id" no-action color="secondary" active-class="secondary--text">
                    <template v-slot:activator>
                        <v-list-item-icon>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on"> {{ item.icon }}</v-icon>
                                </template>
                                <span>{{ $t(item.title) }}</span>
                            </v-tooltip>
                        </v-list-item-icon>
                        <v-list-item-content v-if="item.route">
                            <b class="text-decoration-none node-item" :to="{ name: item.route }">{{ $t(item.title) }}</b>
                        </v-list-item-content>
                        <v-list-item-content v-else>
                            <b class="node-item">{{ $t(item.title) }}</b>
                        </v-list-item-content>
                    </template>

                    <v-list-item v-for="child in item.children" :key="child.id" :to="{ name: child.route }">
                        <v-icon small class="ms-4" color="primary"> {{ child.icon }}</v-icon>
                        <v-list-item-content>
                            <span class="node-item-sub ms-2">{{ $t(child.title) }}</span>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
    import { mapGetters } from 'vuex'
    import getMenus from '@/plugins/router/menus/menus.js'
    import UsersService from '@/services/UsersService.js'
    import ChatsService from '@/services/ChatsService'
    import { createSignalRConnection, startSignalRConnection, subscribeToEvent, unsubscribeFromEvent } from '@/plugins/hub/myHubPlugin.js'

    export default {
        data() {
            return {
                chatMessagesCount: 0,
                drawer: true,
                selectedItem: 1,
                mini: true,
                menus: []
            }
        },

        computed: {
            ...mapGetters('user', ['language']),

            isArabicLanguage() {
                return this.language == 'ar'
            }
        },

        mounted() {
            this.getChatMessagesCount()

            createSignalRConnection()
            startSignalRConnection().then(() => {

                subscribeToEvent('ChatMessagesCountChanges', this.getChatMessagesCount)
            }).catch((err) => {
            })
            
            UsersService.listCurrentUserPermissions().then((res) => {
                if (res.data) {
                    this.filterMenu(res.data)
                }
            }).catch(() => {
                this.$root.$notification(this.$t('ErrorOccurred'), 'error')
            }).finally(() => {
                this.loading = false
            })
        },

        methods: {
            filterMenu(menuItems) {
                let menuList = getMenus()
                let rootElements = menuList.filter(x => x.children == null)
                let nodeElements = menuList.filter(x => x.children != null)

                for (var i = 0; i < menuItems.length; i++) {
                    let currentItem = menuItems[i]
                    if (currentItem.hasAccess) {
                        let menuItem = rootElements.filter(r => r.id == currentItem.name)[0]
                        if (menuItem) {
                            this.menus.push(menuItem)
                        }
                    }
                }

                for (var j = 0; j < nodeElements.length; j++) {
                    let nodeElement = nodeElements[j]
                    let childsArray = []
                    if (nodeElement) {
                        for (var k = 0; k < nodeElement.children.length; k++) {
                            let childElement = nodeElement.children[k]
                            if (menuItems.filter(x => x.name == childElement.id).length > 0) {
                                childsArray.push(childElement)
                            }
                        }
                        if (childsArray.length > 0) {
                            nodeElement.children = childsArray
                            this.menus.push(nodeElement)
                        }
                    }
                }
            },
            getChatMessagesCount() {
                ChatsService.getUnreadChatMessages().then((res) => {

                    this.chatMessagesCount = res.data

                }).catch(() => {
                    //this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                })
            },
        },
        beforeDestroy() {
            unsubscribeFromEvent('ChatMessagesCountChanges')
        },
    }
</script>
<style scoped>
    .node-item, .node-item-sub {
        font-size: 12px
    }
</style>