<template>
    <div>
        <v-card class=" rounded-lg" :style="{ height: cardSize}">
            <div class="d-flex align-center justify-space-between">
                <h3 class="mb-0 pa-2">{{ $t('Attachments') }}</h3>
                <IconAction v-if="showsAddAttachmentAction" class="icon"
                            :title="$t('Add')"
                            @click="addNewAttachments">
                    mdi-playlist-plus
                </IconAction>
            </div>
            <v-divider class="my-0" color="#fefefe" />
            <div class="scrollable-list">
                <v-list dense>
                    <!-- Meeting Files-->
                    <v-list-group key="meetingFiles" :value="true">
                        <template v-slot:activator>
                            <v-list-item class="pa-0 ma-0">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ $t('MeetingAttachments') }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <v-list-item v-for="file in meetingAttachment" :key="file.id" class="pa-0 ma-0">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-icon size="35" :color="colors[file.type]" class="ms-4">{{ icons[file.type] }} </v-icon>
                                    {{ file.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-row class="d-flex align-center">
                                    <v-col class="d-flex" cols="auto">
                                        <v-icon v-if="file.id==selectedFile" color="error" class="me-4" @click="closeOpenedFile">mdi-close</v-icon>
                                        <v-icon v-else color="primary" class="me-4" @click="loadAttachment(file.id)">mdi-eye</v-icon>
                                    </v-col>
                                </v-row>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-group>
                    <!-- Agenda Files-->
                    <v-list-group v-for="(agenda, index) in filteredAgendas" :key="index" class="pa-0 ma-0" :value="true">
                        <template v-slot:activator>
                            <v-list-item class="pa-0 ma-0">
                                <v-list-item-content>
                                    <v-list-item-title>{{ agenda.title|trim(25) }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </template>
                        <v-list-item v-for="file in agendaAttachment(agenda.id)" :key="file.id">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-icon size="35" :color="colors[file.type]" class="me-2">{{ icons[file.type] }} </v-icon>
                                    {{ file.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-row class="d-flex align-center">
                                    <v-col class="d-flex" cols="auto">
                                        <v-icon v-if="file.id==selectedFile" color="error" class="ms-1" @click="closeOpenedFile">mdi-close</v-icon>
                                        <v-icon v-else color="primary" class="ms-2" @click="loadAttachment(file.id)">mdi-eye</v-icon>
                                    </v-col>
                                </v-row>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-group>
                </v-list>
            </div>
            <v-dialog v-if="addAttachmentDialog" v-model="addAttachmentDialog" width="70%">
                <AddMeetingOrAgendaAttachment :meeting-id="meetingId" @close="addAttachmentDialog = false" />
            </v-dialog>
        </v-card>
    </div>
</template>

<script>

    import { AttachmentsRecordType, MeetingStatusEnum } from '@/helpers/constants/enumerations'
    import IconAction from '@/components/controls/IconAction.vue'
    import AddMeetingOrAgendaAttachment from '@/components/app/meeting/AddMeetingOrAgendaAttachment.vue'
    import MeetingsService from '@/services/MeetingsService'
    import { createSignalRConnection, startSignalRConnection, subscribeToEvent, unsubscribeFromEvent } from '@/plugins/hub/myHubPlugin.js'

    export default ({
        components: {
            IconAction, AddMeetingOrAgendaAttachment
        },
        props: {
            agendaItems: {
                type: Array,
                default: () => []
            },
            attachmentsHeight: {
                type: Number,
                default: () => 28
            },
            statusId: {
                type: Number,
                default: 0
            },
            meetingId: {
                type: Number,
                default: null
            },
            selectedFile: {
                type: Number,
                default: null
            },
            viewMode: {
                type: Boolean,
                default: false
            },
            meetingOwner: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                addAttachmentDialog: false,
                attachments: [],
                icons: {
                    'Pdf': 'mdi-file-pdf-box mdi-30px',
                    'Docx': 'mdi-file-word mdi-30px',
                    'Xlsx': 'mdi-file-excel mdi-30px',
                    'Excel': 'mdi-file-excel mdi-30px',
                    'File': 'mdi-file mdi-30px',
                    'Png': 'mdi-file-image mdi-30px',
                    'Jpeg': 'mdi-file-image mdi-30px',
                    'Jpg': 'mdi-file-image mdi-30px',
                    'Image': 'mdi-file-image mdi-30px',
                    'Txt': 'mdi-text-box-outline mdi-30px',
                    'Rar': 'mdi-folder-zip mdi-30px',
                    'Exe': 'mdi-cog mdi-30px',
                },
                colors: {
                    'Pdf': '#FF5252',   // Red
                    'Docx': '#1E88E5',  // Blue
                    'Xlsx': '#4CAF50',  // Green
                    'Excel': '#4CAF50',  // Green
                    'File': '#9E9E9E',  // Gray
                    'Png': '#FFB300',   // Amber
                    'Image': '#FFB300',   // Amber
                    'Jpeg': '#FFB300',  // Amber
                    'Jpg': '#FFB300',   // Amber
                    'Txt': '#757575',   // Dark Gray
                    'Rar': '#E64A19',   // Deep Orange
                    'Exe': '#673AB7',   // Deep Purple
                }
            }
        },

        computed: {
            cardSize() {
                return `calc(${this.attachmentsHeight}vh - 15px)`
            },
            filteredAgendas() {
                if (!this.agendaItems) return []
                return this.agendaItems.filter(x =>
                    this.attachments.some(a => a.recordTypeId == AttachmentsRecordType.MeetingAgenda && a.recordId == x.id)
                )
            },
            meetingAttachment() {
                if (!this.meetingId || !this.attachments) return []
                return this.attachments.filter(x => x.recordId == this.meetingId && x.recordTypeId == AttachmentsRecordType.Meeting)
            },
            showsAddAttachmentAction() {
                return !this.viewMode && this.meetingOwner
                    && this.statusId <= MeetingStatusEnum.PendingFinalMeetingMinutesSign
            },
        },
        mounted() {
            if (!this.viewMode) {
                createSignalRConnection()
                startSignalRConnection().then(() => {
                    subscribeToEvent(`MeetingAttachmentsChanges${this.meetingId}`, this.handleAttachmentsChange)
                }).catch((err) => {
                })
            }

            this.loadMeetingAttachment()
        },

        beforeDestroy() {
            if (!this.viewMode)
                unsubscribeFromEvent(`MeetingAttachmentsChanges${this.meetingId}`)
        },
        methods: {
            loadMeetingAttachment() {
                MeetingsService.listMeetingAttachments(this.meetingId).then((res) => {
                    this.attachments = res.data
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            handleAttachmentsChange(newMeetingAttachmentsItems) {
                    this.attachments = newMeetingAttachmentsItems??[]
            },
            loadAttachment(attachmentId) {
                this.$emit('loadAttachment', attachmentId)
            },
            closeOpenedFile() {
                this.$emit('closeOpenedFile')
            },
            agendaAttachment(agendaId) {
                if (!agendaId || !this.attachments) return []
                return this.attachments.filter(x => x.recordId == agendaId)
            },
            getTypeColot(type) {

            },
            addNewAttachments() {
                this.addAttachmentDialog = true
            },
        },
    })
</script>

<style scoped>
    .d-flex {
        display: flex;
        align-items: center;
    }

    .scrollable-list {
        height: 85%
    }
</style>