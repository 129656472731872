<template>
    <v-card flat dense style="height: 450px;">
        <v-tabs dense height="40" v-model="selectedTab"
                align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab v-for="item in items"
                   :key="item">
                {{ item }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab">
            <v-tab-item key="meetingApproval">
                <MeetingApprovalList :meeting-id="meetingId" />
            </v-tab-item>
            <v-tab-item key="meetingMinutesApproval">
                <MeetingMinutesApprovalList :meeting-id="meetingId" />

            </v-tab-item>
            <v-tab-item key="meetingMinutesSign">
                <MeetingMinutesSignaturesList :meeting-id="meetingId" />
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
    import MeetingApprovalList from '@/components/app/meeting/MeetingDashboard/MeetingApprovalList.vue'
    import MeetingMinutesSignaturesList from '@/components/app/meeting/MeetingDashboard/MeetingMinutesSignaturesList.vue'
    import MeetingMinutesApprovalList from '@/components/app/meeting/MeetingDashboard/MeetingMinutesApprovalList.vue'
    export default ({
        components: {
            MeetingApprovalList, MeetingMinutesSignaturesList,MeetingMinutesApprovalList
        },
        directives: {
        },
        props: {
            meetingId: {
                type: Number,
                default: null,
            },
            attendees: {
                type: Array,
                default: () => [],
            },
            editMode: {
                type: Boolean,
                default: true,
            },
            meetingOwner: {
                type: Boolean,
                default: true,
            },
            currentAttendanceList: {
                type: Array,
                default: () => []
            },
        },
        data() {
            return {
                selectedTab: null,
                items: [
                    this.$t('MeetingApprovals'), this.$t('MeetingMinutesApprovals'), this.$t('MeetingMinutesSignatures'),
                ],
            }
        },
        created() {
        },

        methods: {
            
        },
    })
</script>

<style>
</style>