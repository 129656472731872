
export function generateArrayOfYears(count) {

    const currentYear = new Date().getFullYear()

    if (isNaN(count) || count == undefined || count < 1) return []

    let result = []
    for (let i = currentYear - 1; i < currentYear + count; i++) {
        result.push(i)
    }
    return result
}

export function createGuid() {
    function _p8(s) {
        var p = (Math.random().toString(16) + '000000000').substr(2, 8)
        return s ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p
    }
    return _p8() + _p8(true) + _p8(true) + _p8()
}
export function validateFiles(files, allowedExtensions) {

    if (!files || files.length === 0) {
        return true
    }
    if (!Array.isArray(files)) {
        files = [files]
    }
    for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const extension = file.name.split('.').pop()
        const allowed = allowedExtensions.includes(extension)
        if (!allowed) {
            return false
        }
    }
    return true

}
export function insertParam(key, value) {
    key = encodeURIComponent(key)
    value = encodeURIComponent(value)

    // kvp looks like ['key1=value1', 'key2=value2', ...]
    var kvp = document.location.search.substr(1).split('&')
    let i = 0

    for (; i < kvp.length; i++) {
        if (kvp[i].startsWith(key + '=')) {
            let pair = kvp[i].split('=')
            pair[1] = value
            kvp[i] = pair.join('=')
            break
        }
    }

    if (i >= kvp.length) {
        kvp[kvp.length] = [key, value].join('=')
    }

    // can return this or...
    let params = kvp.join('&')

    // reload page with new params
    document.location.search = params
}

export function getUserImage(userId) {

    return process.env.VUE_APP_MAIN_API + 'users/profile-image/' + userId
}