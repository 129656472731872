export function downloadFile(response) {
    try {
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(response.data)
        a.download = decodeURIComponent(response.headers['content-disposition'].replace('attachment;filename=', '').replace(/\+/g, ' '))
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(a)
    } catch (e) {
        console.log(e)
    }
}
export function openFile(data) {
    try {
        var file = new Blob([data], { type: data.type })
        var fileURL = URL.createObjectURL(file)
        window.open(fileURL)
    } catch (e) {
        console.log(e)
    }
}
export function downloadBlobFile(blob, filename) {
    const a = document.createElement('a')
    a.href = window.URL.createObjectURL(blob)
    a.download = filename
    a.click()
    window.URL.revokeObjectURL(url)
}

export function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
}