<template>
    <div>
        <v-form v-if="task" ref="activityDetails" v-model="valid" class="pt-4">
            <v-text-field v-model="details.Name" class="mb-2 mt-0" :label="$t('TaskReference')" :rules="nameRules" required autocomplete="off" />
            <v-text-field v-model="details.Title" class="mb-2" :label="$t('TaskTitle')" :rules="nameRules" required autocomplete="off" />
            <v-text-field v-model="details.DueDate" class="mb-2" :label="$t('DueDate')" :rules="nameRules" required type="number" autocomplete="off" />
            <v-text-field v-model="details.Description" class="mb-2" :label="$t('Status')" :rules="nameRules" required autocomplete="off" />
        </v-form>
        <v-divider />
        <v-card-actions class="d-block text-end">
            <v-btn color="secondary" class="white--text" tile small @click="saveSettings">
                {{ $t('Save') }}
            </v-btn>
            <v-btn color="error" tile small class="white--text" @click="$emit('close')">
                {{ $t('Cancel') }}
            </v-btn>
        </v-card-actions>
    </div>
</template>

<script>

    export default {
       
        props: {
            task: {
                type: Object,
                default: null
            },
        },

        data() {
            return {
                valid: true,
                nameRules: [
                    v => !!v || this.$t('RequiredField')
                ],
                details: { Name: null, Title: null, DueDate: null, Description: null },
            }
        },
       
        created() {
            this.details.Name = this.task.Name || 'task' + this.task.Index
            this.details.Title = this.task.Title
            this.details.Description = this.task.Description
            this.details.DueDate = this.task.DueDate
        },
        
        methods: {
            
            saveSettings() {
                if (this.$refs.activityDetails.validate()) {
                    this.$emit('update', this.details)
                }
            }
        },
    }
</script>

<style>
</style>