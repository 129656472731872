<template>
    <div>
        <v-toolbar color="primary" dense flat>
            <v-toolbar-title class="white--text c-text-h5">
                {{ $t('VotingResults') }} ({{title}})
            </v-toolbar-title>
            <v-spacer />
            <v-btn dark icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card class="pa-2 pt-4">
            <v-card-text v-if="votingResults.length > 0">
                <v-data-table :headers="headers" dense :items="votingResults" :items-per-page="10">
                    <template v-slot:[`item.createdDate`]="{ item }">
                        {{ item.createdDate | formatDateWithTimeGregorian }}
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-text v-else class="text-center">
                <span>{{ $t('NoItemsToShow') }}</span>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import MeetingAgendaService from '@/services/MeetingAgendaService'

    export default ({
        components: {
          
        },

        props: {
            agendaId: {
                type: Number,
                default: null
            },
            title: {
                type: String,
                default: null
            },
        },
        data() {
            return {
                loading: false,
                options: {
                     page: 1,
                     itemsPerPage: 10
                },
                votingResults:[],
                totalCount: 10,
                headers: [
                  { text: this.$t('Username'), value: 'userFullName' },
                  { text: this.$t('SelectedOption'), value: 'selectedOption' },
                  { text: this.$t('VotingDate'), value: 'createdDate' },

                ],
            }
        },
        created() {
           this.listMeetingAgendaVotingResults(this.agendaId)
        },
        methods: {
          
            listMeetingAgendaVotingResults(agendaId){
              this.loading = true
              MeetingAgendaService.listMeetingAgendaVotingResults(agendaId).then((res) => {
                    if (res.data) {
                        this.votingResults = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            
            close() {
                this.$emit('close')
            },

        },
    })
</script>

<style scoped>
   
</style>