<template>
    <v-card tile>
        <!-- Meeting Title Header -->
        <v-toolbar class="flex-grow-0" color="primary" dark dense flat>
            <v-toolbar-title color="white--text" class="flex-shrink-1 mt-2" style="max-width: 70%;"> {{ $t('Attachments') }}</v-toolbar-title>
            <v-spacer />
            <v-btn dark icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-container fluid class="mt-4">
            <v-row>
                <v-col cols="4">
                    <v-select v-model="selectedPrivacyId" dense outlined :rules="nameRules" item-text="name" item-value="id" :items="privacies" :label="$t('PrivacyLevel')" />

                </v-col>
                <v-col cols="3">
                    <v-switch v-model="isRelatedToAgenda" class="ma-1" :label="$t('RelatedAgenda')" color="teal darken-2" @change="setRelatedToAgenda($event)" />

                </v-col>
                <v-col cols="5">
                    <v-select v-if="isRelatedToAgenda" v-model="agendaId" dense
                              clearable
                              item-value="id"
                              item-text="title"
                              :label="$t('Agenda')"
                              :items="agendas"
                              outlined
                              :rules="isRelatedToAgenda ? requiredRule: []" />
                </v-col>
            </v-row>
        </v-container>
        <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
            <div class="dropZone-info" @drag="onChange">
                <span class="fa fa-cloud-upload dropZone-title" />
                <span class="dropZone-title">{{ $t('DragDrop') }}</span>
                <div class="dropZone-upload-limit-info">
                    <div>{{ $t('MaxFileSize') }} 5 {{ $t('MB') }}</div>
                </div>
            </div>
            <input type="file" multiple @change="onChange">
        </div>
        <div class="uploadedFile-info">
            <v-simple-table dense class="mt-4">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>
                                {{ $t('Icon') }}
                            </th>
                            <th>
                                {{ $t('FileName') }}
                            </th>
                            <th>
                                {{ $t('FileSize') }}
                            </th>
                            <th>
                                {{ $t('FileExtension') }}
                            </th>
                            <th>
                                {{ $t('Actions') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in files" :key="index">
                            <td>
                                <v-icon>{{ icons[item.name.split('.').pop()] }}</v-icon>
                            </td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.size }}</td>
                            <td>{{ item.name.split('.').pop() }}</td>
                            <td>
                                <v-icon color="error" @click="removeFile(index)">mdi-trash-can-outline</v-icon>
                                <v-icon v-if="item.id" color="primary" class="ms-2" @click="loadAttachment(item.id)">mdi-eye</v-icon>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <v-card-actions class="justify-center">
            <v-btn tile color="primary" :disabled="disableAdd" large @click="AddAttachments">
                {{ $t('Add') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script lang="ts">
    import MeetingsService from '@/services/MeetingsService.js'
    import LookupsService from '@/services/LookupsService.js'

    export default ({
        components: {
        },
        directives: {
        },
        filters: {
        },
        props: {
            meetingId: {
                type: Number,
                default: null
            },
        },
        data() {
            return {
                loading: false,
                files: [],
                file: '',
                dragging: false,
                icons: {
                    'pdf': 'mdi-file-pdf-box mdi-30px',
                    'docx': 'mdi-file-word mdi-30px',
                    'xlsx': 'mdi-file-excel mdi-30px',
                    'file': 'mdi-file mdi-30px',
                    'png': 'mdi-file-image mdi-30px',
                    'jpeg': 'mdi-file-image mdi-30px',
                    'jpg': 'mdi-file-image mdi-30px',
                    'txt': 'mdi-text-box-outline mdi-30px',
                    'rar': 'mdi-folder-zip mdi-30px',
                    'exe': 'mdi-cog mdi-30px',
                },
                privacies: [],
                selectedPrivacyId: null,
                agendas: [],
                agendaId: null,
                isRelatedToAgenda: false,
                requiredRule: [v => !!v || this.$t('RequiredField')],

            }
        },
        computed: {
            disableAdd(){
                return this.files.length==0 || (this.isRelatedToAgenda && !this.agendaId) || this.loading ||!this.selectedPrivacyId

            }
        },
        watch: {
        },
        created() {
            if (this.meetingId != null) {
                this.listMeetingAgendas()
                this.listPrivacies()
            }
        },
        methods: {
            listPrivacies() {
                if (this.privacies && this.privacies.length > 0) {
                    return
                }
                LookupsService.listPrivacies().then((res) => {
                    if (res.data) {
                        this.privacies = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                })
            },
            AddAttachments() {
                this.loading = true
                let formdata = new FormData()
                for (let i = 0; i < this.files.length; i++) {
                    formdata.append(`file${i}`, this.files[i])
                }
                if (((!this.isRelatedToAgenda && this.agendaId == null) || (this.agendaId != null)) && this.meetingId != null && this.files.length > 0&&this.selectedPrivacyId) {

                    MeetingsService.addMeetingAttachment(this.meetingId, formdata,this.selectedPrivacyId, this.agendaId).then(response => {
                        if (response.data) {
                            this.close()
                        } else {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
                else {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }
            },

            onChange(e) {
                var files = e.target.files || e.dataTransfer.files
                if (!files.length) {
                    this.dragging = false
                    return
                }
                for (var i = 0; i < files.length; i++) {
                    this.createFile(files[i])
                }

            },

            createFile(file) {
                if (!file.type.match('text.*|image.*|application.*')) {
                    this.$root.$notification(this.$t('InvalidFileType'), 'error')
                    this.dragging = false
                    return
                }

                if (file.size > 5000000) {
                    this.$root.$notification(this.$t('FileSizeExceeded'), 'error')
                    this.dragging = false
                    return
                }

                this.file = file
                this.files.push(file)
                this.dragging = false
            },

            removeFile(index) {
                this.files.splice(index, 1)
            },

            close() {
                this.$emit('close')
            },

            listMeetingAgendas() {
                MeetingsService.listAgendas(this.meetingId).then((res) => {
                    if (res.success) {
                        if (res.data != null) {
                            this.agendas = res.data
                        }
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {

                })
            },

            setRelatedToAgenda(event) {
                if (event == false) {
                    this.agendaId = null
                }
            },
        },
    })
</script>

<style>
</style>