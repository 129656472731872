import { mainApiAxios as axios } from '@/plugins/axios'

export default {

    download(query) {
        return axios.get(`attachments?${query}`, { responseType: 'blob' })
    },


    deleteAttachment(attachmentId) {
        return axios.delete(`attachments/${attachmentId}`)
    },

    getAttachment(attachmentId, recordId) {
        return axios.get(`attachments/${attachmentId}/${recordId}`)
    },

    getAttachment(attachmentId) {
        return axios.get(`attachments/${attachmentId}`)
    },

    getUserGuide() {
        return axios.get('attachments/guide', { responseType: 'blob' })
    }
}