<template>
    <v-card v-if="generalInfo" :loading="loading" tile>
        <div class="card-title pointer" @click="expanded = !expanded">
            {{ $t('ProcessTitle') }}: {{ generalInfo.processTitle }}
            <v-icon v-if="!expanded" class="float-end">mdi-chevron-down</v-icon>
            <v-icon v-if="expanded" class="float-end">mdi-chevron-up</v-icon>
        </div>
        <v-container v-if="expanded" fluid>
                <v-row>
                    <label-data :data="generalInfo.processNumber" :label="$t('ProcessNumber')" />
                    <label-data :data="generalInfo.referenceNumber" :label="$t('ReferenceNumber')" />
                    <label-data :translated="true" :data="generalInfo.status" :label="$t('Status')" css-class="red--text text--darken-4" />
                    <label-data :translated="true" :data="generalInfo.stage" :label="$t('Stage')" css-class="red--text text--darken-4" />
                    <label-data :data="generalInfo.createdDate | formatDateGregorian" :label="$t('CreatedDate')" />
                    <label-data :data="generalInfo.processType" :label="$t('ProcessType')" />
                </v-row>
                <v-divider class="mt-3 mb-3" />
        </v-container>
</v-card>
</template>
<script>
    import LabelData from '@/components/controls/LabelData.vue'

    export default {
        components: {
            LabelData
        },

        props: {
            generalInfo: {
                type: Object,
                default: function () { },
            },
        },

        data() {
            return {
                loading: false,
                expanded: true,
            }
        },

        methods: {

        }

    }
</script>
