<template>
	<v-tooltip bottom>
		<template v-slot:activator="{ on }">
			<template v-if="forDataTable">
				<v-icon :color="getIconColor" class="me-2" :disabled="disabled" @click.stop="btnClicked()" v-on="on">
					<slot />
				</v-icon>
			</template>

			<template v-else>
				<v-btn small class="ma-3" icon :disabled="disabled" :loading="loading" v-on="on">
					<v-icon :color="getIconColor" @click.stop="btnClicked()">
						<slot />
					</v-icon>
				</v-btn>
			</template>
		</template>
		<span>{{ title }}</span>
	</v-tooltip>
</template>
<script>
	export default {

		props: {

			disabled: {
				type: Boolean,
				default: false
			},

			loading: {
				type: Boolean,
				default: false
			},

			title: {
				type: String,
				default: ''
			},

			iconColor: {
				type: String,
				default: null
			},

			forDataTable: {
				type: Boolean,
				default: false
			}
		},

		computed: {

			getIconColor() {
				if (this.iconColor) {
					return this.iconColor
				}

				return this.forDataTable ? 'secondary' : 'primary darken-2'
			}

		},

		methods: {

			btnClicked() {
				this.$emit('click')
			}

		}
	}
</script>