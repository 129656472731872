import * as signalR from '@microsoft/signalr'
import store from '@/plugins/store/index.js'

let connection = null
let eventSubscriptions = [] // Store event subscriptions to re-apply

const createSignalRConnection = () => {
    if (!connection) {
        connection = new signalR.HubConnectionBuilder()
            .withUrl(`${process.env.VUE_APP_MAIN_API}intaliohub`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
                accessTokenFactory: () => {
                    return store.getters['user/token']
                },
            })
            .withAutomaticReconnect()
            .build()

        connection.onreconnected(() => {
            console.log('SignalR reconnected, re-subscribing to events')
            reSubscribeToEvents()
        })
    }

    return connection
}

const startSignalRConnection = async () => {
    if (connection && connection.state === signalR.HubConnectionState.Disconnected) {
        try {
            await connection.start()
            console.log('SignalR connection started successfully')

            reSubscribeToEvents() 
        } catch (err) {
            console.log('Error while starting SignalR connection:', err)
        }
    }
}

const stopSignalRConnection = async () => {
    if (connection && connection.state === signalR.HubConnectionState.Connected) {
        try {
            await connection.stop()
            console.log('SignalR connection stopped successfully')
        } catch (err) {
            console.log('Error while stopping SignalR connection:', err)
        }
    }
}

const subscribeToEvent = (eventName, callback) => {
    if (connection) {
        connection.on(eventName, callback)
        eventSubscriptions.push({ eventName, callback }) // Store event for future re-subscriptions
    }
}

const unsubscribeFromEvent = (eventName) => {
    if (connection) {
        connection.off(eventName)
        eventSubscriptions = eventSubscriptions.filter(sub => sub.eventName !== eventName)

    }
}
const reSubscribeToEvents = () => {
    eventSubscriptions.forEach(({ eventName, callback }) => {
        connection.on(eventName, callback)
    })
}

export { connection, createSignalRConnection, startSignalRConnection, stopSignalRConnection, subscribeToEvent, unsubscribeFromEvent }
