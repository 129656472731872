<template>
    <v-card tile :loading="loading" height="89vh">
        <div class="card-title">
            {{ $t('Apps') }}
        </div>
        <v-card-text>
            <v-row class="grey">
                <v-col v-for="app in apps" :key="app.workflowId" cols="3">
                    <v-card tile outlined min-height="170">
                        <v-list-item three-line style="min-height:120px">
                            <v-list-item-content>
                                <h3 class="my-4">
                                    {{ app.title }}
                                </h3>
                                <v-list-item-subtitle class="mt-2">
                                    {{ $t('InitiatedWorkflows') }}   <v-chip small color="secondary" label class="ms-3">{{ app.runningFlows }}</v-chip>
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-avatar tile size="50" color="primary">
                                <v-icon class="white--text"> {{ app.icon }}</v-icon>
                            </v-list-item-avatar>
                        </v-list-item>
                      <v-divider />
                        <v-card-actions>
                            <v-btn :to="{ name: 'workflow-designer', query: {id: app.workflowDefinitionId} }" icon dark color="error">
                                <v-icon dark>
                                    mdi-lead-pencil
                                </v-icon>
                            </v-btn>
                            <v-btn icon dark color="warning" class="ms-3">
                                <v-icon @click="setPermissions(app.workflowId)">mdi-shield-account</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
        <CommonDialog v-if="permissionDialog" v-model="permissionDialog" :title="$t('Permissions')" :fullscreen="false" width="35%" css-content-class="pa-0" @input="$emit('input', false)">
            <v-container fluid class="stretch">
                <GenericPermissions :item-id="selectedItem" :type-id="typeId" @close="permissionDialog = false" />
            </v-container>
        </CommonDialog>
    </v-card>
</template>

<script>
    import ProcessesService from '@/services/ProcessesService.js'
    import GenericPermissions from '@/components/app/admin/GenericPermissions.vue'
    import { PermissionTypesEnum } from '@/helpers/constants/enumerations'
    import CommonDialog from '@/components/controls/CommonDialog.vue'

    export default {
        components: {
            CommonDialog, GenericPermissions
        },

        data() {
            return {
                loading: false,
                apps: [],
                selectedItem: null,
                permissionDialog: false,
                typeId: PermissionTypesEnum.Process,
            }
        },
        created() {
            this.listWorkflows()
        },

        methods: {
            listWorkflows() {
                this.loading = true
                ProcessesService.listProcesses()
                    .then(res => {
                        if (res.data) {
                            this.apps = res.data
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },

            setPermissions(itemId) {
                this.selectedItem = itemId
                this.permissionDialog = true
            }
        }
    }
</script>

<style>
</style>