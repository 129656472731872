<template>
    <v-data-table :footer-props="footerProps"
                  :loading="loading"
                  :headers="headers"
                  :items="items"
                  :items-per-page="options.itemsPerPage"
                  :options.sync="options"
                  :server-items-length="total"
                  class="elevation-0 small-font"
                  dense
                  :disable-sort="disableSort"
                  single-expand
                  :show-expand="showExpand"
                  @item-expanded="onItemExpanded">
        <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>
    </v-data-table>
</template>

<script>
    export default {

        props: {

            headers: {
                type: Array,
                default: () => ([])
            },
            items: {
                type: Array,
                default: () => ([])
            },
            loading: {
                type: Boolean,
                default: false
            },
            total: {
                type: Number,
                default: 0
            },
            showExpand: {
                type: Boolean,
                default: false
            },
            resetGrid: {
                type: Number,
                default: 0
            },
            itemsPerPage: {
                type: Number,
                default: 10
            },
            disableSort: {
                type: Boolean,
                default: false
            }
        },


        data() {
            return {
                options: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: [],
                    sortDesc: [],
                    groupBy: [],
                    groupDesc: [],
                    mustSort: false,
                    multiSort: false,
                    refreshoptions: false,
                },
                footerProps: {
                    'items-per-page-options': [5, 10, 20, 50, 100],
                    'disable-pagination': false
                }
            }
        },
        created() {
            this.options.itemsPerPage=this.itemsPerPage

        },
        watch: {
            options: {
                handler(newvalue, oldvalue) {
                    if (JSON.stringify(newvalue) != JSON.stringify(oldvalue)) {
                        this.$emit('onChange', this.options)
                    }
                },
            },
            resetGrid() {
                this.resetOptions()
            },
            loading(newvalue) {
                this.footerProps['disable-pagination'] = newvalue
            }
        },

        methods: {

            resetOptions() {
                this.options = {
                    page: 1,
                    itemsPerPage: this.itemsPerPage,
                    sortBy: [],
                    sortDesc: [],
                    groupBy: [],
                    groupDesc: [],
                    mustSort: false,
                    multiSort: false,
                    refreshoptions: !this.options.refreshoptions,
                }
            },

            onItemExpanded(data) {
                this.$emit('item-expanded', data)
            }
        }
    }
</script>