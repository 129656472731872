import SettingsService from '@/services/SettingsService'
const app = {

	namespaced: true,

	state: () => ({
		translationFailed: false,
		options: null
	}),

	mutations: {

		setTranslationFailed: (state, status) => {
			state.translationFailed = status
		},

		SET_OPTIONS(state, opt) {
			state.options = opt
		}
	},

	actions: {
		translationFailed: (context, status) => {
			return new Promise((success) => {
				context.commit('setTranslationFailed', status)
				success()
			})
		},

		async fetch_options({ commit, dispatch }) {
			try {
				const data = await SettingsService.listThemeColors()
				commit('SET_OPTIONS', data)
			} catch (e) {
				console.error(e.message)
			}
		}

	},

	getters: {
		themeOptions(state) {
			return state.options
		},

		isTranslationFailed(state) {
			return state.translationFailed
		},
	}
}

export default app