<template>
    <div v-if="(isDialog && value) || !isDialog" :class="{'h100':!isDialog, 'h100-absolute':isDialog }">
        <!-- if query is null or empty then there is nothing to be diplayed -->
        <template v-if="!query">
            <!-- EMPTY -->
        </template>


        <!-- if file document is not supported by the viewer and the viewer should be opened in a dialog,
        then the file should be downloaded instead of being opened in the viewer
        how to tell is document is supported: the query should contains the following query string: norender=true
        -->
        <template v-else-if="toDownload">
            <v-overlay :value="isdownloading">
                <v-progress-circular :size="70" :width="5" color="teal" indeterminate />
            </v-overlay>
        </template>


        <!-- if file document is not supported by the viewer and the viewer is embedded in the page
            then the viewer should display a message that the file is not supported with an option to download the document-->
        <template v-else-if="toNotDownload">
            <v-container class="grey lighten-5 h100 text-center pt-15">
                <h2>{{ $t('FileIsNotSupported') }}</h2>
                <h2 v-if="!hideDownloadAction" class="mt-4">
                    {{ $t('DownloadFile') }}
                    <v-btn icon elevation="2" @click="download()">
                        <v-icon small>
                            mdi-file-download
                        </v-icon>
                    </v-btn>
                </h2>
                <v-overlay :value="isdownloading">
                    <v-progress-circular :size="70" :width="5" color="teal" indeterminate />
                </v-overlay>
            </v-container>
        </template>


        <!-- if the document is supported and the file should be in dialog-->
        <template v-else-if="isDialog">
            <v-dialog v-if="value"
                      :value="value"
                      dense
                      persistent
                      fullscreen
                      scrollable>
                <v-card>
                    <v-toolbar dense dark color="primary" class="flex-grow-0">
                        <v-toolbar-title>{{ title }}</v-toolbar-title>
                        <v-spacer />
                        <v-btn icon dark @click="close()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <iframe v-if="query != null" :src="documentUrl" :name="name" @load="loaded" />
                </v-card>
            </v-dialog>
        </template>


        <!-- if the document is supported and the file is embedded in the page-->
        <template v-else>
            <iframe v-if="query != null" :src="documentUrl" :name="name" @load="loaded" />
        </template>
    </div>
</template>
<script>
    import AttachmentsService from '@/services/AttachmentsService'
    import { downloadFile } from '@/helpers/downloader.js'
    export default {
        props: {
            query: {
                type: String,
                default: null
            },

            name: {
                type: String,
                required: true,
                default: null
            },

            isDialog: {
                type: Boolean,
                default: false
            },

            title: {
                type: String,
                default: null
            },

            isClientSide: {
                type: Boolean,
                default: false
            },

            value: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                isdownloading: false,
                renderQueryString: '?norender=true',
                renderAppendQueryString: '&norender=true',
                isLoadedFirstTime: false,
                hideDownloadAction: false,
            }
        },

        computed: {
            viewerUrl() {
                if (this.query && this.query.toLowerCase().startsWith('http')) {
                    return process.env.VUE_APP_VIEWER.replace(/file.*/i, 'file=')

                } else if (this.isClientSide && this.query && this.query.toLowerCase().startsWith('blob')) {
                    return this.query
                }
                return process.env.VUE_APP_VIEWER
            },

            documentUrl() {
                if (this.isClientSide && this.query && this.query.toLowerCase().startsWith('blob')) {
                    return this.query
                } else if (!this.toDownload) {
                    return this.query.toLowerCase().startsWith('http')
                        ? `${this.viewerUrl}${this.query}`
                        : `${this.viewerUrl}${encodeURIComponent(this.query)}`
                }
                return ''
            },

            isSupportedByViewer() {
                if (this.query != null) {
                    return !(this.query.includes(this.renderQueryString) || this.query.includes(this.renderAppendQueryString))
                }
                return false
            },

            toNotDownload() {
                return !this.isSupportedByViewer && !this.isDialog
            },

            toDownload() {
                return !this.isSupportedByViewer && this.isDialog
            },
        },

        watch: {
            query() {
                this.onQueryIsChanged()
            },
        },

        methods: {
            close() {
                this.$emit('input', false)
            },

            loaded() {
                if (!this.isLoadedFirstTime) {
                    this.isLoadedFirstTime = true
                    return
                }
                this.$emit('onUpdate')
            },

            onQueryIsChanged() {
                this.hideDownloadAction = false
                if (this.toDownload) {
                    this.download()
                }
            },

            download() {
                this.isdownloading = true
                AttachmentsService.download(this.query)
                    .then((res) => {
                        downloadFile(res)
                    })
                    .catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.isdownloading = false
                        this.hideDownloadAction = true
                        this.close()
                    })
            },
        },
    }
</script>

<style scoped>
    iframe {
        border: 0px;
        width: 100%;
        height: 100%;
    }

    .h100 {
        height: 100%;
    }

    .h100-absolute {
        height: 100%;
        position: absolute;
    }
</style>