<template>
	<div />
</template>
<script>
    import UsersService from '@/services/UsersService'
    import { mapGetters } from 'vuex'

    export default {
        computed: {
            ...mapGetters('user', ['isAuthenticated']),

            isLoggedIn() {
                return this.isAuthenticated && this.$route.name != 'login'
            },



        },
        created() {
            if (this.isAuthenticated) {
                UsersService.logout().then((res) => {
                    if (res.success) {
                        this.$store.dispatch('user/removeUserSession').then(() => {
                            this.$router.push({ name: 'login' })
                        })
                    } else {
                        this.$router.go(-1)
                    }
                }).catch(() => {
                    this.$router.go(-1)

                })
            } else {
                
            }
            
            
        }
    }
</script>