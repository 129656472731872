<template>
    <div>
        <v-toolbar color="primary" dense flat>
            <v-toolbar-title class="white--text c-text-h5">
                {{ $t('VotingTypeOptions') }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn dark icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card class="pa-2 pb-5">
            <div class="title-card">
                {{ $t("VotingTypeOptions") }}
            </div>
            <v-form ref="voteForm">
                <v-row class="ma-3">
                    <v-col cols="6">
                        <v-text-field v-model="voteOptionModel.nameAr" dense outlined :label="$t('NameAr')" :rules="cleanRequiredStringRules" required />

                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="voteOptionModel.nameEn" dense outlined :label="$t('NameEn')" :rules="cleanRequiredStringRules" required />

                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="voteOptionModel.displayOrder" dense outlined type="number" :label="$t('DisplayOrder')" :rules="requiredRules" required />

                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="voteOptionModel.weight" dense outlined type="number" :label="$t('Weight')" :rules="requiredRules" required />

                    </v-col>
                    <v-col cols="6">
                        <v-checkbox v-model="voteOptionModel.active" dense outlined :label="$t('Active')" />

                    </v-col>
                </v-row>
            </v-form>

            <v-card-actions class="justify-center ">
                <v-btn v-show="!selectedOption" class="mb-3" color="teal" dark @click="AddVotingOption">
                    {{ $t('Add') }}
                </v-btn>
                <v-btn v-show="selectedOption" class="mb-3" color="teal" dark @click="EditVotingOption">
                    {{ $t('Edit') }}
                </v-btn>
                <v-btn v-show="selectedOption" class="mb-3" color="teal" dark @click="CancelEdieVoteOption">
                    {{ $t('Cancel') }}
                </v-btn>
            </v-card-actions>
            <v-divider color="teal" class="ma-3" />

            <v-data-table class=" elevated-table" :headers="headers"
                          dense
                          item-key="id"
                          :items="voteOptions"
                          :items-per-page="5">
                <template v-slot:[`item.active`]="{ item }">
                    <v-icon color="primary">
                        {{ item.active ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small
                            class="mx-1"
                            :disabled="loading"
                            color="blue"
                            @click="EnableEdit(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon small
                            color="error"
                            :disabled="loading"
                            @click="deleteVote(item.id)">
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>

            <confirm-dialog ref="confirmDialog" />
        </v-card>
    </div>
</template>

<script>
    import SettingsService from '@/services/SettingsService'
    import ConfirmDialog from '@/components/controls/ConfirmDialog.vue'

    export default ({
        components: {
            ConfirmDialog
        },

        props: {
            votingTypeId : {
                type: Number,
                default: null
            },
        },
        data() {
            return {
                text: '',
                loading: false,
                isPublic: false,
                headers: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('NameAr'), value: 'nameAr' },
                    { text: this.$t('NameEn'), value: 'nameEn' },
                    { text: this.$t('Active'), value: 'active' },
                    { text: this.$t('DisplayOrder'), value: 'displayOrder' },
                    { text: this.$t('Weight'), value: 'weight' },
                    { text: this.$t('Actions'), value: 'actions' }
                ],
                cleanRequiredStringRules: [this.$rules.requiredRule, this.$rules.onlyAlphanumericRule],

                voteOptionModel: {
                    id: 0,
                    nameAr: null,
                    nameEn: null,
                    active: false,
                    weight: null,
                    displayOrder: null,
                    votingTypeId:null

                },
                requiredRules: [
                    v => !!v || this.$t('RequiredField')
                ],
                voteOptions: [],
                selectedOption: null,
            }
        },
        computed: {

        },
        created() {
            if (!this.votingTypeId) {
                this.close()
            }
            this.voteOptionModel.votingTypeId = this.votingTypeId
            this.loadVotingOptions()
        },
        mounted() {
            
        },
        methods: {
            
            EnableEdit(option) {
                this.selectedOption=option
                this.voteOptionModel.id = option.id
                this.voteOptionModel.nameAr = option.nameAr
                this.voteOptionModel.nameEn = option.nameEn
                this.voteOptionModel.active = option.active
                this.voteOptionModel.displayOrder = option.displayOrder
                this.voteOptionModel.weight = option.weight

            },
            
            CancelEdieVoteOption() {
                this.voteOptionModel.id = 0
                this.voteOptionModel.nameAr = null
                this.voteOptionModel.nameEn = null
                this.voteOptionModel.active = false
                this.voteOptionModel.displayOrder =null
                this.voteOptionModel.weight = null
                this.selectedOption=null

            },
            loadVotingOptions() {
                SettingsService.listAllVotingOptions(this.votingTypeId).then((res) => {
                    this.voteOptions = res.data

                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            AddVotingOption() {
                if (this.$refs.voteForm.validate()) {
                    this.loading = true
                    SettingsService.addVotingTypeOption(this.voteOptionModel).then((res) => {
                        if (res.data) {
                            this.$root.$notification(this.$t('AddedSuccess'), 'success')
                            this.CancelEdieVoteOption()
                            this.loadVotingOptions()
                        } else {
                            this.$root.$notification(this.$t('HEEEEE'), 'error')
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }

            },

            EditVotingOption() {
                if (this.$refs.voteForm.validate()) {
                    this.loading = true
                    SettingsService.updateVotingTypeOption(this.voteOptionModel).then((res) => {
                        if (res.data) {
                            this.$root.$notification(this.$t('UpdateSuccess'), 'success')
                            this.CancelEdieVoteOption()
                            this.loadVotingOptions()

                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false

                    })
                }
            },
            deleteVote(votingOptionId) {
                this.CancelEdieVoteOption()
                this.$refs.confirmDialog.open(this.$t('DeleteVotingOption'), this.$t('ConfirmDelete'))
                    .then((confirmed) => {
                        if (confirmed) {
                            SettingsService.deleteVotingTypeOption(votingOptionId).then((res) => {
                                if (res.success) {
                                    this.loadVotingOptions()
                                } else {
                                    this.$root.$notification(res.message, 'error')
                                }

                            }).catch(() => {
                                this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                            }).finally(() => {
                                this.loading = false
                            })
                        } else {

                        }
                    })

            },
            close() {
                this.$emit('close')
            }

        },
    })
</script>

<style scoped>
    .elevated-table {
        border: 1px solid #000000; /* Border around the table */
    }
</style>