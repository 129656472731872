<template>
    <v-menu v-model="menupicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="300">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field :value="hijridate"
                          :label="label"
                          :rules="rules"
                          :dense="dense"
                          :class="styles"
                          :outlined="outlined"
                          :filled="filled"
                          :disabled="disabled"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          clearable
                          :hide-details="hideDetails == null ? false : hideDetails"
                          readonly
                          @click:clear="handleClear" v-on="on" />
        </template>
        <v-hijri-date-picker v-model="hijridate" locale="ar" width="100%" :max="maxHijri" :min="minHijri" @input="handleInput" />
    </v-menu>
</template>

<script>
    import moment from 'moment-hijri'
    import VHijriDatePicker from 'vuetify-umalqura'

    export default {

        components: {
            VHijriDatePicker
        },

        props: ['label', 'rules', 'hijri', 'value', 'min', 'max', 'dense', 'outlined', 'styles', 'disabled', 'hideDetails', 'filled'],

        //value is the date in gregorian format
        //hijri is the date in hijri format
        //min is the min value in gregorian
        //max is the max value in gregorian

        data() {
            return {
                menupicker: false,
                hijridate: this.value != null ? moment(this.value, 'YYYY-MM-DD').format('iYYYY-iMM-iDD') : null
            }
        },

        computed: {
            maxHijri() {
                moment.locale('en-us')
                return this.max != null ? moment(this.max, 'YYYY-MM-DD').format('iYYYY-iMM-iDD') : null
            },

            minHijri() {
                moment.locale('en-us')
                return this.min != null ? moment(this.min, 'YYYY-MM-DD').format('iYYYY-iMM-iDD') : null
            }
        },

        watch: {
            value(value) {
                moment.locale('en-us')
                this.hijridate = value != null ? moment(value, 'YYYY-MM-DD').format('iYYYY-iMM-iDD') : null
                this.$emit('update:hijri', this.hijridate)
            }
        },

        methods: {
            handleInput() {
                this.menupicker = false
                moment.locale('en-us')
                const gregorianDate1 = moment(this.hijridate, 'iYYYY-iMM-iDD').format('YYYY-MM-DD')
                this.$emit('update:hijri', this.hijridate)
                this.$emit('input', gregorianDate1)
            },

            handleClear() {
                this.$emit('input', null)
            }
        }
    }
</script>