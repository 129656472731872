import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import VueCookie from 'vue-cookie'
import user from '@/plugins/store/modules/user.js'
import app from '@/plugins/store/modules/app.js'

Vue.use(Vuex)
Vue.use(VueCookie)

export default new Vuex.Store({
	modules: {
		user,
		app
	},
	plugins: [
		createPersistedState({
			key: 'app-session',
			paths: '/',
			storage: {
				getItem: (key) => Vue.cookie.get(key),
				setItem: (key, value) => Vue.cookie.set(key, value, {
					secure: process.env.NODE_ENV === 'production',
					sameSite: 'strict',
					expires: process.env.VUE_APP_COOKIE_TIMEOUT,
				}),
				removeItem: (key) => Vue.cookie.delete(key),
			},
		}),
	],
})


