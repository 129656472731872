<template>
    <CommonDialog :value="value" :fullscreen="true" :title="$t('Permissions')" show-action-toolbar @input="$emit('input', false)">
        <v-card-text>
            <v-row>
                <v-col v-for="permission in userPermissionsList" :key="permission.id" cols="12" md="12">
                    <v-card tile color="grey lighten-4">
                        <v-toolbar desnse color="primary" class="white--text" height="35px">
                            {{ $t(permission.typeName) }}
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col v-for="group in permission.items" :key="group" cols="4">
                                    <v-card tile>
                                        <div class="card-title"> {{ $t(group.groupName) }}</div>
                                        <v-card-text>
                                            <v-row>
                                                <v-col v-for="item in group.items" :key="item.id" cols="4">
                                                    <v-checkbox v-model="item.hasAccess" :label="$t(item.name)" class="text-start" color="teal" hide-details @change="updatePermission($event, item.id, permission.id)" />
                                                    <v-radio-group v-if="item.hasLevel" v-model="item.levelId">
                                                        <v-radio v-for="permissionLevel in permissionLevels" :key="permissionLevel.id" dense color="secondary" :label="$t(permissionLevel.name)" :value="permissionLevel.id" @change="setLevel(item,permissionLevel.id)" />
                                                    </v-radio-group>
</v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </CommonDialog>
</template>

<script>
    import CommonDialog from '@/components/controls/CommonDialog'
    import UsersService from '@/services/UsersService.js'
    import LookupsService from '@/services/LookupsService.js'
    import PermissionsService from '@/services/PermissionsService.js'

    export default {
        components: {
            CommonDialog
        },

        props: {
            value: {
                type: Boolean,
                default: false
            },
            userId: {
                type: Number,
                default:null
            }
        },
        data() {
            return {
                permissions: [],
                userPermissionsList: [],
                permissionLevels: []
            }
        },

        created() {
            this.loadPermissions()
        },

        methods: {

            updatePermission(event, permissionId, permissionTypeId) {
                UsersService.updatePermission(this.userId, permissionId, permissionTypeId, event).then((res) => {
                    if (res.data) {
                        this.$root.$notification(this.$t('UpdateSuccess'), 'success')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            setLevel(item, permissionId) {
                this.loading = true
                PermissionsService.setPermissionLevel(item.id, permissionId, this.userId).then((res) => {
                    if (res.data) {
                        this.$root.$notification(this.$t('UpdateSuccess'), 'success')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            loadPermissions() {
                this.loading = true
                let promises = [
                    UsersService.listUserPermissions(this.userId),
                    LookupsService.listPermissionLevels(),
                ]
                Promise.all(promises).then(([userPermissions, permissionLevels]) => {
                    this.userPermissionsList = userPermissions.data
                    this.permissionLevels = permissionLevels.data
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            }
        },
    }
</script>

<style>
</style>