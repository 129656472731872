<template>
    <CommonDialog :value="value" :title="$t('UpdateProfilePicture')" width="500" :fullscreen="false" show-action-toolbar @input="$emit('input', false)">
        <template v-slot:actions>
            <v-btn color="secondary" class="white--text" :disabled="!file||loading" small @click="UpdateProfilePicture">
                {{ $t("Update") }}
            </v-btn>
        </template>
        <div>
            <div class="text-center">
                <v-avatar size="100" class="mb-4">
                    <v-img v-if="imageUrl||oldPictureUrl" :src="imageUrl" />
                    <v-icon v-else>mdi-account-circle</v-icon>
                </v-avatar>
            </div>
            <v-file-input v-model="file"
                          label="Choose an image"
                          accept="image/*"
                          prepend-icon="mdi-camera" />
        </div>
    </CommonDialog>
</template>

<script>
    import { mapGetters } from 'vuex'
    import UsersService from '@/services/UsersService.js'
    import CommonDialog from '@/components/controls/CommonDialog.vue'
    import { getUserImage } from '@/helpers/common.js'

    export default ({
        components: {
            CommonDialog
        },
        props: {
            value: {
                type: Boolean,
                default: null
            },
        },
        data() {
            return {
                file: null,
                selectedImageUrl: null,
                loading: false,

            }
        },
        computed: {
            ...mapGetters('user', ['loggedInUser', 'language']),

            oldPictureUrl() {
                return this.loggedInUser.profilePictureUrl
               
            },
            imageUrl() {
                return this.selectedImageUrl ?? this.oldPictureUrl
            }
        },
        watch: {
            file(newFile) {
                if (newFile) {
                    this.selectedImageUrl = URL.createObjectURL(newFile)
                } else {
                    this.selectedImageUrl = null
                }
            },
        },
        created() {
        },
        methods: {
            UpdateProfilePicture() {
                this.loading = true
                let formdata = {
                    'profileImage': this.file
                }
                UsersService.updateProfilePicture(formdata).then(response => {
                    if (response.success) {
                        this.$store.dispatch('user/setHasProfilePicture', response.success)
                        this.$store.dispatch('user/setProfilePictureUrl', getUserImage(this.loggedInUser.id))
                        this.close()
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            close() {
                this.$emit('close')
            },
        },
    })
</script>

<style>
</style>