<template>
    <v-list style="height: 40vh; overflow-y: auto;scrollbar-width: none;">
        <v-list-item v-for="(attendee, index) in attendees" :key="index">
            <v-badge bordered
                     class="me-3"
                     top
                     start
                     :color="getUserStatusColor(attendee.userId)"
                     dot
                     offset-x="40"
                     offset-y="7">
                <v-avatar size="40">
                    <v-img v-if="attendee.hasProfilePicture" :src="getProfileImageUrl(attendee.userId)" />
                    <h3 v-else>{{ getUserInitials(attendee.name) }} </h3>
                </v-avatar>
            </v-badge>

            <v-list-item-content>
                <v-list-item-title>{{ attendee.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ attendee.jobTitle }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-checkbox v-if="meetingOwner" v-model="attendee.attended" class="custom-checkbox" :disabled="!editMode" dense small :label="$t('Attended')" @change="handleAttendanceChange($event, attendee.userId)" />
            </v-list-item-action>
        </v-list-item>
    </v-list>
</template>

<script>
    import MeetingsService from '@/services/MeetingsService'
    import { getUserImage } from '@/helpers/common.js'

    export default ({
        components: {
        },
        props: {
            meetingId: {
                type: Number,
                default: null,
            },
            attendees: {
                type: Array,
                default: () => [],
            },
            editMode: {
                type: Boolean,
                default: true,
            },
            meetingOwner: {
                type: Boolean,
                default: false,
            },
            currentAttendanceList: {
                type: Array,
                default: () => []
            },
        },
        data() {
            return {

            }
        },
        computed: {
        },

        created() {
        },
        beforeDestroy() {
        },
        methods: {
            getProfileImageUrl(userId) {
                return getUserImage(userId)
            },
            getUserStatusColor(userId) {
                return this.currentAttendanceList.some(u => u == userId) ? 'green' : 'error'
            },
            getUserInitials(name) {
                const namesArray = name.trim().split(/\s+/)
                const initials = namesArray.length > 1
                    ? namesArray[0][0] + ' ' + namesArray[namesArray.length - 1][0]
                    : namesArray[0][0]
                return initials ? initials.toUpperCase() : ''
            },
            handleAttendanceChange(newValue, attendeeId) {
                this.loading = true
                let formData = {
                    'userId': attendeeId,
                    'meetingId': this.meetingId,
                    'attended': newValue

                }
                MeetingsService.attendUser(formData).then((res) => {
                    if (res.data) {
                        this.$root.$notification(this.$t('UpdateSuccess'), 'success')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
        },
    })
</script>

<style scoped>
    .custom-checkbox .v-label {
        font-size: 12px;
        font-weight: normal;
    }
</style>