<template>
    <v-card tile :loading="loading" height="89vh">
        <v-tabs grow background-color="#dedede">
            <v-tab>
                <v-icon>
                    mdi-sitemap
                </v-icon>
                <h3 class="ms-2">{{ $t('Structures') }}</h3>
            </v-tab>
            <v-tab @change="listAdminUsers">
                <v-icon>
                    mdi-account
                </v-icon>
                <h3> {{ $t('Users') }}</h3>
            </v-tab>

            <v-tab-item>
<v-card-text>
                    <v-row justify="space-between">
                        <v-col cols="4">
                            <v-row>
                                <v-col cols="6">
                                    <v-btn class="mx-2 my-2" fab dark small color="primary" @click="addNewStructure">
                                        <v-icon>mdi-folder-plus</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="6">
                                    <v-switch v-model="onlyActive" :label="$t('ShowDeactivatedStructures')" dense hide-details color="teal" @change="listOrganization" />
                                </v-col>
                            </v-row>
                            <v-divider />
                            <v-sheet dense class="pa-4 mt-4" color="secondary">
                                <v-text-field v-model="search" dense :label="$t('Search')" dark flat solo-inverted hide-details clearable clear-icon="mdi-close-circle-outline" @input="handleSearch" />
                            </v-sheet>
                            <v-treeview ref="tree" :items="result" :search="search" :filter="filter" activatable item-key="id" :open.sync="open">
                                <template v-slot:prepend="{ open: isOpen }">
                                    <v-icon>
                                        {{ isOpen ? 'mdi-folder-open' : 'mdi-folder' }}
                                    </v-icon>
                                </template>
                                <template v-slot:label="{ item }">
                                        <a class="d-block pa-2" @click="viewStructureDetails(item)">
                                            {{ item.name }}
                                        </a>
                                </template>
                            </v-treeview>
                        </v-col>
                        <v-divider vertical />

                        <v-col>
                            <v-scroll-y-transition mode="out-in">
                                <div v-if="show">
                                    <v-row>
                                        <v-col cols="12" md="9"><h3 class="primary--text">{{ selectedStructureTitle }}</h3></v-col>
                                        <v-col cols="12" md="3" class="text-end">
                                            <!-- to do soft delete -->
                                            <v-btn small color="error" tile>
                                                {{ $t('Delete') }}
                                                <v-icon small class="ms-2">mdi-delete</v-icon>
                                            </v-btn>
                                            <v-btn small color="primary" class="ms-3 white--text" tile @click="editStructure">
                                                {{ $t('Edit') }}
                                                <v-icon small class="ms-2">mdi-pencil</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-card tile class="mt-6">
                                        <div class="card-title">{{ $t('UsersInStructure') }}</div>
                                        <v-data-table dense :headers="headers" :items="users" :items-per-page="10">
                                            <template v-slot:top>
                                                <v-btn small class="ma-4 white--text" tile color="secondary" @click="openUserAddDialog()">
                                                    {{ $t('AddUser') }} <v-icon small class="ms-2">mdi-account</v-icon>
                                                </v-btn>
                                            </template>
                                            <template v-slot:[`item.options`]="{ item }">
                                                <v-icon color="error" @click="removeUserFromStructure(item.id)">mdi-delete</v-icon>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </div>
                                <div v-if="!show">
                                    <v-card-text class="text-center">
                                        <div>{{ $t('NoItemsToShow') }}</div>
                                        <img src="@/assets/images/empty.png" width="50%">
                                    </v-card-text>
                                </div>
                            </v-scroll-y-transition>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-tab-item>

            <v-tab-item>
                <v-sheet dense class="pa-4 mt-4">
                    <v-toolbar class="pt-2" height="80" color="secondary" dark>
                        <UserAutocomplete v-model="selectedUserId" :active="onlyActiveUsers" :label="$t('SelectUserSearch')" class="w-500" />
                        <v-btn tile class="mb-4 ms-4" color="primary" @click="searchUser">
                            {{ $t('Search') }}
                            <v-icon color="white" class="ms-2">mdi-account-search</v-icon>
                        </v-btn>
                        <v-btn tile class="mb-4 ms-4" color="primary" @click="listAdminUsers(); selectedUserId = null">
                            <v-icon color="white" class="ms-2">mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-sheet>
                <v-card-text>
                    <CardSection :title="$t('Users')">
<GridWithServerPaging :headers="adminUserheaders" :items="adminUsers" :total="totalCount" disable-sort show-expand @onChange="listAdminUsers" @item-expanded="expand">
                            <template v-slot:[`item.approved`]="{ item }">
                                <v-chip v-if="item.approved" small color="success">
                                    <v-icon small>mdi-check</v-icon>
                                </v-chip>
                                <v-chip v-else small color="primary">
                                    <v-icon small>mdi-close-outline</v-icon>
                                </v-chip>
                            </template>
                            <template v-slot:[`item.smsNotification`]="{item}">
                                <v-switch v-model="item.smsEnabled" dense :label="$t('Activate')" color="teal" hide-details class="my-2" @change="enableSms(item)" />
                            </template>
                            <template v-slot:[`item.ldapEnabled`]="{item}">
                                <v-switch v-model="item.ldapEnabled" dense :label="$t('Activate')" color="teal" hide-details class="my-2" @change="enableLdap(item)" />
                            </template>
                            <template v-slot:[`item.emailNotification`]="{item}">
                                <v-switch v-model="item.emailNotificationEnabled" dense hide-details class="my-2" :label="$t('Activate')" color="teal" @change="enableEmail(item)" />
                            </template>
                            <template v-slot:top>
                                <v-row>
                                    <v-col cols="10">
                                        <v-btn class="mx-2 my-2" fab dark small color="primary" @click="userDialog= true; isEditUser = false">
                                            <v-icon dark>
                                                mdi-account-plus
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-switch v-model="onlyActiveUsers" class="text-end" :label="$t('InactiveUsers')" @change="listAdminInactiveUsers" />
                                    </v-col>
                                </v-row>
</template>

                            <template v-slot:expanded-item="{ item }">
                                <td :colspan="headers.length" class="td-border">
                                    <v-progress-linear v-if="loadingRoles" color="teal" indeterminate reverse />
                                    <template v-if="item.structuresRoles && item.structuresRoles.length > 0">
                                        <v-simple-table dense>
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            {{ $t('Role') }}
                                                        </th>
                                                        <th>
                                                            {{ $t('Structure') }}
                                                        </th>
                                                        <th>
                                                            {{ $t('IsPrimary') }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(structure, index) in item.structuresRoles" :key="index">
                                                        <td>{{ structure.roleName }}</td>
                                                        <td>{{ structure.structureName }}</td>
                                                        <td>
                                                            <v-icon v-if="structure.isPrimary" color="teal">mdi-check</v-icon>
                                                            <v-icon v-else color="error">mdi-close</v-icon>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </template>
                                    <span v-else>{{ $t('NoItemsToShow') }}</span>
                                </td>
                            </template>

                            <template v-slot:[`item.options`]="{ item }">
                                <v-icon color="primary" @click="selectedUserId = item.id; userDialog= true; isEditUser = true">mdi-pencil</v-icon>
                                <v-icon color="primary" class="ms-3" @click="selectedUserId = item.id; userPermissionDialog= true;">mdi-shield-half-full</v-icon>
                                <v-icon color="teal" class="ms-3" @click="selectedUserId = item.id; userResetPasswordDialog= true;">mdi-lock-reset</v-icon>
                            </template>
                        </GridWithServerPaging>
                    </CardSection>
                </v-card-text>
            </v-tab-item>
        </v-tabs>

        <admin-structure-form v-if="structureDialog" v-model="structureDialog" :edit="isEditStructure" :structure-id="selectedStructureId" @update="updated" />
        <admin-user-form v-if="userDialog" v-model="userDialog" :user-id="selectedUserId" :edit="isEditUser" @update="userUpdated" />
        <user-permissions v-if="userPermissionDialog" v-model="userPermissionDialog" :user-id="selectedUserId" />
        <user-reset-password v-if="userResetPasswordDialog" v-model="userResetPasswordDialog" :user-id="selectedUserId" />

        <v-form ref="userInStructureForm" v-model="valid" lazy-validation>
            <CommonDialog v-if="userAddDialog" v-model="userAddDialog" :fullscreen="false" width="700" :title="$t('AddUser')" show-action-toolbar>
                <v-container>
                    <v-row class="mt-2 mb-2">
                        <v-col cols="12">
                            <UserAutocomplete v-model="selectedUserId" :rules="nameRules" required :label="$t('SelectUserSearch')" class="mt-4 mb-4" />
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete v-model="selectedRoleId" :rules="nameRules" required item-text="name" item-value="id" :items="roles" :label="$t('SelectRole')" />
                        </v-col>
                    </v-row>
                </v-container>
                <template v-slot:actions>
                    <v-btn color="secondary" class="white--text" tile small @click="saveUserToStructure">
                        {{ $t("Add") }}
                    </v-btn>
                    <v-btn color="error" class="white--text" tile small @click="userAddDialog = false; selectedRoleId= null">
                        {{ $t("Cancel") }}
                    </v-btn>
                </template>
            </CommonDialog>
        </v-form>
    </v-card>
</template>

<script>
    import AdminStructureForm from '@/components/app/admin/AdminStructureForm'
    import UserPermissions from '@/components/app/admin/UserPermissions'
    import UserResetPassword from '@/components/app/admin/UserResetPassword'
    import AdminUserForm from '@/components/app/admin/AdminUserForm'
    import CommonDialog from '@/components/controls/CommonDialog'
    import UserAutocomplete from '@/components/controls/UserSelect'
    import GridWithServerPaging from '@/components/controls/GridWithServerPaging.vue'
    import CardSection from '@/components/controls/CardSection.vue'
    import StructuresService from '@/services/StructuresService.js'
    import LookupsService from '@/services/LookupsService.js'
    import UsersService from '@/services/UsersService.js'

    export default {
        components: {
            AdminStructureForm, UserPermissions, AdminUserForm, UserAutocomplete, CommonDialog, GridWithServerPaging, CardSection, UserResetPassword
        },

        data() {
            return {
                show: false,
                valid: true,
                onlyActive: true,
                onlyActiveUsers: true,
                loadingRoles: false,
                userPermissionDialog: false,
                userResetPasswordDialog: false,
                userAddDialog: false,
                loading: true,
                structureDialog: false,
                userDialog: false,
                isEditStructure: false,
                isEditUser: false,
                selectedStructureTitle: '',
                result: [],
                users: [],
                adminUsers: [],
                roles: [],
                open: [1, 2],
                totalCount: 0,
                selectedStructureId: null,
                selectedUserId: null,
                tab: null,
                search: null,
                selectedRoleId: null,
                options: {
                    page: 1, itemsPerPage: 10
                },
                headers: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('FullName'), value: 'fullname' },
                    { text: this.$t('Role'), value: 'roleName' },
                    { text: this.$t('Email'), value: 'email' },
                    { text: this.$t('Options'), value: 'options' },
                ],
                adminUserheaders: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('FullNameAr'), value: 'fullnameAr' },
                    { text: this.$t('FullNameEn'), value: 'fullnameEn' },
                    { text: this.$t('Username'), value: 'username' },
                    { text: this.$t('Email'), value: 'email' },
                    { text: this.$t('EnableSms'), value: 'smsNotification' },
                    { text: this.$t('EnableEmail'), value: 'emailNotification' },
                    { text: this.$t('LdapEnabled'), value: 'ldapEnabled' },
                    { text: this.$t('Approved'), value: 'approved' },
                    { text: this.$t('Options'), value: 'options' },
                ],
                nameRules: [
                    v => !!v || this.$t('RequiredField')
                ],
            }
        },

        computed: {
            filter() {
                return this.caseSensitive
                    ? (item, search, textKey) => item[textKey].indexOf(search) > -1
                    : undefined
            },
        },

        watch: {
            userAddDialog: {
                handler: function (open) {
                    if (open) {
                        this.listRoles()
                    }
                }
            }
        },

        created() {
            this.listOrganization()
        },

        methods: {

            handleSearch(input) {
                if (input) {
                    this.$refs.tree.updateAll(true)
                } else {
                    this.$refs.tree.updateAll(false)
                }
            },
            openUserAddDialog() {
                this.selectedUserId = null
                this.selectedRoleId = null
                this.userAddDialog = true
            },

            addNewStructure() {
                this.isEditStructure = false
                this.structureDialog = true
            },

            updated(value) {
                if (value) {
                    this.listOrganization()
                }
            },

            userUpdated(value) {
                if (value) {
                    this.listAdminUsers()
                }
            },

            listOrganization() {
                StructuresService.listOrganization(this.onlyActive).then((res) => {
                    if (res.data) {
                        this.result = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            listRoles() {
                LookupsService.listRoles().then((res) => {
                    if (res.data) {
                        this.roles = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            viewStructureDetails(item) {
                if (item.id) {
                    this.show = true
                    this.selectedStructureId = item.id
                    this.selectedStructureTitle = item.name
                    this.listUsersInStructure(item.id)
                }
            },

            listUsersInStructure(structureId) {
                StructuresService.listUsersInStructure(structureId).then((res) => {
                    if (res.data) {
                        this.users = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            saveUserToStructure() {
                if (this.$refs.userInStructureForm.validate()) {
                    StructuresService.addUserToStructure(this.selectedStructureId, this.selectedUserId, this.selectedRoleId).then((res) => {
                        if (res.success) {
                            this.userAddDialog = false
                            this.listUsersInStructure(this.selectedStructureId)
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
            },

            editStructure() {
                this.isEditStructure = true
                this.structureDialog = true
            },

            enableSms(user) {
                if (user) {
                    UsersService.enableSms(user.id, user.smsEnabled)
                        .then(() => {
                            this.$root.$notification(user.smsEnabled == true ? this.$t('SmsEnabled') : this.$t('SmsDisabled'), 'success')
                        })
                        .catch(() => {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }).finally(() => this.loading = false)
                }
            },

            enableLdap(user) {
                if (user) {
                    UsersService.enableLdap(user.id, user.ldapEnabled)
                        .then(() => {
                            this.$root.$notification(user.ldapEnabled == true ? this.$t('LdapIsEnabled') : this.$t('LdapIsDisabled'), 'success')
                        })
                        .catch(() => {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }).finally(() => this.loading = false)
                }
            },

            enableEmail(user) {
                if (user) {
                    UsersService.enableEmail(user.id, user.emailNotificationEnabled)
                        .then(() => {
                            this.$root.$notification(user.smsEnabled == true ? this.$t('emailEnabled') : this.$t('emailDisabled'), 'success')
                        })
                        .catch(() => {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }).finally(() => this.loading = false)
                }
            },

            async removeUserFromStructure(userId) {
                if (await this.$root.$confirm(this.$t('Delete'), this.$t('ConfirmDelete'))) {
                    this.loading = true
                    StructuresService.removeUserFromStructure(this.selectedStructureId, userId)
                        .then(() => {
                            this.listUsersInStructure(this.selectedStructureId)
                        })
                        .catch(() => {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }).finally(() => this.loading = false)
                }
            },

            listAdminInactiveUsers() {
                this.listAdminUsers()
            },

            listAdminUsers(options) {
                const page = options != undefined || options == false ? options.page : 1
                const pageSize = options != undefined ? options.itemsPerPage : 10
                let active = this.onlyActiveUsers
                this.loading = true
                UsersService.listAdminUsers(page, pageSize, active)
                    .then((res) => {
                        if (res.data) {
                            this.adminUsers = res.data.data
                            this.totalCount = res.data.total
                        }
                    })
                    .catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => this.loading = false)
            },

            expand(data) {
                if (data.value == true) {
                    this.loadingRoles = true
                    UsersService.listUserRolesStructures(data.item.id)
                        .then((res) => {
                            if (res.data) {
                                data.item.structuresRoles = res.data
                            }
                        })
                        .catch(() => {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }).finally(() => this.loadingRoles = false)
                }
            },

            searchUser() {

                if (this.selectedUserId) {
                    this.loading = true
                    UsersService.getUser(this.selectedUserId)
                        .then((res) => {
                            this.adminUsers = []
                            this.adminUsers.push(res.data)
                        })
                        .catch(() => {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }).finally(() => this.loading = false)
                }
                else {
                    this.$root.$notification(this.$t('SelectUser'), 'error')
                }
            }
        },
    }
</script>

<style>
    .v-treeview-node__label { cursor: pointer !important }

    .w-500 { width: 500px !important }
</style>