<template>
    <v-row>
        <v-col v-if="!viewMode" cols="4">
            <div ref="autocompleteContainer">
                <v-autocomplete v-model="selectedItem" clearable dense tile
                                small
                                :loading="searchLoading"
                                :label="label"
                                :items="items"
                                item-value="id"
                                item-text="name"
                                hide-no-data
                                no-filter
                                :search-input.sync="searchText"
                                :rules="rules"
                                :disabled="disabled"
                                :outlined="outlined"
                                return-object>
                    <template v-slot:item="{ item }">
                        <div class="item-autocomplete-item">
                            <div class="item-autocomplete-text">
                                <span>{{ item.name }}</span>
                            </div>
                        </div>
                    </template>
                </v-autocomplete>
            </div>
        </v-col>
        <v-col v-if="!viewMode" cols="2">
            <v-btn :elevation="0" class="mt-1" fab dark x-small color="secondary" @click="addMeeting">
                <v-icon dark small>
                    mdi-plus
                </v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12">
            <v-simple-table class="mt-4" style="width:83%">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>
                                {{ $t('Icon') }}
                            </th>
                            <th>
                                {{ $t('Id') }}
                            </th>
                            <th>
                                {{ $t('Title') }}
                            </th>
                            <th>
                                {{ $t('Actions') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in associatedMeetings" :key="item.id">
                            <td>
                                <v-icon>mdi-calendar</v-icon>
                            </td>

                            <td>{{ item.id }}</td>
                            <td>{{ item.name }}</td>

                            <td>
                                <v-icon v-if="!viewMode" color="error" @click="removeMeeting(index)">mdi-trash-can-outline</v-icon>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>
</template>
<script>
    import MeetingsService from '@/services/MeetingsService.js'

    export default {

        props: {
            value: {
                type: Array,
                default: () => []
            },

            label: {
                type: String,
                default: ''
            },

            outlined: {
                type: Boolean,
                default: false
            },

            rules: {
                type: Array,
                default: () => []
            },
            disabled: {
                type: Boolean,
                default: false
            },

            viewMode: {
                type: Boolean,
                default: false
            },
            meetingId: {
                type: Number,
                default: null
            }
        },

        data() {
            return {

                searchLoading: false,
                items: [],
                //setting searchText to empty string will fire the watch.
                //DO NOT UPDATE
                searchText: '',
                selectedItem: null,
                associatedMeetings: [],
                textLoadedFromWatch: false,
                textLoadedFromApi: false
            }
        },

        computed: {

        },


        watch: {
            value: {
                handler: function () {
                    this.associatedMeetings = this.value
                }
            },

            searchText(val) {
                if (this.selectedItem != null && val == this.selectedItem.name && this.selectedItem.name != null) {
                    //prevent calling the api when setting the text
                    return
                }
                if (val) {
                    this.loadItems()
                }
            },

        },

        created() {
            this.associatedMeetings = this.value
        },

        methods: {

            loadItems() {
                if (this.searchLoading) {
                    return
                }

                this.searchLoading = true
                MeetingsService.listMeetingsForAutoComplete(this.searchText != null ? this.searchText : '')
                    .then((res) => {
                        this.items = res.data

                        if (!this.textLoadedFromApi) {
                            this.textLoadedFromApi = true
                            this.preLoadSelectedItem(this.value, this.selectedText)
                        }

                    })
                    .catch(() => {
                        this.items = []
                    })
                    .finally(() => {
                        this.searchLoading = false
                    })
            },

            removeMeeting(index) {
                MeetingsService.removeAssociatedMeetings(this.meetingId, this.associatedMeetings[index].id).then(response => {
                    if (response.data) {
                        this.associatedMeetings = response.data
                        this.$emit('update', response.data)
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                    this.selectedItem = null
                })
            },

            preLoadSelectedItem(id, name) {
                if (id != null && name != null && this.items.findIndex(stc => stc.id == this.value) == -1) {
                    this.items.push({
                        id: id,
                        name: name
                    })

                }
            },

            addMeeting() {
                MeetingsService.addAssociatedMeetings(this.meetingId, this.selectedItem.id).then(response => {
                    if (response.data) {
                        this.associatedMeetings = response.data
                        this.$emit('update', response.data)
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                    this.selectedItem = null
                })
            }
        }
    }

</script>
<style scoped>
    .item-autocomplete > .v-input__append-outer { margin-top: 2px !important; }

    .item-autocomplete-text { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; line-height: 24px; }

    .item-autocomplete-item { padding: 7px; }
</style>