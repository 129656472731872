<template>
    <v-card height="100vh" color="#f5f5f5">

        <v-toolbar color="primary" dark dense>
            <v-toolbar-title> {{ meetingName|trim(120) }}<template v-if="meetingDetails"> ( {{ meetingDetails.councilSessionName??""  }} ) </template> </v-toolbar-title>
            <v-spacer />
            <v-icon @click="close">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text class="px-0">
            <v-container fluid>
                <v-row>
                    <v-col cols="2">
                        <MeetingDashboardAgenda v-if="meetingDetails" :meeting-id="meeting.id" :meeting-owner="meetingOwner" :attendees="attendees"
                                                :status-id="meetingDetails.statusId" :view-mode="viewMode" :agenda-list="agendaItems"
                                                @generateFinalMeetingMinutes="generateFinalMeetingMinutes" @startNextMeetingAgenda="startNextMeetingAgenda"
                                                @getMeetingMinutes="getMeetingMinutes" @update="updateAgendaList" />
                    </v-col>
                    <v-col cols="10">
                        <LiveMeetingAttendees v-if="showAttendees" :attendees="attendees" :current-attendance-list="currentAttendanceList" />
                        <v-row>
                            <v-col cols="9">
                                <v-card class="fill-height grey lighten-4">
                                    <v-col v-if="loading" class="loader-container">
                                        <v-progress-circular :rotate="360"
                                                             size="100"
                                                             width="10"
                                                             indeterminate
                                                             color="primary">
                                        </v-progress-circular>
                                        <span class="loading-text">{{ loadingText }}</span>
                                    </v-col>
                                    <FileViewer v-else-if="selectedFile" :query="meetingFileDocumentQuery" :name="$t('File')" />
                                    <v-col v-else-if="showGenerateInitialMeetingMinutes" cols="12" class="loader-container">
                                        <v-btn class="white--text"
                                               color="primary"
                                               @click="openGenerateMeetingMinutesDialogue">
                                            {{ $t("GenerateInitialMeetingMinutes") }}
                                        </v-btn>
                                    </v-col>
                                    <FileViewer v-else-if="showInitialMeetingMinutes" :query="initialDocumentQuery" :name="$t('MeetingMinutes')" />
                                    <FileViewer v-else-if="showFinalMeetingMinutes" :query="finalDocumentQuery" :name="$t('MeetingMinutes')" />
                                    <div v-else class="file-placeholder"> {{ $t('FilePlaceHolder') }}</div>
                                </v-card>
                            </v-col>
                            <v-col cols="3" class="pb-4">
                                <MeetingDashboardAttachments v-if="meetingDetails" class="mt-2" :attachments-height="attachmentsHeight"
                                                             :meeting-id="meeting.id"
                                                             :agenda-items="agendaItems"
                                                             :selected-file="selectedFile"
                                                             :meeting-owner="meetingOwner"
                                                             :status-id="meetingDetails.statusId"
                                                             :view-mode="viewMode"
                                                             @loadAttachment="loadAttachment"
                                                             @closeOpenedFile="closeOpenedFile"
                                                              />
                                <MeetingDashboardChat class="my-4" style="height:35vh;" :meeting-id="meeting.id" :view-mode="viewMode" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

            </v-container>
        </v-card-text>
        <v-card-actions v-if="meetingDetails" class="main-card-action" color="grey">

            <v-avatar :color="showAttendees?'primary':'white'"
                      size="40"
                      @click="toggleAttendeesVisibility">
                <v-icon :color="showAttendees?'white':'black'">
                    mdi-account-multiple
                </v-icon>
            </v-avatar>

            <v-menu v-model="showAttendeesStatusList"
                    :close-on-content-click="false"
                    offset-y
                    top
                    min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar v-bind="attrs"
                              :color="showAttendeesStatusList?'primary':'white'"
                              v-on="on"
                              size="40"
                              class="ms-3"
                              @click="showAttendeesStatusList = true">
                        <v-icon :color="showAttendeesStatusList?'white':'black'">mdi-account-group</v-icon>
                    </v-avatar>
                </template>
                <v-card class="tooltip-card">
                    <attendees-status-list :meeting-id="meeting.id" :meetingOwner="meetingOwner" :attendees="attendees" :current-attendance-list="currentAttendanceList" />
                </v-card>
            </v-menu>
            <v-menu v-model="showInfoMenu"
                    :close-on-content-click="false"
                    offset-y
                    top
                    min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar v-bind="attrs"
                              size="40"
                              class="ms-3"
                              :color="showInfoMenu?'primary':'white'"
                              v-on="on"
                              @click="showInfoMenu=true">
                        <v-icon :color="showInfoMenu?'white':'black'">mdi-information-variant</v-icon>
                    </v-avatar>
                </template>
                <v-card>
                    <v-card-text>
                        <meeting-dashboard-info :meeting-details="meetingDetails" />
                    </v-card-text>
                </v-card>
            </v-menu>
            <v-menu v-if="meetingOwner" v-model="showAssociatedMenu"
                    :close-on-content-click="false"
                    offset-y
                    top
                    min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar v-bind="attrs"
                              size="40"
                              class="ms-3"
                              :color="showAssociatedMenu?'primary':'white'"
                              v-on="on"
                              @click="showAssociatedMenu=true">
                        <v-icon :color="showAssociatedMenu?'white':'black'">mdi-monitor-account</v-icon>
                    </v-avatar>
                </template>
                <v-card>
                    <v-card-text>
                        <meeting-dashboard-associated :meeting-id="meeting.id" :meeting-owner="meetingOwner" />
                    </v-card-text>
                </v-card>
            </v-menu>
            <v-menu v-if="meetingOwner" v-model="showApprovalsMenu"
                    :close-on-content-click="false"
                    offset-y
                    top>
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar v-bind="attrs"
                              size="40"
                              class="ms-3"
                              :color="showApprovalsMenu?'primary':'white'"
                              v-on="on"
                              @click="showApprovalsMenu=true">
                        <v-icon :color="showApprovalsMenu?'white':'black'">mdi-tag-check-outline</v-icon>
                    </v-avatar>
                </template>
                <v-card>
                    <meeting-dashboard-approvals height="400" :meeting-id="meeting.id" />
                </v-card>
            </v-menu>
            <v-menu v-if="showAgendaVotingMenu" v-model="showMeetingAgendaVotingReportDialogue"
                    offset-y
                    top>
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar v-bind="attrs"
                              size="40"
                              class="ms-3"
                              :color="showMeetingAgendaVotingReportDialogue?'primary':'white'"
                              v-on="on"
                              @click="showMeetingAgendaVotingReportDialogue=true">
                        <v-icon :color="showMeetingAgendaVotingReportDialogue?'white':'black'">mdi-clipboard-text</v-icon>
                    </v-avatar>
                </template>
            </v-menu>
            <div v-if="showInitialMeetingMinutes">
                <v-select v-model="selectedVersion"
                          class="mb-0 pa-0 mx-3"
                          :items="InitialMeetingMinutesVersions"
                          item-text="name"
                          dense
                          return-object
                          outlined
                          :label="$t('Versions')"
                          item-value="id"
                          style="height: 36px; margin-bottom: 0;"
                          @change="onVersionChange" />
            </div>
            <v-spacer />

            <v-row v-if="showInitialMeetingMinutesActions" class="justify-end" no-gutters>
                <v-col cols="auto">
                    <v-btn class="blue white--text" disable="loading" dense @click="openSendMeetingMinutes">
                        <v-icon> mdi-format-list-bulleted</v-icon>
                        {{ $t('SendInitialMeetingMinutes') }}
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn class="secondary mx-3" disable="loading" dense @click="openGenerateMeetingMinutesDialogue">
                        <v-icon v-if="isArabicLanguage" left>mdi-refresh mdi-rotate-180 </v-icon>
                        <v-icon v-else left>mdi-refresh  </v-icon>
                        {{ $t('ReCreate') }}
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn class="primary" disable="loading" dense @click="approveInitialMeetingMinutes">
                        <v-icon>mdi-check  </v-icon>
                        {{ $t('Approve') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-row v-else-if="showInitialMeetingMinutes&&!viewMode" class="justify-end" no-gutters>
                <v-btn class="primary mx-3" disable="loading" dense @click="openGenerateMeetingMinutesDialogue">
                    <v-icon v-if="isArabicLanguage" left>mdi-send mdi-rotate-180 </v-icon>
                    <v-icon v-else left>mdi-send  </v-icon>
                    {{ $t('GenerateFinalMeetingMinutes') }}
                </v-btn>
            </v-row>

            <v-row v-if="showFinalMeetingMinutesActions" class="justify-end align-end">
                <v-col cols="auto" class="ma-0">
                    <v-btn class="primary" disable="loading" dense @click="openSendFinalMeetingMinutes">
                        <v-icon> mdi-format-list-bulleted </v-icon>
                        {{ $t('SendFinalMeetingMinutes') }}
                    </v-btn>
                </v-col>
                <v-col cols="auto" class="ma-0">
                    <v-btn class="primary" disable="loading" dense @click="approveFinalMeetingMinutes">
                        <v-icon>mdi-check  </v-icon>
                        {{ $t('Approve') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-btn v-if="canStartMeeting" tile class="primary ms-3 px-5 white--text" disable="loading" @click="startMeeting">
                <v-icon>mdi-power</v-icon>
                {{ $t('StartMeeting') }}
            </v-btn>
            <v-btn color="error" class="ms-3 px-5 white--text" :loading="endLoading" :disabled="!canEndMeeting" @click="finishMeeting">{{ $t('EndMeeting') }}</v-btn>
        </v-card-actions>


        <v-dialog v-if="showSendMeetingMinutes" v-model="showSendMeetingMinutes" width="1000">
            <SendMeetingMinutesForApproval :id="meeting.id" :attendees="attendees" @close="showSendMeetingMinutes = false" />
        </v-dialog>
        <v-dialog v-if="showSendFinalMeetingMinutes" v-model="showSendFinalMeetingMinutes" width="1000">
            <SendFinalMeetingMinutesForApproval :id="meeting.id" :attendees="attendees" @close="showSendFinalMeetingMinutes = false" />
        </v-dialog>
        <v-dialog v-if="showMeetingAgendaVotingReportDialogue" v-model="showMeetingAgendaVotingReportDialogue" width="70%">
            <MeetingAgendaVotingResultsReport :id="meeting.id" @close="showMeetingAgendaVotingReportDialogue = false" />
        </v-dialog>
        <v-dialog v-if="showGenerateInitialMeetingMinutesDialogue" v-model="showGenerateInitialMeetingMinutesDialogue" width="40%">
            <GenerateInitialMeetingMinutes :meeting-id="meeting.id" @update="handleMeetingMinutesLoading" :meeting-minutes-type="getCurrentMeetingMinutesType" @close="showGenerateInitialMeetingMinutesDialogue = false" />
        </v-dialog>
        <confirm-dialog ref="confirmDialog" />
    </v-card>
</template>

<script>
    import LiveMeetingAttendees from '@/components/app/meeting/MeetingDashboard/MeetingDashboardAttendees.vue'
    import MeetingDashboardAgenda from '@/components/app/meeting/MeetingDashboard/MeetingDashboardAgenda.vue'
    import MeetingsService from '@/services/MeetingsService'
    import { connection, createSignalRConnection, startSignalRConnection, subscribeToEvent, unsubscribeFromEvent } from '@/plugins/hub/myHubPlugin.js'
    import { mapGetters } from 'vuex'
    import AttachmentsService from '@/services/AttachmentsService.js'
    import { MeetingStatusEnum, SignalRStatus, MeetingMinutesTypesEnum } from '@/helpers/constants/enumerations'
    import MeetingDashboardInfo from '@/components/app/meeting/MeetingDashboard/MeetingDashboardInfo.vue'
    import MeetingDashboardAssociated from '@/components/app/meeting/MeetingDashboard/MeetingDashboardAssociated.vue'
    import AttendeesStatusList from '@/components/app/meeting/MeetingDashboard/MeetingDashboardAttendeesStatusList.vue'
    import FileViewer from '@/components/controls/FileViewer.vue'
    import ConfirmDialog from '@/components/controls/ConfirmDialog.vue'
    import SendMeetingMinutesForApproval from '@/components/app/meeting/MeetingAttendeesForApproval'
    import SendFinalMeetingMinutesForApproval from '@/components/app/meeting/SendFinalMeetingMinutesForApproval'
    import MeetingDashboardChat from '@/components/app/meeting/MeetingDashboard/MeetingDashboardChat'
    import MeetingDashboardAttachments from '@/components/app/meeting/MeetingDashboard/MeetingDashboardAttachments'
    import MeetingDashboardApprovals from '@/components/app/meeting/MeetingDashboard/MeetingDashboardApprovals.vue'
    import MeetingAgendaVotingResultsReport from '@/components/app/meeting/MeetingDashboard/MeetingAgendaVotingResultsReport.vue'
    import GenerateInitialMeetingMinutes from '@/components/app/meeting/MeetingDashboard/GenerateInitialMeetingMinutes.vue'


    export default {
        components: {
            LiveMeetingAttendees,
            MeetingDashboardInfo,
            AttendeesStatusList,
            MeetingDashboardAgenda,
            FileViewer,
            ConfirmDialog,
            SendMeetingMinutesForApproval,
            SendFinalMeetingMinutesForApproval,
            MeetingDashboardChat,
            MeetingDashboardAttachments,
            MeetingDashboardApprovals,
            MeetingAgendaVotingResultsReport,
            MeetingDashboardAssociated,
            GenerateInitialMeetingMinutes
        },
        props: {
            meeting: {
                type: Object,
                default: () => null
            },
            userPermissions: {
                type: Object,
                default: () => null
            },
            viewMode: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                startIndex: 0,
                readOnly: true,
                selectedVersion: null,
                showAttachments: false,
                loading: false,
                endLoading: false,
                meetingName: '',
                meetingDetails: null,
                attachments: [],
                agendaItems: [],
                attendees: [],
                meetingOwner: false,
                initialDocumentQuery: null,
                finalDocumentQuery: null,
                showSendMeetingMinutes: false,
                showSendFinalMeetingMinutes: false,
                InitialMeetingMinutesVersions: [],
                showAttendees: true,
                showAttendeesStatusList: false,
                showInfoMenu: false,
                showApprovalsMenu: false,
                showAssociatedMenu: false,
                meetingFileDocumentQuery: null,
                selectedFile: null,
                currentAttendanceList: [],
                showMeetingAgendaVotingReportDialogue: false,
                showGenerateInitialMeetingMinutesDialogue: false,

                attachmentsHeight: 37,
                loadingText: ''
            }
        },
        computed: {

            ...mapGetters('user', ['loggedInUser', 'language']),
            isArabicLanguage() {
                return this.language == 'ar'
            },
            canStartMeeting() {
                return !this.viewMode && this.meetingOwner && this.agendaItems.length > 0 && !this.agendaItems[0].actualStartDate
            },
            showGenerateInitialMeetingMinutes() {
                return this.meetingOwner && this.meetingDetails.statusId == MeetingStatusEnum.Finished

            },
            showInitialMeetingMinutes() {
                return !this.selectedFile && (this.meetingOwner || (this.userPermissions&&this.userPermissions.meetingsMinutes)) && this.meetingDetails && this.initialDocumentQuery && (this.meetingDetails.statusId == MeetingStatusEnum.PendingInitialMeetingMinutesApproval ||
                    this.meetingDetails.statusId == MeetingStatusEnum.InitialMeetingMinutesApproved)
            },
            showInitialMeetingMinutesActions() {
                return !this.viewMode && this.meetingOwner && this.meetingDetails && this.meetingDetails.statusId == MeetingStatusEnum.PendingInitialMeetingMinutesApproval
            },

            showFinalMeetingMinutes() {
                return !this.selectedFile && (this.meetingOwner || (this.userPermissions&&this.userPermissions.meetingsMinutes)) && this.finalDocumentQuery && this.meetingDetails && (this.meetingDetails.statusId == MeetingStatusEnum.PendingFinalMeetingMinutesSign ||
                    this.meetingDetails.statusId == MeetingStatusEnum.FinalMeetingMinutesSigned)
            },
            showFinalMeetingMinutesActions() {
                return !this.viewMode && this.meetingOwner && this.meetingDetails && this.meetingDetails.statusId == MeetingStatusEnum.PendingFinalMeetingMinutesSign
            },
            canEditAttendees() {
                return !this.viewMode && this.meetingDetails && this.meetingDetails.statusId >= MeetingStatusEnum.Started
                    && this.meetingDetails.statusId <= MeetingStatusEnum.InitialMeetingMinutesApproved
            },
            canEndMeeting() {
                return this.meetingOwner && this.meetingDetails.statusId == MeetingStatusEnum.Started
            },
            meetingEnd() {
                return this.meetingDetails.statusId >= MeetingStatusEnum.Finished

            },
            showAgendaVotingMenu() {
                return this.meetingEnd && (this.meetingOwner || (this.userPermissions&&this.userPermissions.meetingsVotingResults))
            },
            getCurrentMeetingMinutesType() {
                if (this.meetingDetails.statusId >= MeetingStatusEnum.Finished &&
                    this.meetingDetails.statusId < MeetingStatusEnum.InitialMeetingMinutesApproved) {
                    return MeetingMinutesTypesEnum.Initial
                }

                if (this.meetingDetails.statusId >= MeetingStatusEnum.InitialMeetingMinutesApproved &&
                    this.meetingDetails.statusId < MeetingStatusEnum.FinalMeetingMinutesSigned) {
                    return MeetingMinutesTypesEnum.Final
                }
                return null
            }
        },
        created() {
            if (this.meeting) {
                this.meetingName = this.meeting.name ?? this.meeting.title
                this.loadMeetingDetails()
            } else {
                this.$emit('close')
            }
        },
        mounted() {
            if (!this.viewMode) {
                createSignalRConnection()
                startSignalRConnection().then(() => {

                    connection.invoke('ChangeMeetingAttendanceStatus', this.meeting.id, true)
                    subscribeToEvent('NotifyMeetingAttendanceChange', this.HandleMeetingAttendanceChange)
                    subscribeToEvent(`MeetingStatusChange${this.meeting.id}`, this.HandleMeetingStatusChange)
                }).catch((err) => {
                })


            }


        },
        beforeDestroy() {
            connection.invoke('ChangeMeetingAttendanceStatus', this.meeting.id, false)
            unsubscribeFromEvent('NotifyMeetingAttendanceChange')
            unsubscribeFromEvent(`MeetingStatusChange${this.meeting.id}`)
        },
        methods: {
            HandleMeetingAttendanceChange(meetingId, attendanceIds) {
                if (meetingId == this.meeting.id) {
                    this.currentAttendanceList = attendanceIds
                }
            },
            HandleMeetingStatusChange(newStatus) {
                if (newStatus) this.meetingDetails.statusId = newStatus
            },
            startMeeting() {
                this.$refs.confirmDialog.open(this.$t('StartMeeting'), this.$t('ConfirmStartMeeting'))
                    .then((confirmed) => {
                        if (confirmed) {
                            this.startNextMeetingAgenda()
                        } else {

                        }
                    })
            },
            loadMeetingDetails() {
                this.loading = true
                this.loadingText = this.$t('LoadingMeetingDetails')
                MeetingsService.loadLiveMeeting(this.meeting.id).then((res) => {
                    if (res.data) {
                        this.meetingDetails = res.data
                        this.attachments = res.data.attachments ?? []
                        this.attendees = res.data.meetingAttendees ?? []
                        this.agendaItems = res.data.meetingAgendas ?? []
                        this.meetingOwner = (this.meetingDetails.createdby == this.loggedInUser?.id)
                        this.handleMeetingMinutesLoading()


                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    this.close()
                }).finally(() => {
                    this.loading = false
                })
            },
            listMeetingAttachments() {
                this.loading = true
                MeetingsService.listMeetingAttachments(this.meeting.id).then((res) => {
                    if (res.data) {
                        this.attachments = res.data.attachments ?? []
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    this.close()
                }).finally(() => {
                    this.loading = false
                })
            },
            startNextMeetingAgenda() {
                this.loading = true
                MeetingsService.nextAgenda(this.meeting.id).then((res) => {
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })

            },
            close() {
                this.$emit('close')
            },
            openSendMeetingMinutes() {
                this.showSendMeetingMinutes = true
            },
            openSendFinalMeetingMinutes() {
                this.showSendFinalMeetingMinutes = true
            },
            confirmGenerateFinalMeetingMinutes() {
                this.$refs.confirmDialog.open(this.$t('GenerateFinalMeetingMinutes'), this.$t('ConfirmGenerateFinalMeetingMinutes'))
                    .then((confirmed) => {
                        if (confirmed) {
                            this.generateFinalMeetingMinutes()
                        } else {

                        }
                    })
            },
            generateFinalMeetingMinutes() {
                if (this.finalDocumentQuery) return
                this.loading = true
                this.loadingText = this.$t('LoadingFinalMeetingMinutes')
                MeetingsService.getFinalMeetingMinutes(this.meeting.id).then((res) => {
                    if (res.data) {

                        let meetingMinutesAttachment = res.data
                        AttachmentsService.getAttachment(meetingMinutesAttachment.id).then(response => {
                            this.finalDocumentQuery = response.data
                        }).catch(() => {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        })
                    } else {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            getMeetingMinutes() {
                this.loading = true
                this.loadingText = this.$t('LoadingInitialMeetingMinutes')
                MeetingsService.getMeetingMinutes(this.meeting.id).then((res) => {
                    if (res.data) {

                        this.selectedVersion = res.data
                        AttachmentsService.getAttachment(this.selectedVersion.id).then(response => {
                            this.initialDocumentQuery = response.data
                        }).catch(() => {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        })
                    } else {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            approveInitialMeetingMinutes() {
                MeetingsService.approveInitialMeetingMinutes(this.meeting.id).then((res) => {
                    if (res.data) {
                        this.$root.$notification(this.$t('ApprovedSuccessfully'), 'success')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            approveFinalMeetingMinutes() {
                MeetingsService.approveFinalMeetingMinutes(this.meeting.id).then((res) => {
                    if (res.data) {
                        this.$root.$notification(this.$t('ApprovedSuccessfully'), 'success')
                    }
                    else {
                        this.$root.$notification(this.$t('UsersNotSigned'), 'error')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            getInitialMeetingMinutesVersions() {
                MeetingsService.getInitialMeetingMinutesVersions(this.meeting.id).then((res) => {
                    if (res.data) {
                        this.InitialMeetingMinutesVersions = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            onVersionChange(selectedVersion) {
                this.loading = true
                this.loadingText = this.$t('LoadingInitialMeetingMinutes')
                AttachmentsService.getAttachment(selectedVersion.id).then((res) => {
                    if (res.data) {
                        this.initialDocumentQuery = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            loadAttachment(attachmentId) {
                if (this.selectedFile == attachmentId) return

                this.loading = true
                this.loadingText = this.$t('LoadingFile')

                AttachmentsService.getAttachment(attachmentId).then(response => {
                    this.meetingFileDocumentQuery = response.data
                    this.selectedFile = attachmentId
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            closeOpenedFile() {
                this.meetingFileDocumentQuery = null
                this.selectedFile = null
                if (this.showFinalMeetingMinutes) {
                    this.finalDocumentQuery = null
                    this.generateFinalMeetingMinutes()
                } else if (this.showInitialMeetingMinutes) {
                    this.getMeetingMinutes()
                }
            },
            toggleAttendeesVisibility() {
                if (this.showAttendees) {
                    this.showAttendees = false

                    this.attachmentsHeight = 47
                } else {
                    this.showAttendees = true

                    this.attachmentsHeight = 37

                }

            },
            updateAgendaList(newAgendaList) {
                this.agendaItems = newAgendaList
            },
            finishMeeting() {
                this.$refs.confirmDialog.open(this.$t('EndMeeting'), this.$t('ConfirmEndMeeting'))
                    .then((confirmed) => {
                        if (confirmed) {
                            this.endLoading = true
                            MeetingsService.endMeeting(this.meeting.id).then((res) => {
                                if (res.data) {

                                }
                            }).catch(() => {
                                this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                            }).finally(() => {
                                this.endLoading = false
                            })
                        }
                    })

            },
            openGenerateMeetingMinutesDialogue() {
                this.showGenerateInitialMeetingMinutesDialogue = true
            },
            handleMeetingMinutesLoading(newStatus) {
                if (!this.meetingOwner&& !this.userPermissions?.meetingsMinutes) return
                if (this.meetingDetails.statusId == MeetingStatusEnum.PendingInitialMeetingMinutesApproval ||
                    this.meetingDetails.statusId == MeetingStatusEnum.InitialMeetingMinutesApproved) {
                    this.getMeetingMinutes()
                    this.getInitialMeetingMinutesVersions()
                } else if (this.meetingDetails.statusId > MeetingStatusEnum.InitialMeetingMinutesApproved) {
                    this.generateFinalMeetingMinutes()
                }
            }

        },
    }
</script>

<style>
    .file-placeholder {
        width: 100%;
        height: 300px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #000;
    }

    .loader-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        text-align: center;
    }

    .loading-text {
        margin-top: 20px;
        font-size: 18px;
        color: var(--v-primary-base) !important;
    }

    .primary-border {
        border-color: var(--v-primary-base) !important;
    }

    .btn-enabled {
        color: white !important;
        border-radius: 50%;
        background-color: var(--v-primary-base) !important;
    }

    .btn-disabled {
        border-radius: 50%;
        background-color: lightgrey !important;
    }

    .meeting-card {
        background-color: #F5F5F5;
        height: calc(95vh);
        padding-bottom: 222px;
    }

    .main-card-action {
        margin: 0px;
        background-color: #E6E6E6;
        position: fixed;
        bottom: 0;
        width: 100%
    }

    .mdi-align-vertical-center {
        display: flex;
        align-items: center;
    }

    .active {
        background-color: var(--v-primary-base) !important;
    }

    .circle-btn {
        border-radius: 50%;
        margin-inline: 5px;
        padding: 0;
    }

    .tooltip-card {
        position: relative;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;
    }

    .tooltip-content {
        overflow: hidden;
    }

    .scrollable-list {
        max-height: 100%;
        overflow-y: auto;
    }

    .messages-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: auto;
    }

    .message {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

        .message.me {
            align-items: flex-end;
        }
</style>
