<template>
    <div>
        <VueFileAgent ref="vueFileAgent"
                      v-model="fileRecords"
                      :theme="'list'"
                      :linkable="true"
                      :multiple="true"
                      :deletable="true"
                      :meta="true"
                      :accept="accept"
                      :max-size="maxSize"
                      :max-files="max"
                      :help-text="helpText"
                      :error-text="{type: invalidType, size: 'Files size exceeded'}"
                      @select="filesSelected($event)"
                      @beforedelete="onBeforeDelete($event)"
                      @delete="fileDeleted($event)" />
        <v-btn small :disabled="!fileRecordsForUpload.length" color="teal" class="white--text mt-2" @click="uploadFiles()">
            {{ $t('Upload') }} {{ fileRecordsForUpload.length }} {{ $t('Files') }}
        </v-btn>
    </div>
</template>

<script>
    import Vue from 'vue'
    import 'vue-file-agent/dist/vue-file-agent.css'
    import VueFileAgent from 'vue-file-agent'


    Vue.use(VueFileAgent)

    export default {

        props: {
            min: {
                type: Number,
                default: 0
            },

            max: {
                type: Number,
                default: null
            },
            accept: {
                type: String,
                default: null
            },
            fileType: {
                type: String,
                default: null
            },

            helpText: {
                type: String,
                default: null
            }, maxSize: {
                type: String,
                default: null
            },

            attachments: {
                type: Array,
                default: () => []
            },
            multiple: {
                type: Boolean,
                default: false
            },
        },
        data: function () {
            return {
                fileRecords: this.attachments,
                uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
                fileRecordsForUpload: [],
                invalidType: this.$t('InvalidFileType')
            }
        },


        methods: {
            uploadFiles() {
                let formdata = new FormData()
                let processId = this.$route.query.id
                let tempId = this.$route.query.temp
                for (let i = 0; i < this.fileRecordsForUpload.length; i++) {
                    formdata.append('files', this.fileRecordsForUpload[i].file)
                }
                formdata.append('processId', processId)
                formdata.append('tempId', tempId)
                formdata.append('fileType', this.fileType)

                StorageService.upload(formdata).then((res) => {
                    if (res.data.data) {
                        this.fileRecordsForUpload = []
                        this.fileRecords = []
                        this.fileRecords = res.data
                        this.$root.$displayMessage(this.$t('resources_fileUploaded'), 'success')
                    }
                    else {
                        this.$root.$displayMessage(res.data.message, 'error')
                    }
                })
                    .catch(() => {
                        this.loading = false
                        this.$root.$displayMessage(this.$t('error-occurred'), 'error')
                    })
            },

            deleteUploadedFile: function (fileRecord) {
                // Using the default uploader. You may use another uploader instead.
                this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord)
            },

            filesSelected: function (fileRecordsNewlySelected) {
                var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error)
                this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords)
            },



            onBeforeDelete: async function (fileRecord) {
                this.$refs.vueFileAgent.deleteFileRecord(fileRecord)
                //if (await this.$root.$confirm(

                //    this.$t('resources_delete_title'),
                //    this.$t('resources_delete_text'),)) {
                //    StorageService.remove(fileRecord.id).then((res) => {
                //        debugger
                //        if (res.data.success) {
                //            this.$refs.vueFileAgent.deleteFileRecord(fileRecord)
                //            this.$root.$displayMessage(this.$t('resources_fileDeleted'), 'success')
                //        }
                //    }).catch(() => {
                //        this.loading = false
                //        this.$root.$displayMessage(this.$t('error-occurred'), 'error')
                //    })
                //}
            },

            fileDeleted: function (fileRecord) {
                var i = this.fileRecordsForUpload.indexOf(fileRecord)
                if (i !== -1) {
                    this.fileRecordsForUpload.splice(i, 1)
                } else {
                    this.deleteUploadedFile(fileRecord)
                }
            },
        },
    }
</script>
<style>
    .vue-file-agent .file-preview-new:before {
        background: lightgrey !important
    }

    .vue-file-agent .file-preview-new svg {
        fill: gray;
    }

    .theme-list .vue-file-agent .file-preview-new .help-text {
        color: teal;
        font-size: 12px
    }

    .theme-list .vue-file-agent .file-preview-wrapper .file-preview:before {
        background: #fff
    }

    .vue-file-agent .file-progress.file-progress-full .file-progress-bar {
        background: #42b983
    }

    .theme-list .vue-file-agent .file-preview-wrapper .file-preview .file-ext, .theme-list .vue-file-agent .file-preview-wrapper .file-preview .file-name, .theme-list .vue-file-agent .file-preview-wrapper .file-preview .file-progress {
        color: #6a8bad;
        font-size: 11px
    }

    .theme-list .vue-file-agent .file-preview-wrapper .file-preview .file-size {
        color: #2c3e50;
        font-size: 12px;
        font-weight: 600;
    }

    .vue-file-agent .file-error-wrapper .file-error-message {
        font-size: 12px;
        font-weight: normal;
        background: #b82323 !important
    }

    .vue-file-agent .has-error .file-preview .file-delete svg {
        fill: #fff !important;
        top: 8px;
        position: absolute;
        left: 0px;
    }

    .file-name-text {
        color: rgba(0,0,0,.6)
    }
</style>