import Tasks from '@/pages/Tasks.vue'

const baseUrl = process.env.VUE_APP_BASE_URL 

export default [
	{
		name: 'tasks',
		path: `${baseUrl}/tasks`,
		component: Tasks,
		meta: {
			secure: true
		}
	},
]