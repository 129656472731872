<template>
    <v-card tile class="rounded-lg" :loading="loading">
        <v-toolbar>
            <h3> {{ $t('DraftMeetings') }}</h3>
        </v-toolbar>
        <v-card-text>
            <GridWithServerPaging :search="search" :headers="headers" dense :items="drafts" :total="totalCount" @onChange="listDrafts" :items-per-page="options.itemsPerPage">
                <template v-slot:top>
                    <v-text-field v-model="search" :label="$t('Search')" />
                </template>
                <template v-slot:[`item.date`]="{ item }">
                    {{ item.date | formatDateGregorian }}
                </template>
                <template v-slot:[`item.startTime`]="{ item }">
                    <v-chip class="ma-2" small color="primary" text-color="white">
                        {{ item.startTime }}
                        <v-icon right small>
                            mdi-timer-check-outline
                        </v-icon>
                    </v-chip>
                </template>
                <template v-slot:[`item.MeetingDate`]="{ item }">
                    {{ item.MeetingDate | formatDateGregorian }}
                </template>
                <template v-slot:[`item.endTime`]="{ item }">
                    <v-chip class="ma-2" color="error" small text-color="white" width="250">
                        {{ item.endTime }}
                        <v-icon right small>
                            mdi-timer-pause-outline
                        </v-icon>
                    </v-chip>
                </template>
                <template v-slot:[`item.options`]="{ item }">
                    <router-link :to="{ name: 'addMeeting', params: { id: item.id } }">
                        <v-icon color="primary" class="ms-3"> mdi-pen</v-icon>
                    </router-link>
                    <v-icon color="error" class="ms-3" @click="deleteMeeting(item.id)">mdi-trash-can-outline</v-icon>
                </template>
            </GridWithServerPaging>
        </v-card-text>
    </v-card>
</template>

<script>
    import MeetingsService from '@/services/MeetingsService.js'
    import GridWithServerPaging from '@/components/controls/GridWithServerPaging.vue'

    export default {
        components: {
           GridWithServerPaging
        },
        data() {
            return {
                loading: false,
                search: '',
                drafts: [],
                headers: [
                    { text: this.$t('Id'), value: 'id', width: '3%' ,sortable: false},
                    { text: this.$t('ReferenceNumber'), value: 'referenceNumber', width:'10%',sortable: false },
                    { text: this.$t('MeetingDate'), value: 'date', width: '7%',sortable: false },
                    { text: this.$t('Subject'), value: 'title', width: '22%',sortable: false },
                    { text: this.$t('StartTime'), value: 'startTime', width: '6%',sortable: false },
                    { text: this.$t('EndTime'), value: 'endTime', width: '6%',sortable: false },
                    { text: this.$t('MeetingRoom'), value: 'location', width: '15%',sortable: false },
                    { text: this.$t('Comment'), value: 'notes', width: '21%',sortable: false },
                    { text: this.$t('Options'), value: 'options', width: '10%' ,sortable: false},
                ],
                options: {
                    page: 1, itemsPerPage: 10
                },
                totalCount: null
            }
        },

        created() {
            this.listDrafts()
        },

        methods: {
            listDrafts(options) {
                this.loading = true
                const page = options != undefined ? options.page : this.options.page
                const pageSize = options != undefined ? options.itemsPerPage : this.options.itemsPerPage
                MeetingsService.listDraftMeetings(page, pageSize).then((res) => {
                    if (res.data) {
                        this.drafts = res.data.data
                        this.totalCount = res.data.total
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            async deleteMeeting(meetingId) {
                if (await this.$root.$confirm(this.$t('Delete'), this.$t('ConfirmDelete'))) {

                    this.loading = true
                    MeetingsService.delete(meetingId).then(() => {
                        this.listDrafts()
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
            }
        },
    }
</script>

<style>
</style>