
const user = {

    namespaced: true,

    state: () => ({
        user: null,
        defaultLanguage: process.env.VUE_APP_DEFAULT_LANGUAGE,
        token: null,
        TwoFA: null
    }),

    mutations: {
        setToken: (state, token) => {
            state.token = token
        },
        setRefreshToken: (state, refreshToken) => {
            state.refreshToken = refreshToken
        },
        setUser: (state, user) => {
            state.user = user
        },

        set2FA: (state, user) => {
            state.TwoFA = user
        },
        setProfilePictureUrl: (state, profilPictureUrl) => {
            let timeStamp = Date.now()
            state.user.profilePictureUrl = `${profilPictureUrl}?t=${timeStamp}`
        },
        setHasProfilePicture: (state, hasProfile) => {
            state.user.hasProfilePicture = hasProfile
        },
        setDefaultLanguage: (state, language) => {
            state.defaultLanguage = language
        }
    },

    actions: {

        setUserSession: (context, { user, token,refreshToken }) => {

            return new Promise((success) => {
                if (user && token && refreshToken) {
                    context.commit('setToken', token)
                    context.commit('setRefreshToken', refreshToken)
                    context.commit('setUser', user)
                    context.commit('setDefaultLanguage', user.language)
                    success()
                }
            })
        },
        setUser2FA: (context, user) => {
            return new Promise((success) => {
                if (user) {
                    context.commit('set2FA', user)
                    success()
                }
            })
        },

        removeUser2FA: (context, user) => {
            return new Promise((success) => {
                if (user) {
                    context.commit('set2FA', null)
                    success()
                }
            })
        },

        setLanguage: (context, language) => {

            return new Promise((success) => {

                const lang = language == 'ar' ? 'ar' : 'en'
                context.commit('setDefaultLanguage', lang)

                success()
            })
        },
        setHasProfilePicture: (context, hasProfilePicture) => {

            return new Promise((success) => {

                context.commit('setHasProfilePicture', hasProfilePicture)
                success()
            })
        },
        setProfilePictureUrl: (context, profilePictureUrl) => {

            return new Promise((success) => {

                context.commit('setProfilePictureUrl', profilePictureUrl)
                success()
            })
        },
        updateToken: (context, token) => {

            return new Promise((success) => {
                context.commit('setToken', token)
                success()
            })
        },
        removeUserSession: (context) => {

            return new Promise((success) => {
                context.commit('setToken', null)
                context.commit('setRefreshToken', null)
                context.commit('setUser', null)
                success()
            })

        }
    },

    getters: {
        isAuthenticated(state) {
            return state.token && state.user
        },

        loggedInUser: state => {
            return state.user
        },

        token: state => {
            return state.token || null
        },
        refreshToken: state => {
            return state.refreshToken || null
        },
        TwoFA: state => {
            return state.TwoFA || null
        },
        language: state => {
            return state.defaultLanguage
        },

    }
}

export default user