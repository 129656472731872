<template>
	<v-overlay :value="overlay" color="grey lighten-1">
		<v-card elevation="3" width="400" class="text-center" light>
			<v-card-text>
				<div v-if="!isTranslationFailed" class="loader">
					<div />
					<div />
					<div />
				</div>
				<div v-else class="pa-4">
					<h2>Error loading the application</h2>

					<h3>Please try again or contact the system administrator</h3>
				</div>
			</v-card-text>
		</v-card>
	</v-overlay>
</template>
<script>
	import { mapGetters } from 'vuex'

	export default {
		data() {
			return {
				overlay: true
			}
		},

		computed: {
			...mapGetters('app', ['isTranslationFailed']),

		},
	}
</script>
<style scoped>
	/* reference: https://loading.io/css/*/
	.loader {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}

		.loader div {
			display: inline-block;
			position: absolute;
			left: 8px;
			width: 16px;
			background: var(--v-primary-base);
			animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
		}

			.loader div:nth-child(1) {
				left: 8px;
				animation-delay: -0.24s;
			}

			.loader div:nth-child(2) {
				left: 32px;
				animation-delay: -0.12s;
			}

			.loader div:nth-child(3) {
				left: 56px;
				animation-delay: 0;
			}

	@keyframes loader {
		0% {
			top: 8px;
			height: 64px;
		}

		50%, 100% {
			top: 24px;
			height: 32px;
		}
	}
</style>