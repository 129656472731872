<template>
    <v-card class="elevation-0">
        <v-card-text min-height="300">
            <v-row>
                <v-col cols="11">
                    <v-select v-model="permissionDegreeId" :items="permissionDegrees" :label="$t('PermissionLevel')" dense />
                </v-col>
                <v-col v-if="isEveryone" cols="1">
                    <v-btn fab x-small color="teal" class="white--text mt-2" @click="addEveryone">
                        <v-icon dark>
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-col v-if="isUserLevel" cols="11">
                    <UserAutocomplete v-model="selectedUser" :label="$t('SelectUserSearch')" class="mt-3" />
                </v-col>
                <v-col v-if="isRoleLevel" cols="11">
                    <Roleselect v-model="selectedRole" :label="$t('SelectRoleSearch')" class="mt-3" />
                </v-col>

                <v-col v-if="isDepartmentLevel" cols="11">
                    <StructureSelect v-model="selectedDepartment" />
                </v-col>
                <v-col v-if="isRoleLevel || isUserLevel || isDepartmentLevel" cols="1" class="text-center">
                    <v-btn fab x-small color="teal" class="white--text mt-2" @click="addPermission">
                        <v-icon dark>
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-card class="mt-4" tile :loading="loading" color="grey lighten-4">
                <div class="card-title">{{ $t('Permissions') }}</div>
                <v-data-table :headers="headers" dense :items="permissions" hide-footer :items-per-page="5" class="elevation-1">
                    <template v-slot:[`item.options`]="{ item }">
                        <v-icon color="error" @click="remove(item.id)">mdi-delete</v-icon>
                    </template>
                    <template v-slot:[`item.levelName`]="{ item }">
                        {{ $t(item.levelName) }}
                    </template>
                </v-data-table>
            </v-card>
        </v-card-text>
        <v-divider class="mt-8" />
        <v-card-actions class="d-block text-end">
            <v-btn color="secondary" class="white--text" tile small @click="savePermissions">
                {{ $t("Save") }}
            </v-btn>
            <v-btn color="error" class="white--text" tile small @click="$emit('close', false)">
                {{ $t("Cancel") }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import UserAutocomplete from '@/components/controls/UserSelect'
    import Roleselect from '@/components/controls/RoleSelect'
    import StructureSelect from '@/components/controls/StructureSelect.vue'

    import { PermissionLevelsEnum } from '@/helpers/constants/enumerations.js'
    import LookupsService from '@/services/LookupsService.js'
    import PermissionsService from '@/services/PermissionsService.js'

    export default {
        components: {
            UserAutocomplete, Roleselect, StructureSelect
        },

        props: {
            itemId: {
                type: Number,
                default:null,
            },

            typeId: {
                type: Number,
                default:null,
            },
        },

        data() {
            return {
                loading: false,
                permissionDegrees: [{ text: this.$t('User'), value: 1 }, { text: this.$t('Role'), value: 2 }, { text: this.$t('Department'), value: 3 }, { text: this.$t('Everyone'), value: 4 }],
                permissionDegreeId: null,
                selectedDepartment: null,
                selectedUser: null,
                selectedRole: null,
                permissions: [],
                headers: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('Level'), value: 'levelName' },
                    { text: this.$t('Name'), value: 'name' },
                    { text: this.$t('Options'), value: 'options' },
                ],
            }
        },

        computed: {
            isUserLevel() {
                return this.permissionDegreeId == PermissionLevelsEnum.User
            },

            isRoleLevel() {
                return this.permissionDegreeId == PermissionLevelsEnum.Role
            },

            isDepartmentLevel() {
                return this.permissionDegreeId == PermissionLevelsEnum.Department
            },

            isEveryone() {
                return this.permissionDegreeId == PermissionLevelsEnum.Everyone
            }
        },

        created() {
            this.loadPermissions()
        },

        methods: {
            async addPermission() {

                switch (this.permissionDegreeId) {
                    case PermissionLevelsEnum.User:
                        if (this.selectedUser) {
                            let user = await this.getUser(this.selectedUser)
                            this.permissions.push({ levelId: PermissionLevelsEnum.User, id: this.selectedUser, name: user, levelName: 'User' })
                            this.selectedUser = null
                            this.selectedRole = null
                            this.permissionDegreeId = null
                        }
                        break
                    case PermissionLevelsEnum.Role:
                        if (this.selectedRole) {
                            let role = await this.getRole(this.selectedRole)
                            this.permissions.push({ levelId: PermissionLevelsEnum.Role, id: this.selectedRole, name: role, levelName: 'Role' })
                            this.selectedUser = null
                            this.selectedRole = null
                            this.permissionDegreeId = null
                        }
                        break
                    case PermissionLevelsEnum.Department:
                        if (this.selectedDepartment) {
                            let department = await this.getDepartment(this.selectedDepartment)
                            this.permissions.push({ levelId: PermissionLevelsEnum.Department, id: this.selectedDepartment, name: department, levelName: 'Department' })
                            this.selectedUser = null
                            this.selectedRole = null
                            this.permissionDegreeId = null
                        }
                        break
                }
            },

            addEveryone() {
                this.permissions.push({ levelId: PermissionLevelsEnum.Everyone, id: 1, name: this.$t('Everyone'), levelName: 'Everyone' })
            },

            getUser(userId) {
                this.loading = true
                return LookupsService.getUserById(userId).then((res) => {
                    if (res.data) {
                        return res.data.name
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => { this.loading = false })
            },

            getRole(roleId) {
                this.loading = true
                return LookupsService.getRoleById(roleId).then((res) => {
                    if (res.data) {
                        return res.data.name
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => { this.loading = false })
            },

            getDepartment(departmentId) {
                this.loading = true
                return LookupsService.getDepartmentById(departmentId).then((res) => {
                    if (res.data) {
                        return res.data.name
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => { this.loading = false })
            },

            remove(itemId) {
                this.permissions = this.permissions.filter(x => x.id != itemId)
            },

            savePermissions() {
                var postData = this.permissions.map(x => ({ itemId: this.itemId, typeId: this.typeId, levelId: x.levelId, value: x.id }))
                if (postData.length == 0) {
                    postData.push({ itemId: this.itemId, typeId: this.typeId, AllDeleted: true })
                }
                this.loading = true
                return PermissionsService.post(postData).then((res) => {
                    if (res.success) {
                        this.$root.$notification(this.$t('SaveSuccess'), 'success')
                        this.$emit('close', false)
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => { this.loading = false })
            },

            loadPermissions() {
                PermissionsService.loadPermissions(this.itemId, this.typeId).then((res) => {
                    if (res.data) {
                        this.permissions = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => { this.loading = false })
            }
        }
    }
</script>

<style>
</style>