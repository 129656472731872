<template>
    <div>
        <v-app-bar dense tile app dark color="primary">
            <v-app-bar-nav-icon @click.stop="$emit('sidebarToggled', false)" />

            <router-link to="/" class="white--text text-decoration-none"><v-icon>mdi-home</v-icon> {{ $t('Home') }}</router-link>
            <v-spacer />
            <v-menu tile rounded="0" offset-y>
                <template #activator="{ attrs, on }">
                    <v-btn elevation="0" tile color="primary" dark :loading="changingLanguage" v-bind="attrs" v-on="on">
                        <v-icon>
                            mdi-earth
                        </v-icon>
                        {{ isArabicLanguage ? 'العربية' : 'English' }}
                    </v-btn>
                </template>

                <v-list tile dense color="grey lighten-4" class="pb-0">
                    <template v-if="isArabicLanguage">
                        <v-list-item @click="changeLanguage('en')">
                            <v-list-item-title>
                                <h4>
                                    English
                                </h4>
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider />
                    </template>
                    <template v-else>
                        <v-list-item @click="changeLanguage('ar')">
                            <v-list-item-title>
                                <h4>
                                    العربية
                                </h4>
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider />
                    </template>
                </v-list>
            </v-menu>
            <v-menu tile left bottom rounded offset-y transition="scale-transition">
                <template v-slot:activator="{ on }">
                    <v-btn small color="primary" elevation="0" v-on="on">
                        <v-badge :content="messages"
                                 :value="messages"
                                 color="secondary"
                                 overlap>
                            <v-icon>
                                mdi-bell
                            </v-icon>
                        </v-badge>
                    </v-btn>
                </template>
                <v-card v-if="messages > 0" tile width="300">
                    <v-card-title class="primary--text"><h5>{{ $t('Notifications') }}</h5></v-card-title>
                    <v-divider />
                    <v-card-text>
                        <v-list-item v-for="item in notifications" :key="item.id" :to="{ name: 'tasks'}">
                            <v-list-item-content v-if="item.typeName == 'Task'">
                                <v-list-item-title>{{ $t('Youhave') }} {{ $t(item.count) }} {{ $t(item.typeName) }} {{ $t('YourInbox') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card-text>
                </v-card>
            </v-menu>


            <v-menu tile rounded="0" offset-y>
                <template #activator="{ attrs, on }">
                    <v-btn elevation="0" tile color="primary" v-bind="attrs" v-on="on">
                        <v-avatar size="35" class="me-1">
                            <v-img v-if="pictureUrl" :src="pictureUrl" loading="lazy"/>
                            <v-icon v-else large>mdi-account-circle</v-icon>
                        </v-avatar>
                        <span class="me-3">{{ $t('Hello') }}</span>
                        <h3>{{ isArabicLanguage ? loggedInUser?.fullnameAr : loggedInUser?.fullnameEn }}</h3>
                    </v-btn>
                </template>

                <v-list tile color="grey lighten-4" class="pb-0">
                    <v-list-item class="pb-4">
                        <v-list-item-avatar>
                            <v-avatar color="primary" size="56">
                                <v-img v-if="pictureUrl" :src="pictureUrl" />
                                <v-icon v-else dark>mdi-account-circle</v-icon>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                                <h3>{{ isArabicLanguage ? loggedInUser?.fullnameAr : loggedInUser?.fullnameEn }}</h3>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ loggedInUser?.email }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link @click="showProfilePictureDialogue = true">
                        <v-list-item-title>
                            <h4>
                                <v-icon dense color="dark">
                                    mdi-account-circle
                                </v-icon>
                                {{ $t("UpdateProfilePicture") }}
                            </h4>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider />
                    <v-divider />

                    <v-list-item link @click="showPasswordDialog = true">
                        <v-list-item-title>
                            <h4>
                                <v-icon dense color="dark">
                                    mdi-lock
                                </v-icon>
                                {{ $t("ChangePassword") }}
                            </h4>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider />
                    <v-list-item link @click="showSignatureDialog = true">
                        <v-list-item-title>
                            <h4>
                                <v-icon dense color="dark">mdi-account-edit</v-icon>
                                {{ $t("EditSignature") }}
                            </h4>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider />
                    <v-list-item link @click="downloadGuide">
                        <v-list-item-title>
                            <h4>
                                <v-icon dense color="dark">
                                    mdi-book-open-page-variant
                                </v-icon>
                                {{ $t("UserGuide") }}
                            </h4>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider />
                    <v-list-item link @click="logout()">
                        <v-list-item-title>
                            <h4>
                                <v-icon dense color="dark">
                                    mdi-power
                                </v-icon>
                                {{ $t("SignOut") }}
                            </h4>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-icon size="28" class="mx-3" @click="logout()">mdi-power</v-icon>
        </v-app-bar>

        <user-signature v-if="showSignatureDialog" v-model="showSignatureDialog" />
        <user-password v-if="showPasswordDialog" v-model="showPasswordDialog" />
        <user-profile-picture v-if="showProfilePictureDialogue" v-model="showProfilePictureDialogue" @close="showProfilePictureDialogue=false" />
        <NotificationNewMeetingAgendaBegins v-if="isNewAgendaStart" :title="agenda.title" :meetingTitle="agenda.meetingTitle" v-model="isNewAgendaStart" />
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    import { clearTranslation } from '@/plugins/i18n/index.js'
    import { createSignalRConnection, startSignalRConnection, subscribeToEvent, unsubscribeFromEvent } from '@/plugins/hub/myHubPlugin.js'
    import UsersService from '@/services/UsersService.js'
    import AttachmentsService from '@/services/AttachmentsService'
    import UserSignature from '@/components/app/user/UserSignature.vue'
    import UserPassword from '@/components/app/user/UserPassword.vue'
    import UserProfilePicture from '@/components/app/user/UserProfilePicture.vue'
    import { openFile } from '@/helpers/downloader.js'
    import NotificationNewMeetingAgendaBegins from '@/components/app/meeting/NotificationNewMeetingAgendaBegins.vue'
    import { getUserImage } from '@/helpers/common.js'

    export default {

        components: {
            UserSignature, UserPassword, UserProfilePicture, NotificationNewMeetingAgendaBegins
        },

        data() {
            return {
                messages: 0,
                changingLanguage: false,
                showSignatureDialog: false,
                showPasswordDialog: false,
                showProfilePictureDialogue: false,
                notifications: [],
                isNewAgendaStart: false,
                agenda: {
                    title: null,
                    meetingUrl: null,
                    meetingTitle: null
                },
            }
        },
        computed: {
            ...mapGetters('user', ['loggedInUser', 'language']),

            isArabicLanguage() {
                return this.language == 'ar'
            },
            pictureUrl() {

                return this.loggedInUser.profilePictureUrl
            }
        },
        created() {
            if (this.loggedInUser) {
                this.loadNotifications()
            }
        },



        mounted() {
            this.currentLanguage = this.language
            createSignalRConnection()
            startSignalRConnection().then(() => {

                subscribeToEvent('ReceiveChanges', this.loadNotifications)
                subscribeToEvent('ReceiveClaim', this.loadNotifications)
                subscribeToEvent('NewMeetingAgendaBegins', this.handleNewMeetingAgenda)
            }).catch((err) => {
            })

        },
        beforeDestroy() {
            unsubscribeFromEvent('ReceiveChanges')
            unsubscribeFromEvent('ReceiveClaim')
            unsubscribeFromEvent('NewMeetingAgendaBegins')
        },
        methods: {
            changeLanguage(language) {

                clearTranslation()

                this.changingLanguage = true
                UsersService.changeLanguage(language).then((res) => {

                    this.$store.dispatch('user/setUserSession', res.data)
                    this.$router.go()

                }).catch(() => {

                }).finally(() => {
                    this.changingLanguage = false
                })
            },

            downloadGuide() {
                AttachmentsService.getUserGuide().then((res) => {
                    openFile(res.data)
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                })
            },

            loadNotifications() {
                UsersService.loadNotifications().then(res => {
                    if (res.data) {
                        this.messages = res.data.reduce((n, { count }) => n + count, 0)
                        this.notifications = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            logout() {
                this.$router.push({ name: 'logout' })
            },
            handleNewMeetingAgenda(newMeetingAgendaStarted, meetingTitle) {
                if (newMeetingAgendaStarted) {
                    this.agenda.title = newMeetingAgendaStarted.title
                    this.agenda.meetingTitle = meetingTitle
                    this.isNewAgendaStart = true
                }
            },

        }
    }
</script>