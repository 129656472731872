<template>
    <div>
        <v-card :loading="loading" tile>
            <div class="card-title ">
                {{ $t("RecommendationNotes") }}
                <v-icon v-if="!viewMode" class="float-end me-2" color="primary" @click="openAddNoteDialogue">
                    mdi-plus-circle-outline
                </v-icon>
            </div>

            <GridWithServerPaging :headers="headers" :items="notes" :items-per-page="options.itemsPerPage" :total="totalCount" disable-sort class="" @onChange="loadNotes">
                <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt| formatDateAr }}
                </template>
                <template v-if="!viewMode" v-slot:[`item.actions`]="{ item }">
                    <v-icon v-if="!viewMode"
                            class="mx-1"
                            :disabled="loading"
                            color="primary"
                            @click="EnableEdit(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon v-if="!viewMode"
                            color="error"
                            :disabled="loading"
                            @click="deleteNote(item.id)">
                        mdi-trash-can-outline
                    </v-icon>
                </template>
            </GridWithServerPaging>

            <confirm-dialog ref="confirmDialog" />
            <v-dialog v-if="showNoteDialogue" v-model="showNoteDialogue" width="1000">
                <AddRecommendationNote :id="id" :note="selectedNote" @close="showNoteDialogue = false" @update="loadNotes" />
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
    import RecommendationsService from '@/services/MeetingAgendaRecommendationsService'
    import ConfirmDialog from '@/components/controls/ConfirmDialog.vue'
    import AddRecommendationNote from '@/components/app/meeting/Recommendations/AddRecommendationNote.vue'
    import GridWithServerPaging from '@/components/controls/GridWithServerPaging.vue'

    export default ({
        components: {
            ConfirmDialog, GridWithServerPaging, AddRecommendationNote
        },

        props: {
            id: {
                type: Number,
                default: null
            },
            viewMode: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                loading: false,
                showNoteDialogue: false,
                headers: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('Text'), value: 'text' },
                    { text: this.$t('CreatedDate'), value: 'createdAt' },
                    { text: this.$t('Actions'), value: 'actions' },
                ],
                notes: [],
                selectedNote: null,
                options: {
                    page: 1, itemsPerPage: 5
                },
                totalCount: null
            }
        },
        mounted() {
            this.loadNotes(this.options)
        },
        methods: {
            EnableEdit(note) {
                this.selectedNote = note
                this.showNoteDialogue = true

            },
            openAddNoteDialogue() {
                this.showNoteDialogue = true
            },
            loadNotes(options) {

                const page = options != undefined ? options.page : this.options.page
                const pageSize = options != undefined ? options.itemsPerPage : this.options.itemsPerPage
                this.loading = true
                RecommendationsService.listRecommendationNotes(this.id, page, pageSize).then((res) => {
                    this.notes = res.data.data
                    this.totalCount = res.data.total
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            deleteNote(noteId) {
                this.$refs.confirmDialog.open(this.$t('DeleteNote'), this.$t('ConfirmDelete'))
                    .then((confirmed) => {
                        if (confirmed) {
                            RecommendationsService.deleteRecommendationNote(noteId).then((res) => {
                                if (res) {
                                    this.loadNotes()
                                }

                            }).catch(() => {
                                this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                            }).finally(() => {
                                this.loading = false
                            })
                        } else {

                        }
                    })

            },
            close() {
                this.$emit('close')
            }

        },
    })
</script>

<style scoped>
    .elevated-table {
        border: 1px solid #000000; /* Border around the table */
    }
</style>