import { mainApiAxios as axios } from '@/plugins/axios'


export default {

    listDictionary() {
        return axios.get('dictionary')
    },

    updateDictionary(dictionaryId, dictionaryObj) {
        return axios.put(`dictionary/${dictionaryId}`, dictionaryObj)
    },

    addDictionary(dictionaryObj) {
        return axios.post('dictionary', dictionaryObj)
    },

    deleteDictionary(dictionaryId) {
        return axios.delete(`dictionary/${dictionaryId}`)
    }
}