<template>
    <v-card tile :loading="loading" class="rounded-lg">
        <v-toolbar>
            <h3>{{ $t('CommitteeRoles') }}</h3>
        </v-toolbar>
        <v-card-text>
            <v-data-table :headers="headers" dense :items="roles" :items-per-page="10">
                <template v-slot:[`item.options`]="{ item }">
                    <v-icon class="me-3" color="primary" @click="editRole(item)"> mdi-pen</v-icon>
                    <v-icon color="error" @click="deleteRole(item.id)"> mdi-delete</v-icon>
                </template>
                <template v-slot:top>
                    <v-btn small class="ma-4 white--text" tile color="primary" @click="open()">
                        {{ $t('Add') }} <v-icon small class="ms-2">mdi-plus</v-icon>
                    </v-btn>
                </template>
            </v-data-table>

            <v-form ref="roleForm" v-model="valid" lazy-validation>
                <CommonDialog v-model="dialog" :fullscreen="false" width="500" :title="title" show-action-toolbar>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="role.nameAr" :label="$t('NameAr')" :rules="cleanRequiredStringRules" required />
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="role.nameEn" :label="$t('NameEn')" :rules="cleanRequiredStringRules" required />
                            </v-col>
                        </v-row>
                    </v-container>

                    <template v-slot:actions>
                        <v-btn v-if="!edit" color="primary" class="white--text" tile :disabled="loading" small @click="save()">
                            {{ $t("Add") }}
                        </v-btn>
                        <v-btn v-if="edit" color="primary" class="white--text" tile :disabled="loading" small @click="update()">
                            {{ $t("Update") }}
                        </v-btn>
                        <v-btn color="error" class="white--text" tile :disabled="loading" small @click="dialog = false; reset()">
                            {{ $t("Cancel") }}
                        </v-btn>
                    </template>
                </CommonDialog>

            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>

    import CommonDialog from '@/components/controls/CommonDialog'
    import CommitteeRolesService from '@/services/CommitteeRolesService.js'
    import LookupsService from '@/services/LookupsService.js'

    export default {

        components: {
            CommonDialog
        },

        data() {
            return {
                loading: false,
                dialog: false,
                valid: true,
                edit: false,
                selectedRoleId: null,
                roles: [],
              
                headers: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('Name'), value: 'nameAr' },
                    { text: this.$t('NameEn'), value: 'nameEn' },
                    { text: this.$t('Options'), value: 'options', width: '8%' }
                ],
                title: '',
                cleanRequiredStringRules: [this.$rules.requiredRule, this.$rules.onlyAlphanumericRule],

                role: {
                    nameAr: '',
                    nameEn: '',
                   
                }
            }
        },


        created() {
            this.loadRoles()
            this.loadRoleTypes()
        },

        methods: {
            loadRoles() {
                this.loading = true
                CommitteeRolesService.listRoles().then((res) => {
                    this.roles = res.data

                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            loadRoleTypes() {
                this.loading = true
                LookupsService.listRoleTypes().then((res) => {
                    this.roleTypes = res.data

                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            save() {
                if (this.$refs.roleForm.validate()) {
                    CommitteeRolesService.addRole(this.role).then((res) => {
                        this.loadRoles()
                        this.reset()
                        this.$refs.roleForm.resetValidation()
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
            },

            update() {
                if (this.$refs.roleForm.validate()) {
                    CommitteeRolesService.updateRole(this.selectedRoleId, this.role).then((res) => {
                        this.dialog = false
                        this.loadRoles()
                        this.reset()
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
            },

            reset() {
                this.role.nameAr = ''
                this.role.nameEn = ''
            },

            open() {

                this.reset()
                this.edit = false
                this.selectedRoleId = null
                this.$refs.roleForm.resetValidation()
                this.title = this.$t('Add')
                this.dialog = true
            },

            editRole(item) {
                this.edit = true
                this.role.nameAr = item.nameAr
                this.role.nameEn = item.nameEn
                this.role.typeId = item.typeId
                this.selectedRoleId = item.id
                this.title = this.$t('Edit')
                this.dialog = true
            },

            async deleteRole(id) {
                if (await this.$root.$confirm(this.$t('Delete'), this.$t('ConfirmDelete'))) {
                    this.loading = true
                    CommitteeRolesService.deleteRole(id)
                        .then(() => {
                            this.loadRoles()
                        })
                        .catch(() => {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }).finally(() => this.loading = false)
                }
            },
        },
    }
</script>

<style>
</style>