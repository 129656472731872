const FormMethodEnum = Object.freeze({
    ShortAnswer: 1,
    MultipleChoice: 2,
    Checkboxes: 3,
    Dropdown: 4,
    Rating: 5,
    number: 6,
    YesNo: 7,
    Date: 8,
    TextArea: 9,
    BipolarMatrix: 10,
    PredefineDropdown: 11,
    Image: 12,
    VedioLink: 13,
})

const ProcessTypesEnum = Object.freeze({

})

const FieldTypesEnum = Object.freeze({
    Textfield: 1,
    Checkbox: 2,
    Radio: 3,
    Select: 4,
    Date: 5,
    Textarea: 6,
    Masters: 7
})

const ActivityTypesEnum = Object.freeze({
    StartActivity: 1,
    TaskActivity: 2,
    WaitActivity: 3,
    CodeActivity: 4,
    EndActivity: 5,
    CTSActivity: 7,
})

const ActivityClassTypesEnum = Object.freeze({
    StartActivity: 2,
    TaskActivity: 7,
    EndActivity: 6,
})

const OwnerTypes = Object.freeze({
    User: 1,
    Role: 2,
    CustomRole: 3,
    Structure: 4,
    Initiator: 5,
    CustomUser: 6

})

const AccountTypes = Object.freeze({
    User: 1,
    Role: 2
})

const PermissionLevelsEnum = Object.freeze({
    User: 1,
    Role: 2,
    Department: 3,
    Everyone: 4

})


const PermissionsMatrixEnum = Object.freeze({
    Full: 1,
    Read: 2,
    Write: 3
})



const PermissionTypesEnum = Object.freeze({
    Menu: 1,
    Survey: 2,
    Process: 3
})

const PermissionsEnum = Object.freeze({


})

const SurveyStatusEnum = Object.freeze({
    Draft: 1,
    Pending: 2,
    Approved: 3
})

const TaskTypeEnum = Object.freeze({
    MeetingApproval: 1,
    InitialMeetingAgendaApproval: 2,
    InitialMeetingMinutesApproval: 3,
    SignFinalMeetingMinutes: 4,
})

const MeetingStatusEnum = Object.freeze({
    Draft : 1,
    PendingMeetingApproval : 2,
    Approved: 3,
    Started: 4,
    Finished: 5,
    PendingInitialMeetingMinutesApproval: 6,
    InitialMeetingMinutesApproved : 7,
    PendingFinalMeetingMinutesSign : 8,
    FinalMeetingMinutesSigned : 9,
})

const MeetingTypeEnum = Object.freeze({
    Attend: 2,
    Remote: 3,
})

const AgendaRecommendationStatusEnum = Object.freeze({
    Draft: 1,
    InProgress: 2,
    Completed: 3,
    
})
const CommitteeType = Object.freeze({
    Council: 1,
    Committee: 2,
})
const SignalRStatus = Object.freeze({
    Connecting: 0,
    Connected: 1,
    Reconnecting: 2,
    Disconnected: 3,

})
const AttachmentsRecordType = Object.freeze({
    Meeting: 1,
    MeetingMinutes: 2,
    FinalMeetingMinutes: 3,
    AgendaRecommendation: 4,
    MeetingAgenda: 5,

})
const VotingTypesEnum = Object.freeze({
    YesOrNo: 1,
    WithoutVoting: 2,
    Evaluation: 3,
})
const MeetingMinutesTypesEnum = Object.freeze({
    Initial: 1,
    Final: 2,
})
export {
    FormMethodEnum, FieldTypesEnum, OwnerTypes, ActivityTypesEnum, ActivityClassTypesEnum, PermissionLevelsEnum,
    PermissionTypesEnum, AccountTypes, PermissionsMatrixEnum, PermissionsEnum, ProcessTypesEnum, SignalRStatus, MeetingTypeEnum,
    SurveyStatusEnum, TaskTypeEnum, MeetingStatusEnum, AgendaRecommendationStatusEnum, CommitteeType, AttachmentsRecordType, VotingTypesEnum, MeetingMinutesTypesEnum
}