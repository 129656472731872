<template>
    <CommonDialog :value="value" :title="$t('ChangePassword')" width="500" :fullscreen="false" show-action-toolbar @input="$emit('input', false)">
<v-form ref="form" v-model="valid" lazy-validation :disabled="loading" @submit.prevent="save">
            <v-container>
                <v-row>
                    <v-col cols="12" class="pb-0">
                        <v-text-field v-model="oldPassword"
                                      type="password"
                                      :rules="[rules.required]"
                                      autocomplete="off"
                                      :label="$t('CurrentPassword')" />
                    </v-col>
                    <v-col cols="12" class="pb-0 pt-0">
                        <v-text-field v-model="password1"
                                      :type="show ? 'text' : 'password'"
                                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                      :rules="[rules.required, rules.min8, rules.nospaces, rules.complex, rules.nomatch1]"
                                      autocomplete="off"
                                      :label="$t('NewPassword')"
                                      @click:append="show = !show" />
                    </v-col>
                    <v-col cols="12" class="pt-0">
                        <v-text-field v-model="password2"
                                      :type="show ? 'text' : 'password'"
                                      :rules="[rules.required, rules.nomatch2, rules.min8, rules.nospaces, rules.complex]"
                                      autocomplete="off"
                                      :label="$t('ReNewPassword')" />
                    </v-col>
                    <v-col v-if="showCodeInput" cols="12" class="pt-0">
                        <span class="subtitle-1">{{ $t('code-sent-tomobile') }}</span>
                    </v-col>
                    <v-col v-if="showCodeInput" cols="12" class="pt-0">
                        <v-otp-input v-model="code" length="4" />
                    </v-col>
                </v-row>
            </v-container>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn color="secondary" class="white--text" tile :disabled="loading" type="submit">{{ $t("Update") }}</v-btn>
                <v-btn color="error" tile :disabled="loading" @click="close()">{{ $t("Cancel") }}</v-btn>
            </v-card-actions>
        </v-form>
</CommonDialog>
</template>
<script>


    import CommonDialog from '@/components/controls/CommonDialog.vue'
    import UsersService from '@/services/UsersService'

    export default {

        components: {
            CommonDialog
        },

        props: {
            value: {
                type: Boolean,
                default: null
            },
        },

        data() {
            return {
                password1: null,
                password2: null,
                oldPassword: null,
                code: null,
                showCodeInput: false,
                validator: null,
                show: false,
                loading: false,
                valid: false,
                rules: {
                    required: (v) => !!v || this.$t('Required'),
                    min8: (v) => v && v.length >= 8 || this.$t('Atleast8Characters'),
                    nospaces: (v) => v && v.match(/^\S*$/g) != null || this.$t('spaces-not-allowed'),
                    complex: (v) => v && v.match(/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~!@#$%^*\-\_=+[{\]};:,.?])/g) != null || this.$t('ComplexPassword'),
                    nomatch1: (v) => !this.password2 || v == this.password2 || this.$t('PasswordsDontMatch'),
                    nomatch2: (v) => !this.password1 || v == this.password1 || this.$t('PasswordsDontMatch'),
                }
            }
        },

        methods: {
            close() {
                this.$emit('input', false)
            },

            save() {
                if (this.$refs.form.validate()) {
                    this.loading = true

                    UsersService.updatePassword({
                        oldPassword: this.oldPassword,
                        newPassword: this.password2,
                        code: this.code,
                        validator: this.validator
                    }).then((res) => {
                        if (res.data) {
                            //if data is empty then change password without sms verification
                            this.close()
                        } else {
                            this.$root.$notification(this.$t(res.message), 'error')
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
            },
        }
    }
</script>