<template>
    <CommonDialog :value="value" :fullscreen="false" width="800" :title="edit == true ? $t('Edit') : $t('Add')" show-action-toolbar @input="$emit('input', false)">
        <v-form ref="structureForm" v-model="valid" lazy-validation>
            <v-container>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-switch v-model="structure.active" :label="$t('Active')" color="teal" hide-details />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field v-model="structure.nameAr" dense :label="$t('NameAr')" :rules="cleanRequiredStringRules" required />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field v-model="structure.nameEn" dense :label="$t('NameEn')" :rules="cleanRequiredStringRules" required />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field v-model="structure.code" dense :label="$t('Code')" :rules="cleanRequiredStringRules" required />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-autocomplete v-model="structure.typeId" :loading="loading" :rules="nameRules" item-text="name" item-value="id" :items="structureTypes" :label="$t('Type')" dense />
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="structure.description" :label="$t('Description')" :rules="cleanStringRules" hide-details outlined rows="3" />
                    </v-col>
                    <v-col cols="6">
                        <v-autocomplete v-model="structure.parentId" :loading="loading" outlined item-text="name" item-value="id" :items="structures" :label="$t('ParentStructure')" dense />
                    </v-col>
                    <v-col cols="6">
                        <v-autocomplete v-model="structure.branchId" :loading="loading" outlined item-text="name" item-value="id" :items="branches" :label="$t('Branch')" dense />
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
        <template v-slot:actions>
            <v-btn v-if="!edit" color="secondary" class="white--text" tile :disabled="posting" small @click="save()">
                {{ $t("Add") }}
            </v-btn>
            <v-btn v-if="edit" color="secondary" class="white--text" tile :disabled="posting" small @click="update()">
                {{ $t("Update") }}
            </v-btn>
            <v-btn color="error" class="white--text" tile :disabled="posting" small @click="$emit('input', false); resetStructureObj()">
                {{ $t("Cancel") }}
            </v-btn>
        </template>
    </CommonDialog>
</template>

<script>
    import CommonDialog from '@/components/controls/CommonDialog'
    import LookupsService from '@/services/LookupsService.js'
    import StructuresService from '@/services/StructuresService.js'

    export default {
        components: {
            CommonDialog
        },

        props: {
            value: {
                type: Boolean,
                default: false
            },
            edit: {
                type: Boolean,
                default: false
            },
            structureId: {
                type: String,
                default: null
            },
            title: {
                type: String,
                default: ''
            }
        },

        data() {
            return {
                valid: true,
                loading: false,
                posting: false,
                structureTypes: [],
                structures: [],
                branches: [],
                nameRules: [
                    v => !!v || this.$t('RequiredField')
                ],
                cleanRequiredStringRules: [this.$rules.requiredRule, this.$rules.noHtmlTagsRule, this.$rules.onlyAlphanumericRule],
                cleanStringRules: [this.$rules.noHtmlTagsRule, this.$rules.onlyAlphanumericRule],

                structure: {
                    id: null,
                    nameAr: '',
                    nameEn: '',
                    code: '',
                    description: '',
                    typeId: null,
                    parentId: null,
                    active: true,
                    branchId: null,
                }
            }
        },

        created() {
            this.listLookups()

            if (this.edit) {
                this.loadStructure()
            }
        },

        methods: {

            loadStructure() {
                StructuresService.getStructure(this.structureId).then((res) => {
                    if (res.data) {
                        this.structure = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            listLookups() {
                this.loading = true
                let promises = [
                    LookupsService.listStructureTypes(),
                    LookupsService.listStructures(),
                    LookupsService.listBranches()
                ]

                Promise.all(promises)
                    .then(
                        ([
                            structureTypes,
                            structures,
                            branches
                        ]) => {
                            this.structureTypes = structureTypes.data
                            this.structures = structures.data
                            this.branches = branches.data
                        },
                    ).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
            },

            save() {
                if (this.$refs.structureForm.validate()) {
                    StructuresService.addStructure(this.structure).then((res) => {
                        this.$refs.structureForm.resetValidation()
                        this.resetStructureObj()
                        this.$emit('update', true)

                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
            },


            update() {
                if (this.$refs.structureForm.validate()) {
                    StructuresService.updateStructure(this.structureId, this.structure).then((res) => {
                        this.$refs.structureForm.resetValidation()
                        this.resetStructureObj()
                        this.$emit('update', true)
                        this.$emit('input', false)
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
            },

            resetStructureObj() {
                this.structure.id = null
                this.structure.nameAr = ''
                this.structure.nameEn = ''
                this.structure.code = ''
                this.structure.description = ''
                this.structure.typeId = null
                this.structure.parentId = null
                this.structure.branchId = null
                this.structure.active = true
            }
        },
    }
</script>

<style>
</style>