<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card class="rounded-lg" color="grey lighten-4" :loading="loading">
                    <v-toolbar>
                        <v-row justify="start" align="center">
                            <v-col cols="auto">
                                <Breadcrumb :parents="parents" />
                            </v-col>
                            <v-spacer />
                        </v-row>
                    </v-toolbar>
                    <v-card-text>
                        <v-card class="mb-3">
                            <v-card-text class="mb-2" v-if="!this.$route.params.councilId">
                                <p>{{ $t('AdvancedSearch') }}</p>
                                <v-row>
                                    <v-col class="pt-0 pb-0" cols="4">
                                        <v-text-field v-model="searchCriteria.committeeTitle" :label="$t('Committee')" clearable maxlength="100" v-on:keydown.enter.prevent="listComittees()"></v-text-field>
                                    </v-col>
                                    <v-col class="pt-0 pb-0" cols="4">
                                        <v-text-field v-model="searchCriteria.meetingTitle" :label="$t('MeetingTitle')" clearable maxlength="100" v-on:keydown.enter.prevent="listComittees()"></v-text-field>
                                    </v-col>
                                    <v-col class="pt-0 pb-0" cols="4">
                                        <v-text-field v-model="searchCriteria.agendaTitle" :label="$t('Agenda')" clearable maxlength="100" v-on:keydown.enter.prevent="listComittees()"></v-text-field>
                                    </v-col>
                                    <v-col class="pt-0 pb-0" cols="4">
                                        <v-text-field v-model="searchCriteria.agendaTopicTitle" :label="$t('Topic')" clearable v-on:keydown.enter.prevent="listComittees()"></v-text-field>
                                    </v-col>
                                    <v-col class="pt-0 pb-0" cols="4">
                                        <v-text-field v-model="searchCriteria.agendaNote" :label="$t('AgendaNote')" clearable v-on:keydown.enter.prevent="listComittees()"></v-text-field>
                                    </v-col>
                                    <v-col class="pt-0 pb-0" cols="4">
                                        <v-text-field v-model="searchCriteria.recommendationTitle" :label="$t('Recommendations')" clearable v-on:keydown.enter.prevent="listComittees()"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-1">
                                    <v-col cols="12">
                                        <v-btn :disabled="loading"
                                               class="me-1 pt-0 white--text"
                                               color="teal"
                                               type="submit"
                                               @click="listComittees()">
                                            {{ $t('Search') }}
                                        </v-btn>
                                        <v-btn :disabled="loading"
                                               class="me-1 pt-0 white--text"
                                               color="teal"
                                               @click="reset()">
                                            {{ $t('Reset') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                        <v-row>
                            <v-col v-for="(council,index) in filteredCouncils" :key="'council'+index" cols="3">
                                <v-card class="rounded-xl" hover>
                                    <v-list-item three-line>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <h3> {{ council.name | trim(35) }}</h3>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-avatar size="50" color="primary">
                                            <v-icon size="30" class="white--text">{{ council.typeId==1?'mdi-account-multiple-outline':'mdi-handshake' }}</v-icon>
                                        </v-list-item-avatar>

                                    </v-list-item>
                                    <v-card-text>

                                    </v-card-text>


                                    <v-card-actions class="ma-2 d-flex flex-wrap" style="max-width: 100%">
                                        <v-chip outlined color="indigo" class="white--text"> {{ council.code }}</v-chip>
                                        <v-chip outlined color="primary" class="mx-2">{{ council.typeId==1? $t('Council') : $t('Committee') }}</v-chip>
                                        <router-link v-if="council.showDetails" :to="{ name: 'comittee-details', params: { comitteeId: council.id}  }" class="text-decoration-none">
                                            <v-btn text class="text-truncate" dense color="warning" dark>
                                                <b>{{ $t('Details') }}</b>
                                            </v-btn>
                                        </router-link>
                                        <v-btn text v-if="council.hasChilds&&council.parents.length==0" dense class="ms-2 mt-1 text-truncate" color="deep-purple accent-4" dark @click="navigateToNextComittee(council)">
                                            <b>{{ $t('Comittees') }}</b>
                                            <v-chip outlined color="indigo" class="white--text ms-2"> {{ council.childernsCount }}</v-chip>
                                        </v-btn>
                                        <v-btn text v-if="council.hasChilds&&council.parents.length>0" dense class="ms-2 text-truncate" color="deep-purple accent-4" dark @click="navigateToNextComittee(council)">
                                            <b>{{ $t('SubComittees') }}</b>
                                            <v-chip outlined color="indigo" class="white--text  ms-2"> {{ council.childernsCount }}</v-chip>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                            <v-col cols="12" v-if="filteredCouncils.length == 0" class="d-flex justify-center align-center">
                                <h3 class="text-center">{{ $t('ItemNotFound') }}</h3>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Breadcrumb from '@/components/controls/ComitteeBreadcrumb.vue'
    import CouncilCommitteesService from '@/services/CouncilCommitteesService'

    export default {
        components: { Breadcrumb },


        data() {
            return {
                loading: false,
                searchCouncil: null,
                searchMeetings: null,
                comittees_councils: null,
                parents: [],
                headers: [
                    { text: this.$t('Id'), value: 'id' ,sortable: false},
                    { text: this.$t('Title'), value: 'title',sortable: false },
                    { text: this.$t('Status'), value: 'statusName' ,sortable: false},
                    { text: this.$t('MeetingRoom'), value: 'location' ,sortable: false},
                    { text: this.$t('ReferenceNumber'), value: 'referenceNumber' ,sortable: false},
                    { text: this.$t('Date'), value: 'date',sortable: false },
                    { text: this.$t('Options'), value: 'options' ,sortable: false},
                ],
                meetings: [],
                selectedCouncil: null,
                searchCriteria:
                {
                    committeeTitle:'',
                    meetingTitle: '',
                    agendaTitle:'',
                    agendaTopicTitle:'',
                    recommendationTitle: '',
                    agendaNote: '',
                },
            }
        },

        computed: {
            filteredCouncils() {
                if (!this.comittees_councils) {
                    return []
                }
                if (!this.searchCouncil) {
                    return this.comittees_councils
                }
                return this.comittees_councils.filter(item => {
                    return item.name.toLowerCase().includes(this.searchCouncil.toLowerCase()) || String(item.id).includes(this.searchCouncil.toLowerCase())
                })
            },
        },
        watch: {
            selectedCouncil() {
                this.searchComittee = null
                this.searchCouncil = null
            },
            '$route.params.councilId': 'resetPage'
        },
        created() {
            this.selectedCouncil = null
            this.listComittees()
        },

        methods: {
            navigateToNextComittee(council) {
                this.parents = council.parents
                this.$router.push({ name: 'council-comittee-general-info', params: { councilId: council.id } })
            },
            resetPage() {
                this.parents = []
                this.listComittees()
            },
            getColor(type) {
                if (type == 1) return 'primary'
                else return 'secondary'
            },
            listComittees() {

                if (!this.$route.params.councilId) {
                    this.loading = true
                    const searchObj = {
                        committeeTitle: this.searchCriteria.committeeTitle||'',
                        meetingTitle: this.searchCriteria.meetingTitle ||'',
                        agendaTitle: this.searchCriteria.agendaTitle ||'',
                        agendaTopicTitle: this.searchCriteria.agendaTopicTitle ||'',
                        recommendationTitle: this.searchCriteria.recommendationTitle ||'',
                        agendaNote: this.searchCriteria.agendaNote ||'',
                    }

                    CouncilCommitteesService.listUserCouncilsAndCommitteesForGeneralInfo(searchObj).then((res) => {
                        if (res.data) {
                            this.comittees_councils = res.data
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false

                    })
                } else {
                    this.loading = true
                    Promise.all([

                        CouncilCommitteesService.getCouncilCommittee(this.$route.params.councilId),
                        CouncilCommitteesService.getComitteesByCouncilId(this.$route.params.councilId),
                    ]).then(([council, comitteesList]) => {
                        this.selectedCouncil = council.data
                        this.comittees_councils = comitteesList.data
                        if (this.comittees_councils.length > 0) {
                            this.parents = this.comittees_councils[0].parents
                        }

                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false

                    })
                }

            },
            reset() {
                this.searchCriteria.meetingTitle = ''
                this.searchCriteria.agendaNote = ''
                this.searchCriteria.recommendationTitle = ''
                this.searchCriteria.agendaTopicTitle = ''
                this.searchCriteria.agendaTitle = ''
                this.searchCriteria.committeeTitle=''
            },
        },


    }

</script>

<style scoped>
    .text-h5 {
        font-family: Tajawal !important
    }

    .comittee-box {
        border: thin solid rgba(0, 0, 0, 0.12);
        min-height: 100px;
        font-size: 12px;
        background-color: #f5f5f5;
    }

        .comittee-box:hover {
            background-color: #fff;
            box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
            cursor: pointer;
        }
</style>