import Login from '@/pages/Login.vue'
import TwoFA from '@/pages/2FA.vue'
import Home from '@/pages/Home.vue'
import Logout from '@/pages/Logout.vue'
import Chat from '@/pages/Chat.vue'
import CouncilsAndCommitteesData from '@/pages/GeneralInfo/CouncilsAndComiteesGeneralInfo.vue'
import CommitteeDetails from '@/pages/GeneralInfo/CommitteeDetails.vue'
import UnmappedCommitteeMeetings from '@/pages/Meetings/UnmappedCommitteeMeetings.vue'
import Dashboard from '@/pages/Dashboard/Dashboard.vue'
import PageNotFound from '@/pages/PageNotFound.vue'
import MobileFileViewer from '@/pages/MobileSupport/MobileFileViewer.vue'

const baseUrl = process.env.VUE_APP_BASE_URL 

export default [
	
	
	{
		name: '2FA',
		path: `${baseUrl}/2FA`,
		component: TwoFA,
		meta: {
			secure: false
		}
	},
	{
		name: 'login',
		path: `${baseUrl}/`,
		component: Login
	},
	{
		name: 'logout',
		path: `${baseUrl}/logout`,
		component: Logout,
		meta: {
			secure: true
		}
	},
	{
		name: 'home',
		path: `${baseUrl}/home`,
		component: Home,
		meta: {
			secure: true
		}
	},
	{
		name: 'dashboard',
		path: `${baseUrl}/dashboard`,
		component: Dashboard,
		meta: {
			secure: true
		}
	},
	{
		name: 'chat',
		path: `${baseUrl}/chat`,
		component: Chat,
		meta: {
			secure: true
		}
	},
	{
		name: 'council-comittee-general-info',
		path: `${baseUrl}/council-comittee-general-info/:councilId?`,
		component: CouncilsAndCommitteesData,
		meta: {
			secure: true
		}
	},
	{
		name: 'comittee-details',
		path: `${baseUrl}/comittee-details/:comitteeId`,
		component: CommitteeDetails,
		meta: {
			secure: true
		}
	},
	{
		name: 'unmapped-committee-meetings',
		path: `${baseUrl}/unmapped-committee-meetings`,
		component: UnmappedCommitteeMeetings,
		meta: {
			secure: true
		}
	},
	{
		name: 'not-found',
		path: '*',
		component: PageNotFound
	},
	{
		name: 'mobile-file-viewer',
		path: `${baseUrl}/mobile-file-viewer`,
		component: MobileFileViewer
	},
]