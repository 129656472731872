<template>
    <v-autocomplete :dense="dense" clearable
                    :value="value"
                    item-value="id"
                    item-text="name"
                    :label="label"
                    :items="structures"
                    :rules="rules"
                    :disabled="disabled"
                    return-object
                    :outlined="outlined"
                    @change="onChange" />
</template>
<script>
    import StructuresService from '@/services/StructuresService.js'

    export default {

        props: {

            value: {
                type: [Number, Object],
                default: null
            },

            label: {
                type: String,
                default: function () {
                    return this.$t('Structure')
                }
            },
            dense: {
                type: Boolean,
                default: false
            },

            disabled: {
                type: Boolean,
                default: false
            },
            outlined: {
                type: Boolean,
                default: false
            },

            rules: {
                type: Array,
                default: () => []
            },

            returnObject: {
                type: Boolean,
                default: false
            },

            ixExternal: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                structures: [],
            }
        },

        mounted() {

            this.listStructures()
        },

        methods: {

            listStructures() {
                if (this.ixExternal) {
                    StructuresService.listExternalStructures().then((res) => {
                        this.structures = res.data
                    }).catch(() => { })
                }
                else {
                    StructuresService.listStructures().then((res) => {
                        this.structures = res.data
                    }).catch(() => { })
                }
            },

            onChange(item) {

                if (this.returnObject) {
                    this.$emit('input', item)
                    this.$emit('change', item)
                }
                else {
                    this.$emit('input', item != null ? item.id : null)
                    this.$emit('change', item)
                }
            },
        }
    }
</script>