<template>
    <v-card class="mb-2 pa-1" height="10vh">
        <div class="attendees-scroll"
             @mousedown="startDrag"
             @mouseup="stopDrag"
             @mouseleave="stopDrag"
             @mousemove="onDrag">
                <v-badge 
                         v-for="(user, index) in attendees"
                 :key="'attendee_' + index"
                         class="px-2  mt-1" top
                         left
                         :color="getUserStatusColor(user.userId)"
                         dot
                         offset-x="12"
                         offset-y="5">
                    <v-card class="white--text d-inline-flex justify-center align-center"
                            color="grey"
                            :style="{ height: cardSize, width: cardSize }">
                        <v-img v-if="user.hasProfilePicture"
                               :src="getProfileImageUrl(user.userId)"
                               :height="cardSize"
                               loading="lazy" />
                        <h3 v-else>{{ getUserInitials(user.name) }} </h3>
                    </v-card>
                </v-badge>
        </div>
    </v-card>
</template>

<script>
    import MeetingsService from '@/services/MeetingsService'
    import { getUserImage } from '@/helpers/common.js'
    export default {
        props: {
            meetingId: {
                type: Number,
                default: null,
            },
            attendees: {
                type: Array,
                default: () => [],
            },
            editMode: {
                type: Boolean,
                default: true,
            },
            currentAttendanceList: {
                type: Array,
                default: () => []
            },
            meetingOwner: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                isDragging: false,
                startX: 0,
                scrollLeft: 0,
            }
        },
        computed: {
            cardSize() {
                return 'calc(10vh - 15px)'
            },
        },
        created() {

        },
        methods: {

            getProfileImageUrl(userId) {
                return getUserImage(userId)
            },
            handleAttendanceChange(newValue, attendeeId) {
                this.loading = true
                const formData = {
                    userId: attendeeId,
                    meetingId: this.meetingId,
                    attended: newValue,
                }
                MeetingsService.attendUser(formData)
                    .then((res) => {
                        if (res.data) {
                            this.$root.$notification(this.$t('UpdateSuccess'), 'success')
                        }
                    })
                    .catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            getUserInitials(name) {
                const namesArray = name.trim().split(/\s+/)
                const initials = namesArray.length > 1
                    ? namesArray[0][0] + ' ' + namesArray[namesArray.length - 1][0]
                    : namesArray[0][0]
                return initials ? initials.toUpperCase() : ''
            },
            close() {
                this.$emit('close')
            },
            startDrag(e) {
                this.isDragging = true
                this.startX = e.pageX - this.$el.querySelector('.attendees-scroll').offsetLeft
                this.scrollLeft = this.$el.querySelector('.attendees-scroll').scrollLeft
            },
            stopDrag() {
                this.isDragging = false
            },
            onDrag(e) {
                if (!this.isDragging) return
                e.preventDefault()
                const x = e.pageX - this.$el.querySelector('.attendees-scroll').offsetLeft
                const walk = (x - this.startX) * 2
                this.$el.querySelector('.attendees-scroll').scrollLeft = this.scrollLeft - walk
            },
            getUserStatusColor(userId) {
                return this.currentAttendanceList.some(u => u == userId) ? 'green' : 'error'
            }
        },
    }
</script>

<style scoped>
    .attendees-scroll {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        white-space: nowrap;
        align-items: flex-start;
        cursor: grab;
    }

        .attendees-scroll:active {
            cursor: grabbing;
        }

        .attendees-scroll::-webkit-scrollbar {
            height: 4px;
        }

        .attendees-scroll::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        .attendees-scroll::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
        }

            .attendees-scroll::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
</style>
