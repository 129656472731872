<template>
    <div>
        <v-toolbar color="primary" dense flat>
            <v-toolbar-title class="white--text c-text-h5">
                {{ $t('GenerateMeetingMinutes') }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn dark icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card class="pa-2 pb-5 justify-center text-center">

            <v-col>
                <v-row class="mx-2">
                    <v-file-input v-model="file"
                                  :label="$t('ChooseFile')"
                                  accept="pdf/*"
                                  prepend-icon="mdi-file" />
                </v-row>
            </v-col>
            <v-btn v-if="isInitialMeetingMinutes" class="mb-3 white--text" color="primary"
                   :loading="loading"
                   :disabled="!file||loading"
                   @click="uploadMeetingMinutes">
                {{ $t('UploadInitialMeetingMinutes') }}
            </v-btn>
            <v-btn v-else class="mb-3 white--text" color="primary"
                   :loading="loading"
                   :disabled="!file||loading"
                   @click="uploadMeetingMinutes">
                {{ $t('UploadFinalMeetingMinutes') }}
            </v-btn>
            <v-divider color="primary" class="ma-5" />
            <v-col class="justify-center mb-4">
                <v-btn class="white--text"
                       color="primary"
                       :loading="loading"
                       @click="generateSystemMeetingMinutes">
                    {{ $t("GenerateSystemMeetingMinutes") }}
                </v-btn>
            </v-col>
        </v-card>
    </div>
</template>

<script>
    import { MeetingMinutesTypesEnum } from '@/helpers/constants/enumerations'
    import MeetingsService from '@/services/MeetingsService'

    export default ({
        components: {
        },
        props: {
            meetingId: {
                type: Number,
                default: null
            },
            meetingMinutesType: {
                type: Number,
                default: null
            },
        },
        data() {
            return {
                file: null,
                loading: false,
            }
        },
        computed: {
            isInitialMeetingMinutes(){

                return this.meetingMinutesType== MeetingMinutesTypesEnum.Initial
            }
        },
        created() {
            if (!this.meetingId || !this.meetingMinutesType) {
                this.close()
            }
        },

        methods: {
            close() {
                this.$emit('close')
            },
            generateSystemMeetingMinutes() {
                if (this.meetingMinutesType == MeetingMinutesTypesEnum.Initial) {
                    this.loading = true
                    MeetingsService.generateMeetingMinutes(this.meetingId).then(response => {
                        if (response.success) {
                            this.$emit('update')
                            this.close()
                        } else {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                } else {
                    this.loading = true
                    MeetingsService.generateFinalMeetingMinutes(this.meetingId).then(response => {
                        if (response.success) {
                            this.$emit('update')
                            this.close()
                        } else {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })

                }
            },
            uploadMeetingMinutes() {
                let formdata = {
                    'file': this.file
                }
                this.loading=true
                if (this.meetingMinutesType == MeetingMinutesTypesEnum.Initial) {

                    MeetingsService.uploadInitialMeetingMinutes(this.meetingId, formdata).then(response => {
                        if (response.success) {
                            this.$emit('update')
                            this.close()
                        } else {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                } else {
                    let formdata = {
                        'file': this.file
                    }
                    MeetingsService.uploadFinalMeetingMinutes(this.meetingId, formdata).then(response => {
                        if (response.success) {
                            this.$emit('update')
                            this.close()
                        } else {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })

                }

            },
        },
    })
</script>

<style>
</style>