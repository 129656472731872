<template>
    <v-form ref="activityOwners" v-model="valid">
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-select v-model="ownerType" :loading="loading" dense class="mt-4" :items="owners" :label="$t('OwnerType')" item-text="name" item-value="id" :rules="nameRules" required @change="reset($event)" />
                </v-col>
                <v-col v-if="ownerType == userOwnerType" cols="12">
                    <UserSelect v-model="selectedId" :rules="nameRules" :label="$t('SelectUserSearch')" required />
                </v-col>

                <v-col v-if="ownerType == roleOwnerType" cols="12">
                    <RoleSelect v-model="selectedId" :rules="nameRules" :label="$t('SelectRoleSearch')" class="mt-3" required />
                </v-col>

                <v-col v-if="ownerType == structureOwnerType" cols="12">
                    <StructureSelect v-model="selectedId" :rules="nameRules" :label="$t('SelectStructureSearch')" class="mt-3" required />
                </v-col>

                <v-col v-if="ownerType == customOwnerType || ownerType == customUserType" cols="12">
                    <v-textarea v-model="selectedId" outlined :label="$t('ProcessVariables')" hide-details rows="3" />
                </v-col>

                <v-col v-if="ownerType == initiatorOwnerType" cols="12">
                    <v-text-field v-model="selectedId" disabled outlined :label="$t('ProcessVariables')" hide-details />
                </v-col>
            </v-row>
        </v-card-text>

        <v-divider class=" mt-4" />

        <v-card-actions class="d-block text-end">
            <v-btn tile color="secondary" small class="white--text" @click="SaveSettings">
                {{ $t('Save') }}
            </v-btn>
            <v-btn tile color="error" small class="white--text" @click="$emit('close')">
                {{ $t('Cancel') }}
            </v-btn>
        </v-card-actions>
    </v-form>
</template>

<script>
    import { OwnerTypes } from '@/helpers/constants/enumerations'
    import LookupsService from '@/services/LookupsService'
    import UserSelect from '@/components/controls/UserSelect'
    import RoleSelect from '@/components/controls/RoleSelect'
    import StructureSelect from '@/components/controls/StructureSelect'

    export default {

        components: {
            UserSelect, RoleSelect, StructureSelect
        },

        props: {
            task: {
                type: Object,
                default: null
            },
        },

        data() {
            return {
                valid: true,
                loading: false,
                ownerType: null,
                selectedId: null,
                userOwnerType: OwnerTypes.User,
                roleOwnerType: OwnerTypes.Role,
                customOwnerType: OwnerTypes.CustomRole,
                customUserType: OwnerTypes.CustomUser,
                structureOwnerType: OwnerTypes.Structure,
                initiatorOwnerType: OwnerTypes.Initiator,
                owners: [],
                nameRules: [
                    v => !!v || this.$t('RequiredField')
                ],
            }
        },


        created() {
            this.listOwnerTypes()
            this.ownerType = this.task.OwnerTypeId
            switch (this.task.OwnerTypeId) {
                case OwnerTypes.User:
                case OwnerTypes.Role:
                case OwnerTypes.Structure:
                    this.selectedId = parseInt(this.task.Owner)
                case OwnerTypes.Initiator:
                case OwnerTypes.CustomRole:
                case OwnerTypes.CustomUser:
                    this.selectedId = this.task.Owner
            }
        },

        methods: {

            listOwnerTypes() {
                this.loading = true
                LookupsService.listOwnerTypes()
                    .then(res => {
                        this.owners = res.data.map(x => {
                            let retVal = { name: null, id: null }
                            retVal.name = this.$t(x.name)
                            retVal.id = x.id
                            return retVal
                        })
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },

            reset(event) {
                if (event == OwnerTypes.Initiator) {
                    this.selectedId = 'initiator'
                }
                else {
                    this.selectedId = null
                }
            },

            SaveSettings() {
                if (this.$refs.activityOwners.validate()) {
                    this.$emit('update', this.ownerType, this.selectedId.toString())
                }
            },
        },
    }
</script>
