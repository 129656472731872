<template>
    <v-container fluid>
        <v-row>
            <v-col cols="3" class="ps-0 pt-0">
                <v-card tile class="mx-auto" height="100%">
                    <v-toolbar dense color="primary" dark>
                        <h3>
                            {{ $t('Tasks') }}
                        </h3>
                        <v-spacer />
                        <v-avatar size="36" small color="secondary">
                            <span class="white--text">{{ totalCount }}</span>
                        </v-avatar>
                    </v-toolbar>
                    <v-overlay :absolute="absolute" :value="loading">
                        <v-progress-circular indeterminate color="primary" />
                    </v-overlay>
                    <v-list two-line dense>
                        <v-list-item-group v-model="selected" active-class="primary--text">
                            <template v-for="(item, index) in tasks">
                                <v-list-item :key="item.id" :disabled="itemDisabled" :class="{ claimed:item.claimed}" @click="showData(item)">
                                    <v-list-item-content>
                                        <div v-if="item.claimed" v-text="item.type" />
                                        <h4 v-else v-text="item.type" />

                                        <v-list-item-subtitle :class="!item.claimed ? 'primary--text' : ''">
                                            <h4 class="my-2">{{ item.status }}</h4>
                                        </v-list-item-subtitle>
                                        <v-list-item-title v-text="item.meetingTitle" />
                                        <v-list-item-subtitle class="mt-1">
                                            <v-chip small label color="primary" class="mt-2">{{ item.meetingReference }}</v-chip>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-chip small label color="grey lighten-2"> <b class="black--text"> <v-list-item-action-text v-text="item.id" /></b></v-chip>
                                        <v-icon v-if="item.isDelayed" size="20" color="error">mdi-calendar-clock</v-icon>
                                        <v-icon v-else size="20" color="teal">mdi-calendar-clock</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-divider v-if="index < tasks.length - 1" :key="index" />
                            </template>
                            <div v-if="tasks.length == 0" class="text-center">
                                {{ $t('NoItemsToShow') }}
                            </div>
                        </v-list-item-group>
                    </v-list>
                    <v-divider />
                    <v-pagination v-model="page" class="pagination"
                                  :length="totalPages"
                                  total-visible="5"
                                  next-icon="mdi-menu-right"
                                  prev-icon="mdi-menu-left"
                                  @input="handlePageChange" />
                </v-card>
            </v-col>
            <v-divider vertical />

            <v-col cols="9" class="pt-0">
                <v-card tile height="89vh" class="elevation-0">
                    <v-overlay :absolute="absolute" :value="loadingData">
                        <v-progress-circular indeterminate color="primary" />
                    </v-overlay>
                    <v-card-text v-if="show" class="pa-0">
                        <v-stepper v-model="currentStep">
                            <v-stepper-header>
                                <v-stepper-step v-for="(stepper,index) in stepperItems" :key="stepper.id" :complete="currentStep > index+1" :step="index+1" color="primary" @click="setCurrentStep(index+1)">
                                    {{ stepper.Title }}
                                </v-stepper-step>
                            </v-stepper-header>

                            <v-stepper-items>
                                <v-stepper-content v-for="(step, index) in stepperItems" :key="'step_' + index" :step="index + 1">
                                    <v-card class="mb-12 elevation-0" height="65vh">
                                        <component :is="step.component" v-bind="step.value" />
                                    </v-card>
                                    <v-bottom-navigation grow background-color="primary" dark height="50px">
                                        <v-btn style="height: 50px" v-if="index > 0" color="transparent" @click="previousStep()">
                                            <span>{{ $t('Previous') }}</span>
                                            <v-icon v-if="$i18n.locale =='ar'">mdi-share</v-icon>
                                            <v-icon v-else>mdi-reply</v-icon>
                                        </v-btn>

                                        <v-btn style="height: 50px" v-if="step.hasApprove"  color="transparent" :loading="btnLoading" @click="approve(true)">
                                            <span>{{ $t('Approve') }}</span>
                                            <v-icon>mdi-thumb-up</v-icon>
                                        </v-btn>
                                        <v-btn style="height: 50px" v-if="step.hasReject" color="transparent" :loading="btnLoading" @click="approve(false)">
                                            <span>{{ $t('Reject') }}</span>
                                            <v-icon>mdi-thumb-down</v-icon>
                                        </v-btn>
                                        <v-btn style="height: 50px" v-if="index < stepperItems.length - 1"  color="transparent" @click="nextStep()">
                                            <span>{{ $t('Next') }}</span>
                                            <v-icon v-if="$i18n.locale =='ar'">mdi-reply</v-icon>
                                            <v-icon v-else>mdi-share</v-icon>
                                        </v-btn>
                                    </v-bottom-navigation>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-card-text>
                    <v-card-text v-else class="text-center">
                        <img src="@/assets/images/empty.png" width="50%">
                        <div>{{ $t('NoItemsToShow') }}</div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <CommonDialog v-if="noteDialog" v-model="noteDialog" :fullscreen="false" width="30%" :title="$t('AddNote')" show-action-toolbar>
            <v-card-text>
                <v-textarea v-model="note" :rules="cleanStringRules" outlined :label="$t('Note')" />
            </v-card-text>
            <template v-slot:actions>
                <v-btn :color="buttonColor" small tile class="white--text" @click="send">
                    {{ $t(buttonTitle) }}
                    <v-icon small class="ms-2">{{ buttonIcon }}</v-icon>
                </v-btn>
            </template>
        </CommonDialog>
    </v-container>
</template>
<script>


    import { createSignalRConnection, startSignalRConnection, subscribeToEvent, unsubscribeFromEvent } from '@/plugins/hub/myHubPlugin.js'
    import { TaskTypeEnum } from '@/helpers/constants/enumerations'
    import MeetingTasksService from '@/services/MeetingTasksService.js'
    import MeetingsService from '@/services/MeetingsService.js'

    import MeetingMetaData from '@/components/app/meeting/MeetingMetaData.vue'
    import MeetingAttendees from '@/components/app/meeting/MeetingAttendees.vue'
    import MeetingAttachments from '@/components/app/meeting/MeetingAttachments.vue'
    import MeetingAgenda from '@/components/app/meeting/MeetingAgenda.vue'
    import AssociatedMeetings from '@/components/app/meeting/AssociatedMeetings.vue'
    import FileViewer from '@/components/controls/FileViewer.vue'
    import CommonDialog from '@/components/controls/CommonDialog'
    import AttachmentsService from '@/services/AttachmentsService.js'
    export default {

        components: {
            MeetingAttendees, MeetingAttachments, AssociatedMeetings,
            CommonDialog, MeetingMetaData, MeetingAgenda, FileViewer
        },

        data() {

            return {
                MeetingMetaData: 'MeetingMetaData',
                currentStep: 1,
                show: false,
                noteDialog: false,
                itemDisabled: false,
                loading: false,
                loadingData: false,
                btnLoading: false,
                selected: [2],
                tasks: [],
                taskId: null,
                typeId: null,
                totalCount: 0,
                page: 1,
                totalPages: 0,
                pageSize: 10,
                absolute: true,
                meeting: {
                    title: null,
                    isCommittee: false,
                    committeeId: null,
                    date: null,
                    startTime: null,
                    endTime: null,
                    location: null,
                    notes: null,
                    meetingAttendees: [],
                    meetingAgendas: [],
                    associatedMeetings: [],
                },
                cleanStringRules: [ this.$rules.onlyAlphanumericRule],
                attachments: [],
                note: '',
                buttonColor: '',
                buttonIcon: '',
                approved: true,
                steps: null,
                documentQuery: null,
                meetingMinutesAttachment: null,
                selectedStep: null,
                stepperItems: [



                ]
            }

        },


        watch: {
            steps(val) {
                if (this.currentStep > val) {
                    this.currentStep = val
                }
            },
        },

        created() {
            this.loadTasks()

        },

        mounted() {
            createSignalRConnection()
            startSignalRConnection().then(() => {

                subscribeToEvent('ReceiveChanges', this.loadTasks)
            }).catch((err) => {
                console.log('Error establishing SignalR connection:', err)
            })

        },
        beforeDestroy() {
            unsubscribeFromEvent('ReceiveChanges')
        },

        methods: {

            generateStepperItems() {
                var currentId = 1
                this.stepperItems = []
                if (this.typeId == TaskTypeEnum.InitialMeetingMinutesApproval) {
                    this.stepperItems.push({
                        id: currentId++, Title: this.$t('MeetingMinutes'), component: 'FileViewer',
                        value: { query: this.documentQuery, name: "$t('MeetingMinutes')" }, hasApprove: true, hasReject: true
                    })
                } else if (this.typeId == TaskTypeEnum.SignFinalMeetingMinutes) {
                    this.stepperItems.push({
                        id: currentId++, Title: this.$t('FinalMeetingMinutes'), component: 'FileViewer',
                        value: { query: this.documentQuery, name: "$t('FinalMeetingMinutes')" }, hasApprove: true, hasReject: false
                    })
                }
                if (this.typeId == TaskTypeEnum.MeetingApproval ||
                    this.typeId == TaskTypeEnum.InitialMeetingAgendaApproval ||
                    this.typeId == TaskTypeEnum.InitialMeetingMinutesApproval ||
                    this.typeId == TaskTypeEnum.SignFinalMeetingMinutes) {
                    let notMeetingMinutes = this.typeId != TaskTypeEnum.InitialMeetingMinutesApproval && this.typeId != TaskTypeEnum.SignFinalMeetingMinutes
                    this.stepperItems.push({
                        id: currentId++, Title: this.$t('Data'), component: 'MeetingMetaData',
                        value: { value: this.meeting, viewMode: true }, hasApprove: notMeetingMinutes, hasReject: notMeetingMinutes
                    })
                    this.stepperItems.push({
                        id: currentId++, Title: this.$t('Attendees'), component: 'MeetingAttendees',
                        value: { value: this.meeting.meetingAttendees, viewMode: true }, hasApprove: notMeetingMinutes, hasReject: notMeetingMinutes
                    })

                    this.stepperItems.push({
                        id: currentId++, Title: this.$t('Agenda'), component: 'MeetingAgenda', value: {
                            value: this.meeting.meetingAgendas, viewMode: true,
                            isCommittee: this.meeting.isCommittee, committeeId: this.meeting.committeeId
                        }, hasApprove: notMeetingMinutes, hasReject: notMeetingMinutes
                    })
                    this.stepperItems.push({
                        id: currentId++, Title: this.$t('Attachments'), component: 'MeetingAttachments',
                        value: { meetingId:this.meeting.id,viewMode: true, meetingAgendas: this.meeting.meetingAgendas }, hasApprove: notMeetingMinutes, hasReject: notMeetingMinutes
                    })
                    this.stepperItems.push({
                        id: currentId++, Title: this.$t('AssociatedMeetings'), component: 'AssociatedMeetings', value: {

                            value: this.meeting.associatedMeetings, viewMode: true,
                            label: this.$t('Search'), outlined: true
                        }, hasApprove: notMeetingMinutes, hasReject: notMeetingMinutes
                    })

                }
                this.steps = this.stepperItems.length
                this.show = true

            },
            loadTasks() {
                this.loading = true
                MeetingTasksService.listTasks(this.page, this.pageSize).then((res) => {
                    this.tasks = res.data.data
                    this.totalCount = res.data.total
                    this.totalPages = Math.ceil(res.data.total / this.pageSize)
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            showData(task) {

                if (this.taskId != task.id) {
                    this.claimTask(task)
                    this.loadingData = true
                    let promises = [
                        MeetingsService.loadMeeting(task.meetingId)
                    ]
                    if (task.typeId == TaskTypeEnum.InitialMeetingMinutesApproval) {
                        promises.push(MeetingsService.getMeetingMinutes(task.meetingId))
                    } else if (task.typeId == TaskTypeEnum.SignFinalMeetingMinutes) {
                        promises.push(MeetingsService.getFinalMeetingMinutes(task.meetingId))
                    }

                    Promise.all(promises).then(([meeting, meetingMinutesAttachment]) => {
                        this.meeting = meeting.data
                        this.attachments = meeting.data.attachments
                        this.taskId = task.id
                        this.typeId = task.typeId
                        if (meetingMinutesAttachment) {

                            this.meetingMinutesAttachment = meetingMinutesAttachment.data
                            AttachmentsService.getAttachment(this.meetingMinutesAttachment.id).then(response => {
                                this.documentQuery = response.data
                                this.generateStepperItems()
                            }).catch(() => {
                                this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                            })

                        } else {
                            this.generateStepperItems()
                        }


                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loadingData = false
                    })


                }

            },

            handlePageChange(value) {
                this.page = value
                this.loadTasks()
            },

            claimTask(task) {
                task.claimed = true
                MeetingTasksService.claimTask(task.id).then(res => {

                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            nextStep() {
                this.currentStep++
            },
            previousStep() {
                this.currentStep--

            },
            setCurrentStep(newValue) {

                this.currentStep = newValue

            },
            async approve(approved) {
                this.buttonTitle = approved ? this.$t('Approve') : this.$t('Reject')
                this.buttonColor = approved ? 'primary' : 'error'
                this.buttonIcon = approved ? 'mdi-thumb-up' : 'mdi-thumb-down'
                this.approved = approved
                this.noteDialog = true
            },

            send() {
                this.btnLoading = true
                MeetingTasksService.approveTask(this.taskId, this.approved, String(this.note ?? '')).then(res => {
                    this.noteDialog = false
                    if (res.success) {
                        this.loadTasks()
                        this.note = null
                        this.show = false
                    }
                    else {
                        this.$root.$notification(res.message, 'error')
                    }

                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.btnLoading = false
                })
            }
        },
    }
</script>
<style scoped>
    .pagination {
        margin: 0 auto;
        position: absolute;
        bottom: 0
    }

    .claimed {
        background: !important
    }
</style>