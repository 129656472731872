<template>
    <div>
        <v-toolbar>
            <v-row justify="end">
                <v-btn icon color="primary" elevation="2" rounded class="me-3" dense @click="goBack">
                    <v-icon>mdi-arrow-right-circle</v-icon>
                </v-btn>
            </v-row>
        </v-toolbar>
        <RecommendationData :id="id" class="mt-3" :view-mode="viewMode" />
        <RecommendationNotes :id="id" class="mt-3" :view-mode="viewMode" />
        <RecommendationAttachments :id="id" class="mt-3" :view-mode="viewMode" />
    </div>
</template>

<script>
    import RecommendationNotes from '@/components/app/meeting/Recommendations/RecommendationNotes'
    import RecommendationData from '@/components/app/meeting/Recommendations/RecommendationData'
    import RecommendationAttachments from '@/components/app/meeting/Recommendations/RecommendationAttachments'

    export default ({
        components: {
            RecommendationNotes, RecommendationData, RecommendationAttachments
        },

        data() {
            return {
                id: parseInt(this.$route.params.id),
                viewMode : this.$route.params.viewMode
            }
        },

        computed: {
            
        },
        methods: {
            goBack() {
                this.$router.go(-1)
            }

        },
    })


</script>

<style>
</style>