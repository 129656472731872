import { mainApiAxios as axios } from '@/plugins/axios'

export default {

    listWorkflows() {
        return axios.get('caseEngine/apps')
    },

    getProcess(workflowId) {
        return axios.get(`caseEngine/${workflowId}`)
    },

    publish(workflowObjectString, workflowId, newVersion) {
        return axios.post(`caseEngine/${workflowId}/publish?newVersion=${newVersion}`, workflowObjectString)
    },
}