<template>
    <div class="login-main-container">
        <div class="login-center-container">
            <div class="text-center mt-15">
                <img src="../assets/images/logo.png" alt="" class="mb-0 logo">
            </div>
            <v-card :loading="optionsLoading"
                    elevation="3"
                    class="mt-7 pb-7 ps-6 pe-6">
                <v-container>
                    <div class="text-center pt-4 pb-4">
                        <h3 class="tile">
                            {{ $t("ApplicationName") }}
                        </h3>
                    </div>
                    <v-layout v-if="optionsLoading" column>
                        <v-progress-circular :rotate="360"
                                             size="50"
                                             width="8"
                                             indeterminate
                                             color="primary">
                        </v-progress-circular>
                    </v-layout>
                    <LoginForm v-else-if="showLoginForm" column>
                        <v-progress-circular :rotate="360"
                                             size="50"
                                             width="8"
                                             indeterminate
                                             color="primary">
                        </v-progress-circular>
                    </LoginForm>
                    <ExternalLoginForm v-else-if="showExternalLoginForm" :token-name="externalTokenName" column>
                        <v-progress-circular :rotate="360"
                                             size="50"
                                             width="8"
                                             indeterminate
                                             color="primary">
                        </v-progress-circular>
                    </ExternalLoginForm>
                    <v-layout v-else-if="showSelectLoginOption" column>
                        <v-btn class="white--text mb-5"
                               color="primary"
                               block
                               @click="selectExternalLogin">
                            {{ $t("ExternalLogin") }}
                        </v-btn>
                        <v-btn class="white--text"
                               color="primary"
                               block
                               @click="selectSystemLogin">
                            {{ $t("SystemLogin") }}
                        </v-btn>
                    </v-layout>
                    <v-layout v-else column>
                        <div class="text-center mt-2">
                            <img src="@/assets/images/locker.png" alt="" class="mb-1 logo">
                        </div>
                        <h3 class="tile text-center">
                            {{ $t("NoLoginOptionsEnabled") }}
                        </h3>
                    </v-layout>
                    <v-btn v-if="showLoginOptions" class="white--text mt-3"
                           color="primary"
                           block
                           @click="showSelectLoginOptions">
                        {{ $t("Back") }}
                    </v-btn>
                </v-container>
            </v-card>
        </div>
    </div>
</template>
<script>
    import AuthService from '@/services/AuthService'
    import LoginForm from '@/components/app/Auth/LoginForm.vue'
    import ExternalLoginForm from '@/components/app/Auth/ExternalLoginForm.vue'
    export default {
        components: {
            LoginForm,
            ExternalLoginForm
        },
        data() {
            return {
                user: {
                    fullNameAr: '',
                    fullNameEn: '',
                    email: '',
                    mobile: '',
                    accessToken:''
                },
                optionsLoading: false,

                validateUserLoading: false,
                authorized: false,
                required: [
                    v => !!v || this.$t('RequiredField')
                ],
                enabledLogin: false,
                showLoginForm: false,

                enableExternalLogin: false,
                externalTokenName:null,
                showExternalLoginForm: false,

                showSelectLoginOption:false,
                showErrorForm:false,
            }
        },
        computed: {
            showLoginOptions() {
                return this.enableExternalLogin && this.enabledLogin && !this.showSelectLoginOption
            }
        },
        created() {
            this.loadOptions()
        },
        methods: {
            loadOptions() {

                this.optionsLoading = true
                AuthService.getLoginOptions().then(res => {
                    this.enabledLogin = res.data.enableLogin
                    this.enableExternalLogin = res.data.enableExternalLogin
                    this.externalTokenName = res.data.externalTokenName
                    if (this.enabledLogin && this.enableExternalLogin) { //both login options enabled(make user select login method)
                        this.showSelectLoginOption = true
                        this.showExternalLoginForm = false
                        this.showLoginForm = false

                    } else if (this.enabledLogin && !this.enableExternalLogin) {
                        this.showSelectLoginOption = false
                        this.showExternalLoginForm = false
                        this.showLoginForm = true

                    }
                    else if (!this.enabledLogin && this.enableExternalLogin) {
                        this.showSelectLoginOption = false
                        this.showExternalLoginForm = true
                        this.showLoginForm = false

                    } else {//all login options disabled (this case shouldnt occured and one option must be enabled in the db)
                        this.showErrorForm=true
                    }
                       
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.optionsLoading = false

                })
            },
            selectSystemLogin() {
                this.showSelectLoginOption = false
                this.showExternalLoginForm = false
                this.showLoginForm = true
            },
            selectExternalLogin() {
                this.showSelectLoginOption = false
                this.showExternalLoginForm = true
                this.showLoginForm = false
            },
            showSelectLoginOptions() {
                this.showSelectLoginOption = true
                this.showExternalLoginForm = false
                this.showLoginForm = false
            }
            
        }
    }
</script>
<style scoped>

    .login-main-container {
        height: 100%;
        overflow: hidden;
        width: 100%;
        background: linear-gradient(90deg, var(--v-primary-base) 0%, var(--v-primary-base) 35%, lightgrey);
    }

    .login-center-container {
        width: auto;
        margin: 0 auto;
        max-width: 400px
    }

    .logo {
        max-height: 175px
    }
</style>