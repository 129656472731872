<template>
    <div style="height: 400px;">
        <v-data-table class="elevated-table bordered-table"
                      :headers="headers"
                      dense
                      item-key="id"
                      style="height: 400px; overflow-y: auto; display: flex; flex-direction: column;"
                      :items="approvals">
            <template v-slot:[`item.approveDate`]="{ item }">
                {{ item.approveDate| formatDateWithTimeAr }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import MeetingsService from '@/services/MeetingsService'


    export default({
        props: {
            meetingId: {
                type: Number,
                default: null,
            },
        },
        data() {
            return {
                approvals: [],
                loading: false,
                headers: [
                    { text: this.$t('Name'), value: 'userName' },
                    { text: this.$t('Status'), value: 'statusName' },
                    { text: this.$t('ApproveDate'), value: 'approveDate' },
                ]
            }
        },
        created() {
            this.loadMeetingApprovals()
        },
        methods: {
            loadMeetingApprovals() {
                this.loading = true
                MeetingsService.loadMeetingApprovals(this.meetingId).then((res) => {
                    if (res.data) {
                        this.approvals = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            }
        },
    })
</script>

<style>
    .bordered-table {
        border: 1px solid #ccc;
    }

    .v-data-table__wrapper {
        flex: 1 1 auto;
    }
</style>