<template>
    <div>
        <NotRelactedByComitteeMeetings class="mb-4" />
    </div>
</template>

<script>
    import NotRelactedByComitteeMeetings from '@/components/app/GeneralInfo/NotRelatedByComitteeMeetings.vue'
    export default {
        components: {
            NotRelactedByComitteeMeetings
        },
    }
</script>

<style>
</style>