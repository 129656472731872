
const sideBar = () => {

    const menus = [
        {
            id: 'Home',
            route: '/',
            title: '',
            icon: 'mdi-home'
        },
        {
            id: 'Dashboard',
            route: 'dashboard',
            title: 'Dashboard',
            icon: 'mdi-chart-line'
        },
        {
            id: 'MMSTasks',
            route: 'tasks',
            title: 'Tasks',
            icon: 'mdi-clipboard-account',
            active : false
        },
       
        {
            id: 'Chat',
            route: 'chat',
            title: 'Chat',
            icon: 'mdi-message-text',

        },
        {
            id: 'Meetings',
            title: 'MeetingManagement',
            icon: 'mdi-monitor-account',

            children: [
                {
                    id: 'AddMeeting',
                    route: 'addMeeting',
                    title: 'AddMeeting',
                    icon: 'mdi-calendar-plus-outline',
                },
                {
                    id: 'Meetings',
                    route: 'meetings',
                    title: 'Meetings',
                    icon: 'mdi-clipboard-list-outline',
                },
                {
                    id: 'DraftMeetings',
                    route: 'draftMeetings',
                    title: 'DraftMeetings',
                    icon: 'mdi-calendar-edit-outline',
                },
                {
                    id: 'NotLinkedMeeting',
                    route: 'not-related-meetings',
                    title: 'NotRealatedComitteesMeetings',
                    icon: 'mdi-calendar-edit-outline',
                },
                
            ]
        },
        {
            id: 'Recommendations',
            route: 'recommendations',
            title: 'AgendaRecommendations',
            icon: 'mdi-checkbox-marked-outline',
        },
        {
            id: 'CouncilsAndCommitteesGeneralInfo',
            route: 'council-comittee-general-info',
            title: 'CouncilsAndCommitteesData',
            icon: 'mdi-account-multiple-outline',
        },
        {
            id: 'NotRelatedByComitteeMeetings',
            route: 'unmapped-committee-meetings',
            title: 'NotRealatedComitteesMeetings',
            icon: 'mdi-account-multiple-outline',
        },
        {
            id: 'SystemAdmin',
            title: 'SystemAdmin',
            icon: 'mdi-cog',
           
            children: [
                {
                    id: 'ManageOrganization',
                    route: 'manage-organization',
                    title: 'ManageOrganization'
                },
                {
                    id: 'SystemSettings',
                    route: 'system-settings',
                    title: 'SystemSettings'
                },
                {
                    id: 'SystemPermissions',
                    route: 'system-permissions',
                    title: 'SystemPermissions'
                },
                {
                    id: 'VotingTypesSettings',
                    route: 'voting-types',
                    title: 'VotingTypesSettings'
                },

                {
                    id: 'Dictionary',
                    route: 'dictionary',
                    title: 'Dictionary'
                },
                {
                    id: 'CouncilsAndCommittees',
                    route: 'councils-committees',
                    title: 'CouncilsAndCommittees'
                },
                {
                    id: 'Roles',
                    route: 'roles',
                    title: 'Roles'
                },
                {
                    id: 'CommitteeRoles',
                    route: 'committeeRoles',
                    title: 'CommitteeRoles'
                },
                {
                    id: 'QuestionDropDown',
                    route: 'QuestionDropDown',
                    title: 'QuestionDropDown'
                },

            ]
        },
        {
            id: 'Reports',
            title: 'Reports',
            icon: 'mdi-file-chart-outline',

            children: [
                {
                    id: 'ComitteesSummaryReport',
                    route: 'comittees-summary-report',
                    title: 'CouncilsAndCommittees',
                    icon: 'mdi-account-multiple-outline',
                },
                {
                    id: 'AttendanceReport',
                    route: 'attendance-report',
                    title: 'AttendanceReport',
                    icon: 'mdi-account-clock',
                },

            ]
        },
    ]



    return menus

}

export default sideBar

