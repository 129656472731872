<template>
    <div>
        <v-toolbar color="primary" dense flat>
            <v-toolbar-title class="white--text c-text-h5">
                {{ $t('MeetingSummary') }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn dark icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card class="pa-2 pt-4">
            <TextEditor v-model="summaryContent" :editable="true" :required="true" maxHeight="30rem" />
            <v-card-actions class="justify-center ">
                <v-btn class="mb-3" color="primary" dark @click="saveMeetingSummary">
                    {{ $t('Save') }}
                </v-btn>
                <v-btn class="mb-3" color="error" dark @click="close">
                    {{ $t('Cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import MeetingsService from '@/services/MeetingsService'
    import ConfirmDialog from '@/components/controls/ConfirmDialog.vue'
    import TextEditor from '@/components/controls/TextEditor.vue'
    import { mapGetters } from 'vuex'

    export default ({
        components: {
            TextEditor
        },

        props: {
            meetingId: {
                type: Number,
                default: null
            },
        },
        data() {
            return {
                loading: false,
                summaryContent:null,
            }
        },
        computed: {
        },
        watch: {
        },

        created() {
            if(this.meetingId){
                this.loadMeetingSummary()
               }
        },

        mounted() {
           
        },
        destroyed() {
        },
        methods: {

            loadMeetingSummary(){
                   MeetingsService.getMeetingSummary(this.meetingId).then((res) => {
                    if (res.success) {
                        this.summaryContent = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                        this.loading = false
                })
            },
             saveMeetingSummary() {

                     this.loading = true
                     MeetingsService.addOrUpdateMeetingSummary(this.meetingId,this.summaryContent).then((res) => {

                         if (res.success) {
                              this.$root.$notification(this.$t('SavedSuccess'), 'success')
                              this.close()
                         } else {
                               this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                         }

                     }).catch(() => {
                         this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                     }).finally(() => {
                         this.loading = false
                     })
             },

            close() {
                this.$emit('close')
            }
        },
    })
</script>

<style scoped>
   
</style>