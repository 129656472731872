import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import ar from 'vuetify/src/locale/ar.ts'
import en from 'vuetify/src/locale/en.ts'
import store from '@/plugins/store/index.js'

Vue.use(Vuetify)

const defaultLanguage = store.getters['user/language']
const themOpt = store.getters['app/themeOptions']


export default new Vuetify({

    rtl: defaultLanguage == 'ar',
    theme: {
        light: true,
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: themOpt ? themOpt.data.primaryColor : '#04663e',
                secondary: themOpt ? themOpt.data.secondaryColor :'#85BD48' ,
                navigation: themOpt ? themOpt.data.navigationColor : '#e6e5e4',
                accent: '#8c9eff',
                error: '#b71c1c',
                success: '#00897b',
            },
        },
    },
    lang: {
        locales: { ar, en },
        current: defaultLanguage,
    },

})
