import AddMeeting from '@/pages/Meetings/AddMeeting.vue'
import DraftMeetings from '@/pages/Meetings/DraftMeetings.vue'
import MyMeetings from '@/pages/Meetings/MyMeetings.vue'
import Recommendations from '@/pages/Recommendations/Recommendations.vue'
import RecommendationDetails from '@/pages/Recommendations/RecommendationDetails.vue'
import UnmappedCommitteeMeetings from '@/pages/Meetings/UnmappedCommitteeMeetings.vue'

const baseUrl = process.env.VUE_APP_BASE_URL 

export default [
	{
		name: 'addMeeting',
		path: `${baseUrl}/addMeeting/:id?`,
		component: AddMeeting,
		meta: {
			secure: true
		}
	},
	{
		name: 'draftMeetings',
		path: `${baseUrl}/draftMeetings`,
		component: DraftMeetings,
		meta: {
			secure: true
		}
	},
	{
		name: 'meetings',
		path: `${baseUrl}/meetings`,
		component: MyMeetings,
		meta: {
			secure: true
		}
	},
	{
		name: 'not-related-meetings',
		path: `${baseUrl}/not-related-meetings`,
		component: UnmappedCommitteeMeetings,
		meta: {
			secure: true
		}
	},
	{
		name: 'recommendations',
		path: `${baseUrl}/recommendations`,
		component: Recommendations,
		meta: {
			secure: true
		}
	},
	{
		name: 'recommendation-details', 
		path: `${baseUrl}/recommendation-details/:id`,
		component: RecommendationDetails,
		meta: {
			secure: true
		}
	},
]