<template>
    <v-form v-if="!isPosted" ref="dynForm" v-model="valid" class="dynamic-form" lazy-validation>
        <v-card tile :loading="loading" fill-height color="white">
            <div class="card-title primary--text">New Process</div>
            <v-divider />
            <v-card-text>
                <v-row class="mt-3">
                    <v-col v-for="control in formControls" :key="control.id" cols="12" :md="control.col">
                        <v-card-title v-if="control.inputType === 'Header'" class="py-0 primary--text"><b>{{ control.fieldName }}</b></v-card-title>
                        <v-text-field v-if="control.inputType === 'Text'" v-model="control.fieldValue[0]" outlined tile :hint="control.helpText" persistent-hint :maxlength="control.maxChar"
                                      :rules="control.required ? nameRules : []" dense prepend-icon="mdi-text-shadow" :counter="control.maxChar" :label="control.fieldName" clearable />

                        <v-text-field v-if="control.inputType === 'Email'" v-model="control.fieldValue[0]" outlined :hint="control.helpText" persistent-hint :maxlength="control.maxChar"
                                      :rules="control.required ? emailRules : emailRules" dense prepend-icon="mdi-email" :counter="control.maxChar" :label="control.fieldName" clearable />

                        <v-text-field v-if="control.inputType === 'Number'" v-model="control.fieldValue[0]" outlined type="number" :hint="control.helpText" persistent-hint :maxlength="control.maxChar"
                                      :rules="control.required ? nameRules : []" dense prepend-icon="mdi-numeric" :counter="control.maxChar" :label="control.fieldName" clearable />

                        <v-select v-if="control.inputType === 'DropDown'" v-model="control.fieldValue[0]" outlined dense :items="control.options" :label="control.fieldName" clearable
                                  item-text="label" item-value="value" :rules="control.required ? nameRules : []"
                                  prepend-icon="mdi-chevron-down" persistent-hint :hint="control.helpText" />

                        <v-textarea v-if="control.inputType === 'TextArea'" v-model="control.fieldValue[0]" outlined :label="control.fieldName" :rules="control.required ? nameRules : []"
                                    :maxlength="control.maxChar" :hint="control.helpText" persistent-hint clearable prepend-icon="mdi-format-align-justify" />
                        <span v-if="control.inputType === 'Checkbox'">
                            <span v-if="control.inputType === 'Checkbox'" class="v-label">{{ control.fieldName }}</span>
                            <v-checkbox v-for="option in control.options" :key="option.value" v-model="control.fieldValue" :rules="control.required ? selectRules : []" dense :label="option.label" :value="option.value" />
                        </span>
                        <v-radio-group v-if="control.inputType === 'Radio'" v-model="control.fieldValue" dense :rules="control.required ? selectRules : []" :label="control.fieldName" class="text-start mt-0" :row="control.options.length <= 2">
                            <v-radio v-for="option in control.options" :key="option.value" :label="option.label" :value="option.value" />
                        </v-radio-group>

                        <v-switch v-if="control.inputType === 'Boolean'" v-model="control.fieldValue[0]" :value="control.fieldValue[0]" :label="control.fieldName" />

                        <file-upload v-if="control.inputType === 'Upload'" ref="uploader" :help-text="control.helpText" :control-id="control.id" :attachments="control.attachments" :multiple="control.multiple" :accept="control.accept.toString()" :max-size="control.maxFileSize" :max="control.max" :file-type="control.fileType" :min="control.min" />

                        <DatePicker v-if="control.inputType === 'Date'" v-model="control.fieldValue[0]" :outlined="true" :dense="true" :label="control.fieldName" :rules="control.required ? nameRules : []" />


                        <v-combobox v-if="control.inputType === 'Masters' && control.controlType == 'dropdown'" outlined :rules="control.required ? nameRules : []"
                                    prepend-icon="mdi-database"
                                    :loading="loading" dense clearable
                                    hide-no-data
                                    :label="control.fieldName"
                                    :items="control.options"
                                    item-value="value"
                                    item-text="label" />
</v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-bottom-navigation background-color="primary" dark>
                    <v-btn class="white--text" :loading="btnLoading" @click="startProcess">
                        <span class="white--text">{{ $t('StartProcess') }}</span>
                        <v-icon class="white--text">mdi-power</v-icon>
                    </v-btn>
                </v-bottom-navigation>
            </v-card-actions>
        </v-card>
</v-form>

    <v-card v-else tile width="30%" class="mx-auto my-12">
        <v-card-text class="pb-0">
            <h2 class="text-overline mb-3">{{ $t('resources_success_start_process') }}</h2>
            <p class="text-overline">
                <b>{{ $t('process-number') }}</b>
                : {{ response != null ? response.processId : '' }}
            </p>
            <p class="text-overline">
                <b>{{ $t('reference-number') }}</b>
                : {{ response != null ? response.referenceNumber : '' }}
            </p>
        </v-card-text>
        <v-card-actions class="pt-0">
            <v-btn text color="teal accent-4" to="/">{{ $t('resources_homepage') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import ProcessesService from '@/services/ProcessesService'
    import FileUpload from '@/components/controls/FileUpload.vue'
    import DatePicker from '@/components/controls/DatePicker.vue'


    export default {
        components: {
            FileUpload, DatePicker
        },
        directives: {
        },
        filters: {
        },
        props: {
        },
        data() {
            return {
                loading: false,
                formControls: [],
                processName: '',
                processId: null,
                response: null,
                valid: false,
                btnLoading: false,
                isPosted: false,
                dateMenu: false,
                nameRules: [
                    v => !!v || this.$t('RequiredField')
                ],
                selectRules: [(v) => v.length > 0 || this.$t('RequiredField')],
                emailRules: [
                    v => !!v || this.$t('RequiredField'), v => /.+@.+\..+/.test(v) || this.$t('InvalidEmail'),
                ],
            }
        },
        computed: {

        },
        watch: {
        },
        beforeCreate() {
        },
        created() {
            this.listInitiationData()
        },
        beforeMount() {
        },
        mounted() {
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeDestroy() {
        },
        destroyed() {
        },
        methods: {

            listInitiationData() {
                ProcessesService.listInitiationData(this.$route.params.workflowId).then((res) => {
                    if (res.data) {
                        this.formControls = res.data
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            startProcess() {
                if (this.$refs.dynForm.validate()) {
                    this.btnLoading = true

                    ProcessesService.createProcess(this.wrapForm()).then((res) => {
                        this.isPosted = true
                        this.$root.$notification(this.$t('WorkflowStarted'), 'success')

                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })

                }
            },

            wrapForm() {
                let formdata = new FormData()
                formdata.append('objectData', JSON.stringify({
                    formControls: this.formControls
                }))
                formdata.append('processTypeId', this.$route.params.workflowId)
                return formdata
            },
        },
    }
</script>

<style>
    .dynamic-form .v-label { font-weight: 600 !important; font-size: 14px !important }
</style>