import { mainApiAxios as axios } from '@/plugins/axios'

export default {

    listTasks(page, pageSize) {
        return axios.get(`tasks/meeting?page=${page}&pageSize=${pageSize}`)
    },

    claimTask(taskId) {
        return axios.post(`tasks/${taskId}/meeting/claim`)
    },

    approveTask(taskId, approved, note) {
        return axios.post(`tasks/${taskId}/meeting/approve?approved=${approved}`, note)
    }
}