import Vue from 'vue'
import Router from 'vue-router'
import store from '@/plugins/store/index.js'
import { loadTranslation } from '@/plugins/i18n/index.js'

import mainRoutes from './routes/mainRoutes.js'
import adminRoutes from './routes/adminRoutes.js'
import processes from './routes/processes.js'
import meetings from './routes/meetings.js'
import tasks from './routes/tasks.js'
import reports from './routes/reportsRoutes.js'

Vue.use(Router)

const router = new Router({
	mode: 'hash',
	routes: [
		...processes,
		...meetings,
		...tasks,
		...adminRoutes,
		...mainRoutes,
		...reports,
	]
})

router.beforeEach((to, from, next) => {

	store.dispatch('app/translationFailed', false)
	loadTranslation()
		.then(() => {
			//if user is authenticated and tries to access login
			if (to.name == 'login' && store.getters['user/isAuthenticated']) {
				next({ name: 'home' })
			}
			else {

				//check if page is for authenticated users only
				if (to.meta && to.meta.secure && !store.getters['user/isAuthenticated']) {
					next({ name: 'login' })
				}
				else {
					//this is for pages that doesnt require authentication
					next()
				}
			}

		})
		.catch(() => {
			console.log('failed to load translations')
			store.dispatch('app/translationFailed', true)
		})
})

export default router