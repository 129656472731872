<template>
    <div v-if="loading" class="loader-container">
        <v-progress-circular :rotate="360"
                             size="100"
                             width="10"
                             indeterminate
                             color="primary">
        </v-progress-circular>
        <span class="loading-text">{{ loadingText }}</span>
    </div>
    <FileViewer v-else-if="validParam" :query="documentQuery" name="Attachment" />
    <div v-else> No Data</div>
</template>

<script>
    import FileViewer from '@/components/controls/FileViewer.vue'
    import store from '@/plugins/store'
    import AttachmentsService from '@/services/AttachmentsService.js'

    export default ({
        components: {
            FileViewer
        },
        data() {
            return {
                loading:false
            }
        },
        computed: {
            attachmentId() {
                return this.$route.query.attachmentId
            },
            token() {
                return this.$route.query.token
            },
            validParam() {
                return this.attachmentId && this.token
            }
        },
        watch: {
        },
        beforeCreate() {
        },
        created() {

            if (this.validParam) {
                store.dispatch('user/updateToken', this.token)

                this.loadAttachment()
            }
        },
        methods: {
            loadAttachment() {
                this.loading=true
                AttachmentsService.getAttachment(this.attachmentId).then(response => {
                    this.documentQuery = response.data

                    this.loading=false

                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
        },
    })
</script>

<style>
</style>