import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/plugins/store/index.js'
import TranslationsService from '@/services/TranslationsService.js'

Vue.use(VueI18n)

const i18n = new VueI18n({
	locale: store.getters['user/language'],
	fallbackLocale: store.getters['user/language'],
	messages: null,
	silentTranslationWarn: true
})

const loadTranslation = function () {

	const currentLanguage = store.getters['user/language']

	if (Object.keys(i18n.getLocaleMessage(currentLanguage)).length !== 0) {
		return Promise.resolve()
	}

	return TranslationsService.listTranslations().then(res => {
		if (res.success) {
			i18n.setLocaleMessage('ar', res.data.ar)
			i18n.setLocaleMessage('en', res.data.en)
		}
	})
}

const clearTranslation = function () {
	i18n.setLocaleMessage('ar', [])
	i18n.setLocaleMessage('en', [])
}

const isReady = function () {
	const currentLanguage = store.getters['user/language']

	return Object.keys(i18n.getLocaleMessage(currentLanguage)).length !== 0
}

export { i18n, loadTranslation, isReady, clearTranslation }