import Dictionary from '@/pages/Admin/Dictionary.vue'
import ManageOrganization from '@/pages/Admin/ManageOrganization.vue'
import ManagePrivileges from '@/pages/Admin/ManagePrivileges.vue'
import DataSources from '@/pages/Admin/DataSources.vue'
import WorkflowDesigner from '@/pages/Admin/Case/WorkflowDesigner.vue'
import Apps from '@/pages/Admin/Case/Apps.vue'
import Roles from '@/pages/Admin/Roles.vue'
import CommitteeRoles from '@/pages/Admin/CommitteeRoles.vue'
import AuditTrail from '@/pages/Admin/AuditTrail.vue'
import SystemSettings from '@/pages/Admin/SystemSettings.vue'
import CouncilsAndCommittees from '@/pages/Admin/CouncilsAndCommittees.vue'
import VotingTypeSettings from '@/pages/Admin/VotingTypeSettings.vue'
import SystemPermissions from '@/pages/Admin/SystemPermissions.vue'

const baseUrl = process.env.VUE_APP_BASE_URL


export default [

    {
        name: 'manage-organization',
        path: `${baseUrl}/manage-organization`,
        component: ManageOrganization,
        meta: {
            secure: true
        }
    },
    {
        name: 'system-permissions',
        path: `${baseUrl}/system-permissions`,
        component: SystemPermissions,
        meta: {
            secure: true
        }
    },
    {
        name: 'dictionary',
        path: `${baseUrl}/dictionary`,
        component: Dictionary,
        meta: {
            secure: true
        }
    },
    {
        name: 'voting-types',
        path: `${baseUrl}/voting-types`,
        component: VotingTypeSettings,
        meta: {
            secure: true
        }
    },
    {
        name: 'manage-privileges',
        path: `${baseUrl}/manage-privileges`,
        component: ManagePrivileges,
        meta: {
            secure: true
        }
    },

    {
        name: 'data-sources',
        path: `${baseUrl}/data-sources`,
        component: DataSources,
        meta: {
            secure: true
        }
    },
    {
        name: 'audit-trail',
        path: `${baseUrl}/audit-trail`,
        component: AuditTrail,
        meta: {
            secure: true
        }
    },
    {
        name: 'system-settings',
        path: `${baseUrl}/system-settings`,
        component: SystemSettings,
        meta: {
            secure: true
        }
    },

    {
        name: 'councils-committees',
        path: `${baseUrl}/councils-committees`,
        component: CouncilsAndCommittees,
        meta: {
            secure: true
        }
    },

    {
        name: 'workflow-designer',
        path: `${baseUrl}/workflow-designer`,
        component: WorkflowDesigner,
        meta: {
            secure: true
        }
    },
    {
        name: 'apps',
        path: `${baseUrl}/apps`,
        component: Apps,
        meta: {
            secure: true
        }
    },

    {
        name: 'roles',
        path: `${baseUrl}/roles`,
        component: Roles,
        meta: {
            secure: true
        }
    },

    {
        name: 'committeeRoles',
        path: `${baseUrl}/committeeRoles`,
        component: CommitteeRoles,
        meta: {
            secure: true
        }
    },
]