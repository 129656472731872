<template>
    <v-card class="rounded-lg" style="display: flex; flex-direction: column;">
        <h3 class="pa-2 mb-1">{{ $t('Chat') }}</h3>
        <v-divider class="my-0" color="#fefefe" />
        <v-card-text ref="messagesContainer" class="messages-container" style="flex-grow: 1; overflow-y: auto;">
            <div v-for="(msg, index) in messages" :key="index" :class="{ 'message me': msg.me, 'message': !msg.me }">
                <div :class="['message-wrapper',{ 'me': msg.me, 'not-me': !msg.me }]">

                    <v-chip :color="msg.me ? 'primary' : '#e8f6f4'" :dark="msg.me" class="mb-1">
                        {{ msg.messageText }}
                    </v-chip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-avatar size="30" class="avatar" v-bind="attrs" v-on="on">
                                <v-img v-if="msg.hasProfilePicture" :src="getProfileImageUrl(msg.userId)" />
                                <v-icon v-else large>mdi-account-circle</v-icon>
                            </v-avatar>
                        </template>
                        <span>{{ msg.userName }}</span>
                    </v-tooltip>

                </div>
                <sub class="black--text mt-1 mb-4" style="font-size: 0.7rem;">
                    {{ msg.sentAt |formatDateWithTimeGregorian}}
                </sub>
            </div>
        </v-card-text>
        <v-card v-if="!viewMode">
            <v-form ref="chatForm" v-model="valid" lazy-validation>
                <v-card-text class="py-4 d-flex align-center">
                    <v-textarea v-model="content"
                                :placeholder="$t('TypeMessage')"
                                no-details
                                auto-grow
                                outlined
                                rows="1"
                                :rules="cleanRequiredStringRules"
                                dense
                                class="message-input"
                                @keyup.enter="sendMessage"
                                hide-details />
                    <v-btn icon
                           color="primary"
                           :disabled="!content.trim()"
                           class="send-button-inside"
                           dense
                           @click="sendMessage">
                        <v-icon>mdi-send</v-icon>
                    </v-btn>
                </v-card-text>
            </v-form>
        </v-card>
    </v-card>
</template>

<script>
    import ChatsService from '@/services/ChatsService'
    import UsersService from '@/services/UsersService.js'
    import { createSignalRConnection, startSignalRConnection, subscribeToEvent, unsubscribeFromEvent } from '@/plugins/hub/myHubPlugin.js'
    import { mapGetters } from 'vuex'
    import { getUserImage } from '@/helpers/common.js'
    export default ({
        props: {
            meetingId: {
                type: Number,
                default: null
            },
            viewMode: {
                type: Boolean,
            }
        },
        data() {
            return {
                valid: null,
                messages: [

                ],
                cleanRequiredStringRules: [this.$rules.requiredRule, this.$rules.onlyAlphanumericRule],
                content: '',

            }
        },
        computed: {
            ...mapGetters('user', ['loggedInUser', 'language']),

            isArabicLanguage() {
                return this.language == 'ar'
            },
            currentUserName() {
                return this.isArabicLanguage ? this.loggedInUser?.fullnameAr : this.loggedInUser?.fullnameEn
            },

        },
        created() {
            if(!this.viewMode)this.loadChat()
        },
        beforeDestroy() {
            unsubscribeFromEvent(`MeetingChatChanges${this.meetingId}`)
        },

        mounted() {
            createSignalRConnection()
            startSignalRConnection().then(() => {
                subscribeToEvent(`MeetingChatChanges${this.meetingId}`, this.loadChat)
            }).catch((err) => {
                console.log('Error establishing SignalR connection:', err)
            })

        },
        methods: {
            getProfileImageUrl(userId) {
                return getUserImage(userId)
            },
            sendMessage() {

                if (!this.$refs.chatForm.validate()) {
                    return
                }
                this.messages.push({ id: 33, initials: 'M M', messageText: this.content, me: true, sentAt: '' })
                this.scrollToBottom()
                let formData = {
                    'meetingId': this.meetingId,
                    'messageText': this.content,
                }
                ChatsService.sendMeetingMessage(formData).then(res => {

                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                    this.content = ''

                })
            },
            scrollToBottom() {
                this.$nextTick(() => {
                    const container = this.$refs.messagesContainer
                    if (container) {
                        container.scrollTop = container.scrollHeight
                    }
                })
            },
            loadChat() {
                this.messages = []
                ChatsService.loadMeetingChatMessages(this.meetingId).then(res => {
                    if (res.data) {
                        this.messages = res.data
                        this.scrollToBottom()
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })

            },
        },
    })
</script>

<style scoped>

    .messages-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: auto;
    }

    .message {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 1rem;
    }

        .message.me {
            align-items: flex-start;
        }

    .message-wrapper {
        display: flex;
        align-items: center;
    }

        .message-wrapper.me {
            flex-direction: row-reverse;
        }

    .avatar {
        margin-right: 0.5rem;
    }

    .message-wrapper.me .avatar {
        margin-left: 0.5rem;
        margin-right: 0;
    }

    .v-chip {
        max-width: 80%;
    }
</style>