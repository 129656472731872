<template>
    <v-breadcrumbs>
        <v-breadcrumbs-item :class="{ 'selected': parents.length==0, 'not-selected': parents.length >0 }" @click="first">
            <h3> {{ $t('CouncilsAndCommitteesData') }}</h3>
        </v-breadcrumbs-item>
    <template v-for="(comittee,index) in parents">
        <div :key="'breadcrumb'+index">
            <v-icon small class="ps-2 pe-2">mdi-chevron-left</v-icon>
            <v-breadcrumbs-item :class="{ 'selected': index==parents.length-1, 'not-selected': index!=parents.length-1 }" @click="second(comittee,index)">
                <h3>  {{ comittee.name }}</h3>
            </v-breadcrumbs-item>
        </div>
</template>
    </v-breadcrumbs>
</template>

<script>
    export default {
        props: {
            parents: {
                type: Array,
                default:()=> []
            },
        },
        methods: {
            first() {
                if (this.$route.path !== '/council-comittee-general-info') {
                    this.$router.push({ name: 'council-comittee-general-info' })
                }
                /*else {
                    this.$emit('input', null)
                }*/
            },

            second(comittee,index) {

                if(index==this.parents.length-1)return

                if (this.$route.path !== '/council-comittee-general-info/' + comittee.id) {
                    this.$router.push({ name: 'council-comittee-general-info', params: { councilId: comittee.id } })}
                
            }
        }
    }
</script>

<style scoped>
    .selected,
    .not-selected {
        cursor: pointer;
    }

    .selected {
        color: teal;
    }
</style>