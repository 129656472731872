<template>
    <div>
        <v-card :loading="loading" tile>
            <div class="card-title ">
                {{ $t("Meetings") }}
            </div>
            <GridWithServerPaging dense :headers="headers" :items="meetings" :items-per-page="options.itemsPerPage" :total="totalCount" @onChange="listMeetingsInCouncilCommittee">
                <template v-slot:[`item.date`]="{ item }">
                    {{ item.date| formatDateAr }}
                </template>
                <template v-slot:[`item.title`]="{ item }">
                    {{ item.title| trim(100) }}
                </template>
                <template v-slot:[`item.options`]="{ item }">
                    <v-icon v-if="!isDraft(item.statusId)" color="secondary" class="ms-3" @click="viewMeeting(item)">mdi-eye</v-icon>
                </template>

            </GridWithServerPaging>
            <v-dialog v-if="meetingDialogueOpened" v-model="meetingDialogueOpened" fullscreen hide-overlay persistent>
                <MeetingDashboard :meeting="selectedMeeting" view-mode :user-permissions="userPermissions" @close="meetingDialogueOpened = false" />
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
    import CouncilCommitteesService from '@/services/CouncilCommitteesService'
    import MeetingDashboard from '@/components/app/meeting/MeetingDashboard.vue'
    import { MeetingStatusEnum } from '@/helpers/constants/enumerations'
    import GridWithServerPaging from '@/components/controls/GridWithServerPaging.vue'

    export default ({
        components: {
            MeetingDashboard, GridWithServerPaging
        },

        props: {
            id: {
                type: Number,
                default: null
            },
            userPermissions: {
                type: Object,
                default: () => null
            },
        },
        data() {
            return {
                loading: false,
                viewMode: true,
                meetingDialogueOpened: false,
                selectedMeeting: null,
                headers: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('Title'), value: 'title', sortable: false },
                    { text: this.$t('Status'), value: 'statusName', sortable: false },
                    { text: this.$t('MeetingRoom'), value: 'location', sortable: false },
                    { text: this.$t('ReferenceNumber'), value: 'referenceNumber', sortable: false },
                    { text: this.$t('Date'), value: 'date', sortable: false },
                    { text: this.$t('Options'), value: 'options', sortable: false },
                ],
                meetings: [],
                selectedNote: null,
                options: {
                    page: 1, itemsPerPage: 5
                },
                totalCount: null
            }
        },
        computed: {

        },
        watch: {
        },

        created() {
        },

        mounted() {
            this.listMeetingsInCouncilCommittee()
        },
        destroyed() {
        },
        methods: {

            listMeetingsInCouncilCommittee(options) {
                const page = options != undefined ? options.page : this.options.page
                const pageSize = options != undefined ? options.itemsPerPage : this.options.itemsPerPage
                this.loading = true
                CouncilCommitteesService.listCouncilCommitteeMeetings(this.id, page, pageSize).then((res) => {
                    if (res.data) {
                        this.meetings = res.data.data
                        this.totalCount = res.data.total

                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            viewMeeting(meeting) {
                this.selectedMeeting = meeting
                this.meetingDialogueOpened = true

            },
            isDraft(status) {

                return status == MeetingStatusEnum.Draft
            },
        },
    })
</script>

<style scoped>

    .elevated-table {
        border: 1px solid #000000; /* Border around the table */
    }
</style>