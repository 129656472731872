<template>
    <v-form ref="workflowPropsForm" v-model="valid">
        <v-row>
            <v-col cols="12">
                <v-simple-table>
                    <tr>
                        <th class="col-md-3">
                            <v-text-field v-model="propName" dense :rules="rules" required counter="25" clearable :label="$t('PropertyName')" />
                        </th>
                        <th class="col-md-3">
                            <v-select v-model="propTypeId" dense :items="dataTypes" :loading="loading" required :rules="rules" item-value="id" clearable item-text="name" :label="$t('PropertyType')" />
                        </th>
                        <th class="col-md-3">
                            <v-text-field v-model="propValue" dense counter="25" :label="$t('Value')" />
                        </th>
                        <th class="col-md-3 text-center">
                            <v-btn class="mt-2" :elevation="0" fab dark x-small color="teal" @click="addRow">
                                <v-icon dark small>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </th>
                    </tr>
                    <tbody class="prop-table">
                        <tr v-for="(prop, index) in workflowProps" :key="index">
                            <td>
                                <v-text-field v-model="prop.name" dense :label="$t('PropertyName')" />
                            </td>
                            <td>
                                <v-select v-model="prop.typeId" :items="dataTypes" item-value="id" dense item-text="name" :label="$t('PropertyType')" />
                            </td>
                            <td>
                                <v-text-field v-model="prop.value" dense :label="$t('Value')" />
                            </td>
                            <td class="text-center">
                                <v-icon dark color="error" @click="deleteRow(index)">
                                    mdi-delete-circle
                                </v-icon>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>

    import LookupsService from '@/services/LookupsService.js'

    export default {

        props: {

            value: {
                type: Array,
                default: () => []
            },

        },

        data() {
            return {
                valid: true,
                loading: true,
                propName: '',
                propTypeId: 0,
                propValue: '',
                workflowProps: [],
                dataTypes: [],
                rules: [
                    v => !!v || this.$t('RequiredField')
                ],
            }
        },

        watch: {
            workflowProps: {
                handler: function () {
                    this.$emit('input', this.workflowProps)
                }
            },
            value: {
                handler: function () {
                    this.workflowProps = this.value
                }
            }
        },

        created() {
            this.listDataTypes()
        },

        methods: {

            addRow: function () {
                if (this.$refs.workflowPropsForm.validate()) {
                    let prop = { name: this.propName, typeId: this.propTypeId, value: this.propValue }
                    this.propName = ''
                    this.propTypeId = 0
                    this.propValue = ''
                    this.workflowProps.push(prop)
                    this.$refs.workflowPropsForm.resetValidation()
                }
            },

            deleteRow(index) {
                this.workflowProps.splice(index, 1)
            },

            listDataTypes() {
                this.loading = true
                LookupsService.listDataTypes()
                    .then(res => {
                        this.dataTypes = res.data.map(function (obj) {
                            return { id: parseInt(obj.id), name: obj.name }
                        })
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
    }
</script>
<style>
    .prop-table tr:hover {
        background: none !important;
    }

    .prop-table tr td {
        border: 0 !important
    }
</style>