<template>
    <div>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-switch v-model="currentConnection.complete" :label="$t('Complete')" color="teal darken-3" hide-details />
                </v-col>
                <v-col cols="12">
                    <v-text-field v-model="currentConnection.uilabel" outlined :label="$t('ShowAs')" autocomplete="off" hide-details="auto" />
                </v-col>
                <v-col cols="12">
                    <v-icon @click="queryDialog = true">mdi-plus</v-icon>
                    <v-textarea v-model="currentConnection.condition" outlined :label="$t('Condition')" hide-details rows="3" />
                </v-col>
            </v-row>
        </v-container>
        <v-divider />
        <v-card-actions class="d-block text-end">
            <v-btn color="secondary" tile small class="white--text" @click="SaveSettings">
                {{ $t('Save') }}
            </v-btn>
            <v-btn color="error" tile small class="white--text" @click="$emit('close')">
                {{ $t('Cancel') }}
            </v-btn>
        </v-card-actions>
        <CommonDialog v-if="queryDialog" v-model="queryDialog" :fullscreen="true" :title="$t('Query')">
            <QueryBuilder :form-data="formData" />
        </CommonDialog>
    </div>
</template>

<script>
    import QueryBuilder from '@/components/controls/QueryBuilder.vue'
    import CommonDialog from '@/components/controls/CommonDialog'


    export default {


        components: {
            QueryBuilder, CommonDialog
        },
        props: {
            connection: {
                type: Object,
                default: null
            },
            jsPlumbConnection: {
                type: Object,
                default: null
            },
            formData: {
                type: Array,
                default: ()=>[]
            },
        },
        data() {
            return {
                queryDialog: false,
                currentConnection: {
                    connectionId: null,
                    complete: true,
                    condition: '',
                    uilabel: '',
                }
            }
        },
        created() {
            this.currentConnection.connectionId = this.connection.Id
            this.currentConnection.complete = this.connection.Complete
            this.currentConnection.condition = this.connection.Condition
            this.currentConnection.uilabel = this.connection.LabelName
        },
        methods: {
            SaveSettings() {
                this.jsPlumbConnection.setLabel(this.currentConnection.complete === true ? "<i class='mdi mdi-check-all mdi-24px'></i>" : "<i class='mdi mdi-close-outline mdi-24px error--text'></i>")
                this.$emit('update', this.currentConnection)
            }
        },

    }

</script>
