<template>
    <v-col :cols="cols">
        <v-row>
            <v-col cols="12" class="pb-0">
                <b>{{ label }}</b>
            </v-col>
            <v-col cols="12" :class="'pt-1 ' + cssClass">
                <span v-if="!translated">
                    {{ data }}
                </span>
                <span v-else>
                    {{ $t(data) }}
                </span>
                <slot />
            </v-col>
        </v-row>
    </v-col>
</template>
<script>
    export default {
        props: {
            label: {
                type: String,
                default: ''
            },
            data: {
                type: [String, Number, Array],
                default: ''
            },
            cssClass: {
                type: String,
                default: ''
            },
            cols: {
                type: String,
                default: '4'
            },

            translated: {
                type: Boolean,
                default: false
            }
        }
    }
</script>