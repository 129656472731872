<template>
    <v-card class="rounded-lg" color="grey lighten-2">
        <v-toolbar>
            <v-row>
                <v-col cols="auto">
                    <Breadcrumb :parents="parents" />
                </v-col>
                <v-spacer />

            </v-row>
        </v-toolbar>
        <v-card-text>
            <ComitteeMembers v-if="permissions.users" :id="id" />
            <ComitteeMeetings v-if="permissions.meetings" :user-permissions="permissions" :id="id" class="mt-3" />
            <ComitteeRecommendations v-if="permissions.recommendations" :id="id" class="mt-3" />
        </v-card-text>
    </v-card>
</template>

<script>
    import ComitteeMembers from '@/components/app/GeneralInfo/ComitteeMembers'
    import ComitteeMeetings from '@/components/app/GeneralInfo/ComitteeMeetings'
    import ComitteeRecommendations from '@/components/app/GeneralInfo/ComitteeRecommendations'
    import Breadcrumb from '@/components/controls/ComitteeBreadcrumb.vue'
    import CouncilCommitteesService from '@/services/CouncilCommitteesService'

    export default ({
        components: {
            ComitteeMembers, ComitteeMeetings, ComitteeRecommendations, Breadcrumb
        },
        data() {
            return {
                id: parseInt(this.$route.params.comitteeId),
                parents: [],

                permissions: {
                    users: false,
                    meetings: false,
                    recommendations: false
                }
            }
        },


        created() {
            //this.parents = this.$route.query.parents
            let promises = [
                CouncilCommitteesService.getCommitteesParents(this.id),
                CouncilCommitteesService.getMyCommitteesPermissions(this.id),
            ]
            this.loading = true
            Promise.all(promises)
                .then(
                    ([
                        parentsData,
                        permissionsData,
                    ]) => {
                        this.parents = parentsData.data
                        this.permissions = permissionsData.data
                        if (!this.permissions.users && !this.permissions.meetings && !this.permissions.recommendation) {//no committee permissions
                            this.goBack()
                        }
                    },
                ).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
        },
        methods: {
            goBack() {
                this.$router.go(-1)
            },

    },
    })


</script>

<style>
</style>