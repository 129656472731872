<template>
    <v-card tile min-height="500">
        <div class="card-title">
            {{ $t('ManagePrivileges') }}
        </div>
        <v-card-text />
    </v-card>
</template>

<script>

    export default {
        components: {
        },

        props: {
        },

        data() {
            return {

            }
        },
        computed: {
        },
        watch: {
        },

        created() {

        },

        methods: {

        },
    }
</script>

<style>
</style>