<template>
    <div>
        <v-card :loading="loading" tile>
            <div class="card-title " @click="expanded = !expanded">
                <div>
                    {{ $t('RecommendationInfo') }}
                    <v-icon v-if="!expanded" class="float-end">mdi-chevron-down</v-icon>
                    <v-icon v-if="expanded" class="float-end">mdi-chevron-up</v-icon>
                </div>
            </div>
            <v-container v-if="expanded&&recommendation" fluid>
                <v-row>
                    <label-data :data="recommendation.id" :label="$t('RecommendationNo')" />
                    <label-data :data="recommendation.text" :label="$t('Title')" />
                    <label-data :data="recommendation.status" :label="$t('Status')" css-class="red--text text--darken-4" />
                    <label-data :data="recommendation.createdByName" :label="$t('CreatedBy')" />
                    <label-data :data="recommendation.createdAt" :label="$t('CreatedAt')" />
                    <label-data :data="recommendation.percentage" :label="$t('Progress')" />
                </v-row>
                <v-divider class="mt-3 mb-3" />
                <v-row>
                    <v-card-text class="ma-4 ma-2">
                        <v-row>
                            <v-col cols="4">
                                <v-select v-model="selectedStatus"
                                          :items="statuses"
                                          item-text="name"
                                          item-value="id"
                                          outlined
                                          dense
                                          :label="$t('Status')"
                                          :disabled="isRecommendationCompleted" />
                            </v-col>
                            <v-col cols="4">
                                <v-text-field v-model="progress"
                                              dense
                                              outlined
                                              :label="$t('Progress')"
                                              prepend-icon="mdi-numeric"
                                              type="number"
                                              :disabled="isRecommendationCompleted"/>
                            </v-col>
                            <v-col col="4">
                                <v-btn tile :disabled="disableUpdate||isRecommendationCompleted" larg color="primary" class="white--text" @click="updateRecommendationProgress">
                                    {{ $t('Edit') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>
<script lang="ts">
    import LookupsService from '@/services/LookupsService.js'
    import RecommendationsService from '@/services/MeetingAgendaRecommendationsService'
    import { AgendaRecommendationStatusEnum } from '@/helpers/constants/enumerations'
    import LabelData from '@/components/controls/LabelData.vue'
    export default ({
        components: {
            LabelData
        },
        props: {
            id: {
                type: Number,
                default: null
            },
            viewMode: {
                type: Boolean,
                default: false
            },
        },


        data() {
            return {
                loading: false,
                expanded: true,
                selectedStatus: null,
                progress: 0,
                statuses: [],
                recommendation: null
            }
        },
        computed: {
            disableUpdate() {
                return this.viewMode|| (this.progress==null || this.progress < 0 || this.progress > 100 || !this.selectedStatus)
            },

            isRecommendationCompleted() {
                return this.recommendation && this.recommendation.statusId === AgendaRecommendationStatusEnum.Completed
            }
        },
        mounted() {
            this.loading = true
            let promises = [
                LookupsService.listMeetingRecommendationsStatuses(),
                RecommendationsService.getRecommendationDetails(this.id)
            ]

            Promise.all(promises)
                .then(
                    ([
                        statusesData,
                        recommendationData
                    ]) => {
                        this.statuses = statusesData.data.filter(x => x.id !== AgendaRecommendationStatusEnum.Draft)
                        this.recommendation = recommendationData.data
                        this.selectedStatus = this.recommendation.statusId
                        this.progress = this.recommendation.percentage
                    },
                ).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
        },

        methods: {
            loadRecommendationDetials() {
                this.loading = true
                RecommendationsService.getRecommendationDetails(this.id).then((res) => {
                    this.recommendation = res.data
                    this.selectedStatus = this.recommendation.statusId
                    this.progress = this.recommendation.percentage
                    if (this.selectedStatus == AgendaRecommendationStatusEnum.Completed) {
                        this.close()
                    }
                },
                ).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            updateRecommendationProgress() {

                this.loading = true
                let formDate = {
                    'id': this.id,
                    'progress': this.progress,
                    'statusId': this.selectedStatus
                }
                RecommendationsService.updateRecommendationProgress(formDate).then((res) => {
                    if (res.success) {
                        this.$root.$notification(this.$t('UpdateSuccess'), 'success')
                        this.loadRecommendationDetials()
                        this.update()
                        if (this.selectedStatus === AgendaRecommendationStatusEnum.Completed) {
                            this.$router.push({ name: 'recommendations' })
                        }
                    }

                },
                ).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            validateProgress(event) {
                if (event.target.value > 100) {
                    this.progress = 100
                } else if (event.target.value < 0) {
                    this.progress = 0
                } else {
                    this.progress = event.target.value
                }
            },
            update() {
                this.$emit('update')
            },
            close() {
                this.$emit('close')
            }
        },
    })
</script>

<style>
</style>