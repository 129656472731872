import { mainApiAxios as axios } from '@/plugins/axios'

export default {

    getTestToken() {
        return axios.get('absher-services/test-token')
    },

    authAbsherUser(formData) {
        return axios.post('absher-services/auth', formData)
    },

    completeUserData(formData) {
        return axios.put('absher-services/complete-user-info', formData)
    },

}