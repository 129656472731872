<template>
    <CommonDialog :value="value" :title="$t('NotificationNewMeetingAgendaBegins')" :show-action-toolbar="!readOnly" width="30%" :fullscreen="false" @input="$emit('input', false)">
        <div class="pa-4 text-center grey--text text--darken-4 c-text-subtitle-2 font-weight-bold">
            {{$t('MeetingInfo')}} ( {{$t('Title')}} ): {{ meetingTitle }}
            <v-spacer />
            {{ $t('ThisAgendaHasBeenStartedNow') }} ({{ title }})
            <!--<a href=""> test</a>-->
        </div>
        <template v-slot:actions>
            <v-btn color="success" class="white--text" tile small @click="$emit('input', false)">
                {{ $t('Ok') }}
            </v-btn>
        </template>
    </CommonDialog>
</template>
<script>
   
    import CommonDialog from '@/components/controls/CommonDialog.vue'


    export default {

        components: {
           CommonDialog,
        },

        props: {
            value: {
                type: Boolean,
                default: true
            },
           title: {
                type: String,
                default: null
            },
           meetingTitle: {
              type: String,
              default: null
           },
            readOnly: {
                type: Boolean,
                default: false
            },
        },
    }
</script>
