<template>
    <div ref="autocompleteContainer">
        <v-autocomplete v-model="selectedItem" clearable dense tile
                        small
                        :loading="searchLoading"
                        :label="label"
                        :items="items"
                        item-value="id"
                        item-text="name"
                        hide-no-data
                        no-filter
                        :search-input.sync="searchText"
                        :rules="rules"
                        :disabled="disabled"
                        :outlined="outlined"
                        return-object
                        @change="onChange">
            <template v-slot:item="{ item }">
                <div class="item-autocomplete-item">
                    <div class="item-autocomplete-text">
                        <span>{{ item.name }}</span>
                    </div>
                </div>
            </template>
        </v-autocomplete>
    </div>
</template>
<script>
    import UsersService from '@/services/UsersService.js'
    import LookupsService from '@/services/LookupsService.js'

    export default {

        props: {
            value: {
                type: Number,
                default: null
            },

            label: {
                type: String,
                default: ''
            },

            selectedText: {
                type: String,
                default: null
            },

            disabled: {
                type: Boolean,
                default: false
            },

            active: {
                type: Boolean,
                default: true
            },

            outlined: {
                type: Boolean,
                default: false
            },

            rules: {
                type: Array,
                default: () => []
            }
        },

        data() {
            return {
                tempSelected: {
                    id: null,
                    name: ''
                },
                searchLoading: false,
                items: [],
                //setting searchText to empty string will fire the watch.
                //DO NOT UPDATE
                searchText: '',

                textLoadedFromWatch: false,
                textLoadedFromApi: false
            }
        },

        computed: {
            selectedItem: {
                get() {
                    return this.tempSelected.id ? { name: this.tempSelected.name, id: this.tempSelected.id } : null
                },

                set(item) {
                    if (item) {
                        this.tempSelected.name = item.name
                        this.tempSelected.id = item.id
                    }
                }
            }
        },


        watch: {
            selectedText(textval) {
                if (!this.textLoadedFromWatch) {
                    this.textLoadedFromWatch = true
                    this.preLoadSelectedItem(this.value, textval)
                }
                else {
                    this.items = []
                    this.preLoadSelectedItem(this.value, textval)
                }

            },

            searchText(val) {
                if (this.selectedItem != null && val == this.selectedItem.name && this.selectedItem.name != null) {
                    //prevent calling the api when setting the text
                    return
                }
                if (val) {
                    this.loadItems()
                }
            },

            value(val) {
                this.tempSelected.id = val
            },
        },

        created() {
            if (this.value) {
                this.loadSelectedItem(this.value)
            }
        },

        methods: {
            onChange(item) {
                this.$emit('input', item != null ? item.id : null)
            },

            loadItems() {
                if (this.searchLoading) {
                    return
                }

                this.searchLoading = true
                UsersService.listUsersForAutoComplete(this.searchText != null ? this.searchText : '', this.active)
                    .then((res) => {
                        this.items = res.data

                        if (!this.textLoadedFromApi) {
                            this.textLoadedFromApi = true
                            this.preLoadSelectedItem(this.value, this.selectedText)
                        }

                    })
                    .catch(() => {
                        this.items = []
                    })
                    .finally(() => {
                        this.searchLoading = false
                    })
            },

            loadSelectedItem(value) {
                LookupsService.getUserById(value)
                    .then((res) => {
                        if (res.data) {
                            this.items.push(res.data)
                            this.tempSelected = res.data
                        }
                    })
                    .catch(() => {
                        this.items = []
                    })
                    .finally(() => {
                        this.searchLoading = false
                    })
            },

            preLoadSelectedItem(id, name) {
                if (id != null && name != null && this.items.findIndex(stc => stc.id == this.value) == -1) {
                    this.items.push({
                        id: id,
                        name: name
                    })

                }
            }
        }
    }

</script>
<style scoped>
    .item-autocomplete > .v-input__append-outer {
        margin-top: 2px !important;
    }

    .item-autocomplete-text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 24px;
    }

    .item-autocomplete-item {
        padding: 7px;
    }
</style>