<template>
    <v-card tile :loading="loading">
        <div class="card-title">{{ $t('DataSources') }}</div>

        <v-data-table :headers="headers" dense :items="connections" :items-per-page="10" class="custom-table mt-3">
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn small icon><v-icon color="primary" @click="editItem(item)">mdi-pencil</v-icon></v-btn>
                <v-btn small icon><v-icon color="error" @click="deleteDataSource(item.id)">mdi-delete</v-icon></v-btn>
            </template>
            <template v-slot:top>
                <v-btn class="white--text ma-4" small tile color="primary" @click="addNew()">
                    {{ $t('Add') }}
                    <v-icon small>mdi-plus</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <CommonDialog v-if="connectionDialog" v-model="connectionDialog" :fullscreen="false" width="500" :title="edit == true ? $t('Edit') : $t('Add')" show-action-toolbar>
            <v-card-text>
                <v-form ref="connectionForm" v-model="valid" lazy-validation class="mt-2">
                    <v-text-field v-model="dataSource.dbName" :rules="nameRules" :label="$t('DbName')" required />
                    <v-text-field v-model="dataSource.instanceName" :rules="nameRules" :label="$t('InstanceName')" required />
                    <v-text-field v-model="dataSource.username" :rules="nameRules" :label="$t('Username')" required />
                    <v-text-field v-model="dataSource.password" type="password" autocomplete="new-password" :rules="nameRules" :label="$t('Password')" required />
                </v-form>
            </v-card-text>
            <template v-slot:actions>
                <v-btn v-if="!edit" color="primary" small tile class="white--text" @click="save">
                    {{ $t('Save') }}
                </v-btn>
                <v-btn v-if="edit" color="primary" small tile class="white--text" @click="update">
                    {{ $t('Update') }}
                </v-btn>
                <v-btn color="error" class="white--text" tile small @click="connectionDialog = false">
                    {{ $t("Cancel") }}
                </v-btn>
            </template>
        </CommonDialog>
    </v-card>
</template>

<script>
    import DataSourcesService from '@/services/DataSourcesService'
    import CommonDialog from '@/components/controls/CommonDialog'

    export default {
        components: {
            CommonDialog
        },

        data() {
            return {
                connectionDialog: false,
                loading: false,
                edit: false,
                valid: true,
                connections: [],
                headers: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('DbName'), value: 'dbName' },
                    { text: this.$t('InstanceName'), value: 'instanceName' },
                    { text: this.$t('Username'), value: 'username' },
                    { text: this.$t('Password'), value: 'password' },
                    { text: this.$t('Actions'), value: 'actions' },
                ],
                nameRules: [
                    v => !!v || this.$t('RequiredField')
                ],
                title: '',
                dataSource: {
                    id: null,
                    dbname: '',
                    instanceName: '',
                    password: '',
                    username: ''
                },
            }
        },

        created() {
            this.listDbs()
        },

        methods: {

            addNew() {
                this.edit = false
                this.connectionDialog = true
                this.dataSource.dbname = ''
                this.dataSource.instanceName = ''
                this.dataSource.password = ''
                this.dataSource.username = ''
            },

            editItem(item) {
                this.edit = true
                this.connectionDialog = true
                this.dataSource = Object.assign({}, item)
            },

            save() {
                if (this.$refs.connectionForm.validate()) {
                    DataSourcesService.addDataSource(this.dataSource).then((res) => {
                        this.listDbs()
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
            },
            update() {
                if (this.$refs.connectionForm.validate()) {
                    DataSourcesService.updateDataSource(this.dataSource.id, this.dataSource).then((res) => {
                        this.listDbs()
                    }).catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
                }
            },


            listDbs() {
                this.loading = true
                DataSourcesService.listDataSources()
                    .then((res) => {
                        if (res.data) {
                            this.connections = res.data
                        }
                    })
                    .catch(() => {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }).finally(() => {
                        this.loading = false
                    })
            },

            async deleteDataSource(id) {
                if (await this.$root.$confirm(this.$t('Delete'), this.$t('ConfirmDelete'))) {
                    this.loading = true
                    DataSourcesService.deleteDataSource(id)
                        .then(() => {
                            this.listDbs()
                        })
                        .catch(() => {
                            this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                        }).finally(() => this.loading = false)
                }
            },
        }
    }
</script>

<style>
</style>