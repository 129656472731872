<template>
    <div class="text-center">
        <v-snackbar v-model="snackbar" :color="color" elevation="24">
            {{ text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" icon v-bind="attrs" @click="snackbar = false">
                    <v-icon dark>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                snackbar: false,
                status: 'success',
                text: '',
            }
        },

        computed: {
            color() {
                switch (this.status) {
                    case 'success':
                        return 'secondary'
                    case 'error':
                        return 'error'
                    case 'info':
                        return 'cyan darken-2'
                    default:
                        return 'secondary darken-3'
                }
            }
        },

        methods: {
            open(message, status) {
                this.snackbar = false
                this.text = message
                this.status = status
                this.snackbar = true
            }
        }
    }</script>