<template>
    <v-card>
        <v-toolbar color="primary" dense flat>
            <v-toolbar-title class="white--text c-text-h6">
                {{ $t('SendInitialMeetingMinutes') }}
            </v-toolbar-title>
            <v-spacer />


            <v-btn dark icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <div class="title-card mx-2 mt-2">
            {{ $t("Attendees") }}
        </div>
        <v-data-table v-model="selectedUser"
                      :headers="headers"
                      :items="filteredAttendees"
                      item-key="userId"
                      show-select
                      class="elevation-1">
            <template v-slot:[`item.actions`]="{ item }">
                <v-checkbox v-model="selectedUser" :value="item.userId" />
            </template>
        </v-data-table>
        <v-row>
            <v-text-field v-model="DueDate" dense class="mb-2 ma-5" :label="$t('DaysToApprove')" required type="number" autocomplete="off" outlined />
        </v-row>


        <v-card-actions class="justify-center ">
            <v-btn class="mb-3 white--text" color="teal" :disabled="disableSendMeetingMinutes" @click="SendMeetingMinutesTasks">
                {{ $t('Send')+' '+$t('MeetingMinutes') }}
            </v-btn>
        </v-card-actions>
        <confirm-dialog ref="confirmDialog" />
    </v-card>
</template>

<script>
    import MeetingService from '@/services/MeetingsService'
    import ConfirmDialog from '@/components/controls/ConfirmDialog.vue'
    import { mapGetters } from 'vuex'

    export default ({
        components: {
            ConfirmDialog
        },
        props: {
            id: {
                type: Number,
                default: null
            },
            attendees: {
                type: Array,
                default: () => []
            },
        },
        data() {
            return {
                text: '',
                isDisabled: false,
                loading: false,
                selectedUser: [],
                headers: [
                    { text: this.$t('Name'), value: 'name' },
                    { text: this.$t('JobTitle'), value: 'jobTitle' },
                ],
                existUsers: [],
                DueDate: null,
                filteredUsers: [],
            }
        },
        computed: {
            ...mapGetters('user', ['loggedInUser', 'language']),
            disableSendMeetingMinutes() {
                return (!this.DueDate || this.DueDate < 1 || !this.selectedUser || this.selectedUser.length < 1 || (this.selectedUser.length <= this.existUsers.length))
            },
            filteredAttendees() {

                return this.filteredUsers.map(x => ({ ...x, isSelectable: !this.existUsers.some(e => e == x.userId) }))
            }
        },
        watch: {
        },

        created() {


        },

        mounted() {
            MeetingService.getInitialMeetingMinutesExistUsers(this.id).then((res) => {
                if (res.data) {
                    this.existUsers = res.data
                    this.selectedUser = this.existUsers.map(x => ({ userId: x, jobTitle: 'Job' }))

                }
            }).catch(() => {
                this.$root.$notification(this.$t('ErrorOccurred'), 'error')
            }).finally(() => {
                this.filteredUsers = this.attendees.filter(user => user.userId != this.loggedInUser?.id)

            })
        },
        destroyed() {
        },
        methods: {
            SendMeetingMinutesTasks() {
                this.loading = true
                let formData = {
                    'meetingId': this.id,
                    'dueDate': this.DueDate,
                    'usersIds': this.selectedUser.filter(x => !this.existUsers.some(u => u == x.userId)).map(x => x.userId),

                }
                MeetingService.sendInitialMeetingMinutesForApproval(formData).then((res) => {
                    if (res.data) {
                        this.$root.$notification(this.$t('SendSuccess'), 'success')
                        this.close()
                    } else {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },

            close() {
                this.$emit('close')
            },
            alreadySent(user) {

                var res = this.existUsers.some(x => x == user.userId)
                return res
            },
            updateSelectedUserIds(newValues) {
                this.selectedUserIds = newValues
            },

        },
    })
</script>

<style scoped>
    .elevated-table {
        border: 1px solid #000000;
    }
</style>