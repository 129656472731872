import { mainApiAxios as axios } from '@/plugins/axios'

export default {


    changeLanguage(language) {
        return axios.post('users/language', language)
    },
    getUserImage(id) {
        return axios.get(`users/profile-image/${id}`,{ responseType: 'blob' })
    },
    getUser(userId) {
        return axios.get(`users/${userId}`)
    },

    addUser(userObj) {
        return axios.post('users', userObj)
    },

    updateUser(userObj) {
        return axios.put('users', userObj)
    },

    registerUser(userObj) {
        return axios.post('users/register', userObj)
    },

    listUsersForAutoComplete(search, active) {
        return axios.get(`users?search=${search}&active=${active}&mode=Autocomplete`)
    },

    listAdminUsers(page, pageSize, active) {
        return axios.get(`users/admin?page=${page}&pageSize=${pageSize}&active=${active}`)
    },

    setSignature(data) {
        return axios.post('users/signatures', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },
    updateProfilePicture(formData) {
        return axios.post('users/profile-picture', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },
    deleteSignature(signatureId) {
        return axios.delete(`users/signatures/${signatureId}`)
    },

    getSignatures() {
        return axios.get('users/signatures')
    },

    listUsersForPlanTask(structureId) {
        return axios.get(`users/for-plan-task?structureId=${structureId}`)
    },

    enableSms(userId, activated) {
        return axios.post(`users/${userId}/sms?activated=${activated}`)
    },

    enableLdap(userId, activated) {
        return axios.post(`users/${userId}/ldap?activated=${activated}`)
    },

    enableEmail(userId, activated) {
        return axios.post(`users/${userId}/email?activated=${activated}`)
    },

    listUserPermissions(userId) {
        return axios.get(`users/${userId}/permissions`)
    },

    listUserRolesStructures(userId) {
        return axios.get(`users/${userId}/roles`)
    },

    listCurrentUserPermissions() {
        return axios.get('users/permissions')
    },

    updatePermission(userId, permissionId, permissionTypeId, enabled) {
        return axios.post(`users/permissions/${userId}/${permissionId}/${permissionTypeId}?enabled=${enabled}`)
    },

    countUnclaimedTasks() {
        return axios.get('users/current/unclaimed-tasks')
    },

    updatePassword(data) {
        return axios.post('users/current/password', data)
    },

    resetPassword(data) {
        return axios.post('users/admin/reset-password', data)
    },
    logout() {
        return axios.post('users/logout', { })
    },
    loadNotifications() {
        return axios.get('users/notifications')
    }
}