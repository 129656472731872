<template>
    <v-card :class="cssClass" :elevation="elevation" :loading="loading" tile>
        <div class="card-title" :class="{ pointer:expandable }" @click="toggleExpand()">
<v-badge v-if="enableBadge" color="error" class="mt-3" :content="badgeValue">
                {{ title }}
            </v-badge>

            <span v-else>{{ title }}</span>

            <div class="float-end mt-n3">
                <slot name="actions" />
                <template v-if="expandable">
                    <v-icon v-if="!expanded" class="float-end mt-3">mdi-chevron-down</v-icon>
                    <v-icon v-else class="float-end mt-3">mdi-chevron-up</v-icon>
                </template>
            </div>
        </div>
        <template v-if="expanded">
            <slot />
        </template>
    </v-card>
</template>
<script>
    export default {

        props: {

            loading: {
                type: Boolean,
                default: false
            },

            title: {
                type: String,
                default: ''
            },

            cssClass: {
                type: String,
                default: 'mb-3'
            },

            expandable: {
                type: Boolean,
                default: false
            },

            enableBadge: {
                type: Boolean,
                default: false
            },

            badgeValue: {
                type: String,
                default: ''
            },

            isExpanded: {
                type: Boolean,
                default: true
            },

            elevation: {
                type: Number,
                default: 3
            }
        },

        data() {
            return {
                expanded: this.isExpanded
            }
        },

        methods: {

            toggleExpand() {
                if (this.expandable) {
                    this.expanded = !this.expanded
                }
            }

        }
    }
</script>