<template>
    <div>
        <v-card :loading="loading" tile>
            <div class="card-title ">
                <div>
                    {{ $t('Attachments') }}
                    <v-icon v-if="!viewMode" class="float-end me-2" color="primary" @click="openAddAttachmentsDialogue">
                        mdi-plus-circle-outline
                    </v-icon>
                </div>
            </div>
            <GridWithServerPaging :headers="headers" :items="attachments" :total="totalCount" disable-sort class="mt-4" @onChange="loadAttachments">
                <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt| formatDateAr }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon v-if="!viewMode" color="error"
                            :disabled="loading"
                            @click="deleteAttachment(item.id)">
                        mdi-trash-can-outline
                    </v-icon>
                    <v-icon class="ms-2"
                            color="primary"
                            :disabled="loading"
                            @click="loadAttachment(item.id)">
                        mdi-eye
                    </v-icon>
                </template>
            </GridWithServerPaging>
            <confirm-dialog ref="confirmDialog" />
        </v-card>
        <CommonDialog v-model="attachmentDialog" :title="$t('Attachment')" css-content-class="pa-0" @input="attachmentDialog= false">
            <v-container fluid class="stretch">
                <FileViewer :query="documentQuery" name="recommendationAttachment" />
            </v-container>
        </CommonDialog>
        <v-dialog v-if="addAttachmentDialog" v-model="addAttachmentDialog" width="1000">
            <AddRecommendationAttachments :id="id" @close="addAttachmentDialog = false" @update="loadAttachments" />
        </v-dialog>
    </div>
</template>

<script>
    import RecommendationsService from '@/services/MeetingAgendaRecommendationsService'
    import ConfirmDialog from '@/components/controls/ConfirmDialog.vue'
    import GridWithServerPaging from '@/components/controls/GridWithServerPaging.vue'
    import AttachmentsService from '@/services/AttachmentsService.js'
    import CommonDialog from '@/components/controls/CommonDialog.vue'
    import FileViewer from '@/components/controls/FileViewer.vue'
    import AddRecommendationAttachments from '@/components/app/meeting/Recommendations/AddRecommendationAttachments.vue'

    export default ({
        components: {
            ConfirmDialog, GridWithServerPaging, CommonDialog, FileViewer, AddRecommendationAttachments
        },

        props: {
            id: {
                type: Number,
                default:null
            },
            viewMode: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                text: '',
                loading: false,
                attachmentDialog: false,
                addAttachmentDialog:false,
                headers: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('FileName'), value: 'name' },
                    { text: this.$t('FileExtension'), value: 'type' },
                    { text: this.$t('FileSize'), value: 'size' },
                    { text: this.$t('Actions'), value: 'actions' },
                ],
                attachments: [],
                selectedAttachment: null,
                options: {
                    page: 1, itemsPerPage: 5
                },
                totalCount: null,
                documentQuery:null,
            }
        },
        computed: {

        },
        watch: {
        },

        created() {
        },

        mounted() {
            this.loadAttachments(this.options)
        },
        destroyed() {
        },
        methods: {
           
            loadAttachments(options) {

                const page = options != undefined ? options.page : this.options.page
                const pageSize = options != undefined ? options.itemsPerPage : this.options.itemsPerPage
                this.loading = true
                RecommendationsService.listRecommendationAttachments(this.id, page, pageSize).then((res) => {
                    this.attachments = res.data.data
                    this.totalCount = res.data.total
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            deleteAttachment(id) {
                this.$refs.confirmDialog.open(this.$t('Delete'), this.$t('ConfirmDelete'))
                    .then((confirmed) => {
                        if (confirmed) {
                            RecommendationsService.deleteRecommendationAttachment(id).then((res) => {
                                if (res) {
                                    this.loadAttachments(this.options)
                                }

                            }).catch(() => {
                                this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                            }).finally(() => {
                                this.loading = false
                            })
                        } else {

                        }
                    })

            },
            close() {
                this.$emit('close')
            },
            loadAttachment(attachmentId) {
                AttachmentsService.getAttachment(attachmentId).then(response => {
                    this.documentQuery = response.data
                    this.attachmentDialog = true
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                })
            },
            openAddAttachmentsDialogue() {
                this.addAttachmentDialog = true
            }

        },
    })
</script>

<style scoped>
    .elevated-table {
        border: 1px solid #000000; /* Border around the table */
    }
</style>