import { mainApiAxios as axios } from '@/plugins/axios'

export default {

    post(data) {
        return axios.post('permissions', data)
    },

    loadPermissions(itemId, typeId) {
        return axios.get(`permissions/${itemId}/${typeId}`)
    },

    loadPermission(permissionId) {
        return axios.get(`permissions/${permissionId}/current`)
    },
    loadAnnualPLanPermission(permissionId) {
        return axios.get(`permissions/${permissionId}/has-plan-approval`)
    },
    loadCoreMissionPermission() {
        return axios.get('permissions/current/core-mission')
    },

    setPermissionLevel(itemId, levelId, userId) {
        return axios.post(`permissions/${itemId}/${levelId}/${userId}/permission-level`)
    },

    loadSystemPermissions() {
        return axios.get('permissions')
    },
    updateSystemPermission(itemId,data) {
        return axios.put(`permissions/${itemId}`, data)
    },

}