import { mainApiAxios as axios } from '@/plugins/axios'


export default {

    getComitteesSummary(page,pageSize) {
        return axios.get(`reports/comittees-summary/${page}/${pageSize}`)
    },
    getAttendnaceReport(page, pageSize) {
        return axios.get(`reports/attendance/${page}/${pageSize}`)
    },
    getComitteesMeetingsDetails(committeeId) {
        return axios.get(`reports/comittees-meetings-summary/${committeeId}`)
    },
}