<template>
    <v-container fluid class="pe-0">
        <v-row>
            <v-col cols="12" class="px-0 pt-0">
                <v-card outlined class="pa-5 primary-border" height="10vh">
                    <v-row>
                        {{ $t('TimeRemaining') }}
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="text-center primary--text">
                            <h2>{{ meetingRemainingDuration() }}</h2>
                            <small>Hours Minutes Seconds</small>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" class="text-center d-flex justify-center" v-if="showsAddOrUpdateMeetingSummaryAction">
                <v-btn class="primary px-10"
                       @click="showAddOrUpdateMeetingSummaryForm = true">
                    <v-icon left>mdi-clipboard-text</v-icon>
                    {{ $t('MeetingSummary') }}
                </v-btn>
            </v-col>
            <v-col cols="12" class="px-0 pt-0">
                <v-card class="rounded-lg" height="74vh">
                    <h3 class="pa-2 mb-1">{{ $t('Agenda') }}</h3>

                    <v-divider class="my-0" color="#fefefe" />

                    <v-toolbar dense>
                        <v-icon v-if="isArabicLanguage" v-show="showRunAgendaAction" :disabled="!meetingOwner" size="35" color="primary" @click="pauseResumeCurrentAgenda">mdi-play-circle mdi-rotate-180</v-icon>
                        <v-icon v-else v-show="showRunAgendaAction" size="35" :disabled="!meetingOwner" color="primary" @click="pauseResumeCurrentAgenda">mdi-play-circle </v-icon>
                        <v-icon v-if="showPauseAgendaAction" :disabled="!meetingOwner" size="35" color="warning" @click="pauseResumeCurrentAgenda">mdi-pause-circle</v-icon>
                        <v-icon v-if="isArabicLanguage" v-show="!meetingEnd&&runningAgendaItemId&&meetingOwner" size="35" class="ms-1" color="error" @click="startNextMeetingAgenda">mdi-skip-previous-circle</v-icon>
                        <v-icon v-else v-show="!meetingEnd&&runningAgendaItemId&&meetingOwner" size="35" class="ms-1" @click="startNextMeetingAgenda">mdi-play </v-icon>
                    </v-toolbar>
                    <v-card-text style="height:63vh; overflow:auto">
                        <v-row style="background: #f8f8f8;" class="pa-0">
                            <v-col cols="1" class="text-center">#</v-col>
                            <v-col cols="5"><b>{{ $t('Subject') }}</b></v-col>
                            <v-col cols="5"><b>{{ $t('Voting') }}</b></v-col>
                        </v-row>
                        <template v-for="(item, index) in agendaItems">
                            <v-row :key="item.id" class="mt-4">
                                <v-col cols="1"><b>{{ index + 1 }}</b></v-col>
                                <v-col cols="5"><h5>{{ item.title |trim(100) }}</h5></v-col>
                                <v-col v-if="showSelection(index)">
                                   
                                    <v-radio-group  class="mx-2" dense outlined>
                                        <v-radio v-for="(option) in item.votingType.votingOptions"
                                                 :key="option.id"
                                                 :label="option[voteItemTextProperty]"
                                                 :value="option.id"
                                                 @change="onVoteChange(item,option,index)"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col v-else class="text-center">
                                    <v-chip color="primary" label class="mt-4" v-if="getSelectedVoting(item)">  {{ getSelectedVoting(item) }}</v-chip>
                                </v-col>
                            </v-row>
                            <v-row :key="item.id + 'sub'" :class="item.isRunning? 'bg-action-selected' : 'bg-action-not-selected'" style="height: 30px; border-bottom: 2px solid #dcdddd ">
                                <v-col cols="12" class="py-1 px-1">
                                    <v-icon color="#f20011" size="20" v-if="item.isRunning" class="me-2">mdi-clock-outline</v-icon>
                                    <span v-if="item.isRunning">{{ formattedTime }}</span>
                                    <span v-else-if="index >= runningAgendaItemIndex">{{ formatDuration(item.remainingSeconds) }}</span>
                                    <span v-else class="red--text finished"> {{ $t('Finished') }}</span>

                                    <v-tooltip v-if="showAddRecommendationsOption" top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" small :color="getAgendaIconsColor(item)??'secondary'" v-on="on" @click="openAddRecommendationDialogue(item.id)" class="float-end mt-1">
                                                {{item.isRunning?'mdi-plus':'mdi-plus-box'}}
                                            </v-icon>
                                        </template>
                                        <span>{{ $t('AddRecommendation') }}</span>
                                    </v-tooltip>
                                    <v-tooltip v-if="showAddNotesOption" top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="mx-1 mt-1 float-end" v-bind="attrs" :color="getAgendaIconsColor(item)??'primary'" small v-on="on" @click="openAddNoteDialogue(item.id)">mdi-comment-text</v-icon>
                                        </template>
                                        <span>{{ $t('AddNote') }}</span>
                                    </v-tooltip>
                                    <v-tooltip v-if="showMeetingAgendaVotingResultsOption && checkIfVotingOnMeetingAgendaStartedAndHasVoting(index,item.votingType.id)" top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" small :color="getAgendaIconsColor(item)??'secondary'" v-on="on" @click="openMeetingAgendaVotingResultsDialogue(item.id,item.title)" class="float-end mt-1">
                                                {{'mdi-clipboard-text'}}
                                            </v-icon>
                                        </template>
                                        <span>{{ $t('VotingResults') }}</span>
                                    </v-tooltip>
                                    <v-icon class="ms-1 mt-1 float-end" :color="getAgendaIconsColor(item)??'secondary'" small>mdi-checkbox-marked-circle</v-icon>
                                </v-col>
                            </v-row>
                        </template>

                    </v-card-text>
                </v-card>
            </v-col>
            <v-dialog v-if="showAddNoteDialogue" v-model="showAddNoteDialogue" width="900">
                <AddAgendaNote :id="selectedAgendaId" @close="showAddNoteDialogue = false" />
            </v-dialog>
            <v-dialog v-if="showAddRecommendationDialogue" v-model="showAddRecommendationDialogue" no-gutters width="1000">
                <AddAgendaRecommendation :id="selectedAgendaId" :attendees="attendees" @close="showAddRecommendationDialogue = false" />
            </v-dialog>
            <v-dialog v-if="showAddOrUpdateMeetingSummaryForm" v-model="showAddOrUpdateMeetingSummaryForm" no-gutters width="75%">
                <AddOrUpdateMeetingSummary :meetingId="meetingId" @close="showAddOrUpdateMeetingSummaryForm = false" />
            </v-dialog>
            <v-dialog v-if="showMeetingAgendaVotingResultsDialogue" v-model="showMeetingAgendaVotingResultsDialogue" no-gutters width="75%">
                <MeetingAgendaVotingResults :agendaId="selectedAgendaId" :title="selectedAgendaTitle" @close="showMeetingAgendaVotingResultsDialogue = false" />
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
    import AddAgendaNote from '@/components/app/meeting/AddMeetingAgendaNote'
    import AddAgendaRecommendation from '@/components/app/meeting/AddMeetingAgendaRecommendation'
    import AddOrUpdateMeetingSummary from '@/components/app/meeting/AddOrUpdateMeetingSummary'
    import MeetingAgendaVotingResults from '@/components/app/meeting/MeetingAgendaVotingResults'
    import { createSignalRConnection, startSignalRConnection, subscribeToEvent, unsubscribeFromEvent } from '@/plugins/hub/myHubPlugin.js'
    import MeetingsService from '@/services/MeetingsService'
    import MeetingAgendaService from '@/services/MeetingAgendaService'
    import { MeetingStatusEnum, VotingTypesEnum } from '@/helpers/constants/enumerations'
    import { mapGetters } from 'vuex'


    export default ({
        components: {
            AddAgendaNote,
            AddAgendaRecommendation,
            AddOrUpdateMeetingSummary,
            MeetingAgendaVotingResults,

        },
        props: {
            agendaList: {
                type: Array,
                default: () => []
            },
            attendees: {
                type: Array,
                default: () => []
            },
            statusId: {
                type: Number,
                default: 0
            },
            meetingId: {
                type: Number,
                default: null
            },
            viewMode: {
                type: Boolean,
                default: false
            },
            meetingOwner: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                headers: [
                    { text: this.$t('Title'), align: 'start', value: 'title', sortable: false },
                    { text: this.$t('VoteType'), value: 'votingType.nameAr', sortable: false },
                    { text: this.$t('Value'), value: 'value', sortable: false },
                    { text: this.$t('Vote'), value: 'vote', sortable: false },
                    { text: this.$t('Actions'), width: '12%', align: 'center', value: 'Actions' },
                ],
                agendaItems: [],
                secondsRemaining: 0,
                selectedAgendaId: null,
                selectedAgendaTitle: null,
                isPaused: true,
                runningAgendaItemId: null,
                runningAgendaItemIndex: 0,
                meetingEnd: false,
                showAddNoteDialogue: false,
                showAddRecommendationDialogue: false,
                timer: null,
                loading: false,
                selectedVoteId: null,
                showAddOrUpdateMeetingSummaryForm: false,
                showMeetingAgendaVotingResultsDialogue: false,


            }
        },
        computed: {
            voteItemTextProperty() {
                return this.isArabicLanguage ? 'nameAr' : 'nameEn'
            },

            ...mapGetters('user', ['loggedInUser', 'language']),
            isArabicLanguage() {
                return this.language == 'ar'
            },
            formattedTime() {
                const minutes = Math.floor(this.secondsRemaining / 60)
                const seconds = this.secondsRemaining % 60
                return `${minutes}:${seconds.toString().padStart(2, '0')}`
            },
            showAddRecommendationsOption() {
                return !this.viewMode && this.meetingOwner && this.statusId >= MeetingStatusEnum.Started && this.statusId < MeetingStatusEnum.FinalMeetingMinutesSigned
            },
            showAddNotesOption() {
                return !this.viewMode && this.statusId >= MeetingStatusEnum.Started && this.statusId < MeetingStatusEnum.FinalMeetingMinutesSigned
            },
            showPauseAgendaAction() {
                return !this.isPaused && !this.meetingEnd
            },
            showRunAgendaAction() {
                return this.isPaused && !this.meetingEnd
            },
            showsAddOrUpdateMeetingSummaryAction() {
                return !this.viewMode && this.meetingOwner
                    && this.statusId >= MeetingStatusEnum.Started
                    && this.statusId < MeetingStatusEnum.PendingFinalMeetingMinutesSign
            },
            showMeetingAgendaVotingResultsOption() {
                return !this.viewMode && (this.meetingOwner) && this.statusId >= MeetingStatusEnum.Started
            },

        },
        watch: {
            agendaItems: {
                handler(newVal) {
                    try {
                        this.runningAgendaItemIndex = -1
                        this.runningAgendaItemId = null
                        this.secondsRemaining = 0

                        for (let i = 0; i < newVal.length; i++) {
                            if (newVal[i].isRunning) {

                                this.runningAgendaItemIndex = i
                                this.runningAgendaItemId = newVal[i].id
                                this.secondsRemaining = newVal[i].remainingSeconds
                                this.isPaused = newVal[i].paused
                                break
                            }
                        }

                        if (newVal.length > 0 && newVal[newVal.length - 1].actualEndDate) {
                            this.meetingEnd = true
                            this.runningAgendaItemIndex = newVal.length

                        }
                        if (!this.meetingEnd && newVal.length > 0) {
                            if (!this.isPaused) {
                                this.startTimer()
                            } else {
                                this.stopTimer()
                            }
                        }
                    } catch (error) {
                        console.error('Error in agenda timing calculation:', error)
                    }

                },
                deep: true
            }
        },
        created() {
            this.agendaItems = this.agendaList

        },
        mounted() {
            createSignalRConnection()
            startSignalRConnection().then(() => {

                subscribeToEvent(`MeetingAgendaChanges${this.meetingId}`, this.handleMeetingAgendaChanges)
            }).catch((err) => {
                console.log('Error establishing SignalR connection:', err)
            })
        },
        beforeDestroy() {
            unsubscribeFromEvent(`MeetingAgendaChanges${this.meetingId}`)
            this.stopTimer()  // Stop the timer when the component is about to be destroyed

        },
        methods: {
            handleMeetingAgendaChanges(newAgendaItems) {
                if (newAgendaItems && newAgendaItems.length > 0 && newAgendaItems[0].meetingId == this.meetingId) {
                    this.agendaItems = newAgendaItems
                    this.$emit('update', newAgendaItems)
                }

            },
            getAgendaIconsColor(agenda) {
                return agenda.isRunning ? 'white' : null
            },

            checkIfVotingOnMeetingAgendaStartedAndHasVoting(index, votingTypeId) {

                return votingTypeId !== VotingTypesEnum.WithoutVoting && this.runningAgendaItemIndex > index ? true : false //&&

            },

            formatDuration(durationSeconds) {
                const minutes = Math.floor(durationSeconds / 60)
                const seconds = durationSeconds % 60
                return `${minutes}:${seconds.toString().padStart(2, '0')}`
            },
            meetingRemainingDuration() {
                if (this.meetingEnd || !this.agendaItems || this.agendaItems.length<1) return '00:00:00'
                var duration = 0
                for (let i = this.runningAgendaItemIndex; i < this.agendaItems.length; i++) {
                    if (!this.agendaItems[i].actualStartDate) duration += this.agendaItems[i].duration * 60
                    else if (!this.agendaItems[i].actualEndDate) duration += this.secondsRemaining

                }
                const hours = Math.floor(duration / (60 * 60))
                duration = duration % (60 * 60)
                const minutes = Math.floor(duration / 60)
                const seconds = duration % 60
                return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
            },
            startTimer() {
                this.stopTimer()
                this.timer = setInterval(() => {
                    if (this.secondsRemaining > 0) {
                        --this.secondsRemaining

                        if (this.secondsRemaining == 0) {
                            this.runningAgendaItemIndex++
                            if (this.runningAgendaItemIndex < this.agendaItems.length) {
                                this.runningAgendaItemId = this.agendaItems[this.runningAgendaItemIndex].id
                            } else {
                                this.runningAgendaItemId = null
                                this.meetingEnd = true
                                if (this.meetingOwner) {
                                    this.getMeetingMinutes()
                                }
                            }
                            if (this.meetingOwner) {
                                this.startNextMeetingAgenda()
                            }
                        }

                    } else {
                        this.runningAgendaItemId = null
                        this.stopTimer()
                    }
                }, 1000)
            },
            stopTimer() {
                if (this.timer) {
                    clearInterval(this.timer)
                    this.timer = null  // Clearing the timer reference
                }
            },
            pauseResumeCurrentAgenda() {
                this.loading = true
                MeetingsService.pauseOrResume(this.meetingId).then((res) => {

                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
                this.stopTimer()

            },
            showSelection(index) {
                var running = this.agendaItems[index].id == this.runningAgendaItemId
                const hasSelectItems = this.agendaItems[index].votingType.votingOptions.length > 0
                const voted = this.agendaItems[index].meetingUserVotes.some(vote => vote.userId === this.loggedInUser?.id)
                return hasSelectItems && !voted && running
            },
            showChart(agenda) {
                return agenda.votingType.votingOptions.length > 0 && this.meetingEnd
            },
            sendVote(agenda, index) {
                this.loading = true
                let formData = {
                    'votingOptionId': this.selectedVoteId,
                    'meetingAgendaId': agenda.id,
                }
                MeetingAgendaService.sendVote(formData).then((res) => {
                    if (res.data) {
                        this.agendaItems[index].meetingUserVotes = res.data
                        this.$root.$notification(this.$t('SendSuccess'), 'success')
                    } else {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            getSelectedVoting(item) {
                const userVote = item.meetingUserVotes.find(vote => vote.userId == this.loggedInUser?.id)
                const selectedVoteOptionId = userVote ? userVote.vottingOptionId : null
                return item.votingType.votingOptions.find(option => option.id === selectedVoteOptionId)?.nameAr
            },
            setSelectionDefault(index) {
                const selectedOption = this.agendaItems[index].votingType.votingOptions.find(option => option.active == true)
                if (selectedOption) {
                    this.agendaItems[index].selectedVoteOption = selectedOption.id
                    this.selectedVoteId = selectedOption.id
                }
            },
            getChartLables(agenda) {
                return agenda.votingType.votingOptions.map(option => this.isArabicLanguage ? option.nameAr : option.nameEn)
            },
            getChartSeries(agenda) {
                const voteCounts = new Map()
                agenda.votingType.votingOptions.forEach(option => {
                    voteCounts.set(option.id, 0)
                })
                agenda.meetingUserVotes.forEach(vote => {
                    if (voteCounts.has(vote.vottingOptionId)) {
                        voteCounts.set(vote.vottingOptionId, voteCounts.get(vote.vottingOptionId) + 1)
                    }
                })
                return agenda.votingType.votingOptions.map(option => voteCounts.get(option.id))
            },
            openAddNoteDialogue(agendaId) {
                this.selectedAgendaId = agendaId
                this.showAddNoteDialogue = true
            },
            openAddRecommendationDialogue(agendaId) {
                this.selectedAgendaId = agendaId
                this.showAddRecommendationDialogue = true
            },
            openMeetingAgendaVotingResultsDialogue(agendaId, agendaTitle) {
                this.selectedAgendaId = agendaId
                this.selectedAgendaTitle = agendaTitle
                this.showMeetingAgendaVotingResultsDialogue = true
            },
            getMeetingMinutes() {
                this.$emit('getMeetingMinutes')
            },
            generateFinalMeetingMinutes() {
                this.$emit('generateFinalMeetingMinutes')

            },
            startNextMeetingAgenda() {
                this.$emit('startNextMeetingAgenda')
            },
            onVoteChange(agenda, option, index) {

                this.loading = true
                let formData = {
                    'votingOptionId': option.id,
                    'meetingAgendaId': agenda.id,
                }
                MeetingAgendaService.sendVote(formData).then((res) => {
                    if (res.data) {
                        this.agendaItems[index].meetingUserVotes = res.data
                        this.$root.$notification(this.$t('SendSuccess'), 'success')
                    } else {
                        this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
                this.loading = true
                this.selectedVoteId = selectedVote
                this.loading = false
            },

        },
    })
</script>
<style scoped>
    .row-color {
        background-color: #2CA69A;
    }

    .bordered {
        border: .5px solid #c3c0c3;
    }

    .custom-card {
        border: 2px solid lightgrey;
        border-radius: 2px;
    }

    .card-text {
        background-color: white;
        height: calc(15vh );
        overflow: hidden;
    }

    .bg-action-selected {
        background-color: var(--v-primary-base) !important;
        color: white;
    }

    .bg-body-selected {
        background-color: #F1F9F8;
        display: flex;
    }

    .bg-body-not-selected {
        display: flex;
    }

    .bg-action-not-selected {
        background-color: #F8F8F8;
        display: flex;
        color: black;
    }

    .finished {
        font-weight: 600
    }
</style>