import { mainApiAxios as axios } from '@/plugins/axios'

export default {

    listThemeColors() {
        return axios.get('systemSettings/theme-colors')
    },

    updateTheme(themeColors) {
        return axios.put('systemSettings/theme-colors', themeColors)
    },

    updateSettings(setting) {
        return axios.put('systemSettings/setting', setting)
    },

    listAll() {
        return axios.get('systemSettings')
    },
    listAllVotingTypes() {
        return axios.get('systemSettings/voting-type')
    },
     addVotingType(formatData) {
         return axios.post('systemSettings/voting-type', formatData)
    },
    updateVotingType(formatData) {
        return axios.put('systemSettings/voting-type', formatData)
    },
    deleteVotingType(votingTypeId) {
        return axios.delete(`systemSettings/${votingTypeId}/voting-type`)
    },
    listAllVotingOptions(votingTypeOptionId) {
        return axios.get(`systemSettings/${votingTypeOptionId}/voting-type-option`)
    },
    addVotingTypeOption(formatData) {
        return axios.post('systemSettings/voting-type-option', formatData)
    },
    deleteVotingTypeOption(votingTypeOptionId) {
        return axios.delete(`systemSettings/${votingTypeOptionId}/voting-type-option`)
    },
    updateVotingTypeOption(formatData) {
        return axios.put('systemSettings/voting-type-option', formatData)
    },
}