import { mainApiAxios as axios } from '@/plugins/axios'

export default {

    getCouncilCommittee(councilCommitteeId) {
        return axios.get(`councilCommittees/${councilCommitteeId}`)
    },

    listCouncilCommittees() {
        return axios.get('councilCommittees')
    },


    listOrganization(onlyActive) {
        return axios.get(`councilCommittees/organization?onlyActive=${onlyActive}`)
    },

    listCommitteeDuties(councilCommitteeId) {
        return axios.get(`councilCommittees/${councilCommitteeId}/duties`)
    },


    addCouncilCommittee(councilCommitteeObj) {
        return axios.post('councilCommittees', councilCommitteeObj)
    },

    updateCouncilCommittee(councilCommitteeId, councilCommitteeObj) {
        return axios.put(`councilCommittees/${councilCommitteeId}`, councilCommitteeObj)
    },

    listUsersInCouncilCommittee(councilCommitteeId) {
        return axios.get(`councilCommittees/${councilCommitteeId}/users`)
    },

    listUsersInCouncilCommitteeGeneralInfo(councilCommitteeId,page,pageSize) {
        return axios.get(`councilCommittees/${councilCommitteeId}/${page}/${pageSize}/users-general-info`)
    },
    listCouncilCommitteeTasks(councilCommitteeId, page, pageSize) {
        return axios.get(`councilCommittees/${councilCommitteeId}/${page}/${pageSize}/tasks`)
    },
    listCouncilCommitteeMeetings(councilCommitteeId, page, pageSize) {
        return axios.get(`councilCommittees/${councilCommitteeId}/${page}/${pageSize}/meetings`)
    },
    listUserCommittees() {
        return axios.get('councilCommittees/user-committees')
    },

    listUserCommitteesForMeeting() {
        return axios.get('councilCommittees/meeting-user-committees')
    },

    removeUserFromCouncilCommittee(councilCommitteeId, userId) {
        return axios.delete(`councilCommittees/${councilCommitteeId}/user/${userId}`)
    },

    removeDuty(dutyId) {
        return axios.delete(`councilCommittees/duty/${dutyId}`)
    },

    addUserToCouncilCommittee(formData) {
        return axios.post('councilCommittees/add-user', formData)
    },

    addCommitteeDuty(councilCommitteeId, dutyObj) {
        return axios.post(`councilCommittees/${councilCommitteeId}/duty`, dutyObj)
    },
    updateCommitteeDuty(committeeDutyId, dutyObj) {
        return axios.put(`councilCommittees/duty/${committeeDutyId}`, dutyObj)
    },
    listUserCouncilsAndCommitteesForGeneralInfo(searchCriteria) {
        return axios.post('councilCommittees/user-committees/general-info',searchCriteria)
    },
    getComitteesByCouncilId(councilCommitteeId) {
        return axios.get(`councilCommittees/${councilCommitteeId}/user-committees`)
    },
    getCommitteesParents(councilCommitteeId) {
        return axios.get(`councilCommittees/${councilCommitteeId}/parents`)
    },
    updateCommitteeUser(councilCommitteeId, formData) {
        return axios.put(`councilCommittees/${councilCommitteeId}/update-user`, formData)
    },
    getUserCommitteesPermissions(councilCommitteeId,userId) {
        return axios.get(`councilCommittees/${councilCommitteeId}/user-permissions/${userId}`)
    },
    getMyCommitteesPermissions(councilCommitteeId) {
        return axios.get(`councilCommittees/${councilCommitteeId}/my-permissions`)
    },
    updatePermission(councilCommitteeId, formData) {
        return axios.put(`councilCommittees/${councilCommitteeId}/user-permissions`, formData)
    },
}