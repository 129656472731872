<template>
    <div>
        <v-card :loading="loading" class="rounded-lg">
            <v-toolbar>
                <h3>  {{ $t("NotRealatedComitteesMeetings") }}</h3>
            </v-toolbar>
            <GridWithServerPaging dense :headers="headers" :items="meetings" class="mt-3" :items-per-page="options.itemsPerPage" :total="totalCount" @onChange="listMeetingsInCouncilCommittee">
                <template v-slot:[`item.date`]="{ item }">
                    {{ item.date| formatDateAr }}
                </template>
                <template v-slot:[`item.title`]="{ item }">
                    {{ item.title| trim(100) }}
                </template>
                <template v-slot:[`item.options`]="{ item }">
                    <router-link v-if="isDraft(item.statusId)" class="ms-2" :to="{ name: 'addMeeting', params: { id: item.id, viewMode: true } }">
                        <v-icon color="primary"> mdi-pencil</v-icon>
                    </router-link>
                    <v-icon v-else color="secondary" class="ms-3" @click="viewMeeting(item)">mdi-eye</v-icon>
                </template>
            </GridWithServerPaging>
            <v-dialog v-if="meetingDialogueOpened" v-model="meetingDialogueOpened" fullscreen hide-overlay persistent>
                <live-meeting :meeting="selectedMeeting" :view-mode="viewMode" @close="meetingDialogueOpened = false" />
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
    import MeetingsService from '@/services/MeetingsService.js'
    import LiveMeeting from '@/components/app/meeting/MeetingDashboard.vue'
    import { MeetingStatusEnum } from '@/helpers/constants/enumerations'
    import GridWithServerPaging from '@/components/controls/GridWithServerPaging.vue'

    export default ({
        components: {
            LiveMeeting, GridWithServerPaging
        },
        data() {
            return {
                loading: false,
                viewMode: true,
                meetingDialogueOpened: false,
                selectedMeeting: null,
                headers: [
                    { text: this.$t('Id'), value: 'id' },
                    { text: this.$t('Title'), value: 'title' },
                    { text: this.$t('Status'), value: 'statusName' },
                    { text: this.$t('MeetingRoom'), value: 'location' },
                    { text: this.$t('ReferenceNumber'), value: 'referenceNumber' },
                    { text: this.$t('Date'), value: 'date' },
                    { text: this.$t('Options'), value: 'options' },
                ],
                meetings: [],
                selectedNote: null,
                options: {
                    page: 1, itemsPerPage: 10
                },
                totalCount: 0
            }
        },

        created() {
        },

        mounted() {
            this.listMeetingsInCouncilCommittee()
        },
        methods: {
            isDraft(status) {

                return status == MeetingStatusEnum.Draft
            },
            listMeetingsInCouncilCommittee(options) {
                const page = options != undefined ? options.page : this.options.page
                const pageSize = options != undefined ? options.itemsPerPage : this.options.itemsPerPage
                let formData = {
                    statusId: null,
                    committeeId: null,
                    meetingId: null,
                    fromDate: null,
                    toDate: null,
                    location: null,
                    title: null,
                    onlyMyMeetings: false,
                    noComitteeRelated: true,
                    includeDrafts: true,
                }
                this.loading = true
                MeetingsService.searchUserMeetings(formData, page, pageSize).then((res) => {
                    if (res.data) {
                        this.meetings = res.data.data
                        this.totalCount = res.data.total

                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false
                })
            },
            viewMeeting(meeting) {
                this.selectedMeeting = meeting
                this.meetingDialogueOpened = true

            }
        },
    })
</script>

<style scoped>
    .elevated-table {
        border: 1px solid #000000; /* Border around the table */
    }
</style>