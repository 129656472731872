<template>
    <div>
        <v-layout v-if="validateUserLoading" column>
            <v-progress-circular :rotate="360"
                                 size="50"
                                 width="8"
                                 indeterminate
                                 color="primary">
            </v-progress-circular>
            <span class="loading-text">{{ $t('ValidatingUser') }}</span>
        </v-layout>
        <v-layout v-else-if="showCompleteInfoForm" column>
            <div class="grey--text mb-2">
                {{ $t("CompleteMyInfo") }}
            </div>
            <v-text-field v-model="user.fullNameAr"
                          :label="$t('FullNameAr')"
                          outlined color="primary"
                          autocomplete="false"
                          prepend-inner-icon="mdi-account"
                          :rules="cleanRequiredStringRules" />
            <v-text-field v-model="user.fullNameEn"
                          :label="$t('FullNameEn')"
                          outlined color="primary"
                          autocomplete="false"
                          prepend-inner-icon="mdi-account"
                          :rules="cleanRequiredStringRules" />
            <v-text-field v-model="user.email"
                          :label="$t('Email')"
                          outlined color="primary"
                          autocomplete="false"
                          prepend-inner-icon="mdi-email"
                          :rules="cleanRequiredStringRules" />
            <v-text-field v-model="user.mobile"
                          :label="$t('Mobile')"
                          outlined color="primary"
                          autocomplete="false"
                          prepend-inner-icon="mdi-phone"
                          :rules="cleanRequiredStringRules" />

            <v-btn class="white--text"
                   color="primary"
                   block
                   :disabled="disableUpdate"
                   @click="completeUserData">
                {{ $t("Update") }}
            </v-btn>

        </v-layout>
        <v-layout v-else-if="showLoginError" column>
            <div class="text-center mt-2">
                <img src="@/assets/images/locker.png" alt="" class="mb-0 logo">
            </div>
            <h3 class="tile text-center">
                {{ $t("InvalidExternalLogin") }}
            </h3>
        </v-layout>
    </div>
</template>
<script>
    import MoiService from '@/services/MoiServices'
    import { createSignalRConnection, startSignalRConnection } from '@/plugins/hub/myHubPlugin.js'

    export default {
        props: {
            tokenName: {
                type: String,
                default: null
            },
        },
        data() {
            return {
                user: {
                    fullNameAr: '',
                    fullNameEn: '',
                    email: '',
                    mobile: '',
                    accessToken: ''
                },
                loading: false,
                validateUserLoading: false,
                showCompleteInfoForm: false,
                showLoginError: false,
                cleanRequiredStringRules: [this.$rules.requiredRule, this.$rules.onlyAlphanumericRule],
                requiredMobileRules: [this.$rules.requiredRule, this.$rules.phoneNumberRule],

            }
        },

        computed: {
            disableUpdate() {
                return this.loading || !this.user.fullNameAr || !this.user.fullNameEn || !this.user.email || !this.user.mobile
            }
        },
        created() {
            this.validateUser()
        },
        methods: {
            validateUser() {

                this.validateUserLoading = true
                this.user.accessToken = this.readTokenFromCookies()
                if (!this.tokenName||!this.user.accessToken) {
                    this.showLoginError = true
                    this.validateUserLoading = false
                    return
                }

                MoiService.authAbsherUser(this.user.accessToken).then(async (response) => {
                    if (response.success) {
                        await this.$store.dispatch('user/setUserSession', response.data)
                        await this.$store.dispatch('app/fetch_options')
                        createSignalRConnection()
                        await startSignalRConnection()
                        this.$router.push({ name: 'home' })


                    } else {
                        if (response.data.user) {
                            this.user.fullNameAr = response.data.user.fullNameAr
                            this.user.fullNameEn = response.data.user.fullNameEn
                            this.user.email = response.data.user.email
                            this.user.mobile = response.data.user.mobile

                        }
                        this.showCompleteInfoForm = true
                    }
                }).catch(() => {
                    this.showLoginError = false
                }).finally(() => {
                    this.validateUserLoading = false

                })
            },
            completeUserData() {

                MoiService.completeUserData(this.user).then(async (response) => {
                    if (response.success) {
                        await this.$store.dispatch('user/setUserSession', response.data)
                        await this.$store.dispatch('app/fetch_options')
                        window.location.href = '/'


                    } else {
                        if (response.data.user) {
                            this.user.fullNameAr = response.data.user.fullNameAr
                            this.user.fullNameEn = response.data.user.fullNameEn
                            this.user.email = response.data.user.email
                            this.user.mobile = response.data.user.mobile

                        }
                        this.authorized = true
                    }
                }).catch(() => {
                    this.$root.$notification(this.$t('ErrorOccurred'), 'error')
                }).finally(() => {
                    this.loading = false

                })
            },
            readTokenFromCookies() {
                let cookies = document.cookie.split(';')
                for (let cookie of cookies) {
                    const [name, value] = cookie.trim().split('=')
                    if (name === this.tokenName) {
                        return value
                    }
                }
                return null
            }

        }
    }
</script>
<style scoped>

    .login-main-container {
        height: 100%;
        overflow: hidden;
        width: 100%;
        background: linear-gradient(90deg, var(--v-primary-base) 0%, var(--v-primary-base) 35%, lightgrey);
    }

    .login-center-container {
        width: auto;
        margin: 0 auto;
        max-width: 400px
    }

    .logo {
        max-height: 175px
    }
</style>