<template>
    <div>
        <v-toolbar class="flex-grow-0" color="primary" dark dense flat>
            <v-toolbar-title color="white--text" class="flex-shrink-1 mt-2" style="max-width: 70%;">
                {{ $t('Meetings') }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn dark icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card :height="550">
            <BarChart v-if="meetingsSeries" :labels="meetingStatusesLabels" :show-bottom-labels="showBottomLabels" :title="$t('Meetings')" :height="450" :series="meetingsSeries" />
        </v-card>
    </div>
</template>

<script lang="ts">
    import BarChart from '@/components/app/charts/Bar'
    import ReportsService from '@/services/ReportsService.js'

    export default({
        components: {
            BarChart
        },
        props: {
            committeeId: {
                type: Number,
                default: null
            },
        },
        data() {
            return {
                meetingStatusesLabels: [
                ],
                meetingsSeries: null,
            }
        },
        watch: {
        },
        
        created() {
            if (!this.committeeId)return
            this.loading = true
            ReportsService.getComitteesMeetingsDetails(this.committeeId).then((res) => {
                if (res.data) {
                    this.meetingStatusesLabels = res.data.labels
                    this.meetingsSeries = res.data.series
                }

            }).catch(() => {
                this.$root.$notification(this.$t('ErrorOccurred'), 'error')
            }).finally(() => {
                this.loading = false
            })
        },

        methods: {
            close() {
                this.$emit('close')
            }
        },
    })
</script>

<style>
</style>